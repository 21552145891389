<template>
  <div
    class="modal modal--confirmation"
    :class="{ darkmode: this.$store.state.darkmode }"
  >
    <header class="modal-header" id="warning">
      <slot name="header">
        <h3>
          <span v-if="modalTitle"> {{ modalTitle }}</span>
          <span v-else>Warning</span>
        </h3>
      </slot>
    </header>

    <section class="modal-body">
      <slot name="body">
        <div class="confirmation-body">
          {{ message }}
          <div class="form-validation">
            <div
              v-if="form"
              class="credentials"
              :class="{ groupless: !form.groups }"
            >
              <span>
                First Name: <b>{{ form.firstName }}</b>
              </span>
              <span>
                Last Name: <b>{{ form.lastName }}</b>
              </span>
              <span>
                Email: <b>{{ form.email }}</b>
              </span>
              <span v-if="adminLevel.adminLevelInt==0 &&form.loginID!=''">
                LoginID:<b>{{ form.loginID }}</b>
              </span>
            </div>
            <div class="sheet" v-if="sheet">
              <span v-for="(user, index) of workbook" :key="index">
                LoginID: <b>{{ user.loginID }}</b
                ><br />

                First Name: <b>{{ user.firstName }}</b
                ><br />

                Last Name: <b>{{ user.lastName }}</b
                ><br />

                Email: <b>{{ user.email }}</b
                ><br />

                Password: <b>{{ user.password }}</b
                ><br />
                <br />
              </span>
            </div>
            <div class="groups" v-if="groups && Object.keys(adminGroups).length>1">
              <span v-for="(group, index) of groups" :key="index">
                <!-- <span v-if="group.wasMember || group.isMember"> -->
                <span v-if="group.changed">
                  <u><b>{{ $store.state.groups[index] || `Group ${index}` }}</b></u>
                  <br />
                  {{getGroupChanges(group)}}
                  <br />
                  <!-- Member: <b> {{ group.isMember }} </b> <br />
                  Primary Group: <b> {{ group.isPrimaryGroup }} </b> <br />
                  Administrator: <b> {{ group.isAdmin }} </b> <br />
                  Learner: <b> {{ group.isLearner }} </b> <br /> -->
                  <br />
                </span>
              </span>
            </div>
            <div class="unenroll" v-if="enrollment && unenrollMany && unenrollMany.length === 0">
              <span>
                Are you sure you want to unenroll <b>{{enrollment.loginID}}</b> from <b>{{enrollment.contentName}}</b>? 
              </span>
            </div>
            <div class="unenroll-many" v-if="unenrollMany && unenrollMany.length && unenrollMany.length != 0">
              <p class="unenroll-many-text">
              These Learners have started <b>{{unenrollMany[0].contentName}}</b>, are you sure you would like to unenroll them?
              </p>
              <div class="unenroll-many-list" v-for="(user, index) of unenrollMany" :key="index">
                <input class="user-input" :checked="user.checked" type="checkbox" @click="addToUnenroll(user)">
                <b class="user-login">{{user.loginID}}</b>
              </div> 
            </div>
          
          </div>
        </div>

        <div>
          <div class="form-validation" v-if="orgForm">
            <span>
              Organization Name: <b>{{ orgForm.name }}</b>
            </span>
          </div>
        </div>
      </slot>
    </section>

    <footer class="modal-footer">
      <slot name="footer">
        <button type="button" class="btn-danger" @click="cancel">
          Cancel
        </button>
        <button type="button" disabled class="btn-blue" style="background-color:gray;" v-if="loading" @click="confirm">
              <Spinner2 class="spinner" />
        </button>
        <button type="button" class="btn-apply" v-if="!loading" @click="confirm">
        Confirm
        </button>
      </slot>
    </footer>
  </div>
</template>

<script>
import Spinner2 from "../Spinner2.vue";
import { mapState } from "vuex";

export default {
  name: "Confirmation",
  components:{
    Spinner2,
  },
  props: {
    message: String,
    form: Object,
    groups: Object,
    sheet: Object,
    orgForm: Object,
    enrollment: Object,
    unenrollMany: Array, 
    loading: Boolean,
    modalTitle: String,
  },
  data() {
   return {
     confirmedUnenrollMany: [],
   }
  },
  methods: {
    getGroupChanges(group){
      var statusStr="";
      if(group.isMember && !group.wasMember)
      {
        statusStr="Registered";
        if(group.isPrimaryGroup)
        {
          statusStr+=" in their primary group";
        }
        
        if(group.isAdmin)
        {
          // registered as an admin
          statusStr+=" as an Administrator";
        }
      }
      else if (!group.isMember && group.wasMember){
        // removed
        statusStr="Unregistered";
      }
      else{
        statusStr="Modified";
      }
      return statusStr;
    },
    cancel() { 
      this.$emit("cancel");
      this.confirmedUnenrollMany = [];
    },
    confirm() {
      if(this.unenrollMany)
      {
      var confirmedUnenroll=this.unenrollMany.filter((user)=> user.checked==true);
      this.$emit("confirm", confirmedUnenroll);
      this.confirmedUnenrollMany = [];
      } else{
        this.$emit("confirm");
      }
    },
    addToUnenroll(user) {
      if(user.checked)
      {
        user.checked=!user.checked;
      }
      else{
        user.checked=true;
      }
      this.conf
    },
  },
  computed: {
    ...mapState({
      adminLevel:(state)=>state.adminLevel,
      adminGroups:(state)=> state.groups,
    }),
    //File import parse
    workbook: function() {
      if (this.sheet["!ref"]) {
        let end = this.sheet["!ref"].split("E")[1];
        let arr = [];
        for (let x = 2; x <= end; x++) {
          arr.push({
            loginID: this.sheet[`D${x}`]["v"],
            firstName: this.sheet[`A${x}`]["v"],
            lastName: this.sheet[`B${x}`]["v"],
            email: this.sheet[`C${x}`]["v"],
            password: this.sheet[`E${x}`]["v"],
          });
        }
        return arr;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/Confirmation.scss";
.unenroll-many-list {
  margin: auto;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
