<template>
    <div class="fourth">
        <h4>Enroll</h4>
        <p>
        Would you like to enroll {{this.$store.state.firstName}} {{this.$store.state.lastName}} in a course? 
        </p>
        <p>
        You can always do this later. 
        </p>
    </div>
</template>
<script>
export default {
    name: 'FourthStep',
    components: {
    },
}
</script>
<style scoped>
@import './msFormStyles/msForm.scss';
</style>