<template>
<div class="sahara-mitigation-container">
  <div id="side-menu"><SideMenu /></div>
  <div id="navbar-container">
    <Navbar />

  <div class="sahara-mitigation">
    <div class="da-header">
      <!-- Used to make the title appear in the centers -->
      <!-- <div class="left-title"> </div>  -->

      <!-- <div class="sahara-image">
      <img style="height:50px;" src="../assets/sahara_icon_normal.png" >
      <img style="height:85px; padding-right: 40px;" src="../assets/Sahara Logo Horizontal.png" >
    </div> -->
    </div>
    <div class="title">
        <font-awesome-icon class="title-icon" :icon="['fas', 'book-open']" />
        <!-- <img class="title-icon" src="../assets/wellness.png" > -->
        SAHARA Mitigation Recommendations
    </div>
    <Tabs id="tabs" class="tabs">
      <!-- <Tab name="Hazards Graph" selected="true">
        <div class="content"></div>
      </Tab> -->

      <!-- <Tab id="tab" name="Mitigation Recommendations" selected="true"> -->
      <Tab id="tab" name="" selected="true">
        <div class="content">

          <div class="courses-table">
            <div class="filters">
              <div class="search-bar">
              <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />

                <input
                type="search"
                placeholder="Search for Course"
                class="search-input"
                v-model="courseSearch"
                v-on:input="searchCourse"
                id='searchRecords'
              />
              </div>

            </div>
            <div class="table">
              <table class="sortable">
                <thead>
                  <tr>
                    <th class="course-header" colspan="1">Courses<div style="float:right;"><font-awesome-icon @click="sortClicked(0)" class="sort-icon" :style="{'color': sortSelected==0? 'rgb(251, 143, 6)':'white'}" :icon="['fas', courseIcon]" /></div></th>
                    <th class="course-header">%<div style="float:right;"><font-awesome-icon @click="sortClicked(1)" class="sort-icon" :style="{'color': sortSelected==1? 'rgb(251, 143, 6)':'white'}"   :icon="['fas', percentIcon]" /></div></th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="course in displayCourses" :key="course.saharaCourseID+course.contentID" @click="clickSaharaCourse(course)" :class="{'highlight': (course.contentID == selectedCourseID)}">
                      <td class="course-names">{{course.courseName}}</td>
                      <td class="percentage">{{course.percentageUsed | formatPercentage}}</td>
                    </tr>
                </tbody>
              </table>
              <div v-if="coursesLoading" class="loading-spinner"><Spinner/></div>
            </div>
          </div>
           
<!--

          <div class="hazards-table" >
            <div class="filters">
        <div style="height: 36.22px;"></div>
            </div>
            <div class="table">
              <table style="border: none;">
                 
            <table class="generic-header" v-if="selectedCourse.genericCourse && selectedCourse.genericCourse==true">
                <tr >
                  <th colspan="2" style="border:none;" class="inner-header" > &zwnj; </th>
                </tr>
                <tr>
                  <td style="border-left:none;border-right:none;">This is a manditory course</td>
                </tr>
            </table>


           <table class="generic-header" v-if="selectedCourse.employerCourse && selectedCourse.employerCourse==true">
                <tr >
                  <th colspan="2" style="border:none;" class="inner-header" >&zwnj; </th>
                </tr>
                  <tr>
                  <td style="border-left:none;border-right:none;">This is a employer/supervisor manditory course</td>
                </tr>
            </table>


                <tbody v-if="selectedCourse.linkedHazards ||(selectedCourse.linkedToMentalWellness && selectedCourse.linkedToMentalWellness==true)" class="inner-body">
                    <tr class="inner-header">
                    <th colspan="1">Associated Hazards</th>
                      <th >Risk</th>
                    </tr>
                    <tr v-if="selectedCourse.linkedToMentalWellness && selectedCourse.linkedToMentalWellness==true" >
                      <td colspan="1" >Hazards associated with personal wellness self assessment</td>
                      <td>N/A</td>
                    </tr>
                    <tr v-for="hazard in selectedCourse.linkedHazards" :key="hazard.hazardName+hazard.riskValue">
                     <td class="hazard-name">{{hazard.hazardName}}</td> 
                     <td class="risk-level" v-bind:class="getClass(hazard.hazardRisk)" >{{hazard.hazardRisk}}</td>
                    </tr>
                  </tbody>


                  <tbody v-if="selectedCourse.linkedCategories" class="inner-body">
                     <tr class="inner-header">
                    <th colspan="2">Associated Categories</th>
                    </tr>
                    <tr v-for="category in selectedCourse.linkedCategories" :key="category.categoryName">
                        <td colspan="2">{{category.categoryName}}</td>
                    </tr>
                </tbody>

                <tbody v-if="selectedCourse.linkedEnvironments" class="inner-body">
                     <tr class="inner-header">
                    <th colspan="2">Associated Environments</th>
                    </tr>
                    <tr v-for="environment in selectedCourse.linkedEnvironments" :key="environment.environmentID">
                        <td colspan="2">{{environment.environmentName}}</td>
                    </tr>
                </tbody>

                <tbody v-if="selectedCourse.linkedIndustries" class="inner-body">
                     <tr class="inner-header">
                    <th colspan="2">Associated Industries</th>
                    </tr>
                    <tr v-for="industry in selectedCourse.linkedIndustries" :key="industry.industryID">
                        <td colspan="2">{{industry.industryName}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div> 

        -->

          <div class="learners-table" v-if="true">

                <MasteryTableV2 
                :hideFilterOptions=true
                :hideCourseScores="true"                
                :selectedCourse="selectedCourse.contentID?selectedCourse.contentID.toString():null"
                />
                </div>

        </div>

      </Tab>
    </Tabs>
    <ErrorMessageModal 
        v-show="showErrorMessage"
        :errorTitle="errorMsgTitle"
        :errorBody="errorMsgBody"
        @close="closeErrorModal"
        />
  </div>
</div>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import Tab from "../components/Tab.vue";
import Tabs from "../components/Tabs.vue";
// import Spinner2 from "../components/Spinner2.vue";
import TreeBrowser from "../components/TreeBrowser.vue";
// import Tree from "../components/Tree.vue";
import Spinner from "../components/Spinner.vue";
import ErrorMessageModal from "../components/modals/ErrorMessage.vue";
import MasteryTableV2 from '../components/MasteryTableV2.vue';


export default {
  name: "SAHARA_Mitigation",
  components: {
    Navbar,
    SideMenu,
    Tab,
    Tabs,
    Spinner,
    // Tree,
    TreeBrowser,
    ErrorMessageModal,
    MasteryTableV2,
  },
    data() {
    return {
      allCourses:[],
      fetch: 10, 
      offset: 0,
      selectedCourse:{},
      selectedCourseID:null,
      hazardsLoading:false,
      courseSearch:'',
      coursesLoading:true,
      showNoCourse:false,
      sortSelected:0,
      descending:true,
      showErrorMessage:false,
      errorMsgBody:"",
      errorMsgTitle:"",
    };
  },
  created () {
    this.getCourseList();
    // this.getGroupTree();
    // window.addEventListener('resize',this.resizeHandler);
  },
  destroyed(){
    // window.removeEventListener('resize',this.resizeHandler)
  },

  mounted(){
  },

filters:{
    formatPercentage: function(value)
    {
      return value.toFixed(1).toString()+"%";
    },
  },

  computed:{
    courseIcon()
    {
      if(this.sortSelected==0)
      {
        if(this.descending)
        {
          return 'sort-down';
        }
        return 'sort-up';
      }
      return 'sort';
    },
    percentIcon()
    {
      if(this.sortSelected==1)
      {
        if(this.descending)
        {
          return 'sort-down';
        }
        return 'sort-up';
      }
      return 'sort';
    },


    displayCourses(){

      return this.allCourses.filter(course=> course.courseName.toLowerCase().includes(this.courseSearch.toLowerCase())).sort((a,b)=>{
        if(this.sortSelected==0)
        {
          if(this.descending)
          {
            return a.courseName.toLowerCase().localeCompare(b.courseName.toLowerCase());
          }
            return b.courseName.toLowerCase().localeCompare(a.courseName.toLowerCase());
        }
        if(this.sortSelected==1)
        {
          if(this.descending)
          {
            return b.percentageUsed - a.percentageUsed;
          }
            return a.percentageUsed - b.percentageUsed;
        }
      });
    },
    // rowHeight(){
    //   return newHeight=>{
    //     var row=this.$refs.courseRow;
    //       if(row)
    //       {
    //         var found= row.find(function(row){
    //         if(row.innerText==newHeight.hazardName&& newHeight.linkedCourses.length==1)
    //         {
    //           return row
    //         }
    //       })
    //       if(found)
    //       {
    //       return found.clientHeight.toString() +'px';
    //       }
    //       else{
    //         return "100%";
    //       }
    //     }
    //   }
    // },

    //   rowHeightHead(){
    //   return newHeight=>{
    //     var rowHead=this.$refs.courseRowHead;
    //       if(rowHead)
    //       {
    //         var found= rowHead.find(function(rowHead){
    //         if(rowHead.innerText==newHeight.categoryName&& newHeight.categoryCourses.length==1)
    //         {
    //           return rowHead
    //         }
    //       })
    //       if(found)
    //       {
    //       return found.clientHeight.toString() +'px';
    //       }
    //       else{
    //         return "100%";
    //       }
    //     }
    //   }
    // },
  },

  methods: {
    closeErrorModal()
    {
      this.showErrorMessage=false;
      this.errorMsgBody="";
      this.errorMsgTitle="";
    },

    sortClicked(option)
    {
      if(this.sortSelected==option)
      {
        this.descending=!this.descending;
      }
      else{
        this.descending=true;
      }
      this.sortSelected=option;
    },
    closeModals()
    {
      this.groupsModalVisible=false;
    },
    // setStartDate(date) {
    //   this.startDate=date;
    //   this.currentTablePage=1;
    //   this.getRecordList();
    // },
    // setEndDate(date) {
    //   this.endDate=date;
    //   if(this.startDate && this.startDate>date)
    //   {
    //     this.startDate=date;
    //   }
    //   this.currentTablePage=1;
    //   this.getRecordList();
    // },

    getClass(dat)
    {
      if(dat==="LOW")
      {
        return 'low-class';
      }
      if(dat==='MEDIUM')
      {
        return 'medium-class'
      }
      if(dat==='HIGH')
      {
        return 'high-class'
      }
    },

    resizeHandler()//? Get the columns to resize when the page is resized or brought to a larger screen
    {
      // this.calculateWidth();
    },

    searchCourse()
    {
      // this.currentTablePage=1;
      // this.getRecordList();
    },

    getCourseList()
    {
      this.coursesLoading=true;
      this.showNoCourse=false;
      this.allCourses=[];


      let courseObj={
        courseName:"",
        allResults:"false"
      }

      this.$store.dispatch("getSaharaCourses", courseObj)
      .then((result) => {
        if(result.status==='success') 
        {
          this.allCourses=result.results;
          this.coursesLoading=false;
          if(result.results && result.results.length==0)
          {
            this.showNoCourse=true;
          }
          else{
            this.showNoCourse=false;
          }
        }
        else{
          this.errorMsgTitle="Course Error";
          this.errorMsgBody="Failed to get SAHARA courses. Please reload or log in again. If this problem persists please contact us.";
          this.showErrorMessage=true;
        }
      });
    },
    calculateWidth()
    {
      // this.hazardsLoading=true;
      //   setTimeout(()=>{
      //     this.hazardsLoading=false;
      //   },5)
    },

    clickSaharaCourse(course)
    {
      this.selectedCourse=course;
      this.selectedCourseID=course.contentID;
    },

  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/SaharaMitigation.scss";
</style>