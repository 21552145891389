/**
 * This function only returns an array of all the course names only
 * @param {Array} arr
 *    an array of all the objects containing all the information about the courses
 *    each object should have a contentname:
 *    [
 *      { ...
 *        contentname: (...)
 *        ...
 *      }
 *    ]
 * @returns an array of strings with '%20' parsed out
 */

//stripCourseName not being used
const stripCourseNameOnly = function(arr) {
  const result = [];

  for (const obj of arr) {
    const courseName = obj.contentname;
    if (courseName.includes("%20")) {
      courseName.replaceAll("%20", " ");
    }
    result.push(courseName);
  }

  return result;
};

/**
 * This is a cutom built alphabetical sorting function for getCourseList
 * @param {Object} a first object in the array
 * @param {Object} b second object in the array
 * @returns integer for the .sort built in method from JS to sort strings
 */

// const sortCourseNameAlphabetical = function(a, b, key) {
//   if (a.contentName < b.contentName) {
//     return -1;
//   }
//   if (a.contentName > b.contentName) {
//     return 1;
//   }
//   return 0;
// };
// const sortGroupNameAlphabetical = function(a, b) {
//   if (a.groupName < b.groupName) {
//     return -1;
//   }
//   if (a.groupName > b.groupName) {
//     return 1;
//   }
//   return 0;
// };

// The function below relies on the name being the second value in the object.

// const sortNameAlphabetical = function(firstItem, secondItem) {
//   if (
//     firstItem[Object.keys(firstItem)[1]] <
//     secondItem[Object.keys(secondItem)[1]]
//   ) {
//     return -1;
//   }
//   if (
//     firstItem[Object.keys(firstItem)[1]] <
//     secondItem[Object.keys(secondItem)[1]]
//   ) {
//     return 1;
//   }
//   return 0;
// };

/**
 * This is a cutom built alphabetical sorting function for getCourseList
 * @param {Array} arr Array of objects to be sorted
 * @returns Array in alphabetical order of course name
 */

const sortNameAlphabetical = function(arr) {
  let a = [];
  let c = [];
  let b = {};
  for (let i of arr) {
    b[i["courseName"]] = i["courseID"];
    a.push(i["courseName"]);
  }
  a.sort();
  for (let j of a) {
    c.push({ courseID: b[j], courseName: j });
  }
  return c;
};

const sortObjByKey = function(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((newObj, key) => ((newObj[key] = obj[key]), newObj), {});
};

module.exports = {
  stripCourseNameOnly,
  // sortCourseNameAlphabetical,
  // sortGroupNameAlphabetical,
  sortNameAlphabetical,
  sortObjByKey,
};
