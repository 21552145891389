<template>
    <!-- <div
      class="modal modal--confirmation"
      :class="{ darkmode: this.$store.state.darkmode }"
    > -->
    <div class="modal-backdrop">

    <div class="modal ">
    <header class="modal-header">
      <slot name="header">
        <h3>
          Change Language
        </h3>
      </slot>
    </header>
    <input
        class="modal-search-bar"
        type="text"
        placeholder="Search for Language"
        v-model="searchForLanguage"
      />
    <section
      class="modal-body"
      :class="{ darkmode: this.$store.state.darkmode }"
    >
      <slot name="body">
    <!-- <Translator class="language-select" /> -->
    <Translator class="language-select" @on-country-click="close()" :countries="filteredLanguages"/>
    <!-- <Translator class="language-select" @on-country-click="close()" :countries="filteredLanguages"/> -->
     </slot>
     </section>
     <footer class="modal-footer">
      <slot name="footer">
        <button type="button" class="btn-blue" @click="close()">
          Close 
        </button>

        <!-- <button type="button" class="btn-danger" @click="test">
          test
        </button> -->
      </slot>
    </footer>
    </div> 
    </div>
  </template>
  
  <script>
import {Translator} from "vue-google-translate";
// import "vue-gtranslate/translator.css"
  import { mapState } from "vuex";
  
  export default {
    name: "ChangeLanguage",
    components: {
    Translator,
  },
  created: function(){

  },
    props: {
    },
    data() {
     return {
      searchForLanguage:"",
      languageList:[
    {
    code: 'en|en',
    title: 'English',
  },
  {
    code: 'en|fr',
    title: 'French',
  },
   {
    code: 'en|af',
    title: 'Afrikaans',
  },
  {
    code: 'en|sq',
    title: 'Albanian',
  },
  {
    code: 'en|ar',
    title: 'Arabic',
  },
  {
    code: 'en|hy',
    title: 'Armenian',
  },
  {
    code: 'en|az',
    title: 'Azerbaijani',
  },
  {
    code: 'en|eu',
    title: 'Basque',
  },
  {
    code: 'en|be',
    title: 'Belarusian',
  },
  {
    code: 'en|bg',
    title: 'Bulgarian',
  },
  {
    code: 'en|ca',
    title: 'Catalan',
  },
  {
    code: 'en|zh-CN',
    title: 'Chinese (Simplified)',
  },
  {
    code: 'en|zh-TW',
    title: 'Chinese (Traditional)',
  },
  {
    code: 'en|hr',
    title: 'Croatian',
  },
  {
    code: 'en|cs',
    title: 'Czech',
  },

  {
    code: 'en|da',
    title: 'Danish',
  },
  {
    code: 'en|nl',
    title: 'Dutch',
  },

  {
    code: 'en|et',
    title: 'Estonian',
  },
  {
    code: 'en|tl',
    title: 'Filipino',
  },
  {
    code: 'en|fi',
    title: 'Finnish',
  },


  {
    code: 'en|de',
    title: 'German',
  },
  {
    code: 'en|el',
    title: 'Greek',
  },
  {
    code: 'en|hu',
    title: 'Hungarian',
  },
  {
    code: 'en|id',
    title: 'Indonesian',
  },
  {
    code: 'en|ga',
    title: 'Irish',
  },
  {
    code: 'en|it',
    title: 'Italian',
  },
  {
    code: 'en|ja',
    title: 'Japanese',
  },
  {
    code: 'en|ko',
    title: 'Korean',
  },
  {
    code: 'en|lt',
    title: 'Lithuanian',
  },
  {
    code: 'en|ms',
    title: 'Malay',
  },
  {
    code: 'en|no',
    title: 'Norwegian',
  },
  {
    code: 'en|pl',
    title: 'Polish',
  },
  {
    code: 'en|pt',
    title: 'Portuguese',
  },
  {
    code: 'en|ro',
    title: 'Romanian',
    flagIconUrl: 'https://cdn.jsdelivr.net/gh/lewis-kori/vue-google-translate@0.0.10/src/assets/images/flags/__Romanian.png'
  },
  {
    code: 'en|ru',
    title: 'Russian',
  },
  {
    code: 'en|es',
    title: 'Spanish',
  },
  {
    code: 'en|sv',
    title: 'Swedish',
  },
  {
    code: 'en|th',
    title: 'Thai',
  },
  {
    code: 'en|tr',
    title: 'Turkish',
  },
  {
    code: 'en|uk',
    title: 'Ukrainian',
  },
],
     }
    },
    methods: {
      close() {
        // var lang=document.querySelector('html').getAttribute('lang');
          this.$emit("close");
      },
      //   getLanguage(){
      //   // var lang=document.querySelector('html').getAttribute('lang');
      //   var langu=document.querySelector('html').lang;
      //   var foundlang = this.languageList.find((language) =>{
      //     return language.code.split('|')[1].toLowerCase().match(langu.toLowerCase());
      //   })
      // if(foundlang && foundlang.title)
      // {
      //   return foundlang.title;
      // }
      // return 'English';
      // }
    },
    computed: {
      ...mapState({
        // adminLevel:(state)=>state.adminLevel,
        // adminGroups:(state)=> state.groups,
        // languageList:(state)=> state.languageList,
      }),
      filteredLanguages: function()
      {
        // console.log('languageList is',this.languageList)
        // const values = Object.values(this.languageList);
        // console.log('The values are',values)

        return this.languageList.filter((language) => {
        return language.title.toLowerCase().match(this.searchForLanguage.toLowerCase());
      });
      },
      // initialLanguage(){
      //   var lang=document.querySelector('html').getAttribute('lang');
      //   var foundlang = this.languageList.find((language) =>{
      //     return language.code.split('|')[1].toLowerCase().match(lang.toLowerCase());
      //   })
      //   var languageFound=''
      // if(foundlang && foundlang.title)
      // {
      //   languageFound=foundlang.title;
      // }
      // languageFound='English';
      // this.$emit('initialLanguage',languageFound)
      // return languageFound;

      // }
    },
  };
  </script>
  


  <style lang="scss" scoped>

  @import "../styles/ChangeLanguage.scss";

  @import "../styles/BasicModal.scss";
  @import "../styles/Buttons.scss";

  </style>
  

  <style>
  .grid{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    background: lightgrey;
  } 
  </style>