<template>
    <div v-bind:class = "(shorten)?'date-pickers-horizontal':'date-pickers'">
    <DatePicker
        :selected="selectedStart"
        @updatedDate="updateStartDate"
        :maxDate="maxStartDate"
        ref="resetStartDate"
        :short="shorten"
    >
        {{startText}}
    </DatePicker>

    <DatePicker
        :selected="selectedEnd"
        @updatedDate="updateEndDate"
        :maxDate="maxEndDate"
        ref="resetEndDate"
        :short="shorten"
    >
        {{ endText }}
    </DatePicker>
    </div>
  </template>
  
  <script>
import DatePicker from "./DatePicker.vue";

  export default {
    name: "DateRange",
    components:{
        DatePicker
    },
    props:{
        shorten:{
            default:false,
            type: Boolean,
        }
    },
    watch:{
      maxDate: function(newVal){
        if(newVal<this.selectedDate)
        {
          this.selectedDate=newVal;
        }
      }
    },
    data() {
      return {
        selectedStart:"",
        selectedEnd: "",
      };
    },
    computed:{
        startText(){
          return this.shorten?'Start:':'Starting';
        },
        endText(){
          return this.shorten?'End:':'Ending';
        },
        maxEndDate()
        {
                return formatDate(new Date());

        },
        maxStartDate()
        {
            if(!this.selectedEnd)
            {
                return getDayBefore(this.maxEndDate);
            }
            else{
                if(this.selectedStart>this.selectedEnd)
                {
                    this.$refs.resetStartDate.resetDate();
                }
                return getDayBefore(this.selectedEnd);
            }
        }
    },
    methods:{
      updateStartDate(startDat)
      {
        this.selectedStart=startDat;
        this.$emit('updateStartDate', startDat)

      },
      updateEndDate(endDat)
      {
        this.selectedEnd=endDat;
        this.$emit('updateEndDate', endDat)
        if(endDat>this.startDat)
        {
          this.$refs.resetStartDate.resetDate();
        }
      },

      resetDate() {
        this.$refs.resetStartDate.resetDate();
        this.$refs.resetEndDate.resetDate();
      },
  
    }
  };
  function getDayBefore(date) {
  date = date.split("-"); //split the passed in data that was chosen
  let dayBefore = new Date(); //create a new date object and pass in the values
  dayBefore.setFullYear(
    parseInt(date[0]),
    parseInt(date[1]) - 1,
    parseInt(date[2])
  ); //Months are zero indexed so this will take care of them
  dayBefore.setDate(dayBefore.getDate() - 1); //find the day one day in the past
  return formatDate(dayBefore); //return the day in the proper format
}

  function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
  </script>
  
  <style scoped>
@import "./styles/DateRange.scss";
  </style>
  