<template>
  <div class="form-navigation">
    <div class="form-nav-buttons">
      <button
        class="btn-previous"
        @click="navigatePrevious"
        v-show="!isFirst()"
      >
        Previous
      </button>
      <button class="btn-no" @click="navigateDashboard" v-show="isFourth()">
        No
      </button>
      <button
        class="btn-yes"
        @click="
          yesEnroll();
        "
        v-show="isFourth()"
      >
        Yes
      </button>
      <button class="btn-next" @click="navigateNext" v-show="!isFourth()">
        {{ nextButtonText() }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormNavigation",
  data() {
    return {
      currentStep: 0,
    };
  },
  computed: {
  },
  methods: {
    yesEnroll() {
      this.$store.state.hasFifthStep = true;
      this.navigateNext();
    },
    nextButtonText() {
      if (
        this.$route.name === "fifthStep"
      ) {
        return "Finish";
      }
      return "Next";
    },
    isFirst() {
      return this.$route.name === "firstStep";
    },
    isFourth() {
      return this.$route.name === "fourthStep";
    },
    navigateNext() {
      if (this.$route.name === "fifthStep") {
        this.submit();
      }
      this.currentStep++;
      this.$router.push("/add-users/" + this.currentStep);
    },

    navigatePrevious() {
      if (this.$route.name === "fifthStep") {
        this.$router.push("/add-users/4");
      } else if (this.$route.name === "fourthStep") {
        this.$router.push("/add-users/3");
      } else if (this.$route.name === "thirdStep") {
        this.$router.push("/add-users/2");
      } else if (this.$route.name === "secondStep") {
        this.$router.push("/add-users/1");
      }
      this.currentStep--;
    },
    navigateDashboard() {
      this.currentStep = 0;
      this.$router.push("/dashboard");
    },
    submit() {
      alert("Submitted");
    },
  },
};
</script>

<style scoped>
.form-nav-buttons {
  display: flex;
  justify-content: space-evenly;
}
.btn-previous,
.btn-no,
.btn-yes,
.btn-next {
  border-radius: 5px;
  border: 1px solid grey;
}
.btn-previous:hover,
.btn-no:hover,
.btn-yes:hover,
.btn-next:hover {
  border-radius: 5px;
  border: 1px solid#ff7e00;
}

</style>
