<template>
  <div class="groups-container">
    <!-- <iframe width="798" height="399" src="https://www.youtube.com/embed/HKvo6acp5aI" title="The AI Assistant Battle! (2023)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
    <!-- <iframe width="760" height="315" src="https://www.youtube-nocookie.com/embed/48h57PspBec?hl=fr-ca&modestbranding=0"  title="YouTube video player" modestbranding frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
    <!-- <iframe width="760" height="315" src="https://www.youtube-nocookie.com/embed/4QKE9YEIVj0?hl=fr-ca&modestbranding=0"  title="YouTube video player" modestbranding frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
    <div class="sidemenu"> 
      <SideMenu></SideMenu>

    </div>
    <div id="navbar-container">
    <Navbar>Manage Groups</Navbar>

  <div id="da">
    <div class="da-header">
      <span class="title">
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'user-plus']" /> -->
        <font-awesome-icon
                class="dashboard-title-icon"
                :icon="['fas', 'star']"
                style="color: rgb(251, 143, 6) ;transform: rotate(180deg);"
              />
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'user-plus']" /> -->
        Groups
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'chart-bar']" /> -->
        <!-- SWIFT Analytics -->
      </span>
    </div>
    <div class="da-content">
      <div class="wrapper">
        <div class="da-group-list" v-if="!singleGroupUser">
          <div class="search-bar">
          <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
          <!-- <font-awesome-icon class="search-icon" :icon="['fas', 'search']" /> -->
          <input
            type="search"
            placeholder="Search for Group"
            class="search-input"
            v-model="companyName"
          />
        </div>
          <div
            id="group-list"
            :class="{ darkmode: this.$store.state.darkmode }"
            
          >
            <div class="da-group-tree-browser" >
              <TreeBrowser
                :node="this.filteredCompanies || this.companyTree"
                @onClick="nodeWasClicked"
                :selectedKey="selectedKey"
                @update:selectedKey="updateKey"
                :showAdd="true"
                :scrollToSelected="scrollToNewGroup"
                @addingGroup="newGroup"
                v-show="Object.keys(this.companyTree).length !== 0 && !loadingCompanies"
              />
              <div v-show="loadingCompanies" class="loading-spinner">
                <Spinner />
              </div>
            </div>
          </div>
          <!-- <button class="org-list-create-subgroup" @click="doSomething()">
                Create Subgroup
            </button> -->
        </div>
      </div>
      <div class="group-info-container" >
      <div class="group-sub-header">Group Information</div>
      <!-- <div class="group-information assignment-spinner" v-show="groupLoading" ><Spinner/> </div> -->
      <div class="group-information" v-show="(Object.keys(groupInfo).length!=0)||selectedKey!=null||parentGroupID!=null"> 
      <!-- <div class="group-information" v-show="((Object.keys(groupInfo).length!=0)||selectedKey!=null||parentGroupID!=null)&&groupLoading==false">  -->
        <!-- CURRENTLY LOOKING AT WHAT THE OPTIONS ON THE OLD DASHBORD DO IN THE ACE PLACE SCHOOL WITH THE USER
          name: test groupchanges
          email: tgroupchange@example.com
          password: groupchange
        -->
        <div class="group-data-container">
        <label for="groupName">Group Name: </label>&nbsp;
        <input class="text-input" type="text"  @keydown="groupDataChanged=true" v-model="groupInfo.name"/>
      </div>
      <div  class="group-data-container">
        <div class="group-checkbox">
        <label @click="groupDataChanged=true;groupInfo.showUnavailableContent=!groupInfo.showUnavailableContent" for="groupName">Show Unavailable Content: </label>&nbsp;
        <input type="checkbox" @change="groupDataChanged=true" v-model="groupInfo.showUnavailableContent"/>
        </div>
        <button v-if="currentTab=='users'" class="btn-blue" @click="addUser()">
                  Add User
                </button>
      </div>
      <!-- !NEED to look at how the recursive options work it does not seem to be part of the data but it does work on the old LMS-->
      <!-- <div v-if="hasChildren" class="group-data-container"> 
        <label for="groupName">Apply to children: </label>&nbsp;
        <input type="checkbox" v-model="groupInfo.showUnavailableContentRecursive"/>
      </div> -->
        <Tabs class="tabs-container" ref="groupTabs">
        <Tab class="tab" name="Assigned Content" v-show="currentTab=='content'" :selected="currentTab=='content'" @tabSelected="selectedTab('content')"> 
          <div v-if="loadingAssignments" class="loading-spinner" >
                <Spinner />
                </div>
                <tree v-else class="assigned-tree" @treeUpdated="groupDataChanged=true" :tree-data="assignmentGroupTree">
                </tree>
          </Tab>
            <Tab class="tab" name="Users" v-show="editingGroup && currentTab=='users'" @tabSelected="selectedTab('users')" :selected="currentTab=='users'">
              <div v-if="usersLoading" class="loading-spinner" >
                <Spinner />
                </div>
                  <table v-if="!usersLoading && userList.length!=0" class="users-table" >
                  <thead v-if="userList.length>0">
                    <tr>
                      <th style="border:none;padding-right: 10px;">Enabled</th>
                      <th style="border:none;"></th>
                      <th style="border:none;"></th>
                      <!-- <th>Login ID</th> -->
                      <th style="border:none;"></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="user in userList"
                      :key="user.ID"
                      :class="{
                      }"
                    >
                    <td style="text-align:center;" ><span v-if="user.enabled">&#10004;</span></td>
                      <td style="font-size:medium;">{{ user.firstName }} {{ user.lastName }}</td>
                      <td class="growable-cell" >{{user.email}}</td>
                      <td class="table-btn-container">
                      <div v-if="user.canChange==undefined|| user.canChange==true">
                      <button title="Edit User" class="table-btn-confirm" v-on:click="editUser(user)">
                        <font-awesome-icon :icon="['fas', 'user-edit']" />
                      </button>
                      <button
                        class="table-btn-danger"
                        v-on:click="confirmDeleteUser(user)"
                        title="Delete User"
                      >
                        <font-awesome-icon :icon="['fas', 'user-times']" />
                      </button>
                    </div>
                    </td>
    
                    </tr>
                  </tbody>
                </table>
                <h4 class="no-user-message" v-else><span v-if="!usersLoading">No Users Found</span></h4>
              </Tab>
            </Tabs>
            <div v-if="editingGroup"  :class="{'edit-buttons':showDeleteButton||singleGroupUser,}"> 
              <button v-if="showDeleteButton" class="btn-danger" @click="deleteGroupButton">Delete Group</button>
              <button v-bind:style="showDeleteButton||singleGroupUser?'width:50%;':'width: 100%;'" class="btn-edit" :disabled="!groupDataChanged" @click="addEditGroup">Edit Group</button>
              <button v-if="singleGroupUser" class="btn-apply" @click="newGroup(groupInfo.groupID)">Create Subgroup</button>
            </div> 
            <button v-else :disabled="!groupInfo.name" class="btn-apply" @click="addEditGroup">Add Group</button>


        <!-- When looking at the old LMS it gives us options to show demo content show unavilable content, show only assigned content and exhaustive learner tracking
        The only one I have found to work out is show unavailable content but it must be used on the content group that contains the courses
        show only assigned looks like it has some actual uses in the code but does not seem to change anything. I could be wrong about that for sure.
        the show only assigned seems to limit the groups that are checked when making the course list and it seems to limit the groupIDs that will be checked.
        show only assigned also seems to deal with ips which are not used much anymore -->
      </div>
      </div>
</div>
  </div>
  </div>
  <SelectionPopupModal 
    v-if="showSelectionPopup"
    :selectionTitle="selectionOptions.title"
    :message="selectionOptions.message"
    :removing="selectionOptions.removingOptions"
    @selected="selectionMade"
/>
<ErrorMessageModal 
        v-show="showErrorMessage"
        :errorTitle="errorMsgInfo.title"
        :errorBody="errorMsgInfo.body"
        @close="showErrorMessage=false"
  />
  <div v-show=" registerUserModalVisible || confirmationModalVisible" class="modal-backdrop">
      <RegisterUserModal
      v-show="registerUserModalVisible"
      :form="form"
      :isAdmin="editUserIsAdmin"
      :userID="userLoginID"
      :groupSelected="groupInfo.name"
      :groupSelectedID="groupInfo.groupID"
      @update="updateList"
      @close="closeModals"
    />
    
    <Confirmation
        v-show="confirmationModalVisible"
        @confirm="deleteUser"
        @cancel="closeModals"
        message="Are you sure you want to delete this user permanently?"
        :form="form"
        class="confirmation"
      />

    </div>

</div>
</template>
<script>
import { mapState } from "vuex";
// import { sleep } from "../util/helper";
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import TreeBrowser from "../components/TreeBrowser.vue";
import Spinner from "../components/Spinner.vue";
import Tabs from "../components/Tabs.vue";
import Tab from "../components/Tab.vue";
import Tree from "../components/Tree.vue";
import AddOrg from "../components/modals/AddOrg.vue";
import RegisterUserModal from "../components/modals/RegisterUser.vue";
import SelectionPopupModal from "../components/modals/SelectionPopup.vue";
import ErrorMessageModal from "../components/modals/ErrorMessage.vue";
import Confirmation from "../components/modals/Confirmation.vue";

export default {
  name: "Groups",
  components: {
    Navbar,
    SideMenu,
    TreeBrowser,
    Spinner,
    AddOrg,
    RegisterUserModal,
    Tabs,
    Tab,
    Tree,
    SelectionPopupModal,
    ErrorMessageModal,
    Confirmation,
  },
  created() {
    if(Object.keys(this.companyTree).length==0)
    {
      this.getGroupTree();
    }
    if(this.singleGroupUser && this.companyTree[0])
    {
      this.nodeWasClicked(this.companyTree[0]);
    } 
  },
  data() {
    return {
      loadingCompanies: false,
      companyName: "",
      groupInfo: {},
      courseName: "",
      assignmentGroupTree: {},
      userList: [],
      courseList: [],
      selectedKey:null,
      usersLoading: false,
      coursesLoading:false,
      loadingAssignments:false,
      editingGroup:true,
      hasChildren:false,
      parentGroupID:null,
      scrollToNewGroup:null,
      groupLoading:false,
      groupDataChanged:false,
      showSelectionPopup:false,
      selectionOptions:{},
      showErrorMessage:false,
      userLoginID:"",
      editUserIsAdmin:false,
      errorMsgInfo:{
        title:'',
        body:''
      },
      currentTab:'content',
      form: {
        firstName: "",
        lastName: "",
        email: "",
        loginID: "",
        password: "",
      },
      registerUserModalVisible:false,
      confirmationModalVisible: false,
    };
  },
  computed: {
    ...mapState({
      companyTree:(state) => state.groupTree,
      singleGroupUser:(state)=>state.singleGroupUser,
      adminGroups:(state)=>state.groups,
    }),
    event() {
      return this.shouldShow ? "click" : null;
    },
    showDeleteButton()
    {
      if(this.companyTree.length>0 && this.companyTree.find((top)=>top.ID==this.groupInfo.groupID))
      {
        return false;
      }
      return true;
    },
    myStyles() {
      return {
        postion: `relative`,
        width: `450px`,
        height: `320px`,
      };
    },
    filteredCompanies: function () {
      let stack = [],
        node,
        ii;
      let children = [];
      let matches = {};
      if(this.companyTree.length)
      {
        for(var company in this.companyTree)
        {
          stack.push(this.companyTree[company]);
        }
      }
      else{
        stack.push(this.companyTree);
      }
      while (stack.length > 0) {
        node = stack.pop();
        if (
          node.Name ||
          (node.groupName && node.groupName.toLowerCase().match(this.companyName.toLowerCase()))
        ) {
          children.push(node);
        } else if (node.children && node.children.length) {
          for (ii = 0; ii < node.children.length; ii += 1) {
            stack.push(node.children[ii]);
          }
        }
      }
      return Object.assign(matches, {
        children: children,
      });
    },

    filteredCompanyCourses: function () {
      let filter = "";
    //   this.courseList.forEach((courseObj) => {
        filter = this.courseList.filter((course) => {
          return course.courseName
            .toLowerCase()
            .match(this.courseName.toLowerCase());
        // });
      });
      return filter;
    },
  },

  methods: {
    selectedTab(tab)
    {
      this.currentTab=tab;
      if(tab=='content' )
      {
        if(Object.keys(this.groupInfo).length!=0 && this.groupInfo.groupID)
        {
          this.getAssignmentGroupTree(this.groupInfo.groupID);
        }
        else if(this.parentGroupID!=null)
        {
          this.getAssignmentGroupTree(this.parentGroupID)
        }
      }
      else if(tab=='users' && Object.keys(this.groupInfo).length!=0 && this.groupInfo.groupID)
      {
        this.getUserList(this.groupInfo.groupID);
      }
    },

    selectionMade(selected){
      this.showSelectionPopup=false;
      if(selected=='yes')
      {
        this.deleteGroupAndSubgroups(this.groupInfo.groupID);
      }
      else{
        this.selectionOptions={};
        return;
      }
    },

    deleteGroupAndSubgroups(groupID)
    {
      this.parentGroupID=null;
      this.$store.dispatch('deleteGroupAndSubgroups',groupID).then((deleteResponse)=>{
          if(deleteResponse.status && deleteResponse.status!='success')
          {
            this.errorMsgInfo.title="Could not Delete Group";
            if(deleteResponse && deleteResponse.message)
            {
              this.errorMsgInfo.body=deleteResponse.message;
              //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!LOOK AT POSSIBLY SHOWING THE LIST OF ERRORS THAT WE CAN RETURN IN THE RESULTS
            }
            else{
              this.errorMsgInfo.body="There was an error while trying to delete the group. Please contact us if this problem persists";
            }

            this.showErrorMessage=true;
            return;
          }
          else{
            //Clear the group data and reload the group tree
            this.assignmentGroupTree={};
            this.groupInfo={};
            this.selectedKey=null;
            let parentID=null;
            if(deleteResponse.results && deleteResponse.results.parentGroupID)
            {
              parentID=deleteResponse.results.parentGroupID;
            }
            this.getGroupTree(parentID, true);
          }
        })
    },

    deleteGroupButton()
    {
      if(this.companyTree.find((top)=>top.ID==this.groupInfo.groupID))
      {
        this.errorMsgInfo.title="Cannot Delete";
        this.errorMsgInfo.body="It is not possible to delete a group at your root level. If you would like this group deleted please speak to a higher administrator";
        this.showErrorMessage=true;
        return;
      }
      this.$store.dispatch("getGroupSubgroups",this.groupInfo.groupID )
        .then((subgroups) => {
          var subGroupList=this.convertTreeToList(subgroups[0]);
          this.$store.dispatch('getContentUsers',{groupIDs: subgroups[0].ID}).then((users)=>{
            subGroupList.shift();//Remove the first element from the array 
          if(subGroupList && subGroupList.length!=0 && users.length!=0)
          {
            this.showDeletePopup('Group has Subgroups and Users',{groups:subGroupList,users:users},'You cannot delete a group with subgroups and users. Would you like to delete the subgroups and remove the users as well?');
          }
          else if(subGroupList && subGroupList.length!=0 )
          {
            this.showDeletePopup('Group has Subgroups',{groups:subGroupList},'You cannot delete a group with subgroups. Would you like to delete the subgroups as well?');
          }
          else if(users.length!=0)
          {
            this.showDeletePopup('Group has Users',{users:users},'You cannot delete a group with users. Would you like to remove the users and delete the group?');
          }
          else{
            //? we can also use the normal delete group in this case but it was swapped to fit the same error messages and safety checks
            this.deleteGroupAndSubgroups(this.groupInfo.groupID)
          }
        });
        });
    },
    showDeletePopup(popupTitle,removing,popupMsg)
    {
      this.selectionOptions={
        title: popupTitle,
        message: popupMsg,
        removingOptions: removing,
      };
      this.showSelectionPopup=true;
    },
    deleteGroup(groupID){
      this.$store.dispatch("deleteGroup",groupID )
        .then((deleteResponse) => { 
          if(deleteResponse.status=='Error')
          {
            this.showDeletePopup('Error Deleting Group',[],deleteResponse.message)
            return;
          }
        }).catch((err)=>{
          console.error('There was an error deleting the group',err)
        })
    },

    deleteUser()
    {
      this.$store.dispatch("deleteUser", this.form.loginID).then(() => {
        this.confirmationModalVisible = false;
        this.updateList();
      });
    },
    
    newGroup(parentID)
    {
      this.$refs.groupTabs.selectTab({name:'Assigned Content'});//Set the current tab to the assigned content as we will not be showing the users
      this.editingGroup=false;
      this.hasChildren=false;
      //We will get the assignments of the parent group as we assume it will somewhat match what we would like to add. We could look at having it putting all selected to false if we want from scratch
      this.getAssignmentGroupTree(parentID);
      this.parentGroupID=parentID;
      this.groupInfo={};
    },
  addEditGroup(){
    this.groupLoading=true;
    if(this.editingGroup)
    {
      let selectedUnselectedGroups=this.getSelectedAndUnselectedGroups();
      this.editGroup(selectedUnselectedGroups.selected,selectedUnselectedGroups.unselected);
    }
    else{
      this.AddGroup(this.groupInfo)
    }
  },
  nodeWasClicked(node)
  {
    this.groupLoading=true;
    this.parentGroupID=null;
    if(node.children && node.children.length>0)
    {
      this.hasChildren=true;
    }
    else{
      this.hasChildren=false;
    }
    this.editingGroup=true;
    //Need to get the group information
    this.callAdminGroupInfoAPI(node.ID);
    if(this.currentTab=='content')
    {
      this.getAssignmentGroupTree(node.ID);
    }
    else if(this.currentTab=='users')
    {
      this.getUserList(node.ID);
    }
    this.groupLoading=false;
    this.groupDataChanged=false;
  },
    AddGroup(newOrg) {   
      this.scrollToNewGroup=null;
      let selectedGroups=this.getSelectedAndUnselectedGroups();
      this.$store.dispatch("updateGroup", {
          name: newOrg.name.trim(),
          parentGroupID: this.parentGroupID,
          method: "add",
          showUnavailableContent: newOrg.showUnavailableContent??false,
          selectedGroupIDs: selectedGroups.selected,
          unselectedGroupIDs: selectedGroups.unselected,
        })
        .then((res) => {
          if (!res[1]) {
            alert("this name already exists, try something different");
          }
          else{
            this.groupInfo.groupID=parseInt(res[0]);
            this.editingGroup=true;
            this.groupDataChanged=false;
            this.getGroupTree(parseInt(res[0]));
          }
          this.groupLoading=false;
        })
        .catch((error) => {
          console.error('Error adding/updating group in DetailedAnalytics',error);
        });
    },

    updateKey(groupID){
      this.selectedKey=groupID;
    },

    getGroupTree(selected, deleting=false) {
      this.loadingCompanies=true;
      this.$store.dispatch("groupTree",selected!=null?true:false).then((res) => {
        if(res)
        {
          if (res[0].Name !== "undefined") {
            this.$store.state.groupTree = res;
          } else {
            this.$store.state.groupTree = res[1];
          }
          if(this.singleGroupUser)//if we delete and are back to just one we want to select it 
          {
            this.nodeWasClicked(res[0]);
          }
        }
        if(selected)
        {
          if(deleting==false)//When deleting we want to scroll to the parent but we do not want it to be selected 
          {
            this.selectedKey=selected;
          }
          this.scrollToNewGroup=selected;
        }
        this.loadingCompanies=false;
      });
    },
    callAdminGroupInfoAPI(nodeid) {
      this.$store
        .dispatch("callAdminGroupInfoAPI", { groupID: nodeid })
        .then((data) => {
          this.groupInfo = data;
        });
    },
    getUserList(nodeid) {
      this.usersLoading=true;
        this.userList=[];
      const users= this.$store.dispatch("getUserList", { groupID: nodeid }).then((data) => {
        this.userList = data;
        this.usersLoading=false;
        return data;
      });
      return users;
    },
    getAssignmentGroupTree(nodeid) {
      this.loadingAssignments=true;
      this.$store
        .dispatch("getAssignmentGroupTree", { groupID: nodeid })
        .then((data) => {
          this.assignmentGroupTree = data[0];
          this.loadingAssignments=false;
          this.groupDataChanged=false;
        });
    },

    getCourseList(groupID) {
      this.coursesLoading=true;
      this.$store.dispatch("getCourseList", groupID).then((courses) => {
        // this.courseList = Object.keys(this.$store.state.courseNameAndID); // This one for name displayed. issue with passing the key when selected
        // if (this.courseList.length > 0) {
        //   this.courseList = [];
        // }
        // this.courseList.push(Object.values(this.$store.state.courseNameAndID));
        this.courseList=Object.values(courses);
        this.coursesLoading=false;
      });
    },


    closeModals(){
      this.confirmationModalVisible=false;
      this.registerUserModalVisible=false;
    },
    getSelectedAndUnselectedGroups() {
      let AssignmentGroupTreeArr = this.convertTreeToList(
        this.assignmentGroupTree
      );
      let selectedGroups = "";
      let unselectedGroups = "";
      for (let i = 0; i < AssignmentGroupTreeArr.length; i++) {
        if (AssignmentGroupTreeArr[i].Selected == true) {
          selectedGroups += AssignmentGroupTreeArr[i].ID.toString() + ";";
        } else {
          unselectedGroups += AssignmentGroupTreeArr[i].ID.toString() + ";";
        }
      }
      // Remove semicolon from the end of the string.
      selectedGroups = selectedGroups.replace(/;\s*$/, "");
      unselectedGroups = unselectedGroups.replace(/;\s*$/, "");
      this.selectedCourses = selectedGroups;
      this.unselectedCourses = unselectedGroups;
      let groupObj={
        selected: selectedGroups,
        unselected: unselectedGroups
      }
      return groupObj;
      // this.editGroup(this.selectedCourses, this.unselectedCourses);
    },
    convertTreeToList(root) {
      let stack = [],
        array = [],
        hashMap = {};
      stack.push(root);

      while (stack.length !== 0) {
        let node = stack.pop();
        if (node.children.length === 0) {
          this.visitNode(node, hashMap, array);
        } else {
          for (let i = node.children.length - 1; i >= 0; i--) {
            stack.push(node.children[i]);
          }
          array.push(node);
        }
      }
      return array;
    },
    visitNode(node, hashMap, array) {
      if (!hashMap[node.ID]) {
        hashMap[node.ID] = true;
        array.push(node);
      }
    },

    updateList(){
      this.registerUserModalVisible=false;
      this.getUserList(this.groupInfo.groupID);
    },

    editGroup(selected, unselected) {
      this.scrollToNewGroup=null;
      this.$store
        .dispatch("updateGroup", {
          name: this.groupInfo.name,
          groupID: this.groupInfo.groupID,
          method: "Edit",
          selectedGroupIDs: selected,
          unselectedGroupIDs: unselected,
          showUnavailableContent: this.groupInfo.showUnavailableContent,
          randomizeTestQuestions: this.groupInfo.randomizeTestQuestions,
          comment: this.groupInfo.comment,
          clientEmail: this.groupInfo.clientEmail,
          showDemoContent: this.groupInfo.showDemoContent,
          showDemoContentRecursive: this.groupInfo.showDemoContent,
          showSupplemental: this.groupInfo.showSupplemental,
          showOnlyAssigned: this.groupInfo.showOnlyAssigned,
          showOnlyAssignedRecursive: this.groupInfo.showOnlyAssignedRecursive,
          allowGuests: this.groupInfo.allowGuests,
        })
        .then((ret) => {
          if (!ret[1]) {
            alert("this name already exists, try something different");
          }
          else{
            this.getGroupTree(parseInt(ret[0]));
            this.groupDataChanged=false;
          }
          this.groupLoading=false;
        });
    },

    addUser() {
      this.form = {
        method: "Add",
        firstName: "",
        lastName: "",
        email: "",
        loginID: "",
        password: "",
        enabled:true,
      };
      this.editUserIsAdmin=false;
      this.registerUserModalVisible = true;
      this.userLoginID='';
    },
    
    confirmDeleteUser(user){
      this.$store.dispatch("getUserProperties", user.ID)
        .then((user) => { 
      this.form.firstName = user.firstName;
      this.form.lastName = user.lastName;
      this.form.email = user.email;
      this.form.loginID = user.userID;
      this.confirmationModalVisible = true
        });
    },

    editUser(userPassed){
      this.userLoginID='';
      //!! Look into how we can see if the admin has rights to the user so we do not edit a higher up user
      this.$store.dispatch("getUserProperties", userPassed.ID)
        .then((user) => { 
          this.editUserIsAdmin=false;
          this.$store.dispatch("getUserGroups", user.userID)
          .then((groups) => {
          if(groups[Object.keys(this.adminGroups)[0]] && groups[Object.keys(this.adminGroups)[0]].isAdmin)
          {
            this.editUserIsAdmin=true;
          }
        });
          this.form.method = "Edit";
          this.form.firstName = user.firstName;
          this.form.lastName = user.lastName;
          this.form.email = user.email;
          this.form.loginID = user.userID;
          this.userLoginID=user.userID;
          this.form.enabled=user.enabled;
          this.registerUserModalVisible = true;
        });
    },

  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/Groups.scss";
@import "../components/styles/BasicModal.scss";
@import "../components/styles/Buttons.scss";
</style>