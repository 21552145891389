<template>
  <div id="course-usage">
    <div class="content">
      <div class="cards">
        <div class="total-started">
          <div class="heading">Total Started</div>
          <div class="value">105</div>
        </div>
        <div class="total-mastered">
          <div class="heading">Total Mastered</div>
          <div class="value">43</div>
        </div>
        <div class="total-access">
          <div class="heading">Total Accesses</div>
          <div class="value">2045</div>
        </div>
      </div>
      <div class="course-usage-table">
        <div class="filters">
          <input
            class="search-input"
            type="search"
            placeholder="Search for Course"
          />
          <div class="save-file">
            <button class="save-file-button">Save</button>
            <input class="search-input" type="text" placeholder="File Name" />
          </div>
        </div>
        <div class="table">
          <table>
            <thead>
              <tr>
                <th>Course Name</th>
                <th>Started</th>
                <th>Mastered</th>
                <th>Number of Accesses</th>
                <th>Date Last Accessed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Alberta OHS Awareness</td>
                <td>2</td>
                <td>0</td>
                <td>65</td>
                <td>02/22/22</td>
              </tr>
              <tr>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>10</td>
                <td>5</td>
                <td>205</td>
                <td>02/22/22</td>
              </tr>
              <tr>
                <td>Distractions and Proactive Driver Training</td>
                <td>6</td>
                <td>1</td>
                <td>71</td>
                <td>02/22/22</td>
              </tr>
              <tr>
                <td>Distractions and Proactive Driver Training</td>
                <td>5</td>
                <td>5</td>
                <td>102</td>
                <td>02/22/22</td>
              </tr>
              <tr>
                <td>Energy Isolation</td>
                <td>7</td>
                <td>2</td>
                <td>38</td>
                <td>02/22/22</td>
              </tr>
              <tr>
                <td>Fall Protection Equipment</td>
                <td>1</td>
                <td>0</td>
                <td>99</td>
                <td>02/22/22</td>
              </tr>
              <tr>
                <td>General Electrical Safety</td>
                <td>41</td>
                <td>14</td>
                <td>202</td>
                <td>02/22/22</td>
              </tr>
              <tr>
                <td>General Fire Safety</td>
                <td>17</td>
                <td>3</td>
                <td>178</td>
                <td>02/22/22</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
$primary-color: #f5f5f5;
$secondary-color: #343a40;
$accent-color: rgb(255, 126, 0);
$text-color: rgb(27, 29, 40);
// $font-sizes: (
//   "title": 40px,
//   "subtitle": 30px,
//   "text": 20px,
// );
$font-sizes: (
  "title": 35px,
  "subtitle": 25px,
  "text": 15px,
);
$font-weights: (
  "regular": 400,
  "medium": 500,
  "bold": 700,
);

.cards {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.total-started,
.total-mastered,
.total-access {
  width: 250px;
  height: 100px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  padding: 10px 10px 10px 10px;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.course-usage-table {
  width: 80%;
}
.table {
  overflow-y: scroll;
  height: 400px;
  border: 1px solid black;
}
table {
  background-color: white;
  width: 100%;
  border-collapse: collapse;
  font-size: map-get($font-sizes, text);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  thead tr {
    position: sticky;
    top: 0;
    background-color: #343a40;
    color: #ffffff;
    text-align: left;
    font-weight: map-get($font-weights, bold);
  }
  th td {
    padding: 12px 15px;
  }
  tbody tr {
    border-bottom: 1px solid $secondary-color;
  }
  tbody tr:last-of-type {
    border-bottom: 1px solid $secondary-color;
  }
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #343a40;
  outline: 1px solid slategrey;
}
.filters {
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: space-between;
}
.search-input {
  height: 30px;
  width: 300px;
  position: relative;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 5px;
  padding: 2px 5px 2px 30px;
  outline: 0;
  background-color: #343a40;
  display: flex;
  color: #f0f1ee;
}
.search-input:focus {
  border: 1px solid $accent-color;
}
.heading {
  font-weight: map-get($font-weights, bold);
    
}
.value {
  margin: 10px 0 10px 0;
  text-align: center;
}
.save-file {
  display: flex;
  height: 30px;
}
.save-file-button {
  margin: 0 10px 0 0;
  border-radius: 5px;
  color: white;
  background-color: rgb(0, 69, 185);
  align-items: center;
  display: inline-flex;
}

// .title {
//   font-size: map-get($font-sizes, title);
//   font-weight: map-get($font-weights, bold);
// }

// .subtitle {
//   text-align: center;
//   font-size: map-get($font-sizes, subtitle);
//   font-weight: map-get($font-weights, medium);
// }

// .text {
//   font-size: map-get($font-sizes, text);
//   font-weight: map-get($font-weights, regular);
// } 
</style>
