<template>
  <div class="first">
    <h4>Create User</h4>
    <div class="form">
      <div class="form-first-name">
        <label for="first-name">First Name</label>
        <input
          type="text"
          id="first-name"
          name="firstName"
          v-model="firstName"
        />
      </div>

      <div class="form-last-name">
        <label for="last-name">Last Name</label>
        <input type="text" id="last-name" name="lastName" v-model="lastName" />
      </div>

      <div class="form-email">
        <label for="email-address">Email</label>
        <input type="text" id="email-address" name="email" v-model="email" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstStep",
  components: {},
  computed: {
    firstName: {
      get() {
        return this.$store.state.firstName;
      },
      set(value) {
        this.$store.commit("setFirstName", { firstName: value });
      },
    },
    lastName: {
      get() {
        return this.$store.state.lastName;
      },
      set(value) {
        this.$store.commit("setLastName", { lastName: value });
      },
    },
    email: {
      get() {
        return this.$store.state.email;
      },
      set(value) {
        this.$store.commit("setEmail", { email: value });
      },
    },
  },
};
</script>
<style scoped>
@import "./msFormStyles/msForm.scss";
</style>
