<template>
  <div id="registration">
    <Navbar>Registration</Navbar>
    <SideMenu></SideMenu>
    <header class="header">
      <router-link
        to="/dashboard"
        class="router-link"
        style="text-decoration: none;"
      >
        <span
          class="back-to-dashboard"
          :class="{ darkmode: this.$store.state.darkmode }"
        >
          <h3>
            <font-awesome-icon :icon="['fas', 'arrow-left']" /> Back to
            Dashboard
          </h3>
        </span>
      </router-link>

      <span class="title" :class="{ darkmode: this.$store.state.darkmode }">
        <h3>
          <font-awesome-icon class="title-icon" :icon="['fas', 'user-plus']" />
          Registration
        </h3>
      </span>
    </header>

    <Tabs>
      <Tab name="Register User" selected="true">
        <div class="registration-tab">
          <Confirmation
            v-show="confirmationModalVisible"
            @confirm="updateUser(form)"
            @cancel="cancel"
            message="Are you sure you want to create this user?"
            :form="form"
            :groups="groups"
            class="confirmation"
          ></Confirmation>

          <Confirmation
            v-show="fileModalVisible"
            @confirm="submitFile()"
            @cancel="cancel"
            :sheet="sheet"
            :groups="groups"
            message="Please verify the credentials below"
            class="confirmation"
          ></Confirmation>

          <RegisterToGroup
            v-show="groupRegisteryVisible"
            :checked="groups"
            @userGroups="selectedGroups"
            @cancel="cancel"
            class="confirmation"
          />

          <img
            src="../assets/UserRegistrationAsset.jpg"
            alt="Swift Learning Logo"
            class="registration-image"
          />
          <div class="form" :class="{ darkmode: this.$store.state.darkmode }">
            <Spinner v-if="loading" />
            <div
              v-if="!loading"
              v-show="!confirmationModalVisible && !groupRegisteryVisible"
            >
              <header class="registration-type">
                <h1>Register User</h1>
              </header>

              <div
                class="user-type-import"
                name="header"
                v-if="method !== 'Edit'"
              >
                <!-- <h3>Learner</h3>
                <h3>Secondary</h3>
                <h3>Primary</h3> -->
                <input type="file" @change="uploadFile" ref="file" />
                <button
                  type="button"
                  class="btn-blue"
                  @click="showGroups('multi')"
                >
                  Import file
                </button>
              </div>

              <body>
                <div
                  class="user-information"
                  :class="{ darkmode: this.$store.state.darkmode }"
                >
                  <form class="register-form">
                    <div class="register-form-row">
                      <div class="register-form-row-elem">
                        <label class="register-form-label" for="First Name">
                          First Name
                        </label>
                        <input
                          class="register-form-input"
                          name="First Name"
                          v-model="form.firstName"
                        />
                      </div>

                      <div class="register-form-row-elem">
                        <label class="register-form-label" for="Last Name">
                          Last Name
                        </label>
                        <input
                          class="register-form-input"
                          name="Last Name"
                          v-model="form.lastName"
                        />
                      </div>
                    </div>
                    <div class="email">
                      <label class="register-form-email-label" for="Email">
                        Email
                      </label>
                      <input
                        class="register-form-email"
                        name="Email"
                        v-model="form.email"
                      />
                    </div>

                    <div class="register-form-row">
                      <div class="register-form-row-elem">
                        <label class="register-form-label" for="Login ID">
                          Login ID
                        </label>
                        <input
                          class="register-form-input"
                          name="Login ID"
                          v-model="form.loginID"
                        />
                      </div>

                      <div class="register-form-row-elem">
                        <label class="register-form-label" for="Password">
                          Password
                        </label>
                        <input
                          type="password"
                          class="register-form-input"
                          name="Password"
                          v-model="form.password"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </body>
              <footer class="footer">
                <button
                  type="button"
                  class="btn-apply"
                  @click="showGroups('single')"
                >
                  Create Account
                </button>
              </footer>
            </div>
          </div>
        </div>
      </Tab>

      <Tab name="Register Organization">
        <div class="registration-tab">
          <img
            src="../assets/organization.jpg"
            alt="Swift Learning Logo"
            class="registration-image"
          />

          <div class="form" :class="{ darkmode: this.$store.state.darkmode }">
            <Confirmation
              v-show="confirmOrganizationModalVisible"
              @confirm="AddGroup"
              @cancel="cancel"
              message="Create an Org under this name?"
              :orgForm="organizationForm"
              class="confirmation"
            ></Confirmation>

            <div v-show="!confirmationModalVisible">
              <header class="registration-type">
                <h1>Register Organization</h1>
              </header>
              <body>
                <div
                  class="user-information"
                  :class="{ darkmode: this.$store.state.darkmode }"
                >
                  <form class="register-form">
                    <div class="register-form-row">
                      <div class="register-form-row-elem">
                        <label
                          class="register-form-label"
                          for="Organization Name"
                        >
                          Organization Name
                        </label>
                        <input
                          class="register-form-input"
                          name="Organization Name"
                          v-model="organizationForm.name"
                        />

                        <!-- <label
                          class="register-form-label"
                          for="Organization Email"
                        >
                          Organization Email
                        </label>
                        <input
                          class="register-form-input"
                          name="Organization Email"
                          v-model="organizationForm.email"
                        />
                        <label
                          class="register-form-label"
                          for="Organization Comment"
                        >
                          Comment
                        </label>
                        <input
                          type="text"
                          class="register-form-input"
                          name="Organization Comment"
                          v-model="organizationForm.comment"
                        /> -->

                        <!-- <input type="checkbox" name="Recursive Email" /> -->
                      </div>
                    </div>
                  </form>
                </div>
              </body>

              <footer class="footer">
                <button
                  type="button"
                  class="btn-apply"
                  @click="confirmOrganization"
                >
                  {{ method }} Create Organization
                </button>
              </footer>
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
    <AddCourses
      v-show="AddCoursesVisible"
      method="Edit"
      @close="closeModals"
      @apply="editGroup"
      :orgID="this.orgID"
    />
  </div>
</template>

<script>
import Tab from "@/components/Tab";
import Tabs from "@/components/Tabs";
import Navbar from "../components/Navbar.vue";
import SideMenu from "@/components/SideMenu";
import Spinner from "../components/Spinner.vue";
import Confirmation from "../components/modals/Confirmation.vue";
import RegisterToGroup from "../components/modals/RegisterToGroup.vue";
import AddCourses from "../components/modals/AddCourses.vue";

import { sleep } from "../util/helper";

import XLSX from "xlsx";

export default {
  name: "Registration",

  components: {
    Tab,
    Tabs,
    Navbar,
    SideMenu,
    Confirmation,
    RegisterToGroup,
    AddCourses,
    Spinner,
  },

  data() {
    return {
      loading: false,
      organizationForm: {
        name: "",
        email: "",
        isOrganization: true,
        selectedCourses: [],
        unselectedCourses: [],
        method: this.$props.method,
        comment: "",
      },
      orgName: "",
      orgID: "",
      AddCoursesVisible: false,
      confirmOrganizationModalVisible: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        loginID: this.$props.method === "Edit" ? this.$store.state.userID : "",
        password: "",
        endOfList: true,
      },
      sheet: {},
      groups: {},
      confirmationModalVisible: false,
      groupRegisteryVisible: false,
      fileModalVisible: false,
      images: null,
    };
  },

  props: {
    method: String,
    firstName: String,
    lastName: String,
    email: String,
  },

  methods: {
    showGroups(type) {
      this.userAddMethod = type;
      this.groupRegisteryVisible = true;
    },

    selectedGroups(groups) {
      this.groups = groups;
      this.groupRegisteryVisible = false;
      if (this.userAddMethod === "multi") {
        this.fileModalVisible = true;
      }
      if (this.userAddMethod === "single") {
        this.confirmationModalVisible = true;
      }
    },

    close() {
      this.$emit("close");
    },

    addCourses() {
      this.AddCoursesVisible = true;
    },

    closeModals() {
      this.AddCoursesVisible = false;
    },

    AddGroup() {
      //This function is called when creating a new organization.
      this.confirmOrganizationModalVisible = false;
      this.$store
        .dispatch("updateGroup", {
          name: this.organizationForm.name,
          method: this.$props.method,
        })
        .then((res) => {
          // console.log('Updating group in Registration.vue',res);
          if (!res[1]) {
            alert("this name already exists, try something different");
          }
          this.orgID = res[0];
          this.orgName = res[1];
          this.AddCoursesVisible = true;
        })
        .catch((error) => {
          console.log('Error in AddGroup in Registration.vue',error);
        });
    },

    editGroup(selected, unselected) {
      //This function is called when submiting courses to be applied to the newly created organization.
      this.organizationForm.selectedCourses = selected;
      this.organizationForm.unselectedCourses = unselected;
      this.AddCoursesVisible = false;
      this.$store
        .dispatch("updateGroup", {
          name: this.orgName,
          groupID: this.orgID,
          method: "Edit",

          selectedGroupIDs: this.organizationForm.selectedCourses,
          unselectedGroupIDs: this.organizationForm.unselectedCourses,

          clientEmail: this.organizationForm.email,
          comment: this.organizationForm.comment,
        })
        .then(() => {
          this.organizationForm.name = "";
          this.orgID = "";
          this.organizationForm.email = "";
          this.organizationForm.comment = "";
        });
    },

    confirmOrganization() {
      if (this.organizationForm.name !== "") {
        this.confirmOrganizationModalVisible = true;
      }
      if (this.organizationForm.name === "") {
        alert("Enter the name of your Organization to continue ");
      }
    },

    updateUser(form) {
      this.loading = true;
      let groupStr = "";

      for (let groupID in this.groups) {
        //groupID;member;wasMember[;isPrimaryGroup;isAdministrator;isAuthor;isLearner;isVerifier;isSME]
        if (this.groups[groupID].isMember) {
          groupStr = `${groupID};${this.groups[groupID].isMember};false;${this.groups[groupID].isPrimaryGroup};${this.groups[groupID].isAdministrator};${this.groups[groupID].isAuthor};${this.groups[groupID].isLearner};false;false;`;
          // groupStr = `${groupID};${this.groups[groupID].isMember};false;${this.groups[groupID].isPrimaryGroup};${this.groups[groupID].isAdministrator};${this.groups[groupID].isAuthor};${this.groups[groupID].isLearner};${this.groups[groupID].isVerifier};false;`;
        }
      }

      this.$store.dispatch("updateUser", {
        method: "Add",
        userID: form.loginID,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        password: form.password,
        group: groupStr,
      });

      if (form.endOfList) {
        this.resetForm();
      }
      this.confirmationModalVisible = false;
      this.loading = false;
    },

    confirm() {
      if (
        this.form.firstName !== "" &&
        this.form.lastName !== "" &&
        this.form.email !== "" &&
        this.form.password !== "" &&
        this.form.loginID !== ""
      ) {
        this.groupRegisteryVisible = true;
      }
    },

    cancel() {
      this.confirmOrganizationModalVisible = false;
      this.confirmationModalVisible = false;
      this.groupRegisteryVisible = false;
      this.fileModalVisible = false;
    },

    // getAssignmentGroupTree() {
    //     console.log("working on getAssignmentGroupTree request")
    //     this.$store
    //         .dispatch("getAssignmentGroupTree")
    //         .then((data) => {
    //             console.log("getAssignmentGroupTree Data:", data)
    //             this.AssignmentGroupTree = data[0];
    //             console.log(this.AssignmentGroupTree)
    //         })
    // },

    resetForm() {
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.password = "";
      this.form.loginID = "";
      this.groups = {};
    },

    uploadFile() {
      this.images = this.$refs.file.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        this.sheet = workbook.Sheets.Sheet1;
      };
      reader.readAsArrayBuffer(this.images);
    },

    submitFile() {
      this.loading = true;
      let end = this.sheet["!ref"].split("E")[1];
      for (let x = 2; x <= end; x++) {
        this.updateUser({
          loginID: this.sheet[`D${x}`]["v"],
          firstName: this.sheet[`A${x}`]["v"],
          lastName: this.sheet[`B${x}`]["v"],
          email: this.sheet[`C${x}`]["v"],
          password: this.sheet[`E${x}`]["v"],
          endOfList: x === end,
        });
        sleep(2000);
      }
      this.confirmationModalVisible = false;
      this.groupRegisteryVisible = false;
      this.fileModalVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../components/styles/BasicModal.scss";
@import "../components/styles/Buttons.scss";
// @import "../components/modals/styles/RegisterUser.scss";
@import "./styles/Registration.scss";
</style>
