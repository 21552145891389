<template>
  <li class="node-tree">

    <input 
      v-if="node.Name !== 'Clustered Content'"
      class="course-groups" 
      :value="node" 
      type="checkbox" 
      v-model="node.Selected" 
      @change="changedSelect()"
    >
     
    <label  v-if="node.Name !== 'Clustered Content'" class="label" @click="changedSelect(true)" for="child" >{{ node.groupName || node.Name}}</label>
    <ul v-if="node.children && node.children.length"  >
      <node v-for="child in node.children" @changedSelect="changedSelect" :node="child" :key="child.ID">
      </node> 
    </ul> <!-- A NodeTree is also a subtree, it renders its children. -->
          <!-- The NodeTree component calls itself until the condition is not met -->
  </li>
  
</template>

<script>
export default {
  name: "node",
  props: {
    node: Object
  },
  data() {
    return {
    }
  },
 
  methods: {
    changedSelect(changeNodeVal=false){
      this.$emit('nodeUpdated');
      this.$emit('changedSelect');
      if(changeNodeVal)
      {
        this.node.Selected=!this.node.Selected
      }
    }
  }
};
</script>
<style  scoped>
ul {
  list-style-type: none; 
}
li {
  list-style-type: none; 
}
input{
  margin-right: 10px;
}
</style>