<template>
  <div class="course-posttests">
    <div class="content">
      <div class="course-posttest-table">
        <div class="filters">
          <div class="search">
            <input
              class="search-input"
              type="search"
              placeholder="Search for Learner"
            />
            <!-- <input
              class="search-input"
              type="search"
              placeholder="Search for Course"
            /> -->
          </div>
            <DateRange 
            @updateStartDate="updateStartDate"
            @updateEndDate="updateEndDate"
            ref="dateRange"
            ></DateRange>
          <div class="save-file">
            <button class="save-file-button">Save</button>
            <input class="search-input" type="text" placeholder="File Name" />
          </div>
        </div>
        <div class="table">
          <table>
            <thead>
              <tr>
                <th>Learner</th>
                <th>Email</th>
                <th>Course Name</th>
                <th>Test Date</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Annabell Mckinney</td>
                <td>Annabell@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-01-14</td>
                <td>100</td>
              </tr>
              <tr>
                <td>Annabell Mckinney</td>
                <td>Annabell@example.com</td>
                <td>Alberta OHS Awareness</td>
                <td>2022-03-25</td>
                <td>47</td>
              </tr>
              <tr>
                <td>Annabell Mckinney</td>
                <td>Annabell@example.com</td>
                <td>Energy Isolation</td>
                <td>2022-04-20</td>
                <td>78</td>
              </tr>
              <tr>
                <td>Sullivan Bates</td>
                <td>Bates@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-06-30</td>
                <td>95</td>
              </tr>
              <tr>
                <td>Sullivan Bates</td>
                <td>Bates@example.com</td>
                <td>Alberta OHS Awareness</td>
                <td>2022-06-20</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Sullivan Bates</td>
                <td>Bates@example.com</td>
                <td>Alberta OHS Awareness</td>
                <td>2022-06-21</td>
                <td>75</td>
              </tr>
              <tr>
                <td>Sullivan Bates</td>
                <td>Bates@example.com</td>
                <td>Alberta OHS Awareness</td>
                <td>2022-06-22</td>
                <td>100</td>
              </tr>
              <tr>
                <td>Quinn Pearce</td>
                <td>Quinn@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-08-29</td>
                <td>80</td>
              </tr>
              <tr>
                <td>Quinn Pearce</td>
                <td>Quinn@example.com</td>
                <td>Energy Isolation</td>
                <td>2022-08-29</td>
                <td>92</td>
              </tr>
              <tr>
                <td>Quinn Pearce</td>
                <td>Quinn@example.com</td>
                <td>Fall Protection Equipment</td>
                <td>2022-08-29</td>
                <td>70</td>
              </tr>
              <tr>
                <td>Zoha Hope</td>
                <td>Zoha@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-09-22</td>
                <td>72</td>
              </tr>
              <tr>
                <td>Siana Frank</td>
                <td>Frank@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-11-23</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Ashlyn Connor</td>
                <td>Ashlyn@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-08-29</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Billie Cardenas</td>
                <td>Cardenas@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-08-29</td>
                <td>42</td>
              </tr>
              <tr>
                <td>Edwin Enriquez</td>
                <td>Edwin@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-09-22</td>
                <td>30</td>
              </tr>
              <tr>
                <td>Sufyaan Mclaughlin</td>
                <td>Mclaughlin@example.com</td>
                <td>Cyberbullying and Digital Citizenship</td>
                <td>2022-11-23</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateRange from "../components/DateRange.vue";
export default {
  name: "CoursePostTestScores",
  components: {
    DateRange,
  },
};
</script>
<style lang="scss" scoped>
$primary-color: #f5f5f5;
$secondary-color: #343a40;
$accent-color: rgb(255, 126, 0);
$text-color: rgb(27, 29, 40);
// $font-sizes: (
//   "title": 40px,
//   "subtitle": 30px,
//   "text": 20px,
// );
$font-sizes: (
  "title": 35px,
  "subtitle": 25px,
  "text": 15px,
);
$font-weights: (
  "regular": 400,
  "medium": 500,
  "bold": 700,
);

.content {
  display: flex;
  justify-content: space-evenly;
}
.course-posttest-table {
  width: 80%;
}

.filters {
  margin: 10px 0 0px 0;
  display: flex;
  justify-content: space-between;
}
.search {
  //   justify-content: space-between;
  //   width: 700px;
  display: flex;
}
.date-picker {
  display: flex;
}
.dates {
  // height: 30px;
  display: flex;
  margin: 0;
}
.table {
  overflow-y: scroll;
  height: 500px;
  border: 1px solid black;
}
table {
  background-color: white;
  width: 100%;
  border-collapse: collapse;
  font-size: map-get($font-sizes, text);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  thead tr {
    position: sticky;
    top: 0;
    background-color: #343a40;
    color: #ffffff;
    text-align: left;
    font-weight: map-get($font-weights, bold);
  }
  th td {
    padding: 12px 15px;
  }
  tbody tr {
    border-bottom: 1px solid $secondary-color;
  }
  tbody tr:last-of-type {
    border-bottom: 1px solid $secondary-color;
  }
}
.search-input {
  width: 300px;
  height: 30px;

  position: relative;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 5px;
  padding: 2px 5px 2px 30px;
  outline: 0;
  background-color: #343a40;
  display: flex;
  color: #f0f1ee;
}
.search-input:focus {
  border: 1px solid $accent-color;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #343a40;
  outline: 1px solid slategrey;
}
.save-file {
  display: flex;
  height: 30px;
}
.save-file-button {
  margin: 0 10px 0 0;
  border-radius: 5px;
  color: white;
  background-color: rgb(0, 69, 185);
  align-items: center;
  display: inline-flex;
}
</style>