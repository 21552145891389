<template>
  <div class="sidemenu-background">
    <!-- <b-button v-b-toggle.sidebar-no-header>Open Sidebar</b-button> -->
    <b-sidebar
      id="sidebar-no-header"
      aria-labelledby="sidebar-no-header-title"
      backdrop
      :backdrop-variant="variant"
      no-header
      shadow
      visible
    >
      <template #default="{ hide }">
        <div class="p-3">
          <!-- <h4 id="sidebar-no-header-title">Swift Learning</h4> -->
          <div class="sidemenu-logo">
            <img src="../assets/SWIFT LMS.svg" alt="SWIFT LMS" />
          </div>

          <nav class="mb-3">
            <b-nav vertical>
              <!-- <b-nav-item @click="hide">
                <router-link to="/Login">Log In</router-link>
              </b-nav-item> -->

              <hr />

              <b-nav-item @click="hide">
                <router-link to="/dashboard">
                  <h5>Dashboard</h5>
                </router-link>
              </b-nav-item>

              <!-- <b-nav-item @click="hide">
                <router-link to="/overview">
                  <h5>Overview</h5>
                </router-link>
              </b-nav-item> -->

              <b-nav-item @click="hide">
                <router-link to="/reports">
                  <h5>Reports</h5>
                </router-link>
              </b-nav-item>

              <b-nav-item @click="hide">
                <router-link to="/detailed-analytics">
                  <h5>Organization List</h5>
                </router-link>
              </b-nav-item>

              <!-- <b-nav-item @click="hide">
                <router-link to="/registration">
                  <h5>Registration</h5>
                </router-link>
              </b-nav-item> -->

              <b-nav-item @click="hide">
                <router-link to="/user-list">
                  <h5>User List</h5>
                </router-link>
              </b-nav-item>

              <b-nav-item @click="hide">
                <router-link to="/account-information">
                  <h5>Account Information</h5>
                </router-link>
              </b-nav-item>

              <hr />

              <b-nav-item @click="hide">
                <router-link to="/SAHARA">
                  <h5>SAHARA</h5>
                </router-link>
              </b-nav-item>

              <hr />
              <b-nav-item @click="hide">
                <router-link to="/server-events">
                  <h5>Server Events</h5>
                </router-link>
              </b-nav-item>

              <b-nav-item @click="hide">
                <router-link to="/Test_Functions">
                  <h5>Test Functions</h5>
                </router-link>
              </b-nav-item>
            </b-nav>
          </nav>

          <hr /> 

          <b-button
            variant="text-dark bg-light"
            @click="logOut"
            data-cy="logout-button"
            class="logout"
          >
            Log Out
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
export default {
  name: "DashSideMenu",
  data() {
    return {
      variant: "transparent",
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("callLogOutAPI").then(() => {
        location.reload();
      });
    },
  },
};
</script>
<style scoped>
@import "./styles/SideMenu.scss";
</style>
