<template>
    <div class="modal-backdrop">
      <div class="modal modal--industries-modal">
        <header class="modal-header">
          <h2>Environment Selection</h2>
        </header>
        <div class="header-section">
        <input
          class="modal-search-bar"
          type="text"
          placeholder="Search for Environment"
          v-model="searchForEnvironment"
        />
        <div class="live-button">
          <label for="liveCheckbox">Only Live:</label>  
            <input type="checkbox" id="liveCheckbox" @change="getIndustryTree()" v-model="onlyLive" />
        </div>
        </div>
        <body class="modal-body">
          <div class="modal-selected-filters">
            <div v-for="industry in displaySelected" :key="industry.industryID"> 
              <div v-if="industry.hasRecords && industry.isSelected==false" style="border: none;"  class="industry-pill"> 
                <span v-bind:class="industry.isLive? '':'not-live'" >{{ industry.industryName }}</span>
              </div>
              <div v-if="industry.hasRecords && industry.isSelected==true"> 
                <Pill  class="modal-pill industry-pill"  :filterName="industry.industryName" @removeFilter="removeFilter(industry)" />
              </div>
            <div class="modal-pill environment-pill" v-for="environment1 in industry.selectedIndEnvironments" :key="environment1.environmentID">
              <Pill   :filterName="environment1.environmentName" @removeFilter="removeFilter(environment1)" />
            </div>
            </div>
          </div>
  
          <div class="spinner" v-if="loading">
            <Spinner />
          </div>
  
          <form class="modal-filter-options" v-if="!loading" >
            <span
              class="industry-tree-content"
              v-for="(industry, industryID) in filteredIndustries"
              :key="industryID"
              
            >
              <input
                class="checkbox"
                type="checkbox"
                v-model="environmentsSelected"
                :value="industry"
                @click="clickEntryName(industry)"
              />
              <span  v-bind:class="industry.isLive==false? 'not-live':''" @click="clickEntryName(industry)">{{ industry.industryName }}</span>
              <div class="environment-section">
              <span
              class="environment-content"
              v-for="(environment, environmentID) in industry.filteredEnvironments"
              :key="environmentID"
            >
              <input
                class="checkbox"
                type="checkbox"
                v-model="environmentsSelected"
                :value="environment"
              />
              <!-- <span v-bind:class="industry.isLive=='false'? 'not_live':''" @click="clickEntryName(environment)">{{ environment.environmentName }}{{ environment.isLive }}{{ industry.isLive }}</span> -->
              <span  v-bind:class="(industry.isLive==false||environment.isLive==false)? 'not-live':''" @click="clickEntryName(environment)">{{ environment.environmentName }}</span>
              <!-- <h1>IF IT DECIDES TO WORK START THE SERVER AND CHANGE THE API TO NOT HAVE TRUE AND FALSE IN QUOTES</h1> -->
            </span>
            </div>
             </span>
          </form>
        </body>
  
        <footer class="modal-footer">
  
          <button
            type="button"
            class="btn-close"
            @click="
              close();
              reset();
              clear();
            "
          >
            Cancel
          </button>
          <button type="button" class="btn-apply" @click="apply">
            Apply
          </button>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Pill from "../AppliedFilterPill.vue";
  import Spinner from "../Spinner.vue";
  
  export default {
    name: "IndustryTreeModal",
    components: {
      Pill,
      Spinner,
    },
    created() {
      if(!this.industryTyee || this.industryTree.length==0)
      {
        this.getIndustryTree();
      }
      else{
        this.loading=false;
      }
    },
    data() {
      return {
        environmentsSelected: [],
        industriesSelected: [],
        searchForEnvironment: "",
        loading: false,
        onlyLive: true,
      };
    },

    computed: {
      ...mapState({
        industryTree: (state) => state.industryTree,
      }),
      displaySelected: function(){
        let indArr=[];
          if(!this.industryTree|| this.industryTree.length==0)
          {
            return indArr;
          }
          this.industryTree.forEach((indust)=>{
          let addingIndust={...indust};
          var matchingEnvironments=this.environmentsSelected.filter((env)=>env.industryID==addingIndust.industryID && !env.industryName)
          if(matchingEnvironments.length>0)
          {
            addingIndust.selectedIndEnvironments=matchingEnvironments;
            addingIndust.isSelected=(addingIndust.environments.length==matchingEnvironments.length)
            addingIndust.hasRecords=true;
          }
          else{
            addingIndust.isSelected=false;
            addingIndust.selectedIndEnvironments=[];
            addingIndust.hasRecords=false;
          }
            // //This is how we can tell if we want to artificially add or remove the checkmark from the industry as well as all their children
            var indIndex=this.environmentsSelected.indexOf(indust);
            if(addingIndust.isSelected && indIndex==-1)
            {
              this.environmentsSelected.push(indust);
            }
            else if(addingIndust.isSelected==false && indIndex!=-1)
            {
              this.environmentsSelected.splice(indIndex,1);
            }

          indArr.push(addingIndust);
        })
        return indArr;
      },
      filteredIndustries: function () {
        if (this.industryTree === null||this.industryTree === undefined) {
        return []; 
      }

        const values = Object.values(this.industryTree);
        return values.filter((industry) => {
           let environments = industry.environments.filter((environment)=>{
                return environment.environmentName.toLowerCase().match(this.searchForEnvironment.toLowerCase());
            })
            industry.filteredEnvironments=environments
            return industry.filteredEnvironments.length>0 || industry.industryName.toLowerCase().match(this.searchForEnvironment.toLowerCase());
        });
      },

    },
    methods: {
    changeInd(industry,selected)
      {
        let index=selected.indexOf(industry);
        if(index!=-1)
        {
            industry.environments.forEach(function(value){
            if (selected.indexOf(value)==-1) {selected.push(value);}
            });
        }
        else
        {
            industry.environments.forEach(function(value){
            let envIndex=selected.indexOf(value)
            if (envIndex!=-1) {selected.splice(envIndex,1)}
            });
        }
        // this.environmentsSelected=selected;
        return selected;
      },

      clickEntryName(entry)
      {
        let tempArr=[...this.environmentsSelected];
        let index=tempArr.indexOf(entry);
        if(index!=-1)
        {
          tempArr.splice(index,1);
        }else{
          tempArr.push(entry);
        }
        if(entry.industryName)
        {
            tempArr = this.changeInd(entry, tempArr);
        }
        this.environmentsSelected=tempArr;
      },
      close() {
        this.$emit("close");
        this.$emit("resetTable", [])
      },
      clear() {
        this.searchForEnvironment = "";
      },
      apply() {
        this.$emit("apply", this.environmentsSelected);
      },
      checkState() {
      },
  
      reset() {
        this.environmentsSelected = [];
        this.searchForEnvironment = "";
      },
      async getIndustryTree() {
        this.loading = true;
        let industryTreeObject={
          returnOption:"3",
          groupIDs:"",
          onlyLive:this.onlyLive,
          recordsOnly:false,
        }
        await this.$store.dispatch("getSaharaEnvironments",industryTreeObject).then((res) => {
          if(res.results && res.results.industryTree && this.environmentsSelected.length>0)
          {
            let newResults=res.results.industryTree;
            var newEnvironments=[];
            this.environmentsSelected.forEach((selected)=>{
              if(selected.environmentID)
              {
              var indIndex=newResults.findIndex((ind)=>{ 
                return ind.industryID==selected.industryID});
              if(indIndex!=-1)
              {
              let foundEnv =newResults[indIndex].environments.find((env)=>env.environmentID==selected.environmentID)
                if(foundEnv)
                {
                  newEnvironments.push(foundEnv);
                }
              }
              }
            })
            this.environmentsSelected=newEnvironments;
          }
          return res;
        });

        this.loading = false;
      },
      removeFilter: function (filterObj) {

        this.environmentsSelected = this.environmentsSelected.filter((name) => {
          return filterObj !== name;
        });
        if(filterObj.industryName)
            {
                this.environmentsSelected=this.environmentsSelected.filter((obj)=>{
                  return obj.industryID!=filterObj.industryID
                })
            }

      },
  
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../styles/BasicModal.scss";
  @import "../styles/Buttons.scss";
  @import "../styles/IndustryTree.scss";
  </style>
  