<template>
    <div class="enrollment-container">
      <div class="sidemenu"> 
        <SideMenu></SideMenu>
      </div>
      <div id="navbar-container">
      <Navbar>Course Reports</Navbar>
  
    <div id="da">
      <div class="da-header">
        <span class="title">
          <font-awesome-icon
                  class="dashboard-title-icon"
                  :icon="['fas', 'star']"
                  style="color: rgb(251, 143, 6) ;transform: rotate(180deg);"
                />
          Course Reports
        </span>
      </div>
      <div class="da-content">
        <div class="wrapper">
          <div class="da-group-list" 
          v-if="!singleGroupUser"
          >
          
            <div class="search-bar">
            <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
            <input
              type="search"
              placeholder="Search for Group"
              class="search-input"
              v-model="companyName"
            />
          </div>
            <div
              id="group-list"
              :class="{ darkmode: this.$store.state.darkmode }"
            >
              <div class="da-group-tree-browser" >
                <TreeBrowser
                  :node="this.filteredCompanies || this.companyTree"
                  @onClick="groupClicked"
                  :selectedKey="selectedKey"
                  @update:selectedKey="updateKey"
                  v-if="Object.keys(this.companyTree).length !== 0 && !loadingCompanies"
                />
                <div v-if="loadingCompanies" class="loading-spinner">
                  <Spinner />
                </div>
              </div>
            </div>
            <!-- <button @click="resetGroup">Reset</button> -->

          </div>
        </div>
        <div class="da-body" >
          <div class="da-group-header subtitle">
            <div v-if="this.theGroup.groupName && this.theGroup.children &&!this.singleGroupUser" class="group-title"> 
              <span v-if="this.theGroup.children.length>0">&#9660;</span>
              <span v-else>&#9671;</span>
            {{ this.theGroup.groupName }}
            </div>
            <div v-else>
              <span>&nbsp;</span>
              </div>
          </div>
          <div class="da-group-data" >
            <div class="course-list">
              <div class="course-list-header">
                <div class="search-bar">
                <font-awesome-icon
                  class="search-icon"
                  :icon="['fas', 'search']"
                />
                <input
                  type="search"
                  class="search-input"
                  placeholder="Search for Course"
                  v-model="courseName"
                />
              </div>
              </div>
              <div
                class="course-list-data text"
                :class="{ darkmode: this.$store.state.darkmode }"
              >
                  <table style="border:none;" >
                    <tbody v-if="coursesLoading" class="loading-spinner">
                      <Spinner/>
                    </tbody>
                    <tbody v-if="!coursesLoading" >
                      <tr
                        v-for="course in filteredCompanyCourses"
                        :key="course.index"
                        :class="{
                          selected: course.courseID == selectedCourse,
                        }"
                        class="course-tr"
                      >
                         <td class="course-td" @click="selectCourse(course)">&#9671; {{ course.courseName }}</td>
                        </tr>
                        </tbody>
                        </table>

              </div>
            </div>
  
            <div class="learner-data">
              <div class="learner-list">
                <div class="course-info">
              <div class="org-test-stats-data">
              <div
                class="tests-taken-card"
                :class="{ darkmode: this.$store.state.darkmode }"
              >
                <span class="tests-taken-card-title"  ><span v-if="courseData.courseName">{{ courseData.courseName }}</span><span v-else>Select a course to see results</span></span>
                <span class="tests-taken-card-data" >
                  <span>Registered: <span v-show="courseData.registeredUsers!=null">{{ courseData.registeredUsers }}</span></span>
                  <span>Started: <span v-show="courseData.usersInProgress!=null">{{ courseData.usersInProgress }}</span></span>
                  <span>Mastered: <span v-show="courseData.masteredUsers!=null">{{ courseData.masteredUsers }}</span></span>
                </span>
                <span class="score-card-title"> Score </span>
                <span class="score-card-data" >
                  <div v-if=" courseData.courseScores">
                  <span>High: <span v-if=" courseData.courseScores.max ">{{ courseData.courseScores.max }}%</span></span>
                  <span>Low: <span v-if="courseData.courseScores.min">{{ courseData.courseScores.min }}%</span></span>
                  <span>Average: <span v-if="courseData.courseScores.avg">{{ courseData.courseScores.avg }}%</span></span>
                  <span>Pass Rate: <span v-if="courseData.coursePassRate.passRate">{{ courseData.coursePassRate.passRate }}%</span></span>
                  </div>
                  <div v-else>
                  <span>High: </span>
                  <span>Low: </span>
                  <span>Average: </span>
                  <span>Pass Rate: </span>
                  </div>
                </span>
                <span class="duration-card-title">Exam Duration </span>
                <span class="duration-card-data" >
                  <div v-if="courseData.coursePostTestDuration">
                  <span>Max: <span v-if="courseData.coursePostTestDuration.max">{{ secondsToMs(courseData.coursePostTestDuration.max) }}</span></span>
                  <span>Min: <span v-if="courseData.coursePostTestDuration.min">{{ secondsToMs(courseData.coursePostTestDuration.min) }}</span></span>
                  <span>Average: <span v-if="courseData.coursePostTestDuration.avg">{{ secondsToMs(courseData.coursePostTestDuration.avg) }}</span></span>
                </div>
                <div v-else>
                  <span>Max: </span>
                  <span>Min: </span>
                  <span>Average: </span>
                </div>
                </span>
              </div>
            </div>
                </div>
                
                <div class="mastery-table">
                <MasteryTableV2 
                :hideFilterOptions=true
                :selectedGroup="theGroup"                
                :selectedCourse="selectedCourse"
                >
                </MasteryTableV2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  </template>
  <script>
  import { mapState } from "vuex";
  import Navbar from "../components/Navbar.vue";
  import SideMenu from "../components/SideMenu.vue";
  import TreeBrowser from "../components/TreeBrowser.vue";
  import Spinner from "../components/Spinner.vue";
  
  import MasteryTableV2 from "../components/MasteryTableV2";
  
  export default {
    name: "CourseReports",
    components: {
      Navbar,
      SideMenu,
      TreeBrowser,
      Spinner,
      MasteryTableV2,
    },
    watch:{
    companyTree: function() {
      if(this.singleGroupUser)
      {
        this.groupClicked(this.companyTree[0]);
      }
      },
    },
   created() {
      if(Object.keys(this.courseList).length==0)
      {
        this.coursesLoading=true;
        this.getCourseList("");
      }
      if(Object.keys(this.companyTree).length==0)
      {
        this.loadingCompanies=true;
        this.$store.dispatch("groupTree").then(() => {
          this.loadingCompanies=false;
        });
      }
      if(this.singleGroupUser && this.companyTree[0])//When they are only in one group we want to select the only group
      {
        this.groupClicked(this.companyTree[0]);
      } 
    },
    data() {
      return {
        loadingCompanies: false,
        companyName: "",
        theGroup: {},
        theGroupID: "",
        courseName: "",
        courseList: [],
        selectedKey:null,
        selectedCourse:"",
        selectedCourseName:"",
        coursesLoading:false,
        courseData:{},
      };
    },
    computed: {
      ...mapState({
        adminGroups: (state) => state.groups,
        companyTree: (state) => state.groupTree,
        singleGroupUser: (state)=>state.singleGroupUser,
      }),
  
      filteredCompanies: function () {
        let stack = [],
          node,
          ii;
        let children = [];
        let matches = {};
        if(this.companyTree.length)
        {
          for(var company in this.companyTree)
          {
            stack.push(this.companyTree[company]);
          }
        }
        else{
          stack.push(this.companyTree);
        }
        while (stack.length > 0) {
          node = stack.pop();
          if (
            node.Name ||
            (node.groupName && node.groupName.toLowerCase().match(this.companyName.toLowerCase()))
          ) {
            children.push(node);
          } else if (node.children && node.children.length) {
            for (ii = 0; ii < node.children.length; ii += 1) {
              stack.push(node.children[ii]);
            }
          }
        }
        return Object.assign(matches, {
          children: children,
        });
      },
  
      filteredCompanyCourses: function () {
        let filter = "";
      //   this.courseList.forEach((courseObj) => {
          filter = this.courseList.filter((course) => {
            return course.courseName
              .toLowerCase()
              .match(this.courseName.toLowerCase());
          // });
        });
        return filter;
      },
    },
    methods: {
      resetGroup()
      {
        this.theGroup={};
        this.theGroupID="";
        this.selectedKey=null;
        this.getCourseList("");
        // if(this.selectedCourse!="")
        // {
        //   this.selectCourse({courseID:this.selectedCourse,courseName:this.selectedCourseName});
        // }

      },
     secondsToMs(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secondsLeft = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
    },
     
      selectCourse(course)
      {
          this.selectedCourse=course.courseID; 
          this.selectedCourseName=course.courseName;
        let courseInfoObj={
          courseID:course.courseID,
          groupID:this.theGroup.ID?this.theGroup.ID:"",
        }
        this.$store.dispatch("getGeneralContentData", courseInfoObj)
          .then((data) => {
            if(data.status=='success')
            {
              this.courseData=data.results;
            }
          });
          
      },
  
      updateKey(groupID){
        this.selectedKey=groupID;
      },
  
      // getUserList(nodeid) {
      //     this.userList=[];
      //   this.$store.dispatch("getUserList", { groupID: nodeid }).then((data) => {
      //     this.userList = data;
      //   });
      // },
  
      getCourseList(groupID) {
        this.coursesLoading=true;
        this.$store.dispatch("getCourseList", groupID).then((courses) => {
          this.courseList=Object.values(courses);
          this.coursesLoading=false;
          if(Object.keys(this.courseData).length!=0 && this.courseData.contentID && this.courseList.some((course)=>{return course.courseID==this.courseData.contentID;}))
          {
            this.selectCourse({courseID:this.selectedCourse,courseName:this.selectedCourseName});
          }
        });
      },
  
      groupClicked(node) {
        // this.courseData={};
        // this.selectedCourse="";
          if(!node.ID)//The root node would load something when it should not
          {
              return;
          }
          this.courseData={};
          this.selectedCourse="";

        this.theGroup = node;
        this.theGroupID = node.ID;
  
        // this.getUserList(this.theGroupID); //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!IN SOME CASES THESE WILL OVERLAP AND WE WILL HAVE COURSES RETURNED IN THE USER SPOT
        this.getCourseList(this.theGroupID);
  
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/CourseReports.scss";
  @import "../components/styles/BasicModal.scss";
  </style>