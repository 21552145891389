<template>
  <div class="modal modal-groups-select">
    <header class="modal-header">
      <slot name="header">
        <h3>
          Groups
        </h3>
      </slot>
    </header>
    <section
      class="modal-body"
      :class="{ darkmode: this.$store.state.darkmode }"
    >
      <slot name="body">
        <input
          class="modal-search-bar"
          type="text"
          placeholder="Search for Group"
          v-model="searchForGroup"
        />
        <table>
          <!-- //groupID;member;wasMember[;isPrimaryGroup;isAdministrator;isAuthor;isLearner;isVerifier] -->
          <thead>
            <th @click="sortTable('isMember')">Member <SortArrow column="isMember" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>
            <th @click="sortTable('groupName')">Group <SortArrow column="groupName" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>
            <th @click="sortTable('isPrimaryGroup')">Primary Group <SortArrow column="isPrimaryGroup" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>
            <th @click="sortTable('isAdmin')">Administrator <SortArrow column="isAdmin" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>
            <!-- <th>Author</th> -->
            <th @click="sortTable('isLearner')">Learner <SortArrow column="isLearner" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>
            <!-- <th>Verifier</th> -->
          </thead>
          <tr
            v-for="(value, index, test) in filteredGroups"
            v-bind:key="index"
            :class="test % 2 === 0 ? 'even' : 'odd'"
          >
            <td>
              <Checkbox
                :index="value.groupID"
                :checked="userGroups"
                method="isMember"
                @change="toggle"
              />
            </td>
            <td>
              {{ value.groupName }}
            </td>
            <td>
              <Checkbox
                :index="value.groupID"
                :checked="userGroups"
                method="isPrimaryGroup"
                @change="toggle"
              />
            </td>
            <td>
              <Checkbox
                :index="value.groupID"
                :checked="userGroups"
                method="isAdmin"
                @change="toggle"
              />
            </td>
            <!-- <td>
              <Checkbox
                :index="index"
                :checked="userGroups"
                method="isAuthor"
                @change="toggle"
              />
            </td> -->
            <td>
              <Checkbox
                :index="value.groupID"
                :checked="userGroups"
                method="isLearner"
                @change="toggle"
              />
            </td>
            <!-- <td>
              <Checkbox
                :index="index"
                :checked="userGroups"
                method="isVerifier"
                @change="toggle"
              />
            </td> -->
          </tr>
        </table>
      </slot>
    </section>

    <footer class="modal-footer">
      <slot name="footer">

        <button type="button" class="btn-danger" @click="cancel">
          Cancel
        </button>
        <button type="button" class="btn-apply" @click="confirm">
          Apply
        </button>
        <!-- <button type="button" class="btn-danger" @click="test">
          test
        </button> -->
      </slot>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

// import { debounce } from "../../util/helper.js";

import Checkbox from "../Checkbox.vue";

import SortArrow from "../SortArrow.vue";

export default {
  name: "RegisterToGroup",

  components: {
    Checkbox,
    SortArrow,
  },

  data() {
    return {
      searchForGroup: "",
      userGroups: {},
      sortAsc:true,
      sortBy:'groupName',
      sortString:false,
      filteredGroups:{},
      initialSearch:"",
    };
  },

  props: {
    loginID: String,
    checked: Object,
    selectedGroup: String,
    selectedGroupID: Number,
  },

  watch: {
    searchForGroup: {
      // handler: debounce(function(val) {
      //   this.searchForGroup = val;
      //   this.filterGroups();
      // }, 0),
            handler:function(val) {
        this.searchForGroup = val;
        this.filterGroups();
      }
    },

    selectedGroup: {
      handler:function(val){
        this.searchForGroup="";
        this.initialSearch=val;
      }
    },
    loginID:{
      handler:function(){
        //This will clear the 
        this.sortAsc=true;
        this.sortBy='groupName';
        this.searchForGroup="";
        this.initialSearch=this.selectedGroup;
      }
    },

    checked: {
      deep: true,
      handler: function() {
        this.userGroups = this.checked;
        this.searchForGroup="";
        this.initialSearch=this.selectedGroup;
        // this.sortAsc=true;
        // this.sortBy='isMember';
        this.filterGroups();
      },
    },
  },

  computed: {
    ...mapState({
      groups: (state) => state.groups,
    }),
  },

  methods: {
    filterGroups() { //!previously this was using this.groups but we had a group that was undefined and was causing issues. It was swapped over to using all userGroups but might need to go back
      // const values = Object.values(this.groups);
      const keys = Object.keys(this.userGroups);
      var output = {};
      var remaining={};
      if(this.sortBy)
        {
          if(Object.keys(this.userGroups).length>0 &&this.userGroups[0] )
          {
          let innerUserGroups=this.userGroups;
          let innerSort=this.sortBy;
          switch(typeof(innerUserGroups[0][innerSort]))
          {
            case 'boolean':
              //If it is boolean it will sort by the option first then if they are members so it will show related and then alphabetically
              if(this.sortAsc)
              {
                keys.sort(function(y,x){
                return (Number(innerUserGroups[x][innerSort]) - Number(innerUserGroups[y][innerSort])) || (Number(innerUserGroups[x]['isMember']) - Number(innerUserGroups[y]['isMember'])) || innerUserGroups[y]['groupName'].localeCompare( innerUserGroups[x]['groupName']);
                })
              }
              else{
                keys.sort(function(x,y){
                return (Number(innerUserGroups[x][innerSort]) - Number(innerUserGroups[y][innerSort])) || (Number(innerUserGroups[x]['isMember']) - Number(innerUserGroups[y]['isMember'])) || innerUserGroups[x]['groupName'].localeCompare( innerUserGroups[y]['groupName']);
                })
              }
            break;

            case 'string':
              if(this.sortAsc)
              {
                keys.sort(function(x,y){
                return innerUserGroups[x][innerSort].localeCompare( innerUserGroups[y][innerSort]);
                });
              }
              else{
                keys.sort(function(y,x){
                return innerUserGroups[x][innerSort].localeCompare( innerUserGroups[y][innerSort]);
                });
              }
            break;
          }
        }
        else
        {
          // switch(this.sortBy)
          // {
          //   case 'groupName':
          //       var innerGroups=this.groups;
          //       keys.sort(function(x,y){
          //       return innerGroups[x].localeCompare( innerGroups[y]);
          //       })
          //   break;
          // }
        }
          // if(typeof(innerUserGroups[0][innerSort])=='boolean')
          // {
            
          // }

      }
      // else if (this.sortBy && this.sortString )
      // {
      //   let innerUserGroups=this.userGroups;
      //   let innerSort=this.sortBy;
      //   keys.sort(function(y,x){
      //     innerUserGroups[x][innerSort].localeCompare( innerUserGroups[y][innerSort]);
      //   })
      // }
    if(!this.initialSearch)
    {
      keys.forEach((groupID,index) => {
        if(this.userGroups[groupID]==null || this.userGroups[groupID]==undefined || !this.userGroups[groupID].groupName)
        {
          console.error('We have found a groupID that would cause an error',groupID);
          return;
        }
        if (this.userGroups[groupID].groupName.toLowerCase().match(this.searchForGroup.toLowerCase())) {
          let newObj={
            groupID:groupID,
            groupName:this.userGroups[groupID].groupName,
          };
          output[index]=newObj;
        }

      });
      this.filteredGroups=output;
      return output;
    }
    else{
      keys.forEach((groupID,index) => {
        if(this.userGroups[groupID]==null || this.userGroups[groupID]==undefined || !this.userGroups[groupID].groupName)
        {
          console.error('We have found a groupID that would cause an error',groupID);
          return;
        }
        let newObj={
            groupID:groupID,
            groupName:this.userGroups[groupID].groupName,
          };

        if(this.selectedGroupID==groupID)
        {
            if(Object.keys(output).length==0)
            {
              output[0]=newObj;
            }
            else{
              let temp=output[0];
              output[0]=newObj;
              output[Object.keys(output).length]=temp;
              
            }
        }
        //? re enable to have groups with a similar name appear near the selected option
        else if ( this.userGroups[groupID].groupName.toLowerCase().match(this.initialSearch.toLowerCase())) {
          output[Object.keys(output).length]=newObj;
        }
        else{
          remaining[index]=newObj;
        }
      });

      Object.values(remaining).forEach((groupObj)=>{
        output[Object.keys(output).length]=groupObj;
      });
      // output.append(remaining);
      this.initialSearch="";
      this.filteredGroups=output;
      return output;
    }
      
    },

    sortTable(name)
    {
      if(this.sortBy==name)
      {
        this.sortAsc=!this.sortAsc;
      }
      else{
        this.sortAsc=true;
      }
      if(name=='groupName')
      {
        this.sortString=true;
      }
      else{
        this.sortString=false;
      }
      this.sortBy=name;
      this.filterGroups();
    },

    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("userGroups", this.userGroups);
    },

    async toggle(data) {
     data[Object.keys(data)[0]].changed=true;
     //Clear the other variables
     if(data[Object.keys(data)[0]].changed==true && data[Object.keys(data)[0]].isMember==false)
     {
      data[Object.keys(data)[0]].isAdmin=false;
      data[Object.keys(data)[0]].isLearner=false;
      data[Object.keys(data)[0]].isPrimaryGroup=false;
     }

     if(data[Object.keys(data)[0]].changed==true && data[Object.keys(data)[0]].isPrimaryGroup==true)
     {
     await Object.values(this.userGroups).forEach((group)=>{
        if(group.isPrimaryGroup)
        {
          group.isPrimaryGroup=false;
        }
      })
     }

    //  data[Object.keys(data)[0]].isMember=!data[Object.keys(data)[0]].isMember;
    // this.userGroups[Object.keys(data)[0]]=Object.values(data)[0];
      this.userGroups = { ...this.userGroups, ...data };
    },


  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/RegisterToGroup.scss";
</style>
