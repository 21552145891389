<template>
  <div class="enrollment-container">
    <div class="sidemenu"> 
      <SideMenu></SideMenu>

    </div>
    <div id="navbar-container">
    <Navbar>Detailed Analytics</Navbar>

  <div id="da">
    <div class="da-header">
      <span class="title">
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'user-plus']" /> -->
        <font-awesome-icon
                class="dashboard-title-icon"
                :icon="['fas', 'star']"
                style="color: rgb(251, 143, 6) ;transform: rotate(180deg);"
              />
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'user-plus']" /> -->
        SWIFT Courses 
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'chart-bar']" /> -->
        <!-- SWIFT Analytics -->
      </span>
    </div>
    <div class="da-content">
      <div class="wrapper">
        <div class="da-group-list" 
        v-if="!singleGroupUser"
        >
          <div class="search-bar">
          <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
          <!-- <font-awesome-icon class="search-icon" :icon="['fas', 'search']" /> -->
          <input
            type="search"
            placeholder="Search for Group"
            class="search-input"
            v-model="companyName"
          />
        </div>
          <div
            id="group-list"
            :class="{ darkmode: this.$store.state.darkmode }"
          >
            <div class="da-group-tree-browser" >
              <TreeBrowser
                :node="this.filteredCompanies || this.companyTree"
                @onClick="nodeWasClicked"
                :selectedKey="selectedKey"
                @update:selectedKey="updateKey"
                v-if="Object.keys(this.companyTree).length !== 0 && !loadingCompanies"
              />
              <div v-if="loadingCompanies" class="loading-spinner">
                <Spinner />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="da-body" >
        <div class="da-group-header subtitle">
          <div v-if="this.theNode.groupName && this.theNode.children &&!this.singleGroupUser" class="group-title"> 
            <span v-if="this.theNode.children.length>0">&#9660;</span>
            <span v-else>&#9671;</span>
          {{ this.theNode.groupName }}
          </div>
        </div>
        <div class="da-group-data">
          <div class="course-list">
            <div class="course-list-header">
              <div class="search-bar">
              <font-awesome-icon
                class="search-icon"
                :icon="['fas', 'search']"
              />
              <input
                type="search"
                class="search-input"
                placeholder="Search for Course"
                v-model="courseName"
              />
            </div>
            </div>
            <div
              class="course-list-data text"
              :class="{ darkmode: this.$store.state.darkmode }"
            >
              <!-- <div v-if="loading" class="spinner">
                <Spinner />
              </div> -->
              <!-- <div
                v-else
                v-for="course in filteredCompanyCourses"
                :key="course.courseID"
                @click="selectCourse(course.courseID)"
                :class="{
                  selected: course.courseID == selectedCourse,
                }"
              > -->
                <!-- &#9671; {{ course.courseName }}  -->
                <table style="border:none;">
                  <thead v-if="selectedUser!='' && !coursesLoading">
                    <tr >
                      <!-- <th>Course</th> -->
                      <!-- <th>Status</th> -->
                    </tr>
                  </thead>
                  <tbody v-if="coursesLoading" class="loading-spinner">
                    <Spinner/>
                  </tbody>
                  <tbody v-if="!coursesLoading">
                    <tr
                      v-for="course in filteredCompanyCourses"
                      :key="course.index"
                      :class="{
                        selected: course.courseID == selectedCourse,
                      }"
                      class="course-tr"
                    >
                      <td class="course-td" @click="selectCourse(course)">&#9671; {{ course.courseName }}</td>
                      <td v-if="course.groupName && course.registered==false &&selectedCourse==''" class="course-td">
                      <button
                        class="btn"
                        id="enroll"
                        size="sm"
                        @click="enrollLearner(selectedUser,course.courseID,true,course)"
                      >
                        <strong v-if="!course.loadingEnroll==true">Enroll</strong>
                        <strong v-else>Loading</strong>
                      </button>
                      </td>
                      <td v-if="course.groupName && course.registered==true && selectedCourse==''" class="course-td">
                      <button
                        class="btn"
                        id="unenroll"
                        size="sm"
                        @click="confirmUnenroll(selectedUser,course.courseID,true,course)"
                      >
                        <strong>Unenroll</strong>
                      </button>
                      </td>
                      </tr>
                      </tbody>
                      </table>
              <!-- </div> -->
            </div>
          </div>

          <div class="learner-data">
            <div class="learner-list">
              <div class="learner-list-header">
                <div class="search-bar">
                <font-awesome-icon
                  class="search-icon"
                  :icon="['fas', 'search']"
                />
                <input
                  type="search"
                  class="search-input"
                  placeholder="Search for Learner"
                  v-model="memberName"
                />
                </div>
                <button v-if="selectedCourse!==''" class="unenroll-all" @click="this.unenrollAll">
                  Unenroll All
                </button>
                <!-- <button>sdfsds</button> -->
                <button v-if="selectedCourse!==''" class="enroll-all" @click="this.enrollAll">
                  Enroll All
                </button>
                <!-- <button class="enroll-all" @click="addUser()">
                  Add User
                </button> -->
              </div>

              <div
                class="learner-list-data"
                :class="{ darkmode: this.$store.state.darkmode }"
              >
                <table >
                  <thead v-if="filteredCompanyMembers.length>0 && !usersLoading">
                    <tr>
                      <th style="border:none;padding-right: 10px;">Enabled</th>
                      <th style="border:none;"></th>
                      <th style="border:none;"></th>
                      <!-- <th>Login ID</th> -->
                      <th style="border:none;" v-if="selectedCourse!=''"></th>

                      <!-- <th style="border:none;"></th> -->
                    </tr>
                  </thead>
                  <tbody v-if="usersLoading" class="loading-spinner">
                    <Spinner/>
                  </tbody>
                  <tbody v-if="!usersLoading">
                    <tr
                      v-for="user in filteredCompanyMembers"
                      :key="user.ID"
                      
                      :class="{
                        selected: user.ID == selectedUser,
                      }"
                    >
                    <td style="text-align:center;" @click="selectUser(user.ID)"><span v-if="user.enabled">&#10004;</span></td>
                      <td style="font-size:medium;" @click="selectUser(user.ID)">{{ user.firstName }} {{ user.lastName }}</td>
                      <td class="growable-cell" @click="selectUser(user.ID)">{{user.email}}</td>
                      <!-- <td>{{user}}</td> -->
                      <!-- <td>
                        {{ user.ID }}
                      </td> -->

                      <td v-if="selectedCourse!==''">
                      <button
                        class="btn"
                        id="enroll"
                        size="sm"
                        @click="enrollLearner(user.ID,selectedCourse,false,user)"
                        v-if="user.enrolled === false && selectedCourse!==''"
                      >
                        <strong v-if="!user.loadingEnroll">Enroll</strong>
                        <strong v-else>Loading</strong>
                      </button>
                      <button
                        class="btn"
                        id="unenroll"
                        size="sm"
                        @click="confirmUnenroll(user.ID,selectedCourse,false,user)"
                        v-if="user.enrolled !== false && selectedCourse!==''"
                      >
                        <strong>Unenroll</strong>
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <AddOrg v-show="AddOrgvisible" @close="close" @confirm="confirm" /> -->
    <div v-show="Confirmationvisible" class="modal-backdrop">
      <Confirmation
        v-show="Confirmationvisible"
        :unenrollMany="this.unenrollMany"
        :enrollment="this.userToUnenroll"
        @cancel="close"
        @confirm="confirmedToUnenroll"
      />
    </div>
  </div>
  </div>
</div>
</template>
<script>
import { mapState } from "vuex";
// import { sleep } from "../util/helper";
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import TreeBrowser from "../components/TreeBrowser.vue";
import BarLineChart from "../components/BarLineChart.js";
import Spinner from "../components/Spinner.vue";

import AddOrg from "../components/modals/AddOrg.vue";
import Confirmation from "../components/modals/Confirmation.vue";

export default {
  name: "Enrollment",
  components: {
    Navbar,
    SideMenu,
    TreeBrowser,
    BarLineChart,
    Spinner,
    AddOrg,
    Confirmation,
  },
  watch:{
  companyTree: function() {
    if(this.singleGroupUser)
    {
      this.nodeWasClicked(this.companyTree[0]);
    }
    },
  },
 created() {
    if(Object.keys(this.companyTree).length==0)
    {
      this.loadingCompanies=true;
      this.$store.dispatch("groupTree").then(() => {
        this.loadingCompanies=false;
      });
    }
    if(this.singleGroupUser && this.companyTree[0])//When they are only in one group we want to select the only group
    {
      this.nodeWasClicked(this.companyTree[0]);
    } 
  },
  data() {
    return {
      Confirmationvisible: false, 
      userToUnenroll: {
        loginID: "",
        contentName: "",
      },
      unenrollMany: [],
      loadingCompanies: false,
      companyName: "",
      theNode: {},
      theNodeID: "",
      visible: false,
      memberName: "",
      courseName: "",
      userList: [],
      courseList: [],
      status: {},
      selectedKey:null,
      selectedCourse:"",
      selectedCourseName:"",
      selectedUser:"",
      usersLoading: false,
      coursesLoading:false,
    };
  },
  computed: {
    ...mapState({
      adminGroups: (state) => state.groups,
      companyTree: (state) => state.groupTree,
      singleGroupUser: (state)=>state.singleGroupUser,
    }),

    filteredCompanies: function () {
      let stack = [],
        node,
        ii;
      let children = [];
      let matches = {};
      if(this.companyTree.length)
      {
        for(var company in this.companyTree)
        {
          stack.push(this.companyTree[company]);
        }
      }
      else{
        stack.push(this.companyTree);
      }
      while (stack.length > 0) {
        node = stack.pop();
        if (
          node.Name ||
          (node.groupName && node.groupName.toLowerCase().match(this.companyName.toLowerCase()))
        ) {
          children.push(node);
        } else if (node.children && node.children.length) {
          for (ii = 0; ii < node.children.length; ii += 1) {
            stack.push(node.children[ii]);
          }
        }
      }
      return Object.assign(matches, {
        children: children,
      });
    },
    filteredCompanyMembers: function () {
      return this.userList.filter((user) => {
        return (
          user.firstName.toLowerCase().match(this.memberName.toLowerCase()) ||
          user.lastName.toLowerCase().match(this.memberName.toLowerCase())
        );
      });
    },

    filteredCompanyCourses: function () {
      let filter = "";
    //   this.courseList.forEach((courseObj) => {
        filter = this.courseList.filter((course) => {
          return course.courseName
            .toLowerCase()
            .match(this.courseName.toLowerCase());
        // });
      });
      return filter;
    },
  },
  methods: {
    enrollmentStatus() {
      this.status={};
      this.userList=[];
      const enrollmentInfo = {
        groupID: this.theNode.ID,
        contentID: this.selectedCourse,
      };
      this.$store
        .dispatch("getUsersForContentSeating", enrollmentInfo)
        .then((data) => {
          this.status = data;
        this.userList=data;
        });
    },

    selectUser(userID)
    {
        this.selectedCourse="";
        this.selectedUser=userID;
       
        if(this.theNodeID!="")
        {
            this.getCoursesForUser(userID);
        }
    },
    selectCourse(course)
    {
        this.selectedUser="";
        this.selectedCourse=course.courseID; 
        this.selectedCourseName=course.courseName
        this.enrollmentStatus();
    },

    close() {
      this.Confirmationvisible = false;
      this.unenrollMany = [];
    },

    updateKey(groupID){
      this.selectedKey=groupID;
    },

    getUserList(nodeid) {
      this.usersLoading=true;
        this.userList=[];
      this.$store.dispatch("getUserList", { groupID: nodeid }).then((data) => {
        this.userList = data;
        this.usersLoading=false;
      });
    },

    getCourseList(groupID) {
      this.coursesLoading=true;
      this.$store.dispatch("getCourseList", groupID).then((courses) => {
        // this.courseList = Object.keys(this.$store.state.courseNameAndID); // This one for name displayed. issue with passing the key when selected
        // if (this.courseList.length > 0) {
        //   this.courseList = [];
        // }
        // this.courseList.push(Object.values(this.$store.state.courseNameAndID));
        this.courseList=Object.values(courses);
        this.coursesLoading=false;
      });
    },

    getCoursesForUser(userID)
    {
      this.coursesLoading=true;
        this.courseList=[];
        let userCourseObj={
            loginID:userID,
            groupID:this.theNodeID
        }
        this.$store.dispatch('getContentForSeating',userCourseObj).then((courses)=>{
            this.courseList=courses;
            this.coursesLoading=false;
        });
    },
    nodeWasClicked(node) {
      this.visible = !this.visible;
      this.selectedCourse="";
      this.selectedUser="";
        if(!node.ID)//The root node would load something when it should not
        {
            return;
        }

      this.theNode = node;
      this.theNodeID = node.ID;

      // this.callAdminGroupInfoAPI(this.theNodeID);
      this.getUserList(this.theNodeID); //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!IN SOME CASES THESE WILL OVERLAP AND WE WILL HAVE COURSES RETURNED IN THE USER SPOT
      this.getCourseList(this.theNodeID);

      this.resetTabsData();
    },
    resetTabsData() {
      this.status = {};
    //   this.setCourse("", "");
    },
  

    closeModals(){
      this.registerUserModalVisible=false;
      this.confirmationModalVisible=false;
    },

    // the function below is not implemented
    // deleteGroup(nodeid) {
    //   this.$store.dispatch("deleteGroup", { groupID: nodeid }).then((res) => {
    //     this.getGroupTree();
    //   });
    // },
    enrollAll() {
      for (let user of this.userList) {
        if(user.enrolled==false)// We only need to do it for the non registered users 
        {
          const enrollData = {
          loginID: user.loginID,
          contentID: this.selectedCourse,
        };
        this.$store.dispatch("enrollLearner", enrollData).then(() => {
        });
        }
      }
      this.enrollmentStatus();
    },
    unenrollAll() {
      let startedArr = [];
      for (let user of this.status) {
        const enrollData = {
          loginID: user.loginID,
          contentID: this.selectedCourse,
          contentName: this.selectedCourseName,
          fromCourse:false,
        };
        if (user.started != null && user.enrolled == true) {
          startedArr.push(enrollData);
        } else {
          this.$store.dispatch("unenrollLearner", enrollData).then(() => {
            this.enrollmentStatus();
          });
        }
      }
      if(startedArr.length>0)
      {
        this.unenrollMany = startedArr;
        this.Confirmationvisible = true;
      }
    },
    confirmedToUnenroll(data) {
      this.Confirmationvisible = false;
      if (data.length === 0 && this.userToUnenroll.loginID!='') {
        this.unenrollLearner(this.userToUnenroll);
      }
      else{
        data.map((user) => {
        this.unenrollLearner(user);
      });
      }
    },
    enrollLearner(userID,courseID,fromCourse,userCourse) {
      userCourse.loadingEnroll=true;
      const enrollData = {
        loginID: userID,
        contentID: courseID,
      };
      this.$store.dispatch("enrollLearner", enrollData).then(() => {
        if(fromCourse==false)
        {
            // this.enrollmentStatus();
            userCourse.enrolled=true;
        }
        else{
            // this.getCoursesForUser(userID);
            userCourse.registered=true;
        }
        userCourse.loadingEnroll=false;
      });
    },
    unenrollLearner(user) {
      this.Confirmationvisible = false;
      const enrollData = {
        loginID: user.loginID,
        contentID: user.courseID||user.contentID,
      };
      this.$store.dispatch("unenrollLearner", enrollData).then(()=>{
        this.unenrollMany = [];
        // this.enrollmentStatus();
        if(user.fromCourse==false)
        {
          //need to make enrolled = false for the user
          var userIndex=this.userList.findIndex(findUser=>findUser.loginID==user.loginID)
          this.userList[userIndex].enrolled=false;
        }
        else{
          //need to make registered = false for the course
          var courseIndex=this.courseList.findIndex(course=>course.courseID==user.courseID)
          this.courseList[courseIndex].registered=false;
        }
      })

    },
    confirmUnenroll(userID,courseID,fromCourse,userCourse) {
      userCourse.loadingEnroll=true;
      const enrollData = {
        loginID: userID,
        contentID: courseID,
      };
      if(fromCourse==false)
      {
        if (userCourse.started != "null") {
        this.Confirmationvisible = true;
        this.userToUnenroll.loginID = userID;
        this.userToUnenroll.contentName = this.selectedCourseName;
        this.userToUnenroll.courseID=courseID;
        this.userToUnenroll.fromCourse=false;
      } else {
        this.$store.dispatch("unenrollLearner", enrollData).then(() => {
          // this.enrollmentStatus();
          userCourse.enrolled=false;
        });
      }
      }
      else{
      {
        if (userCourse.dateStarted != "null") {
        this.Confirmationvisible = true;

        this.userToUnenroll.loginID = userID;
        this.userToUnenroll.contentName = userCourse.courseName;
        this.userToUnenroll.courseID=courseID;
        this.userToUnenroll.fromCourse=true;
      } else {
        this.$store.dispatch("unenrollLearner", enrollData).then(() => {
          // this.enrollmentStatus();
          userCourse.registered=false;
        });
      }
      }
      }
      userCourse.loadingEnroll=false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/Enrollment.scss";
@import "../components/styles/BasicModal.scss";
</style>