<template>
  <div id="TestFunctions">
    <Navbar>Test Functions</Navbar>
    <SideMenu></SideMenu>

    <button v-on:click="callLogInAPI()">Login</button><br />

    <button v-on:click="callLogOutAPI()">Logout</button><br />

    <button v-on:click="callCourseAPI()">Course API</button><br />

    <button v-on:click="callAdminGroupInfoAPI()">
      Get Admin Group Properties</button
    ><br />
    <button v-on:click="callGetHostIPProperties()">
      Get Host IP Properties</button
    ><br />
    <button v-on:click="getGroupUsers()">Get Group Users - API 1219</button
    ><br />
    <button @click="getGroupsAPI()">getGroups</button>
    <button v-on:click="getGroupList()">Get Group List</button><br />
    <button v-on:click="generateReport()">Make a Report - API 1242</button
    ><br />
    <button v-on:click="getContentUsers()">
      users based on content - API 1221</button
    ><br />

    <button v-on:click="getContentLogins()">
      current logins content - API 1226</button
    ><br />
    <button v-on:click="getTree()">Get Tree</button><br />
    <button v-on:click="getCourseList()">Get Course List - API 1246</button
    ><br />

    <button v-on:click="getRegisteredUsers()">Get Registered Users</button
    ><br />
    <button v-on:click="getRegisteredCertifications()">
      Get Registered Certifications</button
    ><br />
    <button v-on:click="getAssignmentGroupTree()">
      Get Assignment Group Tree</button
    ><br />
    <button v-on:click="getCertifications()">Get Certifications</button><br />
    <button v-on:click="getUserList()">Get User List - API 1245</button><br />
    <button v-on:click="getCourseScores()">Get Course Scores - API 1283</button
    ><br />
    <button v-on:click="getRegisteredContent()">
      Get Registered Content - API 1211
    </button>
    <br />
    <button v-on:click="getContentProperties()">
      Get Content Properties - API 1266
    </button>
    <br />
    <button v-on:click="getUserGroups()">
      Get User Groups - API 1223
    </button>
    <br />
    <button @click="getGroupIDFromUser()">getGroupIDFromUser</button>
    <button v-on:click="getUserProperties()">
      Get User Properties - API 1249
    </button>
    <br />
    <button v-on:click="getSeatDetails()">
      Get Seat Details - API 1216
    </button>
    <br />
    <button v-on:click="getHistoryLogins()">
      Get History Logins - API 1230
    </button>
    <br />
    <button v-on:click="getContentForSeating()">
      Get Content For Seating - API 1248
    </button>
    <br />
    <button v-on:click="getUsersForContentSeating()">
      Get Users For Content Seating - API 1268
    </button>
    <br />
    <button v-on:click="getUserLKBS()">
      Get User LKBS - API 1277
    </button>
    <br />
    <button v-on:click="getUserLKBSdata()">
      Get User LKBS data - API 1279
    </button>
    <br />
    <button v-on:click="getUserLKBtests()">
      Get User LKB tests - API 1281
    </button>
    <br />
    <button v-on:click="getCourseModules()">
      Get Course Modules - API 1285
    </button>
    <br />
    <button v-on:click="getClassroomsForContent()">
      Get Classrooms for Content - API 1301
    </button>
    <br />
    <button v-on:click="updateUser()">
      Update User - API 1250
    </button>
    <br />
    <button v-on:click="getHostInformation()">
      Get Host Information - API 1233
    </button>
    <br />
    <button v-on:click="getUnregisteredCourses()">
      Get Unregistered Courses - API 1291
    </button>
    <br />
    <button v-on:click="getUnregisteredExams()">
      Get Unregistered Exams - API 1292
    </button>
    <br />
    <button v-on:click="getUsersForCourseComplete()">
      Get Users For Course Complete - API 1292
    </button>
    <br />
    <button v-on:click="deleteUser()">
      Delete User - API 1251
    </button>
    <br />
    <button v-on:click="getUsersData()">
      getUsersData - API 1333
    </button>
    <br />
    <button v-on:click="camsTestButton()">
      course list array - API 1246
    </button>
    <button @click="getAccountProperties()">getAccountProperties</button>
    <br />
    <button v-on:click="getModuleScores()">
      getModuleScores - API 1288
    </button>
    <br />
    <button @click="openGraph()">
      open graph modal
    </button>
    <br />
    <button @click="getEventLog()">
      getEvents - API 1210
    </button>
    <br />
    <button @click="getEventLogList()">
      getEventLogList - API 1289
    </button>
    <br />
    <button @click="getGroups2()">
      getGroups2
    </button>
    <br />
    <button @click="enrollLearner()">
      enroll in 384
    </button>
    <br />
    <button @click="loginGEMS()">
      gems login
    </button>
    <br />
    <div v-show="loginIssue" id="loginresults">
      <p>
        There was an issue with the login.This is possibly because you have
        another active session<br />Would you like to Force Login?<br />This
        will end your other sessions
      </p>
      <button v-on:click="forceLogin()">Yes</button>
      <button v-on:click="loginIssue = false">no</button>
    </div>
    <GraphModal v-show="graphModalVisible" @close="closeModal" />
  </div>
</template>
<script src="https://unpkg.com/vue-cookies@1.5.12/vue-cookies.js"></script>
<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import GraphModal from "../components/modals/GraphModal";

export default {
  name: "TestFunctions",
  components: {
    Navbar,
    SideMenu,
    GraphModal,
  },
  data() {
    return {
      loginIssue: false,
      graphModalVisible: false,
      tree: {},
    };
  },
  // Please reference store/readme.md file for all API calls
  methods: {
    callCourseAPI() {
      this.loginIssue = false;
      this.$store.dispatch("callCourseAPI");
    },
    callAdminGroupInfoAPI() {
      this.loginIssue = false;
      this.$store.dispatch("callAdminGroupInfoAPI", { groupID: "1310" });
    },
    callLogInAPI() {
      this.loginIssue = false;

      this.$store.dispatch("callLogInAPI").then((result) => {
        if (result == false) {
          this.loginIssue = true;
        }
      });
    },
    callLogOutAPI() {
      this.loginIssue = false;
      this.$store.dispatch("callLogOutAPI");
    },
    callGetHostIPProperties() {
      this.$store.dispatch("callGetHostIPProperties");
    },
    forceLogin() {
      this.loginIssue = false;
      this.$store.dispatch("callForceLoginAPI");
    },
    getGroupUsers() {
      this.$store.dispatch("getGroupUsers");
    },
    getGroupList() {
      this.$store.dispatch("getGroupList");
    },
    getGroupsAPI() {
      this.$store.dispatch("getGroups");
    },

    generateReport() {
      //(reportType,includeContent,includeGroups,includeUsers,){
      let reportObj = {
        groupID: 1,
        reportType: 10,
        //includeContent: "412;384;355;500;335;443;413;145;414;423;354;369;496;380;415;438;448;376;461;493;139;416;366;370;372;417;453;348;463;439;454;344;444;358;418;449;450;359;374;140;419;144;381;459;362;363;445;446;342;421;422;455;382;353;424;385;425;440;497;361;383;498;364;456;360;426;373;451;368;427;452;330;436;437;336;462;447;356;389;473;466;387;428;495;388;367;499;483;85;429;474;337;430;441;490;491;492;350;431;432;138;433;357;434;386;435;442;494;365", //355 for 1291/1291
        includeContent: "355", //355 for 1291/1291
        includeGroups: "1291; 1292;", // comma or semicolon separated for multiple
        includeUsers: "*",
        startDate: "15,2018",
        endDate: "15,2021",
      };
      this.$store.dispatch("generateReport", reportObj);
    },
    getContentUsers() {
      this.$store.dispatch("getContentUsers");
    },
    getTree() {
      this.$store.dispatch("groupTree").then((res) => {
        // console.log("Test Functions Getting the group tree with the res", res);
        this.tree = res[1];
      });
    },
    getCourseList() {
      this.$store.dispatch("getCourseList");
    },
    getRegisteredUsers() {
      this.$store.dispatch("getRegisteredUsers");
    },
    getRegisteredCertifications() {
      this.$store.dispatch("getRegisteredCertifications");
    },
    getAssignmentGroupTree() {
      this.$store.dispatch("getAssignmentGroupTree", { groupID: "1310" });
    },
    getCertifications() {
      this.$store.dispatch("getCertifications");
    },
    getUserList() {
      this.$store.dispatch("getUserList");
    },
    getCourseScores() {
      this.$store.dispatch("getCourseScores", "384");
      this.$store.dispatch("getCourseScores", "413");
    },
    getRegisteredContent() {
      this.$store.dispatch("getRegisteredContent");
    },
    getContentProperties() {
      this.$store.dispatch("getContentProperties");
    },
    getUserGroups() {
      this.$store.dispatch("getUserGroups", "chris.murray");
    },
    getUserProperties() {
      this.$store.dispatch("getUserProperties", "lucas@safetyinschools.ca");
    },
    getAccountProperties() {
      this.$store.dispatch("getAccountProperties");
    },
    getSeatDetails() {
      this.$store.dispatch("getSeatDetails");
    },
    getContentForSeating() {
      this.$store.dispatch("getContentForSeating");
    },
    getUsersForContentSeating() {
      this.$store.dispatch("getUsersForContentSeating");
    },
    getUserLKBS() {
      this.$store.dispatch("getUserLKBS");
    },
    getUserLKBSdata() {
      this.$store.dispatch("getUserLKBSdata", "angela.odland");
    },
    getUserLKBtests() {
      this.$store.dispatch("getUserLKBtests", {
        userID: "trudy.teichroeb",
        lkbID: "c355",
      });
    },
    getCourseModules() {
      this.$store.dispatch("getCourseModules");
    },
    getClassroomsForContent() {
      this.$store.dispatch("getClassroomsForContent");
    },
    updateUser() {
      this.$store.dispatch("updateUser", {
        userID: "sunnymultigroup2",
        firstName: "Sunny",
        lastName: "Nguyen-Bui",
        email: "sunnymultigroup@example.ca",
        password: "password",
        method: "add",
        group:
          "89;true;false;false;false;false;true;false;false;419;true;false;false;false;false;true;false;false;581;true;false;false;false;false;true;false;false;573;true;false;false;false;false;true;false;false;", //groupID;member;wasMember[;isPrimaryGroup;isAdministrator;isAuthor;isLearner;isVerifier;isSME]
      });
    },
    getHostInformation() {
      this.$store.dispatch("getHostInformation");
    },
    getUnregisteredCourses() {
      this.$store.dispatch("getUnregisteredCourses");
    },
    getUnregisteredExams() {
      this.$store.dispatch("getUnregisteredExams");
    },
    getUsersForCourseComplete() {
      this.$store.dispatch("getUsersForCourseComplete");
    },
    deleteUser() {
      this.$store.dispatch("deleteUser", "sunnyimporttest");
    },
    getUsersData() {
      this.$store.dispatch("getUsersData", {
        offset: "0",
        fetch: 10,
        search: { firstName: "sunny", lastName: "" },
      });
    },

    camsTestButton() {
      const allCourses = this.$store
        .dispatch("testCourseList")
        .then((allCourses) => {
          const courseListArr = [];
          for (let course in allCourses) {
            //console.log("allCourses from Cam's test", allCourses);
            courseListArr.push(course);
          }
          // console.log("course list array", courseListArr);
        });
    },

    // API 1288 //
    getModuleScores() {
      // console.log("working on admin module scores req");
      this.$store.dispatch("getModuleScores").then((data) => {
        // console.log("Test Functions getting Module Scores", data);
      });
    },
    getGroupIDFromUser() {
      // console.log("working on getGroupIDFromUser request");

      this.$store.dispatch("getGroupIDFromUser").then((data) => {
        // console.log("Test Functions getGroupIDsFromUser with the GroupID(s)", data);
      });
    },

    // test graph modal //
    openGraph() {
      this.graphModalVisible = true;
      this.$store.dispatch("retrieveGraphData");
    },

    closeModal() {
      this.graphModalVisible = false;
    },
    /////////////////

    // API 1226 //
    getContentLogins() {
      this.$store.dispatch("getContentLogins");
    },

    // API 1210 //
    getEventLog() {
      this.$store.dispatch("getEventLog", "0");
    },

    // API 1289 //
    getEventLogList() {
      this.$store.dispatch("getEventLogList");
    },

    // API 1235 //
    getGroups2() {
      this.$store.dispatch("getGroups2");
    },

    // API 333 //
    enrollLearner() {
      this.$store.dispatch("enrollLearner");
    },

    loginGEMS() {
      this.$store.dispatch("loginGEMS", {
        email: "sunny@sisfoundation.ca",
        password: "Cuc25169sis",
      });
    },
  },
};
</script>

<style>
#TestFunctions {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
