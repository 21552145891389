<template>
  <div id="app">
    <keep-alive v-if="loggedIn">
      <router-view ></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>
//import {store} from "./store/Store"
//import routes from './util/Routes.js'
import {mapState} from 'vuex'
export default {
  name: "App",
  computed:mapState({
    loggedIn: state=>state.loggedIn,
  }),

} 

// //// for captcha ////
// window.mtcaptchaConfig = {
//     "sitekey": "MTPublic-3TrqY5pRc", // Get the site key from Sites page of MTCaptcha admin site
//     "widgetSize": "mini",
//     "render": "explicit",
//     "renderQueue": [],
// };
// console.log(mtcaptchaConfig);

// (
//   function(){
//     // var mt_service = document.createElement('script');
//     // mt_service.async = true;
//     // mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';
//     // (
//     //   document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]
//     // ).appendChild(mt_service);
//     // var mt_service2 = document.createElement('script');
//     // mt_service2.async = true;
//     // mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';
//     // (
//     //   document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]
//     // ).appendChild(mt_service2);
//   }
// )();
//////////////////////////

/*window.onbeforeunload=function(){
  console.log("On or before unload")
  routes.push({name:"Login"})
console.log("On or before unload")

}*///?This function will be called before the user is logged out and if working should reset everything and send them back to the default page
</script>

<style>
  @import "./App.scss";
</style>

