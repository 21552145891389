<template>
    <div class="modal-backdrop">
      <div class="modal modal--groups-modal">
        <header class="modal-header">
          <h2>{{selectionTitle}}</h2>
        </header>
  
        <body class="modal-body">
          <div class="selection-body">
          {{message}}
          </div>
          <div class="removing-body">
            <div v-if="hasGroups">
              <b v-if="hasUsers">Groups</b>
              <ul>
                <li v-for="group in removing.groups" :key="group.ID">
                {{ group.groupName }}
                </li>
                </ul>
            </div>
            <div v-if="hasUsers">
              <b v-if="hasGroups">Users</b>
              <ul>
                <li v-for="user in removing.users" :key="user.LoginID">
                {{ user.firstName }} {{ user.lastName }}
                </li>
                </ul>
            </div>
          </div>

        </body>
  
        <footer class="modal-footer">
          <!-- <button type="button" class="btn-apply" @click="apply">
            Apply Selection
          </button> -->
          <button
            type="button"
            class="btn-danger"
            @click="$emit('selected','no')">
            No
          </button>
          <button
            type="button"
            class="btn-apply"
            @click="$emit('selected','yes')">
            Yes
          </button>

        </footer>
      </div>
    </div>
  </template>
  
  <script>
  // import { mapState } from "vuex";
  
  export default {
    name: "SelectionPopupModal",
    props:['selectionTitle','message','removing'],
    components: {
    },
  
    mounted(){
    },
    data() {
      return {
      };
    },
    computed: {
      hasGroups(){
        if(this.removing.groups && this.removing.groups.length>0)
        {
          return true;
        }
        else{
          return false;
        }
      },
      hasUsers(){
        if(this.removing.users && this.removing.users.length>0)
        {
          return true;
        }
        else{
          return false;
        }
      }
    },
    methods: {
      close() {
        this.$emit("close");
        this.$emit("resetTable", [])
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../styles/BasicModal.scss";
  @import "../styles/Buttons.scss";
  @import "../styles/SelectionPopup.scss";
  </style>
  