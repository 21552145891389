<template>
  <div id="manage-learners">
    <div id="sidemenu"> 
      <SideMenu />
    </div>
    <div id="navbar-container">
    <Navbar />
    <div id="da">
    <div class="da-header">
      <span class="title">
        <font-awesome-icon class="title-icon" :icon="['fas', 'folder']" />
        All Users
      </span>
    </div>
    <div class="content">
      <div class="learner-table">
        <div class="filters">
          <!-- <select class="group-select" name="group" id="select-group">
            <option value="1">group 1</option>
            <option value="2">group 2</option>
            <option value="3">group 3</option>
            <option value="4">group 4</option>
          </select> -->
          <input
            class="search-input"
            type="search"
            placeholder="Search for User"
          />
        </div>
        <div class="table">
          <table>
            <thead>
              <tr>
                <th>Learner</th>
                <th>Email</th>
                <th>Date Registered</th>
                <th>Groups</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>Enrollment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
              <tr>
                <td>John</td>
                <td>john@example.com</td>
                <td>02/21/2022</td>
                <td>group 2, group 4</td>
                <td>
                  <button class="edit-button">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                </td>
                <td>
                  <button class="delete-button">
                    <font-awesome-icon :icon="['fas', 'user-times']" />
                  </button>
                </td>
                <td>
                  <button class="enrollment-button">Unenroll</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
     <!-- <div class="course-list">
        <div class="filters">
          <input
            class="search-input"
            type="search"
            placeholder="Search for Course"
          />
          <button class="enroll-all-button">Enroll</button>
        </div>
         <div class="list">
          <table>
            <thead>
              <tr>
                <th>Course</th>
                <th>Enrollment</th>
              </tr>
            </thead>
            <tbody>
             
            </tbody>
          </table>
        </div> 
      </div> -->
    </div>
  </div>
  </div>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
export default {
  name: "ManageLearners",
  components: {
    Navbar,
    SideMenu,
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/ManageLearners.scss";
</style>