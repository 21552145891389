<template>
  <div class="modal-backdrop">
    <div class="modal modal--groups-modal">
      <header class="modal-header">
        <h2>Group Selection</h2>
      </header>

      <input
        class="modal-search-bar"
        type="text"
        placeholder="Search for Group"
        v-model="searchForGroup"
      />

      <body class="modal-body">
        <div class="modal-selected-filters">
          <div class="modal-pill" v-for="group in groupsSelected" :key="group">
            <Pill :filterName="group" @removeFilter="removeFilter(group)" />
          </div>
        </div>

        <div class="spinner" v-if="loading">
          <Spinner />
        </div>

        <form class="modal-filter-options">
          <span
            class="groupContent"
            v-for="(group, index) in filteredGroups"
            :key="index"
          >
            <input
              class="checkbox"
              type="checkbox"
              v-model="groupsSelected"
              :value="group"
              :key="index"
            />
            <span  @click="groupNameClicked(group)">
            {{ group }}
            </span>
          </span>
        </form>
      </body>

      <footer class="modal-footer">

        <button
          type="button"
          class="btn-close"
          @click="
            close();
            reset();
            clear();
          "
        >
          Cancel
        </button>
        <button type="button" class="btn-apply" @click="apply">
          Apply
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pill from "../AppliedFilterPill.vue";
import Spinner from "../Spinner.vue";

export default {
  name: "GroupModal",
  components: {
    Pill,
    Spinner,
  },
  // created() {
  //   if(this.groups==[] || this.groups.length==0)
  //   {
  //     this.getGroupList();
  //   }
  // },
  mounted(){
    if(this.groups=={} || Object.keys(this.groups).length==0)
    {
      this.getGroupList();
    }
  },
  data() {
    return {
      groupsSelected: [],
      searchForGroup: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      groups: (state) => state.groups,
    }),

    filteredGroups: function () {
      const values = Object.values(this.groups);
      return values.filter((group) => {
        return group.toLowerCase().match(this.searchForGroup.toLowerCase());
      });
    },
  },
  methods: {
    groupNameClicked(group)
    {
      let index=this.groupsSelected.indexOf(group);
      index==-1?this.groupsSelected.push(group):this.groupsSelected.splice(index,1);
    },
    close() {
      this.$emit("close");
      this.$emit("resetTable", [])
    },
    clear() {
      this.searchForGroup = "";
    },
    apply() {
      let groupsSelectedObject={};
      for(var i in this.groupsSelected)
      {
        groupsSelectedObject[this.getKeyByValue(this.groups,this.groupsSelected[i])]=this.groupsSelected[i];
      }
      this.$emit("apply", groupsSelectedObject);
    },


    getKeyByValue(results,groupName)
    { 
      return Object.keys(results).find(
              (groupID) => results[groupID] === groupName
            );
    },
    reset() {
      this.groupsSelected = [];
      this.searchForGroup = "";
    },
    async getGroupList() {
      this.loading = true;
      await this.$store.dispatch("getGroupList").then((res) => {
        return res;
      });

      this.loading = false;
    },
    removeFilter: function (groupName) {
      this.groupsSelected = this.groupsSelected.filter((name) => {
        return groupName !== name;
      });
    },


  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/Groups.scss";
</style>
