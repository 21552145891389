import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  name: "Doughnut",
  props: {
    options: {
      type: Object,
    },
    render: {
      type: Object,
    },
  },
  mounted() {
    this.renderChart(this.render, this.options);
  },
};
