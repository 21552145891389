<template>
  <div class="fifth">
    <h4>
      Enroll
      {{ this.$store.state.firstName }}
      {{ this.$store.state.lastName }}
    </h4>
    <p>Select courses from the list (this can be undone later)</p>

    <div class="courses">
      <div class="courses-list">
        <input type="search" placeholder="Search Your Courses" />
        <div class="courses-list-select"></div>
      </div>

      <div class="courses-selected">
        <h4>Selected Courses</h4>
        <div class="courses-selected-pills">
          <div class="course-selected">Hazard Recognition</div>
          <div class="course-selected">Alberta OHS</div>
          <div class="course-selected">How to Drink Water Effectively</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FifthStep",

};
</script>
<style scoped>
@import "./msFormStyles/msForm.scss";
</style>