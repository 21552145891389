import { HorizontalBar } from "vue-chartjs";
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
export default {
  extends: HorizontalBar,
  components:{
    ChartJsPluginDataLabels
  },
  name: "HorizontalBarChart",
  props: {
    options: {
      type: Object,
    },
    render: {
      type: Object,
    },
  },
  mounted() {
    if(this.options.plugins && this.options.plugins.datalabels)
    {
      this.addPlugin(ChartJsPluginDataLabels);
    }
    this.renderChart(this.render, this.options);
  },
};
