<template>
  <div >
  <div class='full-height' v-if="wasSelected">
  <div class='full-height' v-show="isActive"> 
    <slot></slot>
    </div>
  </div>  
  <div v-if="!wasSelected">
  <div v-if="isActive"> 
    <slot></slot>
    </div>
  </div>  
  </div>
</template>

<script>
export default {
  props: {
    name: { required: true },
    selected: { default: false },
  },
  data: () => {
    return {
      isActive: false,
      wasSelected: false,
    };
  },
  created(){
    this.wasSelected=this.selected;
  },
  mounted() {
    this.isActive = this.selected;
  },
  //Watch for the isActive value to change to update the wasSelected
  //If something was selected we will not put it as an if so it will not reload
  //We do not want to make them all v-show because they will all initialize on load
  watch:{
    '$data.isActive':{
      handler: function(newVal)
      {
        if(newVal==true)
        { 
          this.wasSelected=true;
          this.$emit('tabSelected');
        }
      },
      deep:true
    }
  }
};
</script>
<style lang="scss" scoped>
  .full-height{
    height:100%;
  }
</style>