<template>
  <div class="sahara-enroll-container">
    <div id="sidemenu-container"><SideMenu></SideMenu></div>
    <div id="navbar-container">
    <Navbar>SaharaEnrollment</Navbar>

  <div id="user-list">

    <div class="header" :class="{ darkmode: this.$store.state.darkmode }">
      <!-- <router-link
        to="/dashboard"
        class="back-to-dashboard"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        <h3>
          <font-awesome-icon :icon="['fas', 'arrow-left']" /> Back to Dashboard
        </h3>
      </router-link> -->
      <!-- <h3>Manage Users</h3> -->
      <span class="title">
        <font-awesome-icon class="title-icon" :icon="['fas', 'users']" />
        SAHARA Enrollment
        <!-- <font-awesome-icon class="title-icon" :icon="['/fas', 'chart-bar']" /> -->
        <!-- SWIFT Analytics -->
      </span>
    </div>

    <div class="body">
      <div class="menu">
        <div class="search">
          <input
            type="search"
            class="search-bar-enroll"
            placeholder="Search by Last Name"
            v-model="search.lastName"
          />
          <input
            type="search"
            class="search-bar-enroll"
            placeholder="Search by First Name"
            v-model="search.firstName"
          />
          &nbsp;
          <span class="sahara-dropdown">
          Show:
          <select v-model="getOnlySahara" type="text" @change="changeView">
            <option value="false">
              All Users
            </option>
            <option value="true">
              SAHARA Users
            </option>
          </select>
        </span>
        &nbsp;
        <span class="user-status">
          <!-- Amount of results: -->
          Status:
          <select v-model="enabledState" @change="changeEnabled()">
            <option value="" selected>
              All
            </option>
            <option value="true">
              Enabled
            </option>
            <option value="false">
              Disabled
            </option>
          </select>
        </span>
        </div>
          <!-- <div class="add-user">
          <button type="button" class="btn-blue" @click="changeView">
            Change View
          </button>
        </div> -->
        <div class="add-user">
          <button type="button" class="btn-blue" @click="addUser">
            Add User
          </button>
        </div>
      </div>
      <div class="table-container">
        <table class="table-main">
          <thead class="table-header">
            <tr>
            <th>Enabled</th>
            <th v-if="adminLevel.adminLevelInt==0">Login ID</th>
            <!-- <th>Last Name</th>
            <th>First Name</th> -->
            <th>Name</th>
            <th>Email</th>
            <th>Date Registered</th>
            <th v-if="userIndustries.length>1">Industries</th>
            <!-- <th>Creation Type</th> -->
            <th></th>
            </tr>
          </thead>

          <tbody 
            class="table-body"
            v-for="user in visibleUsers"
            :key="user.Email"
          >
            <tr>
              <td><span v-if="user.enabled">&#10004;</span></td>
              <td v-if="adminLevel && adminLevel.adminLevelInt==0">{{ user.loginID }}</td>
              <!-- <td>{{ formatName(user.lastName) }}</td>
              <td>{{ formatName(user.firstName) }}</td> -->
              <td>{{formatName(user.firstName)}} {{formatName(user.lastName)}}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.creationDate.split("%")[0] }}</td>
              <td v-if="userIndustries.length>1" ><div v-if="!user.loadingIndustry || user.loadingIndustry==false" class="industries-list"><span v-for="industry in user.industries" :key="industry.industryID" >{{ industry.industryName }}<br></span></div>
              <div v-if="user.loadingIndustry==true" class="center-card-data">          
              <Spinner2 class="center-card-data-spinner" />
            </div></td>
              <!-- <td>{{ user.creationType }}</td> -->
              <td class="table-btn-container">
                <div v-if="user.canChange==undefined|| user.canChange==true">

                <div  v-if="!user.loadingIndustry || user.loadingIndustry==false">
                  <button v-if="userIndustries.length>1 && user.industries" title="Edit User" class="table-btn-edit" v-on:click="editIndustry(user)">
                    <font-awesome-icon :icon="['fas', 'user-edit']" />
                  </button>
                  <button v-if="userIndustries.length>1 && !user.industries" title="Add User" class="table-btn-add" v-on:click="editIndustry(user)">
                    <font-awesome-icon :icon="['fas', 'user-plus']" />
                  </button>
                    <button v-if="userIndustries.length==1 && user.industries" title="Remove User" class="table-btn-remove" v-on:click="removeIndustry(user)">
                    <font-awesome-icon :icon="['fas', 'user-minus']" />
                  </button>
                    <button v-if="userIndustries.length==1 && !user.industries" title="Add User" class="table-btn-add" v-on:click="addIndustry(user)">
                    <font-awesome-icon :icon="['fas', 'user-plus']" />
                  </button>
                </div>
                </div>
                <div v-if="user.canChange!=undefined && user.canChange==false">
                  <!-- Cannot modify Administrator -->
                </div>
                <div v-if="user.loadingIndustry==true" class="center-card-data">          
              <Spinner2 class="center-card-data-spinner" />
            </div>

                <!-- <button
                  class="table-btn-danger"
                  v-on:click="confirmDelete(user)"
                  title="Delete User"
                >
                  <font-awesome-icon :icon="['fas', 'user-times']" />
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-footer"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        <span>
          <button
            class="btn-arrow"
            v-on:click="decreaseTablePage()"
            :disabled="tablePage === 1"
          >
            &larr;
          </button>
          {{ tablePage }} of {{ totalPages }}
          <button
            class="btn-arrow"
            v-on:click="increaseTablePage()"
            :disabled="fetchAmount > resultCount"
          >
            &rarr;
          </button>
        </span>
        <span>
          Go to page:
          <input
            class="table-footer-input"
            type="number"
            v-model="tablePage"
            @change="goToPage(tablePage)"
          />
          <button class="btn-arrow" @click="goToPage(tablePage)">Go</button>
        </span>
        <span>
          Amount of results:
          <select v-model.number="fetchAmount" type="number">
            <option>
              10
            </option>
            <option>
              25
            </option>
            <option>
              50
            </option>
            <option>
              100
            </option>
          </select>
        </span>
      </div>
    </div>
    <RegisterUserModal
      v-show="registerUserModalVisible"
      :form="form"
      :industries="userIndustries"
      :userID="form.loginID"
      @update="update"
      @close="closeModals"
    />
    <ErrorMessageModal 
    v-show="showErrorMessage"
    :errorTitle="errorMsgTitle"
    :errorBody="errorMsgBody"
    @close="closeErrorModal"
    />
    <div
      class="modal-backdrop"
      v-show="
        changeUserIndustryModalVisible || loading" >
      <div v-if="loading">
        <Spinner />
      </div>
    <Industries 
    v-if="changeUserIndustryModalVisible"
    :user="selectedUser"
    :industries="userIndustries"
    @close="closeModals"
    @enroll="enrollUserIndustries"
    ></Industries>
      <!-- <Confirmation
        v-show="confirmationModalVisible"
        @confirm="deleteUser"
        @cancel="closeModals"
        message="Are you sure you want to delete this user permanently?"
        :form="form"
        class="confirmation"
      />
      <Confirmation
        v-show="fileModalVisible"
        @confirm="submitFile()"
        @cancel="closeModals"
        :sheet="sheet"
        :groups="groups"
        message="Please verify the credentials below"
        class="confirmation"
      ></Confirmation>

      <RegisterToGroup
        v-show="groupRegisteryVisible"
        :checked="groups"
        @userGroups="selectedGroups"
        @cancel="closeModals"
        class="confirmation"
      /> -->
    </div>
  </div>
</div>
</div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import Spinner from "../components/Spinner.vue";

import RegisterUserModal from "../components/modals/RegisterUser.vue";
import RegisterToGroup from "../components/modals/RegisterToGroup.vue";
import Confirmation from "../components/modals/Confirmation.vue";
import Industries from "../components/modals/Industries.vue";
import Spinner2 from "../components/Spinner2.vue";

import ErrorMessageModal from "../components/modals/ErrorMessage.vue"
import { debounce } from "../util/helper.js";
// import { sleep } from "../util/helper";
import { mapState } from "vuex";

// import XLSX from "xlsx";

export default {
  name: "SaharaEnroll",

  components: {
    Navbar,
    SideMenu,
    RegisterUserModal,
    RegisterToGroup,
    Confirmation,
    Spinner,
    Industries,
    Spinner2,
    ErrorMessageModal,
  },
  computed:{
    ...mapState({
      adminLevel: (state) => state.adminLevel,
    })
  },
  data() {
    return {
      search: { firstName: "", lastName: "" },
      debounced: { firstName: "", lastName: "" },
      registerUserModalVisible: false,
      changeUserIndustryModalVisible: false,
      confirmationModalVisible: false,
      groupRegisteryVisible: false,
      fileModalVisible: false,
      loading: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        loginID: "",
        password: "",
        enabled:true,
      },
      visibleUsers: [],
      tablePage: 1,
      fetchAmount: 10,
      resultCount: 10,
      totalPages: 0,
      sheet: {},
      groups: {},
      getOnlySahara:false,
      industriesSearch:"",
      userIndustries:{},
      selectedUser:{},
      userIndustryLoading:false,
      showErrorMessage:false,
      errorMsgTitle:"",
      errorMsgBody:"",
      selecting:"All Users",
      usersChanging:0,
      changeUserBusy:false,
      enabledState:'',
    };
  },

  watch: {
    search: {
      handler: debounce(function(val) {
        this.debounced = val;
        this.tablePage = 1;
        this.updateList((this.tablePage - 1) * this.fetchAmount);
      }, 500),
      deep: true,
    },

    fetchAmount: function() {
      this.tablePage = 1;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
    },
  },

  methods: {
    changeEnabled()
    {
      this.tablePage = 1;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
    },
    changeView(){
      // this.getOnlySahara=!this.getOnlySahara; //Used when it was not tied directly to the dropdown
      this.tablePage=1;
      this.updateList((this.tablePage - 1) * this.fetchAmount);

    },
    //Handles names with space character from API
    formatName(name) {
      const formatted = name.split("%20");
      if (formatted.length === 2) {
        return `${formatted[0]} ${formatted[1]}`;
      }
      if (formatted.length === 1) {
        return `${formatted[0]}`;
      }
    },

    //Formatting groupID string from API data
    groupName(groupIDs) {
      let groupIDstr = "";
      const groupArr = groupIDs.split(";");
      for (let groupID of groupArr) {
        groupIDstr += `${this.$store.state.groups[groupID] || groupID}, `;
      }
      return groupIDstr.slice(0, -2);
    },

    closeErrorModal(){
      this.showErrorMessage=false;
      this.errorMsgBody="";
      this.errorMsgTitle="";
    },

  async waitForUser(enrollObj)
  {
    if(this.changeUserBusy)
    {
      setTimeout(()=>{
        this.waitForUser(enrollObj);
        return;
      },3000)
    }
    else{
      this.enrollUserIndustries(enrollObj);
    }
  },

    enrollUserIndustries(enrollObj)
    {
      this.changeUserIndustryModalVisible=false;
      var updateUser=this.visibleUsers.find(user=>user.loginID==enrollObj.user)
      this.$set(updateUser,"loadingIndustry",true)
      if(this.changeUserBusy)
      {
        this.waitForUser(enrollObj);
        return;
      }
      this.changeUserBusy=true;
      if(enrollObj.adding.length!=0 || enrollObj.removing.length!=0)
      {
        // this.visibleUsers.find(user=>user.loginID==enrollObj.user).loadingIndustry=true;


        // foundUser.loadingIndustry=true;
      let addingStr="";
      let removingStr="";
      let newIndustryObjects=[];
      let newIndustries=[];
        enrollObj.adding.forEach((add)=>{
          addingStr+=add.industryID+";";
          newIndustries.push({})
          newIndustryObjects.push(this.userIndustries.find((industry)=>industry.industryID==add.industryID))
        })
          enrollObj.removing.forEach((remove)=>{
          removingStr+=remove.industryID+";";
        })
         this.$store
        .dispatch("changeSaharaUserIndustries", {
          user: enrollObj.user,
          addingIndustries: addingStr,
          removingIndustries: removingStr,
        })
        .then((response) => {
          this.usersChanging--;
          this.changeUserBusy=false;
          if(response.status=="success")
          {
            this.$set(updateUser,"industries",newIndustryObjects);

            if(this.usersChanging<=0)
            {
              this.updateList((this.tablePage - 1) * this.fetchAmount);
            }
            if(response.results && response.results.failures)
            {
              this.enrollmentErr=true;
              this.$store.dispatch('callCourseAPI').then((coursesRet)=>{
                let courses=response.results.failures.split(", ");
                let uniqueCourses=[...new Set(courses)];
                var failedNames="";
                var failedIDs="";
                //  let uniqueCourses=["509","510","511","512","513"];
                for(var i=0; i<uniqueCourses.length;i++)
                {
                  let matchingCourse=coursesRet.find(courseInfo=>courseInfo.contentid === `${uniqueCourses[i]}`)
                  if(matchingCourse)
                  {
                    failedNames+=decodeURIComponent(matchingCourse.contentname)+", ";
                  }
                  else{
                    failedIDs+=uniqueCourses[i]+", ";
                  }
                }
                this.errorMsgTitle="Enrollment Error";
                this.errorMsgBody="Failed to enroll in courses with the ";
                if(failedNames!="")
                {
                  this.errorMsgBody+="names: "+failedNames.substring(0,failedNames.length-2);
                }
                if(failedNames!="" && failedIDs!="")
                {
                  this.errorMsgBody+=" and the ";
                }
                if(failedIDs!="")
                {
                  this.errorMsgBody+="IDs: "+failedIDs.substring(0,failedIDs.length-2);
                }
                this.showErrorMessage=true;
              })
            }
          }
          else{
            this.showErrorMessage=true;
            this.errorMsgTitle="Industry Error";
            this.errorMsgBody=response.message;
          }
          setTimeout(()=>{// Set a small timeout so the industry will not jump around
            this.$set(updateUser,"loadingIndustry",false)
          },500)
        })
      }
      else{
        this.$set(updateUser,"loadingIndustry",false)
      }
    },

    addUser() {
      this.form = {
        method: "Add",
        firstName: "",
        lastName: "",
        email: "",
        loginID: "",
        password: "",
        enabled:true,
      };
      this.registerUserModalVisible = true;
    },


    selectedGroups(groups) {
      this.groups = groups;
      this.groupRegisteryVisible = false;
      this.fileModalVisible = true;
    },

    updateUser(form) {
      let groupStr = "";

      for (let groupID in this.groups) {
        //groupID;member;wasMember[;isPrimaryGroup;isAdministrator;isAuthor;isLearner;isVerifier;isSME]
        if (this.groups[groupID].isMember) {
          groupStr = `${groupID};${this.groups[groupID].isMember};false;${this.groups[groupID].isPrimaryGroup};${this.groups[groupID].isAdministrator};${this.groups[groupID].isAuthor};${this.groups[groupID].isLearner};false;false;`;
          // groupStr = `${groupID};${this.groups[groupID].isMember};false;${this.groups[groupID].isPrimaryGroup};${this.groups[groupID].isAdministrator};${this.groups[groupID].isAuthor};${this.groups[groupID].isLearner};${this.groups[groupID].isVerifier};false;`;
        }
      }

      this.$store.dispatch("updateUser", {
        method: "Add",
        userID: form.loginID,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        password: form.password,
        group: groupStr,
        enabled: form.enabled,
      });

      if (form.endOfList) {
        this.resetForm();
      }
    },

    editIndustry(user) {
      this.usersChanging++;
        // this.loading=true;
        this.selectedUser=user;
      this.changeUserIndustryModalVisible=true;
    },
    addIndustry(user) {
      this.usersChanging++;
          let addList=[];
          this.userIndustries.forEach((industry)=>{
            addList.push(industry);
          })
      this.enrollUserIndustries({
        user:user.loginID,
        adding:addList,
        removing:[]
      })
    },
    removeIndustry(user) {
      this.usersChanging++;
          let removeList=[];
          this.userIndustries.forEach((industry)=>{
            removeList.push(industry);
          })
        this.enrollUserIndustries({
        user:user.loginID,
        adding:[],
        removing:removeList
      })
    },

    update() {
      this.loading = true;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
      this.registerUserModalVisible = false;
    },

    closeModals() {
      this.registerUserModalVisible = false;
    //   this.confirmationModalVisible = false;
    //   this.groupRegisteryVisible = false;
    //   this.fileModalVisible = false;
      this.changeUserIndustryModalVisible=false;
      this.loading=false;
    },

    //API Call to fetch data
    async updateList(offset) {
      await this.$store
        .dispatch("getUsersData", {
          offset,
          fetch: this.fetchAmount,
          search: this.debounced,
          saharaData:true,
          onlySahara: this.getOnlySahara,
          industries:this.industriesSearch,
          enabled: this.enabledState,
        })
        .then((response) => {
          this.resultCount = response.results.length;
          if (this.resultCount === this.fetchAmount) {
            this.totalPages = Math.ceil(response.TotalUsers / this.fetchAmount);
          }
          if (this.resultCount < this.fetchAmount) {
            this.totalPages = this.tablePage;
          }
          if (
            this.search.firstName.length > 0 ||
            this.search.lastName.length > 0
          ) {
            this.totalPages = "?";
          }
          this.filterResults(response.results);
          this.loading = false;
        });
    },

    filterResults(users) {
      const filtered = {};
      for (let x = 0; x < users.length; x++) {
        if (filtered[users[x].loginID]) {
          filtered[users[x].loginID].groupID.push(users[x].groupID);
        }
        if (!filtered[users[x].loginID]) {
          filtered[users[x].loginID] = {
            ...users[x],
            groupID: [users[x].groupID],
          };
        }
      }
      this.visibleUsers = Object.values(filtered);
    },

    increaseTablePage() {
      if (this.resultCount === this.fetchAmount) {
        this.tablePage++;
        this.loading = true;
        this.updateList((this.tablePage - 1) * this.fetchAmount);
      }
    },

    decreaseTablePage() {
      if (this.tablePage > 1) {
        this.tablePage--;
        this.loading = true;
        this.updateList((this.tablePage - 1) * this.fetchAmount);
      }
    },

    goToPage(val) {
      this.tablePage = val > 0 ? val : 1;
      this.loading = true;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
    },
  },

    created(){
        this.$store.dispatch('getSaharaIndustry').then((industries)=>{
            if(industries=='Error')
            {
                alert('Error getting SAHARA Industries');
                this.showErrorMessage=true;
                this.errorMsgBody="Error: Not able to find your SAHARA Industries";
                this.errorMsgTitle="SAHARA Error";
            }
            else{
              this.userIndustries=industries;
            }
        })
        if(this.adminLevel.adminLevelInt==0)
        {
          this.enabledState='';
        }
        else{
          this.enabledState='true';
        }
    },

  mounted() {
    this.loading = true;
    this.updateList((this.tablePage - 1) * this.fetchAmount);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/SaharaEnroll.scss";
@import "../components/styles/Buttons.scss";
@import "../components/styles/BasicModal.scss";
</style>
