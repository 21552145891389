<template>
  <div id="userlist">
    <div id="sidemenu">
      <SideMenu></SideMenu>
    </div>
    <div id="navbar-container">
    <Navbar />
    <div id="da">

    <div class="da-header" :class="{ darkmode: this.$store.state.darkmode }">
      <!-- <router-link
        to="/dashboard"
        class="back-to-dashboard"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        <h3>
          <font-awesome-icon :icon="['fas', 'arrow-left']" /> Back to Dashboard
        </h3>
      </router-link> -->
      <!-- <h3>Manage Users</h3> -->
      <span class="title">
        <font-awesome-icon class="title-icon" :icon="['fas', 'users']" />
        All Users
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'chart-bar']" /> -->
        <!-- SWIFT Analytics -->
      </span>
    </div>

    <div class="content">
      <div class="menu">
        <div class="search">
          <input
            type="search"
            class="search-bar"
            placeholder="First Name"
            v-model="search.firstName"
          />
          <input
            type="search"
            class="search-bar"
            placeholder="Last Name"
            v-model="search.lastName"
          />
        </div>


        <div class="status-dropdown" >
               <label for="statusDrop" >Status</label>
                        <select name="statusDrop" @change="changeEnabled()" v-model="enabledState" id="fetchOption">
                          <option value="">All</option>
                          <option value="true">Enabled</option>
                          <option value="false">Disabled</option>
                        </select>
              </div>



        <!-- <span class="user-status">
          Status:
          <select v-model="enabledState" @change="changeEnabled()">
            <option value="" selected>
              All
            </option>
            <option value="true">
              Enabled
            </option>
            <option value="false">
              Disabled
            </option>
          </select>
        </span> -->
        <div class="add-user">
          <button type="button" class="btn-blue" @click="groupsModalVisible=true">
                Groups
              </button>
          <button type="button" class="btn-blue" style="font-weight: bold;" @click="addUser">
            Add User
          </button>
          <!-- <div class="add-user-file">
            <input type="file" class="input" @change="uploadFile" ref="file" />
            <button type="button" class="btn-blue" @click="addUserSheet">
              Import User Sheet
            </button>
          </div> -->
        </div>
      </div>
      <div class="table-container">
        <table class="table-main">
          <thead class="table-header">
            <tr>
            <th style="width:10%;" @click="sortTable('enabled')">Enabled <SortArrow column="enabled" :useWhite="true" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>
            <!-- <th>Enabled</th> -->
              
            <!-- <th v-if="adminLevel.adminLevelInt==0">Login ID</th> -->
            <th style="width:15%;" v-if="adminLevel.adminLevelInt==0" @click="sortTable('login')">Login ID <SortArrow column="login" :useWhite="true" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>

            <!-- <th>Last Name</th>
            <th>First Name</th> -->
            <!-- <th>Name</th> -->
            <th style="width:20%;" @click="sortTable('name')">Name <SortArrow column="name" :useWhite="true" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>

            <!-- <th>Email</th> -->
            <th style="width:30%;" @click="sortTable('email')">Email <SortArrow column="email" :useWhite="true" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>

            <!-- <th>Date Registered</th> -->
            <th style="width:15%;" @click="sortTable('creation')">Registered <SortArrow column="creation" :useWhite="true" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th>


            <th style="width:20%;">Group(s)</th>
            <!-- <th @click="sortTable('groups')">Group(s) <SortArrow column="groups" :useWhite="true" :sorting="sortBy" :ascending="sortAsc"></SortArrow></th> -->

            <!-- <th>Creation Type</th> -->
            <th style="width:7%;"></th>
            <!-- <th>Administrator</th> -->
            </tr>
          </thead>

          <tbody
            class="table-body"
            v-for="user in visibleUsers"
            :key="user.Email"
          >
            <tr>
              <td style="text-align:center;width:10%;"><span v-if="user.enabled">&#10004;</span> </td>
              <!-- <td v-if="adminLevel.adminLevelInt==0 && enabledState==''"><span v-if="user.enabled">Enabled</span><span v-if="!user.enabled">Disabled</span></td> -->
              <td style="width:15%;" v-if="adminLevel && adminLevel.adminLevelInt==0">{{ user.loginID }}</td>
              <!-- <td>{{ formatName(user.lastName) }}</td>
              <td>{{ formatName(user.firstName) }}</td> -->
              <td style="width:20%;">{{formatName(user.firstName)}} {{formatName(user.lastName)}}</td>
              <td style="width:30%;">{{ user.email }}</td>
              <td style="width:15%;">{{ user.creationDate.split("%")[0] }}</td>
              <td class="groups-cell" style="width:25%;"><div>{{ groupName(user.groupIDs) }}</div></td>
              <!-- <td>{{ user.creationType }}</td> -->
              <td style="width:7%;" class="table-btn-container">
                <div  v-if="user.canChange==undefined|| user.canChange==true">
                <button title="Edit User" class="table-btn-confirm" v-on:click="edit(user)">
                  <font-awesome-icon :icon="['fas', 'user-edit']" />
                </button>
                <button
                  class="table-btn-danger"
                  v-on:click="confirmDelete(user)"
                  title="Delete User"
                >
                  <font-awesome-icon :icon="['fas', 'user-times']" />
                </button>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

<!-- 
      <div class="table-controls"  >
            <div id="fetch-options">
            <label for="fetchSelect" >Results: </label>
            <select name="fetchSelect" @change="togglePerPage" v-model="fetchAmount" id="fetchOption">
              <option>10</option>
                <option>25</option>
                <option>50</option>
                <option value="1000">All</option>
            </select>
          </div>
          <div class="pagination">
            <button :disabled="backDisabled" @click="decreaseTablePage" class="table-btn" v-bind:style="[backDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Back</button>
            {{tablePage}}
            <button :disabled="forwardDisabled" @click="increaseTablePage" class="table-btn" v-bind:style="[forwardDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Next</button>
        </div>
        </div> -->



       <div
        class="table-footer"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
      <div id="fetch-options" class="select-dropdown">
            <label for="fetchSelect" >Results: </label>
            <select name="fetchSelect" v-model.number="fetchAmount" type="number" id="fetchOption">
              <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
            </select>
          </div>
      <!-- <span >
          Results:
          <select v-model.number="fetchAmount" type="number">
            <option>
              10
            </option>
            <option>
              25
            </option>
            <option>
              50
            </option>
            <option>
              100
            </option>
          </select>
        </span> -->
        <span>
          <button
            class="btn-arrow"
            v-on:click="decreaseTablePage()"
            :disabled="tablePage === 1"
          >
            <!-- &larr; -->
            Back
          </button>
          {{ tablePage }} / {{ totalPages }}
          <button
            class="btn-arrow"
            v-on:click="increaseTablePage()"
            :disabled="fetchAmount > resultCount"
          >
            <!-- &rarr; -->
            Next
          </button>
        </span>
       
      </div> 
    </div>
    <!-- registerUserModalVisible is set to true when calling the addUser function -->
    <!-- The form prop is an object that contains the fields for registration this
          will later be passed to the confirmation modal. 
     -->
    <RegisterUserModal
      v-show="registerUserModalVisible"
      :form="form"
      :isAdmin="editUserIsAdmin"
      :userID="userLoginID"
      @update="update"
      @close="closeModals"
    />
    <div
      class="modal-backdrop"
      v-show="
        confirmationModalVisible ||
          fileModalVisible ||
          groupRegisteryVisible ||
          loading" >
      <div v-if="loading">
        <Spinner />
      </div>
      <Confirmation
        v-show="confirmationModalVisible"
        @confirm="deleteUser"
        @cancel="closeModals"
        message="Are you sure you want to delete this user permanently?"
        :form="form"
        class="confirmation"
      />
      <Confirmation
        v-show="fileModalVisible"
        @confirm="submitFile()"
        @cancel="closeModals"
        :sheet="sheet"
        :groups="groups"
        message="Please verify the credentials below"
        class="confirmation"
      ></Confirmation>

      <!-- groupRegisteryVisible is set to true in the addUserSheet function-->
      <!-- addUserSheet is called when importing a "sheet" of users -->
      <!-- the checked prop is an object -->
      <RegisterToGroup
        v-show="groupRegisteryVisible"
        :checked="groups"
        @userGroups="selectedGroups"
        @cancel="closeModals"
        class="confirmation"
      />
    </div>
  </div>
</div>
<GroupsModal 
      v-show="groupsModalVisible"
      @close="closeGroupModal"
      @apply="setGroups"
      ref="resetGroups"
      />

      <ErrorMessageModal 
        v-show="showErrorMessage"
        :errorTitle="errorMsgTitle"
        :errorBody="errorMsgBody"
        @close="showErrorMessage=false"
        />
</div>

</template>

<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import Spinner from "../components/Spinner.vue";

import RegisterUserModal from "../components/modals/RegisterUser.vue";
import RegisterToGroup from "../components/modals/RegisterToGroup.vue";
import Confirmation from "../components/modals/Confirmation.vue";
import GroupsModal from "../components/modals/GroupsModal.vue";
import SortArrow from "../components/SortArrow.vue";
import ErrorMessageModal from "../components/modals/ErrorMessage.vue";

import { debounce } from "../util/helper.js";
import { sleep } from "../util/helper";
import { mapState } from "vuex";

import XLSX from "xlsx";

export default {
  name: "UserList",

  components: {
    Navbar,
    SideMenu,
    RegisterUserModal,
    RegisterToGroup,
    Confirmation,
    Spinner,
    GroupsModal,
    SortArrow,
    ErrorMessageModal,
  },
  computed:{
    ...mapState({
      adminLevel: (state) => state.adminLevel,
      adminGroups: (state) => state.groups
    }),
   
  },
  data() {
    return {
      search: { firstName: "", lastName: "" },
      debounced: { firstName: "", lastName: "" },
      registerUserModalVisible: false,
      confirmationModalVisible: false,
      groupRegisteryVisible: false,
      fileModalVisible: false,
      loading: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        loginID: "",
        password: "",
      },
      visibleUsers: [],
      tablePage: 1,
      fetchAmount: 10,
      resultCount: 10,
      totalPages: 0,
      sheet: {},
      groups: {},
      editUserIsAdmin:false,
      userLoginID:'',
      enabledState:'',
      groupsModalVisible:false,
      groupsSelected:[],
      sortBy:"name",
      sortAsc:true,
      resultsLoading:false,
      showErrorMessage:false,
      errorMsgBody:"",
      errorMsgTitle:"",
    };
  },

  watch: {
    search: {
      handler: debounce(function(val) {
        this.debounced = val;
        this.tablePage = 1;
        this.updateList((this.tablePage - 1) * this.fetchAmount);
      }, 500),
      deep: true,
    },
    fetchAmount: function() {
      this.tablePage = 1;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
    },
  },

  methods: {
    changeEnabled()
    {
      this.tablePage = 1;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
    },

    //Handles names with space character from API
    formatName(name) {
      const formatted = name.split("%20");
      if (formatted.length === 2) {
        return `${formatted[0]} ${formatted[1]}`;
      }
      if (formatted.length === 1) {
        return `${formatted[0]}`;
      }
    },

    //Formatting groupID string from API data
    groupName(groupIDs) {
      let groupIDstr = "";
      const groupArr = groupIDs.split(";");
      for (let groupID of groupArr) {
        groupIDstr += `${this.$store.state.groups[groupID] || groupID}, `;
      }
      return groupIDstr.slice(0, -2);
    },

    uploadFile() {
      this.images = this.$refs.file.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        this.sheet = workbook.Sheets.Sheet1;
      };
      reader.readAsArrayBuffer(this.images);
    },

    addUser() {
      this.form = {
        method: "Add",
        firstName: "",
        lastName: "",
        email: "",
        loginID: "",
        password: "",
        enabled:true,
      };
      this.editUserIsAdmin=false;
      this.registerUserModalVisible = true;
      this.userLoginID='';

    },

    addUserSheet() {
      this.groupRegisteryVisible = true;
    },

    selectedGroups(groups) {
      this.groups = groups;
      this.groupRegisteryVisible = false;
      this.fileModalVisible = true;
    },

    submitFile() {
      this.loading = true;
      this.confirmationModalVisible = false;
      this.groupRegisteryVisible = false;
      this.fileModalVisible = false;
      let end = this.sheet["!ref"].split("E")[1];
      for (let x = 2; x <= end; x++) {
        this.updateUser({
          loginID: this.sheet[`D${x}`]["v"],
          firstName: this.sheet[`A${x}`]["v"],
          lastName: this.sheet[`B${x}`]["v"],
          email: this.sheet[`C${x}`]["v"],
          password: this.sheet[`E${x}`]["v"],
          endOfList: x === end,
        });
        sleep(2000);
      }
      this.loading = false;
    },

    updateUser(form) {
      let groupStr = "";

      for (let groupID in this.groups) {
        //groupID;member;wasMember[;isPrimaryGroup;isAdministrator;isAuthor;isLearner;isVerifier;isSME]
        if (this.groups[groupID].isMember) {
          groupStr = `${groupID};${this.groups[groupID].isMember};false;${this.groups[groupID].isPrimaryGroup};${this.groups[groupID].isAdministrator};${this.groups[groupID].isAuthor};${this.groups[groupID].isLearner};false;false;`;
          // groupStr = `${groupID};${this.groups[groupID].isMember};false;${this.groups[groupID].isPrimaryGroup};${this.groups[groupID].isAdministrator};${this.groups[groupID].isAuthor};${this.groups[groupID].isLearner};${this.groups[groupID].isVerifier};false;`;
        }
      }
      this.$store.dispatch("updateUser", {
        method: "Add",
        userID: form.loginID,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        password: form.password,
        group: groupStr,
      });

      if (form.endOfList) {
        this.resetForm();
      }
    },

    confirmDelete(user) {
      this.form.firstName = user.firstName;
      this.form.lastName = user.lastName;
      this.form.email = user.email;
      this.form.loginID = user.loginID;
      this.confirmationModalVisible = true;
    },

    deleteUser() {
      this.loading = true;
      this.$store.dispatch("deleteUser", this.form.loginID).then(() => {
        this.updateList((this.tablePage - 1) * this.fetchAmount);
      });
      this.confirmationModalVisible = false;
    },

    edit(user) {
      this.editUserIsAdmin=false;
      if(Object.keys(this.adminGroups).length==1 && user.groupIDs.indexOf( Object.keys(this.adminGroups)[0]) !=-1)
      {
         this.$store
        .dispatch("getUserGroups", user.loginID)
        .then((groups) => {
          if(groups[Object.keys(this.adminGroups)[0]] && groups[Object.keys(this.adminGroups)[0]].isAdmin)
          {
            this.editUserIsAdmin=true;
          }
        });
      }
      this.form.method = "Edit";
      this.form.firstName = user.firstName;
      this.form.lastName = user.lastName;
      this.form.email = user.email;
      this.form.loginID = user.loginID;
      this.userLoginID=user.loginID;
      this.form.enabled=user.enabled;
      this.registerUserModalVisible = true;
    },

    update() {
      this.loading = true;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
      this.registerUserModalVisible = false;
    },

    closeModals() {
      this.registerUserModalVisible = false;
      this.confirmationModalVisible = false;
      this.groupRegisteryVisible = false;
      this.fileModalVisible = false;
      this.groupsModalVisible=false;
    },

    //API Call to fetch data
    async updateList(offset) {
      this.resultsLoading=true;
      this.loading=true;
      await this.$store
        .dispatch("getUsersData", {
          offset,
          fetch: this.fetchAmount,
          search: this.debounced,
          enabled: this.enabledState,
          selectedGroups: this.groupsSelected.join(","),
          sortBy: this.sortBy,
          sortAscending:this.sortAsc,
        })
        .then((response) => {
          this.resultsLoading=false;
          if(typeof response ==='string')
          {
            this.errorMsgTitle="Error getting users";
            this.errorMsgBody=response;
            this.showErrorMessage=true;
            this.loading=false;
            return;
          }
          else if (!response || !response.results)
          {
            this.errorMsgTitle="Error getting users";
            this.errorMsgBody="Could not find any results. Please reload the page or contact us if you continue having issues.";
            this.loading=false;
            this.showErrorMessage=true;
            return;
          }
          this.resultCount = response.results.length;
          if (this.resultCount === this.fetchAmount) {
            this.totalPages = Math.ceil(response.TotalUsers / this.fetchAmount);
          }
          if (this.resultCount < this.fetchAmount) {
            this.totalPages = this.tablePage;
          }
          if (
            this.search.firstName.length > 0 ||
            this.search.lastName.length > 0
          ) {
            this.totalPages = "?";
          }
          this.filterResults(response.results);
          this.loading = false;
        });
    },

    filterResults(users) {
      const filtered = {};
      for (let x = 0; x < users.length; x++) {
        if (filtered[users[x].loginID]) {
          filtered[users[x].loginID].groupID.push(users[x].groupID);
        }
        if (!filtered[users[x].loginID]) {
          filtered[users[x].loginID] = {
            ...users[x],
            groupID: [users[x].groupID],
          };
        }
      }
      this.visibleUsers = Object.values(filtered);
    },

    increaseTablePage() {
      if (this.resultCount === this.fetchAmount) {
        this.tablePage++;
        this.loading = true;
        this.updateList((this.tablePage - 1) * this.fetchAmount);
      }
    },

    decreaseTablePage() {
      if (this.tablePage > 1) {
        this.tablePage--;
        this.loading = true;
        this.updateList((this.tablePage - 1) * this.fetchAmount);
      }
    },

    goToPage(val) {
      this.tablePage = val > 0 ? val : 1;
      this.loading = true;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
    },

    setGroups(groups){
    this.groupsModalVisible=false;
    this.groupsSelected=[];
    Object.keys(groups).forEach((group)=>
    {
      this.groupsSelected.push(group);
    });
      this.tablePage=1;
      this.updateList((this.tablePage - 1) * this.fetchAmount);

    },

    closeGroupModal(){
      this.groupsSelected=[];
      this.groupsModalVisible=false;
      //todo call the function with the changed data 
      this.tablePage=1;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
    },

    sortTable(name)
    {
      if(this.sortBy==name)
      {
        this.sortAsc=!this.sortAsc;
      }
      else{
        this.sortAsc=true;
      }
      this.sortBy=name;
      //todo: Find the users again with the proper ordering in place and bring back to page one
      this.tablePage = 1;
      this.updateList((this.tablePage - 1) * this.fetchAmount);
      // if(name=='groupName')
      // {
      //   this.sortString=true;
      // }
      // else{
      //   this.sortString=false;
      // }
      // this.sortBy=name;
      // this.filterGroups();
    },


  },





  mounted() {
    this.loading = true;
    this.updateList((this.tablePage - 1) * this.fetchAmount);
  },
  created(){
    if(this.adminLevel.adminLevelInt==0)
    {
      this.enabledState='';
    }
    else{
      this.enabledState='true';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/UserList.scss";
@import "../components/styles/Buttons.scss";
@import "../components/styles/BasicModal.scss";
</style>
