import Vue from "vue";
import Router from "vue-router";
/** Old Routes **/

import TestFunctions from "../pages/TestFunctions";
import Login from "../pages/Login";

/** New Routes **/
import AccountInformation from "../pages/AccountInformation";
import Dashboard from "../pages/Dashboard";
// import Overview from "../pages/Overview";
import Reports from "../pages/Reports";
import CourseReports from "../pages/CourseReports";
import UserList from "../pages/UserList";
import Registration from "../pages/Registration";
import ServerEvents from "../pages/ServerEvents";
import DetailedAnalytics from "../pages/DetailedAnalytics";
import Enrollment from '../pages/Enrollment';
import ManageLearners from "../pages/ManageLearners"

import SaharaHazards from "../pages/SaharaHazards";
import SaharaIndividualWellness from "../pages/SaharaIndividualWellness";
import SaharaEnroll from "../pages/SaharaEnroll";

import SaharaWellness from "../pages/SaharaWellness";

import SaharaMitigation from "../pages/SaharaMitigation";

import Groups from "../pages/Groups";
import AddUser from "../pages/AddUser";
import FirstStep from "../components/msForm/FirstStep.vue";
import SecondStep from '../components/msForm/SecondStep.vue';
import ThirdStep from '../components/msForm/ThirdStep.vue';
import FourthStep from '../components/msForm/FourthStep.vue';
import FifthStep from '../components/msForm/FifthStep.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/", //?Uncomment when login page is done
      //path: "/Login",
      name: "Login",
      component: Login,
    },
    {
      path: "/account-information",
      name: "Account Information",
      component: AccountInformation,
    },
    {
      path: "/add-users/1",
      name: "Add Users",
      component: AddUser,
      children: [
        { path: "/add-users/1", name: "firstStep", component: FirstStep },
        { path: '/add-users/2', name: 'secondStep', component: SecondStep },
        { path: '/add-users/3', name: 'thirdStep', component: ThirdStep },
        { path: '/add-users/4', name: 'fourthStep', component: FourthStep },
        { path: '/add-users/5', name: 'fifthStep', component: FifthStep, meta: {hasFifthStep: true} },
      ]
    },
    {
      //New page being worked on to show and better control the groups
      path: "/groups",
      name: "Add Groups",
      component: Groups,
    },
    {
      //The main and starting page 
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      //The current reports page that has the list of users and their courses
      path: "/reports",
      name: "Reports",
      component: Reports,
    },
    {
      //The current reports page that has the list of users and their courses
      path: "/course-reports",
      name: "Course Reports",
      component: CourseReports,
    },
    {
      //!Older version that has all users and allows people to change it 
      path: "/user-list",
      name: "User List",
      component: UserList,
    },
    {
      //?Older quite complex page that shows the groups and lots of information 
      path: "/detailed-analytics",
      name: "Detailed Analtyics",
      component: DetailedAnalytics,
    },
    {
      //More modern page that has the groups with a list of courses and learners. Courses and People can be clicked to enroll the other and people can be added 
      path: "/enrollment",
      name: "Enrollment",
      component: Enrollment,
    },
    {
      //!Old version of the enrollment page that has the learners and courses and allows peole to change them 
      path: "/manage-learners",
      name: "Manage Learners",
      component: ManageLearners,
    },

    {
      //?List of users with their information. Looks similar to the old manage users but has options to enroll people in different industries
      path: "/sahara-enrollment",
      name: "Sahara Enrollment",
      component: SaharaEnroll,
    },
    {
      //The hazard report for sahara that will give the records, hazards and courses
      path: "/sahara-hazards",
      name: "Sahara Hazards",
      component: SaharaHazards,
    },
    
    {
      //The hazard report for sahara that will give the records, hazards and courses
      path: "/sahara-individual-wellness",
      name: "Sahara Individual Wellness",
      component: SaharaIndividualWellness,
    },


    {
      //working page that has the courses and the amount they are recommeneded and will give the learner status 
    path: "/sahara-mitigation-recommendations",
    name: "Sahara Mitigation Recommendations",
    component: SaharaMitigation,
  },

    {
      //working page that shows the graph for mental wellness questions but is kept vauge
      path: "/sahara-wellness",
      name: "Sahara Wellness",
      component: SaharaWellness,
    },

    {
      //! Very old page that allows registering a user or an organization
      path: "/registration",
      name: "Registration",
      component: Registration,
    },
    {
      //? Page only used by top of the top level admins to see the server events and allow people to force log off
      path: "/server-events",
      name: "Server Events",
      component: ServerEvents,
    },
    // The below routes are the old ones, feel free to remove them as the project progresses
    {
      //! Test page with a lot of buttons that we used to test out different calls to the server
      path: "/Test_Functions",
      name: "Test Functions",
      component: TestFunctions,
    },
  ],
});
