<template>
  <div class="tree">
    <ul class="tree-list">
      <node-tree @nodeUpdated="treeUpdate" :node="treeData">  <!-- The NodeTree component is passed the treeData prop as the first node in the tree-->
      </node-tree>
    </ul>
  </div>
</template>

<script>
import NodeTree from "./NodeTree";

export default {
  props: {
    treeData: Object  // This component takes in an Object(that represents a directory tree)as a prop 
  },                  
  components: {
    NodeTree
  },
  methods:{
    treeUpdate(){
      this.$emit('treeUpdated');
    }
  }
};
</script>


<style>
.tree{
  display: flex;
  justify-content: center;
  /* overflow-y: scroll; */
  /* height:100%; */
  /* border: solid red 1px; */
}

.tree-list ul {
  height: 100%;
  padding-left: 16px; /* padding for heirarchical look */
  margin: 0px 0;
}
</style>