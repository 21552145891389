const debounce = (fn, delay) => {
  let timeout = null;
  return function() {
    clearTimeout(timeout);
    let args = arguments;
    let that = this;
    timeout = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
};

const sleep = (ms) => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < ms);
};

module.exports = { debounce, sleep };


