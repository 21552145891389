<template>
  <div v-bind:class="short?'short-picker':'picker'">
    <label for="datepicker"><slot></slot></label>
    <b-form-datepicker
      v-model="selectedDate"
      class="mb-2"
      :max="maxDate"
      @input="updatedDate"
      dropup
      reset-button
      :placeholder="short?'DD/MM/YY':'Select Date'"
      :date-format-options="dateFormatOptions"
      :size="short?'sm':''"
      locale="en-GB"
    ></b-form-datepicker>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  props:['selected','maxDate','blockResetMessage','short'],
  watch:{
    maxDate: function(newVal){
      if(newVal<this.selectedDate)
      {
        this.selectedDate=newVal;
      }
    }
  },
  data() {
    return {
      selectedDate: "",
    };
  },
  computed:{
    dateFormatOptions() {
      if (this.short) {
        return { year: '2-digit', month: '2-digit', day: '2-digit' };
      } else {
        return {month:"short", day:'numeric',year:'numeric'};
      }
    },
  },
  methods:{
    updatedDate(){
      this.$emit('updatedDate', this.selectedDate)
    },
    resetDate() {
      // let enddate = new Date();
      // this.enddate = enddate.setMonth(enddate.getMonth());
      // this.selectedDate = formatDate(enddate);
      this.selectedDate = "";
      if(this.blockResetMessage!=true)
      {
        this.$emit('updatedDate', this.selectedDate)
      }
    },

  }
};

// function formatDate(date) {
//   var d = new Date(date),
//   month = "" + (d.getMonth() + 1),
//   day = "" + d.getDate(),
//   year = d.getFullYear();

//   if (month.length < 2) month = "0" + month;
//   if (day.length < 2) day = "0" + day;

//   return [year, month, day].join("-");
// }
</script>

<style scoped>
@import "./styles/DatePicker.scss";

</style>
