<template>
  <div class="sidemenu-background" id="side-menu" v-if="menuVisible">
    <!-- <b-sidebar
      id="sidebar-no-header"
      data-cy="side-nav"
      aria-labelledby="sidebar-no-header-title"
      backdrop
      :backdrop-variant="variant"
      no-header
      shadow
      :visible="isVisible"
    > -->
      <template class="side-menu" >
        <div class="p-3">
          <div class="sidemenu-logo">
            <img src="../assets/SWIFT LMS.svg" alt="SWIFT LMS" />
          </div>
          <hr />
          <nav class="mb-3" >
            <b-nav vertical >

              <b-nav-item @click="hide">
                <router-link to="/dashboard">
                  <h5>Dashboard</h5>
                </router-link>
              </b-nav-item>

              <!-- <b-nav-item @click="hide">
                <router-link to="/add-users/1">
                  <h4>Add Users</h4>
                </router-link>
              </b-nav-item>

              <b-nav-item @click="hide">
                <router-link to="/add-groups">
                  <h4>Add Groups</h4>
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link to="/manage-learners">
                  <h4>Manage Learners</h4>
                </router-link>
              </b-nav-item> -->
              <!-- <b-nav-item>
                <router-link to="/detailed-analytics">
                  <h4>Analytics</h4>
                </router-link>
              </b-nav-item> -->

              <!-- <b-nav-item>
                <router-link to="/enrollment">
                  <h4>Enrollment</h4>
                </router-link>
              </b-nav-item> -->


              <div class="reports" v-if="!adminLevel.custodian">
                <h5>Manage</h5>
                <router-link to="/enrollment">
                    <h6>SWIFT Courses</h6>
                  </router-link> 
                   <router-link to="/groups">
                    <h6>Groups</h6>
                  </router-link>
                  <!-- <router-link v-if="!singleGroupUser" to="/manage-learners">
                    <h6>All Users</h6>
                  </router-link> -->
                  <router-link v-if="!singleGroupUser" to="/user-list">
                    <h6>All Users</h6>
                  </router-link>
                  <!-- <router-link  to="/enrollment1" >
                    <h5>SAHARA</h5>
                  </router-link> -->
              </div>

<!-- 
              <b-nav-item>
                <router-link to="/user-list">
                  <h4>Manage Users</h4>
                </router-link>
              </b-nav-item> -->


              <div class="reports">
                <h5>Reports</h5>
                <span v-if="!adminLevel.custodian">
                <h6 @click="swiftShow" class="nav-header">
                  SWIFT
                  <span class="caret" :class="{ down: swiftDropdown || swiftPageOpen }"
                    >&#9650;</span
                  >
                </h6>
                <div v-show="swiftDropdown || swiftPageOpen" class="menu-dropdown">
                  <router-link to="/course-reports">
                    <h6 class="h7">Course Reports</h6>
                  </router-link>  

                  <router-link to="/reports">
                    <h6 class="h7">All Course Reports</h6>
                  </router-link>
                  <!-- <router-link to="/detailed-analytics">
                    <h6>Detailed Analytics</h6>
                  </router-link> -->
                </div>
                </span>
                <div v-if="!adminLevel.custodian">
                <h6 @click="saharaShow" class="nav-header" v-if="adminLevel.isSaharaAdmin">
                  SAHARA
                  <span class="caret" :class="{ down: saharaDropdown || saharaPageOpen }"
                    >&#9650;</span>
                </h6>
                <div v-show="adminLevel.isSaharaAdmin&&(saharaDropdown||saharaPageOpen)" class="menu-dropdown">
                  <!-- <router-link to="/sahara-risk">
                    <h6>Risk Level</h6>
                  </router-link> -->
                  <router-link to="/sahara-hazards">
                    <h6 class="h7">Hazards</h6>
                  </router-link>
                  <!-- <router-link to="/sahara-wellness">
                    <h6>Corporate Wellness</h6>
                  </router-link> -->
                  <router-link to="/sahara-wellness">
                    <h6 class="h7">Wellness Report</h6>
                  </router-link>
                  <!-- <router-link to="/sahara-individual-wellness">
                    <h6 class="h7">Individual Wellness Report</h6>
                  </router-link> -->
                  <router-link to="/sahara-mitigation-recommendations">
                    <h6 class="h7">Mitigation Recommendations</h6>
                    <!-- Similar to the either/both the mastery tab or the detailed analysis page -->
                  </router-link>
                  <!-- <router-link to="/sahara-enrollment">
                    <h6>Enrollment</h6>
                  </router-link> -->
                </div>
                </div>

                <div v-if="adminLevel.custodian">
                <div  class="menu-dropdown-no-title">
                  <!-- <router-link to="/sahara-risk">
                    <h6>Risk Level</h6>
                  </router-link> -->
                  <router-link to="/sahara-hazards">
                    <h6 class="h7">Hazards</h6>
                  </router-link>
                  <!-- <router-link to="/sahara-wellness">
                    <h6>Corporate Wellness</h6>
                  </router-link> -->
                  <router-link to="/sahara-wellness">
                    <h6 class="h7">Wellness Report</h6>
                  </router-link>
                  <router-link to="/sahara-individual-wellness">
                    <h6 class="h7">Individual Wellness Report</h6>
                  </router-link>
                  <router-link to="/sahara-mitigation-recommendations">
                    <h6 class="h7">Mitigation Recommendations</h6>
                    <!-- Similar to the either/both the mastery tab or the detailed analysis page -->
                  </router-link>
                  <!-- <router-link to="/sahara-enrollment">
                    <h6>Enrollment</h6>
                  </router-link> -->
                </div>
                </div>


              </div>

              <b-nav-item @click="hide">
                <router-link to="/account-information">
                  <h5>Account Information</h5>
                </router-link>
              </b-nav-item>

              <!-- <b-nav-item v-if=" adminLevel.adminLevelInt==0"  @click="hide">
                <router-link to="/server-events">
                  <h4>Server Events</h4>
                </router-link>
              </b-nav-item> -->
            </b-nav>
            
          </nav>
            <div class="spacer">
          <b-button
            variant="text-dark bg-light"
            @click="logOut"
            data-cy="logout-button"
            class="logout"
          >
            Log Out
          </b-button>
        </div>
         
        </div>
      </template>
    <!-- </b-sidebar> -->
  </div>
</template>

<script>
import { mapState } from "vuex";


export default {
  name: "SideMenu",
  props:['isVisible'],
  data() {
    return {
      variant: "transparent",
      swiftDropdown: false,
      saharaDropdown: false,
      showVisible: false,
    };
  },

    computed: {
    ...mapState({
      adminLevel: (state) => state.adminLevel,
      menuVisible: (state) => state.menuVisible,
      singleGroupUser:(state) => state.singleGroupUser,
    }),
    currentRoutePath(){
      return this.$route.path;
    },
    saharaPageOpen()
    {
      if(this.currentRoutePath=="/sahara-mitigation-recommendations"||this.currentRoutePath=="/sahara-hazards"||this.currentRoutePath=="/sahara-wellness"||this.currentRoutePath=="/sahara-risk"
      ||this.currentRoutePath=="/sahara-enrollment"||this.currentRoutePath=="/sahara-individual-wellness")
      {
        return true;
      }
      return false;
    },
    swiftPageOpen()
    {
      if(this.currentRoutePath=="/reports"||this.currentRoutePath=='/course-reports'||this.currentRoutePath=='/detailed-analytics')
      {
        return true;
      }
      return false;
    },
  },
  mounted(){
  },
  watch:{
    isVisible: function(newVal){
      if(newVal!=this.menuVisible)
      {
        this.$store.dispatch("toggleMenu");
      }
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("callLogOutAPI").then(() => {
        location.reload();
      });
    },
    swiftShow() {
      this.swiftDropdown = !this.swiftDropdown;
    },
    saharaShow() {
      this.saharaDropdown = !this.saharaDropdown;
    },
    hide(){
      // console.log('Looking to hide the menu')
    }
  },
};
</script>

<style scoped>
@import "./styles/SideMenu.scss";


</style>
