/**
 * This file is to convert all responses from the old server to
 * readable JSON objects to be rendered in the frontend.
 *
 * Each function breaks down specific types of responses from the old server.
 */

const parseUsersForContentSeating = (users) => {
  let usersSplit = users.split("\n");
  let usersArr = [];
  for (let i = 0; i < usersSplit.length - 1; i++) {
    let userArr = usersSplit[i].split(";");
    let userObj = {};

    userObj.loginID = userArr[0];
    userObj.changed = userArr[1];
    userObj.enrolled = userArr[2];
    userObj.enrolled1 = userArr[3];
    userObj.select = userArr[4];
    userObj.ID = userArr[5];
    userObj.firstName = userArr[6];
    userObj.lastName = userArr[7];
    userObj.registeredForDate = userArr[8];
    userObj.started = userArr[9];
    userObj.availableHours = userArr[10];
    userObj.timeLimit = userArr[11];
    userObj.x = userArr[12];

    usersArr.push(userObj);
  }
  return usersArr;
};

const parseContentList = (content) => {
  let contentArr = content.split("\n");
  let coursesArr = [];

  for (let i = 0; i < contentArr.length - 1; i++) {
    let courseArr = contentArr[i].split(";");
    let courseObj = {};
    courseObj.courseID = courseArr[0];
    courseObj.courseName = courseArr[1];
    coursesArr.push(courseObj);
  }

  return coursesArr;

  // contentArr.forEach(course => {
  //   let courseObj = {};
  //   courseObj.courseID = courseArr[0];
  //   courseObj.courseName = courseArr[1];
  //   coursesArr.push(courseObj);
  // })
};

const parseNewOrganization = (organization) => {
  let firstSplit = organization.split("\n");
  let relevantInfo = firstSplit[1].split(";");
  return relevantInfo;
};
///////////////////////

//// for API 1275 ////
const parseTestLoginHx = (loginHistory) => {
  let login = loginHistory.split("\n");
  return login;
};
///////////////////////

//// for API 1288 ////
const parseModuleScores = (modScores) => {
  const adminModScores = [];

  const score = modScores.split("\n");

  for (let value of score) {
    const modValues = value.split(";");
    adminModScores.push({
      userID: modValues[0],
      name: modValues[1],
      course: modValues[2],
      stateKey: modValues[3],
      stateName: modValues[4],
      stateParentName: modValues[5],
      stateInfo: modValues[6],
    });
  }
  return adminModScores;
};

//////////////////////

const parseLoginsHistory = (loginsHistory) => {
  let login = loginsHistory.split("\n");
  let loginsArr = [];

  for (let i = 0; i < login.length - 1; i++) {
    let recentLogin = login[i].split(";");

    const dateStr = new Date(recentLogin[4] * 1000);

    let eachLogin = {};

    eachLogin.loginID = recentLogin[0];
    // eachLogin.loginID = recentLogin[1];  // Redundancy
    eachLogin.fullName = recentLogin[2];
    eachLogin.loginType = recentLogin[3];
    eachLogin.timeStarted = `${dateStr.getDate()}/${dateStr.getMonth() +
      1}/${dateStr.getFullYear()}`;
    eachLogin.sessionDuration = recentLogin[5];
    eachLogin.peer = recentLogin[6];
    eachLogin.hostName = recentLogin[7];

    loginsArr.push(eachLogin);
  }
  return loginsArr;
};

const parseCurrentLogins = (currentLogins) => {
  let loggedInUsers = currentLogins.split("\n");
  let currentLoginsArr = [];

  for (let i = 0; i < loggedInUsers.length - 1; i++) {
    let loggedInUserValues = loggedInUsers[i].split(";");
    let currentLoginsObj = {};

    currentLoginsObj.loginID = loggedInUserValues[0];
    currentLoginsObj.userID = loggedInUserValues[1];
    currentLoginsObj.fullName = loggedInUserValues[2];
    currentLoginsObj.loginType = loggedInUserValues[3];
    currentLoginsObj.sessionStart = loggedInUserValues[4];
    currentLoginsObj.peer = loggedInUserValues[5];
    currentLoginsObj.hostName = loggedInUserValues[6];

    currentLoginsArr.push(currentLoginsObj);
  }
  return currentLoginsArr;
};

const parseContentLogins = (contentLogins) => {
  let contentUsers = contentLogins.split("\n");
  let contentLoginsArr = [];

  for (let i = 0; i < contentUsers.length - 1; i++) {
    let userValues = contentUsers[i].split(";");
    let userObj = {};

    userObj.loginID = userValues[0];
    userObj.userID = userValues[1];
    userObj.fullName = userValues[2];
    userObj.loginType = userValues[3];
    userObj.sessionStart1 = userValues[4];
    userObj.sessionStart2 = userValues[5];
    userObj.courseName = userValues[6];

    contentLoginsArr.push(userObj);
  }
  return contentLoginsArr;
};

const parseGroup = (groupString) => {
  var groupObject = {};
  let breaks = groupString.split("\n");
  groupObject.groupID = parseInt(breaks[1]);
  groupObject.name = breaks[2];
  groupObject.creationDate = breaks[3];
  groupObject.creationType = breaks[4];
  groupObject.sourceAddress = breaks[5];
  groupObject.allowGuests = checkTrueFalse(breaks[6]);
  groupObject.clientEmail = breaks[7];
  groupObject.exaustiveTracking = checkTrueFalse(breaks[8]);
  groupObject.ipAddress = breaks[9];
  groupObject.availableIPAddresses = breaks[10];
  groupObject.randomizeTestQuestions = checkTrueFalse(breaks[11]);
  groupObject.showDemoContent = checkTrueFalse(breaks[12]);
  groupObject.showSupplemental = checkTrueFalse(breaks[13]);
  groupObject.showUnavailableContent = checkTrueFalse(breaks[14]);
  groupObject.showOnlyAssigned = checkTrueFalse(breaks[15]);
  groupObject.isOrganization = checkTrueFalse(breaks[16]);
  var pos = 17; //! There is an error here where I seem to be missing a variable when set to 16 which it theoretically should be the results seem to be pushed down by one
  //Is organization has to do with the CAP Servers which are structured by having an organizations subgroup of the main server
  //The server first checks if the parent group name is Organizations then if the parentID is a child of the companyID
  //An example of this is on the SWIFT Pilot server is group 370
  if (groupObject.isOrganization) {
    pos = 19;
    groupObject.country = breaks[16];
    groupObject.provState = breaks[17];
    groupObject.city = breaks[18];
  }
  // This is if we are in a CAP Server and has team information
  // It is deciding based on if the length of the return is longer but there is likely a much better way to determine this
  if (breaks.length > 25) {
    groupObject.isTeam = checkTrueFalse(breaks[pos]);
    pos++;
    groupObject.isNonTeam = checkTrueFalse(breaks[pos]);
    pos++;
    groupObject.sportName = breaks[pos];
    pos++;
  }
  groupObject.supportEmail = breaks[pos];
  pos++;
  groupObject.licenseKey = breaks[pos];
  pos++;
  groupObject.expiryDate = breaks[pos];
  pos++;
  groupObject.seatsAvailable = breaks[pos];
  pos++;
  groupObject.customField1Name = breaks[pos];
  pos++;
  groupObject.customField1Value = breaks[pos];
  pos++;
  groupObject.comment = breaks[pos];

  return groupObject;
};

const parseGroupedUsers = (groupedUsers) => {
  let start = new Date().getTime();
  start;
  var groupedUser = [];
  let users = groupedUsers.split("\n");

  for (var i = 0; i < users.length; i++) {
    let user = {};
    let userArr = users[i].split(";");
    user.loginID = userArr[0];
    user.changed = checkTrueFalse(userArr[1]);
    user.isMember = checkTrueFalse(userArr[2]);
    user.isMember1 = checkTrueFalse(userArr[3]);
    user.isPrimaryGroup = checkTrueFalse(userArr[4]);
    user.loginID1 = userArr[5];
    user.firstName = userArr[6];
    user.lastName = userArr[7];
    user.isAdmin = checkTrueFalse(userArr[8]);
    user.isAuthor = checkTrueFalse(userArr[9]);
    user.isLearner = checkTrueFalse(userArr[10]);
    user.isVerifier = checkTrueFalse(userArr[11]);
    user.isSME = checkTrueFalse(userArr[12]);
    groupedUser.push(user);
  }
  let end = new Date().getTime();
  end;
  return groupedUser;
};

const parseGroupList = (groupList) => {
  // Used with getGroupList API 1205
  // let groups = {};
  // let groupsList = groupList.split("\n");
  // for (var a = 0; a < groupsList.length - 1; a++) {
  //   let groupContents = groupsList[a].split(";");
  //   groups[groupContents[0]] = groupContents[1];

  // }
  // return groups;

  const groups = {};
  const a = groupList.split("\n");
  a.pop();
  a.forEach((element) => {
    if (element !== "eob") {
      groups[element.split(";")[0]] = element.split(";")[1];
    }
  });
  return groups;
};

const parseHostIPProperties = (prop) => {
  let propVal = prop.split("\n");
  var hostIP = {};
  if (propVal.length == 9) {
    hostIP.defaultHTTPPort = propVal[5];
    hostIP.defaultHTTPSPort = propVal[6];
  } else if (propVal.length == 22) {
    hostIP.hostIP = propVal[1];
    hostIP.hostName = propVal[2];
    hostIP.groupID = propVal[3];
    hostIP.groupName = propVal[4];
    hostIP.httpPort = parseInt(propVal[5]);
    hostIP.httpsPort = parseInt(propVal[6]);
    hostIP.portsEditable = checkTrueFalse(propVal[7]);
    hostIP.DSNName = propVal[8];
    hostIP.DSNType = propVal[9];
    hostIP.numDBConnectionsCreated = parseInt(propVal[10]);
    hostIP.numDBConnectionsDestroyed = parseInt(propVal[11]);
    hostIP.numDBConnectionsFailed = parseInt(propVal[12]);
    hostIP.numDBConnectionsFromPool = parseInt(propVal[13]);
    hostIP.numDBConnectionsPooled = parseInt(propVal[14]);
    hostIP.numDBConnectionsRequested = parseInt(propVal[15]);
    hostIP.numDBReleasesRequested = parseInt(propVal[16]);
    hostIP.peakConnectionsActive = parseInt(propVal[17]);
    hostIP.peakConnectionsPooled = parseInt(propVal[18]);
    hostIP.numConnectionsInPool = parseInt(propVal[19]);
    hostIP.maxConnectionsInPool = parseInt(propVal[20]);
  }
  return hostIP;
};

const parseContentUsers = (ContentUsers) => {
  let contentUsers = [];
  let user = ContentUsers.split("\n");
  for (var i = 0; i < user.length - 1; i++) {
    let userStr = user[i].split(";");
    let contentUser = {};
    contentUser.LoginID = userStr[0];
    contentUser.Selected = checkTrueFalse(userStr[1]);
    contentUser.LoginID = userStr[2];
    contentUser.firstName = userStr[3];
    contentUser.lastName = userStr[4];

    contentUsers.push(contentUser);
  }
  return contentUsers;
};

const parseAddEditUser = (addResult) => {
  let results = addResult.split("\n");
  let userResults={};
  let userStatus=results[0].split(';');
  userResults.operation=userStatus[0];
  userResults.operation=userStatus[1];
  if(!(userResults.operation=='Add'||userResults.operation=="Edit"))
  {
    userResults.status='Error';
    userStatus.forEach((error)=>{
      userResults.errorMsg+=error;
    })
    for(var i=1;i<results.length;i++)
    {
      userResults.errorMsg+=results[i];
    }
    return userResults;
  }
  userResults.status=userStatus[2];
  if(userResults.status!='OK')
  {
    userResults.errorMsg=userStatus[3];
    return userResults;
  }
  let userInfo=results[1].split(';');
  userResults.loginID=userInfo[0];
  userResults.firstName=userInfo[1];
  userResults.lastName=userInfo[2];
  return userResults;
};

// const parseContentList = (contentList) => {
//   // let contentArr = [];
//   let contentArr = {};
//   let content = contentList.split("\n");
//   for (var c = 0; c < content.length - 1; c++) {
//     // let cont = {};
//     let info = content[c].split(";");
//     // cont.contentID = parseInt(info[0]);
//     // cont.contentName = info[1];
//     // contentArr.push(cont);
//     contentArr[info[1]] = parseInt(info[0]);
//     // contentArr[parseInt(info[0])] = info[1];
//   }
//   return contentArr;
// };

const parseReports = (report, type) => {
  let start = new Date().getTime();
  start;
  var reportObj = {};
  var usersReport = [];
  const split = /\r?\n|\r/g; //split the data on all \r and \n
  let vertical = report.split(split); //get all of the vertical rows
  reportObj.generalInformation = vertical[0]; //the first line is some general information such as the report type and when it was generated
  reportObj.groupsIncluded = vertical[1]; //incldues information such as what is included, the group and the users
  reportObj.dateRange = vertical[2]; //includes the date range of the report
  if (type === 3) {
    const scoreFields = vertical[4].split(","); // creates fields for object keys
    for (let i = 5; i < vertical.length - 1; i++) {
      //separates user data user data
      let scoreReportData = {};
      let scoreInfo = vertical[i].split(",");

      scoreInfo.splice(5, 2, `${scoreInfo[5]}${scoreInfo[6]}`);

      for (let a = 0; a < scoreInfo.length; a++) {
        if (scoreFields.length === scoreInfo.length) {
          scoreReportData[scoreFields[a].replace(/ /g, "")] = scoreInfo[a].replace(/['"]+/g, "");
        }
      }
      usersReport.push(scoreReportData);
    }
    reportObj.results = usersReport;
  } else if (type != 11) {
    //if it is not 11 it is broken by comma
    var reportFields = vertical[4].split(","); //find the feilds of the report from the fourth line
    for (
      var i = 5;
      i < vertical.length - 1;
      i++ //go through all of the user records
    ) {
      let userReportData = {};
      let userData = vertical[i].split(",");


      for (var a = 0; a < userData.length; a++) {
        //assign the object names to the data
        if (reportFields.length === userData.length) {
          userReportData[reportFields[a].replace(/ /g, "")] = userData[
            a
          ].replace(/['"]+/g, "");
        }
      }
      usersReport.push(userReportData); //add to the users object
    }
    reportObj.results = usersReport; //set the users results object to be the array of results
  } else {
    //in the case of report type 11
    var variables = vertical[4].split("\t");
    for (var t = 5; t < vertical.length - 1; t++) {
      let userReportData = {};
      let userData = vertical[t].split("\t");

      for (var b = 0; b < userData.length; b++) {
        userReportData[variables[b].replace(/ /g, "")] = userData[b].replace(
          /['"]+/g,
          ""
        );
      }
      usersReport.push(userReportData);
    }
    reportObj.results = usersReport;
  }

  return reportObj;
};

const parseRegisteredUsers = (users) => {
  var regUsers = [];
  let userList = users.split("\n");
  for (var i = 0; i < userList.length - 1; i++) {
    let userObj = {};
    let user = userList[i].split(";");
    userObj.UserID = user[0];
    userObj.Enabled = parseInt(user[1]);
    userObj.LoginID = user[2];
    userObj.firstName = user[3];
    userObj.lastName = user[4];
    userObj.Email = user[5];
    userObj.DateRegistered = parseInt(user[6]);
    userObj.LastLogin = parseInt(user[7]);
    userObj.HireDate = parseInt(user[8]);
    regUsers.push(userObj);
  }
  return regUsers;
};

// Parse into a tree
const parseAssignmentGroupTree = (assignmentGroupTree) => {
  //?This will take into account if there is only one level of abstraction not any more than that
  let vertGroupTree = assignmentGroupTree.split("\n");
  let firstSplit = vertGroupTree[0].split(";");
  let allNodes = []; //set an array to hold all of the nodes of the tree
  let rootGroup = {
    //set the root node with a parentID of 0
    ID: parseInt(firstSplit[0]),
    parentID: 0,
    Name: firstSplit[1],
    Selected: checkTrueFalse(firstSplit[2]),
  };
  allNodes.push(rootGroup);
  let parentIDs = []; //set a stack of parentIDs in order to keep push one in after a group is added and push one out after hitting an eob
  parentIDs.push(rootGroup.ID);
  for (var i = 1; i < vertGroupTree.length - 1; i++) {
    if (vertGroupTree[i] !== "eob") {
      //if it is not the end of a branch add the data to the array and push the id to the stack
      let groupObj = {};
      let groupDat = vertGroupTree[i].split(";");
      groupObj.parentID = parentIDs[parentIDs.length - 1]; //set the parent ID to be the later one in the stack
      groupObj.ID = parseInt(groupDat[0]);
      groupObj.groupName = groupDat[1];
      groupObj.Selected = checkTrueFalse(groupDat[2]);
      allNodes.push(groupObj);
      parentIDs.push(groupObj.ID);
    } else {
      parentIDs.pop(); //remove the parent ID from the stack
    }
  }
  let tree = listToTree(allNodes); //convert the list with all of the parentID's to a tree form with a node group and subgroups of children
  return tree;
};

const parseGroupTree = (groupTree) => {
  //?This will take into account if there is only one level of abstraction not any more than that
  let vertGroupTree = groupTree.split("\n");
  let firstSplit = vertGroupTree[0].split(";");
  let allNodes = []; //set an array to hold all of the nodes of the tree
  let rootGroup = {
    //set the root node with a parentID of 0
    ID: parseInt(firstSplit[0]),
    parentID: 0,
    groupName: firstSplit[1],
  };
  allNodes.push(rootGroup);
  let parentIDs = []; //set a stack of parentIDs in order to keep push one in after a group is added and push one out after hitting an eob
  parentIDs.push(rootGroup.ID);
  for (var i = 1; i < vertGroupTree.length - 1; i++) {
    if (vertGroupTree[i] != "eob") {
      //if it is not the end of a branch add the data to the array and push the id to the stack
      let groupObj = {};
      let groupDat = vertGroupTree[i].split(";");
      groupObj.parentID = parentIDs[parentIDs.length - 1]; //set the parent ID to be the later one in the stack
      groupObj.ID = parseInt(groupDat[0]);
      groupObj.groupName = groupDat[1];
      allNodes.push(groupObj);
      parentIDs.push(groupObj.ID);
      if (groupObj.parentID != 1) {
        //console.log(groupObj);
      }
    } else {
      parentIDs.pop(); //remove the parent ID from the stack
    }
  }
  let tree = listToTree(allNodes); //convert the list with all of the parentID's to a tree form with a node group and subgroups of children
  return tree;
};

const parseCertifications = (certifications) => {
  //?This will take into account if there is only one level of abstraction not any more than that
  let verticalCert = certifications.split("\n");
  let certificationArray = [];
  for (var i = 0; i < verticalCert.length - 1; i++) {
    let certs = verticalCert[i].split(";");
    let certObj = {};
    certObj.certificationID = parseInt(certs[0]);
    certObj.Selected = checkTrueFalse(certs[1]);
    certObj.certificationName = certs[2];
    certificationArray.push(certObj);
  }
  return certificationArray;
};

const parseUserList = (userListRaw) => {
  let userList = [];
  let newList = userListRaw.split("\n");
  newList.pop();
  for (let user of newList) {
    let userComponents = user.split(";");
    let userObj = {
      ID: userComponents[0],
      firstName: userComponents[1],
      lastName: userComponents[2],
    };
    userList.push(userObj);
  }
  return userList;
};

const parseUserGroups = (userGroups) => {
  // let list = [];
  // const splitUserGroups = userGroups.split("\n");
  // splitUserGroups.pop();
  // for (let group of splitUserGroups) {
  //   let groupSplit = group.split(";");
  //   let groupObj = {
  //     groupID: parseInt(groupSplit[0]),
  //     changed: checkTrueFalse(groupSplit[1]),
  //     isMember: checkTrueFalse(groupSplit[2]),
  //     wasMember: checkTrueFalse(groupSplit[3]),
  //     groupName: groupSplit[4],
  //     parentGroupID: groupSplit[5],
  //     customField1: groupSplit[6],
  //     isPrimaryGroup: checkTrueFalse(groupSplit[7]),
  //     isAdmin: checkTrueFalse(groupSplit[8]),
  //     isAuthor: checkTrueFalse(groupSplit[9]),
  //     isLearner: checkTrueFalse(groupSplit[10]),
  //     isVerifier: checkTrueFalse(groupSplit[11]),
  //     isSME: checkTrueFalse(groupSplit[12]),
  //   };
  //   list.push(groupObj);
  // }
  // return list;
  let list = {};
  const groupList = userGroups.split("\n");
  groupList.pop();
  for (let group of groupList) {
    let groupProperties = group.split(";");
    if(groupProperties[1]=="Error")
    {
      return {};
    }
    list[groupProperties[0]] = {
      changed: checkTrueFalse(groupProperties[1]),
      isMember: checkTrueFalse(groupProperties[2]),
      wasMember: checkTrueFalse(groupProperties[3]),
      groupName: groupProperties[4],
      parentGroupID: groupProperties[5],
      customField1: groupProperties[6],
      isPrimaryGroup: checkTrueFalse(groupProperties[7]),
      isAdmin: checkTrueFalse(groupProperties[8]),
      isAuthor: checkTrueFalse(groupProperties[9]),
      isLearner: checkTrueFalse(groupProperties[10]),
      isVerifier: checkTrueFalse(groupProperties[11]),
      isSME: checkTrueFalse(groupProperties[12]),
    };
  }
  return list;
};

//creationType so far only has 3 values: GeMS - admin, Unknown - ??, E-Commerce - user
// userIDs with unknown albrac56, aldrinfloriano, alysia.webster
const parseUserProperties = (users) => {
  let userDetails = users.split("\n");
  return {
    userID: userDetails[1],
    firstName: userDetails[2],
    lastName: userDetails[3],
    learnerID: userDetails[4],
    email: userDetails[5],
    password: userDetails[6],
    enabled: checkTrueFalse(userDetails[7]),
    registrationDate: userDetails[8],
    creationType: userDetails[9],
    source: userDetails[10],
    isAvailableFrom: checkTrueFalse(userDetails[11]),
    availableFromDate: parseInt(userDetails[12]),
    isAvailableTo: checkTrueFalse(userDetails[13]),
    availableToDate: parseInt(userDetails[14]),
  };
};

const parseCourseScore = (data) => {
  let result = [];
  const scores = data.split("\n");
  scores.pop();
  for (let score of scores) {
    const scoreData = score.split(";");
    const obj = {
      userID: scoreData[0],
      firstName: scoreData[1].split(" ")[0],
      lastName: scoreData[1].split(" ")[1],
      dateOfExam: parseInt(scoreData[2]), // all data beyond this point is an assumption
      score: parseInt(scoreData[3]),
      targetScore: parseInt(scoreData[4]),
      mastery: scoreData[5],
      numberOfQuestions: parseInt(scoreData[6]), //taken from server code HandleAdminGetCourseScoresTable
      duration: parseInt(scoreData[7]), // end of assumption
    };
    result.push(obj);
  }
  return result;
};

const parseContentProperties = (data) => {
  let contentProperties = data.split("\n");
  return {
    contentID: parseInt(contentProperties[0]),
    name: contentProperties[1],
    location: contentProperties[2],
    enabled: checkTrueFalse(contentProperties[3]),
    demo: checkTrueFalse(contentProperties[4]),
    expirationMonths: parseInt(contentProperties[5]),
    licenseKey: contentProperties[6],
    expiryDate: contentProperties[7],
    numSeatsAvailable: contentProperties[8],
    numSeatsConsumed: parseInt(contentProperties[9]),
    hoursRequired: parseFloat(contentProperties[10]),
    luCost: parseInt(contentProperties[11]),
    certificateNumber: parseInt(contentProperties[12]),
    certificateExpiresMonths: parseInt(contentProperties[13]),
    luThreshold: parseInt(contentProperties[14]),
    numUsersRegistered: parseInt(contentProperties[15]),
    numUsersRegisteredAndStarted: parseInt(contentProperties[16]),
    allowAICC: checkTrueFalse(contentProperties[17]),
    AICCLaunchURL: contentProperties[18],
    version: contentProperties[19],
    registrationDate: contentProperties[20],
    creationType: contentProperties[21],
    source: contentProperties[22],
    isAvailableFrom: checkTrueFalse(contentProperties[23]),
    availableFromDate: parseInt(contentProperties[24]),
    isAvailableTo: checkTrueFalse(contentProperties[25]),
    availableToDate: parseInt(contentProperties[26]),
    timeLimit: parseFloat(contentProperties[27]),
    availableHours: parseInt(contentProperties[28]),
    allowRestart: parseInt(contentProperties[29]),
    visibleToChildHostIPs: checkTrueFalse(contentProperties[30]),
    resumeable: checkTrueFalse(contentProperties[31]),
    editable: checkTrueFalse(contentProperties[32]),
  };
};

const parseSeatDetails = (data) => {
  return {
    timeStarted: parseInt(data.split("\n")[1]),
    timeUsed: parseInt(data.split("\n")[2]),
  };
};

const parseContentForSeating = (data) => {
  // contentID - c: course, r: cert
  // changed
  // registered -- needed for GeMS per server code
  // registered1 -- needed for GeMS per server code
  // contentType
  // contentName
  // groupName **subject to change
  // dateRegistered - null for certs
  // dateStarted - null for certs
  // availableHours - inf for certs
  // defaultAvailableHours - inf for certs
  // defaultTimeLimit - N/A for certs
  // timeLimit - N/A for certs
  // availableSeats - inf for certs
  let result = [];
  const table = data.split("\n");
  table.pop();
  for (let row of table) {
    const info = row.split(";");
    const obj = {
      courseID: info[0].substring(1),
      changed: checkTrueFalse(info[1]),
      registered: checkTrueFalse(info[2]),
      registered1: checkTrueFalse(info[3]),
      contentType: info[4],
      courseName: info[5],
      groupName: info[6],
      dateRegistered: info[7],
      dateStarted: info[8],
      availableHours: info[9],
      defaultAvailableHours: info[10],
      defaultTimeLimit: info[11],
      timeLimit: info[12],
      availableSeats: info[13],
    };
    if(obj.contentType==='Course')
    {
      result.push(obj);
    }
  }
  return result;
};

const parseUserLKBS = (data) => {
  // const result = [];
  const result = {};
  const table = data.split("\n");
  table.pop();
  for (let row of table) {
    const info = row.split(";");
    result[info[0]] = info[1];
    // const obj = {
    //   contentID: info[0],
    //   name: info[1],
    // };
    // result.push(obj);
  }
  return result;
};

const parseUserLKBSdata = (data) => {
  const result = [];
  const table = data.split("\n");
  for (let row of table) {
    result.push(row.split(";")[1]);
    result.push(row.split(";")[3]);
  }
  return {
    dateStarted: result[0],
    topicsInAdaptedCourse: result[1],
    dateCompleted: result[2],
    modulesInAdaptedCourse: result[3],
    timeUsed: result[4],
    conceptsInAdaptedCourse: result[5],
    posttestsTaken: result[6],
    topicsAdaptedViaPretest: result[7],
    posttestsAvailable: result[8],
    modulesAdaptedViaPretest: result[9],
    mastery: result[10],
    conceptsAdaptedViaPretest: result[11],
    percentComplete: result[12],
    posttestAverage: result[13],
  };
};

const parseUserLKBtests = (data) => {
  const result = [];
  const table = data.split("\n");
  table.pop();
  for (let row of table) {
    let info = row.split(";");
    const obj = {
      name: info[0],
      testType: info[1],
      dateCompleted: parseInt(info[2]),
      score: parseInt(info[3]),
      targetScore: parseInt(info[4]),
      result: info[5],
      numberOfQuestions: parseInt(info[6]),
      duration: parseInt(info[7]),
    };
    result.push(obj);
  }
  return result;
};

const parseCourseModules = (data) => {
  // const result = [];
  const result = {};
  const table = data.split("\n");
  table.pop();
  for (let row of table) {
    let info = row.split(";");
    // const obj = {
    //   moduleIndex: info[0],
    //   moduleName: info[1],
    // };
    // result.push(obj);
    result[info[0]] = info[1];
  }
  return result;
};

const parseUnregisteredCourses = (data) => {
  // const result = [];
  const result = {};
  const table = data.split("\n");
  table.pop();
  for (let row of table) {
    let info = row.split(";");
    // const obj = {
    //   name: info[0],
    //   path: info[1],
    // };
    // result.push(obj);
    result[info[0]] = info[1];
  }
  return result;
};

const parseUsersForCourseComplete = (data) => {
  const result = [];
  const table = data.split("\n");
  table.pop();
  for (let row of table) {
    let info = row.split(";");
    const obj = {
      loginID: info[0],
      changed: checkTrueFalse(info[1]),
      completed: checkTrueFalse(info[2]),
      wasCompleted: checkTrueFalse(info[3]),
      userID: info[4],
      firstName: info[5],
      lastName: info[6],
      masteryDate: info[7],
      associatedFile: info[8],
    };
    result.push(obj);
  }
  return result;
};

const parseEventLog = (data) => {
  const events = [];
  const logs = data.split("\n");
  logs.pop();
  for (let event of logs) {
    let info = event.split(";");
    if (info[0] != "[t]") {
      const obj = {
        type: info[0],
        date: new Date(info[1] * 1000),
        hostIP: info[2],
        source: info[3],
        user: info[4],
        peer: info[5],
        generalDescription: info[6],
        specificDescription: info[7],
      };
      events.push(obj);
    } else {
      const err = {
        flag: info[0],
        type: info[1],
        bytePos: info[2],
      };
      events.push(err);
    }
  }
  return events;
};

const parseDeleteGroup = (msg) => {
  var responseBreak=msg.split('\n');
  if(responseBreak.length>1)
  {
    responseBreak=responseBreak[1].split(";");
    var deleteObj={};
    if(responseBreak.length==3)
    {
      deleteObj={
      groupID: parseInt(responseBreak[0]),
      status: responseBreak[1],
      message:responseBreak[2],
    };
    }
    else{
      deleteObj={
        groupID: parseInt(responseBreak[0]),
        status: responseBreak[1],
     };
  }
    return deleteObj;
  }
  else{
    console.warn('There was an error getting the response from the delete group',responseBreak)
  }

};



function listToTree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].ID] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentID && node.parentID !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentID]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

function checkTrueFalse(str) {
  if (str === "true") {
    return true;
  } else if (str === "false") {
    return false;
  } else {
    return str;
  }
}




export {
  parseGroup,
  parseGroupedUsers,
  parseGroupList,
  parseHostIPProperties,
  parseContentUsers,
  parseAddEditUser,
  parseContentList,
  parseReports,
  parseRegisteredUsers,
  parseAssignmentGroupTree,
  parseGroupTree,
  parseCertifications,
  parseUserList,
  parseUserGroups,
  parseUserProperties,
  parseCourseScore,
  parseContentProperties,
  parseSeatDetails,
  parseContentForSeating,
  parseUserLKBS,
  parseUserLKBSdata,
  parseUserLKBtests,
  parseCourseModules,
  parseUnregisteredCourses,
  parseUsersForCourseComplete,
  listToTree,
  checkTrueFalse,
  parseLoginsHistory,
  parseCurrentLogins,
  parseContentLogins,
  parseNewOrganization,
  parseTestLoginHx,
  parseModuleScores,
  parseEventLog,
  // parseClassroomData,
  parseUsersForContentSeating,
  parseDeleteGroup,
};
