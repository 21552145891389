<template>
  
  <div class="dashboard-container">

    <div id="side-menu" >
      <SideMenu :isVisible="true"></SideMenu>
    </div>
    <div id="navbar-container">
      <Navbar>Dashboard</Navbar>
    
  <div id="dashboard">

    <!-- <Navbar>Dashboard</Navbar> -->
    <!-- <button @click="showOld()">Show Old Menu</button> -->
    <!-- <DashSideMenu v-if="this.checkRoute='/dashboard'"></DashSideMenu> -->
    <!-- !!!!!!!!!!!!!!!!!!!! UNDO THIS TO GET THE MENU BACK -->
    <!-- <SideMenu :isVisible="true" v-if="this.checkRoute='/dashboard'"></SideMenu> -->
    <!-- <SideMenu  v-if="this.checkRoute='/dashboard'"></SideMenu> -->
    <DashSideMenu v-if="showOldDash"></DashSideMenu>
    <div class="dashboard-header">Welcome to Your Dashboard {{user.firstName}}</div>

    <div class="dashboard-content">
      <div class="swift-dash" v-if="!adminLevel.custodian" v-bind:style="adminLevel.isSaharaAdmin?'':'width: 100%;'">

      <div class="dashboard-sub-header"><font-awesome-icon
                class="dashboard-title-icon"
                :icon="['fas', 'star']"
                style="color: rgb(251, 143, 6) ;transform: rotate(180deg);"
              />SWIFT Analytics</div>
      <span></span>

        <div class="dashboard-cards">

  
          <table class="analytics-table">
            <thead>
              <tr>
                <th class="analytics-table-title">Learners</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="analytics-table-category">Total</td>
                <td class="analytics-table-result">{{ groupedUsersStore.length }}</td>
              </tr>
              <tr>
                <td class="analytics-table-category">Active</td>
                <td class="analytics-table-result">{{ this.registeredUsers.length }}</td>
              </tr>
              <tr>
                <td class="analytics-table-category">Course Mastery</td>
                <td class="analytics-table-result">{{ this.masteryReports.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div class="dashboard-chart-cards">
          <div
            class="chart-card-swift"
            :class="{ darkmode: this.$store.state.darkmode }"
          >
            <Tabs>
              <Tab name="Course Post Test Scores"  >
                <CourseScore></CourseScore>
              </Tab>
              <Tab name="Learner Engagement" selected="true">
                <LearnerEngagement></LearnerEngagement>
              </Tab>
            </Tabs>
          </div>
        </div>
    </div>
      <div class="sahara-dash" v-if="adminLevel.isSaharaAdmin|| adminLevel.custodian" v-bind:style="adminLevel.custodian?'width: 100%;':''">
      <div class="dashboard-sub-header"><img class="title-icon" src="../assets/sahara_icon_normal.png" > SAHARA Analytics</div>

      <div class="dashboard-cards">
    
          <table class="analytics-table" >
            <thead>
              <tr style="border-radius:10px;">
                <th class="analytics-table-title">Users</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="analytics-table-category">Total</td>
                <td class="analytics-table-result">
                 <span v-if="saharaDataLoading==false && saharaDashboardData.totalUsers">{{saharaDashboardData.totalUsers}}</span>
                </td>
              </tr>
              <tr>
                <td class="analytics-table-category">Active</td>
                <td class="analytics-table-result">
                  <span v-if="saharaDataLoading==false && saharaDashboardData.activeUsers">{{saharaDashboardData.activeUsers}}</span>
                </td>
                

              </tr>
              <tr>
                <td class="analytics-table-category">Average Risk Level</td>
                <td class="analytics-table-result">
                  <span v-if="(saharaDataLoading==false && saharaDashboardData.averageRisk && saharaDashboardData.averageRisk.riskLevel)">{{saharaDashboardData.averageRisk.riskLevel}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      <div class="dashboard-chart-cards">
        
        <div
          class="chart-card-sahara"
          :class="{ darkmode: this.$store.state.darkmode }"
        >
              <SaharaHazardsTable :saharaData="saharaDashboardData"></SaharaHazardsTable>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import DashSideMenu from '../components/DashSidemenu.vue'
import { mapState } from "vuex";
import Tabs from "../components/Tabs.vue";
import Tab from "../components/Tab.vue";
import CourseScore from "../components/CourseScore.vue";
import PopularCourse from "../components/PopularCourse.vue";
import LearnerEngagement from "../components/LearnerEngagement.vue";
import Spinner2 from "../components/Spinner2.vue";
import SaharaHazardsTable from "../components/SaharaHazardsTable.vue";
// import {Translator} from "vue-google-translate";
export default {
  name: "Dashboard",
  components: {
    Navbar,
    SideMenu,
    DashSideMenu,
    Tabs,
    Tab,
    CourseScore,
    PopularCourse,
    LearnerEngagement,
    Spinner2,
    SaharaHazardsTable,
    // Translator,
  },
  data() {
    return {
      registeredUsers: [],
      masteryReports: [],
      recentLogins: [],
      contentLogins: [],
      currentlyOnline: [],
      registeredUserLoading: true,
      masteryLoading: true,
      checkRoute: '',
      showOldDash:false,
      groupedUsersStore:[],
      userListLoading:true,
      leadingIndicatorLoading:false,
      user:{},
      saharaDashboardData:{},
      saharaDataLoading:false,
    };
  },
  computed: {
    ...mapState({
      // groupedUsersStore: (state) => state.users,
      firstName: (state) =>state.firstName,
      adminLevel:(state) =>state.adminLevel,
    }),

    // Document this later
    removedDuplicateLogins: function() {
      return Array.from(new Set(this.recentLogins.map((a) => a.loginID))).map(
        (loginID) => {
          return this.recentLogins.find((a) => a.loginID === loginID);
        }
      );
    },
  },

  created() {
    if(this.adminLevel.isSaharaAdmin)
    {
      this.getSaharaData();
    }
     this.$store
      .dispatch("getUserProperties", this.$store.state.userID)
      .then((user) => {
        this.user.firstName = user.firstName;
        this.user.lastName = user.lastName;
        this.user.email = user.email;
        this.user.creationType = user.creationType;
        this.user.registrationDate = user.registrationDate;
      });
      
    this.getRegisteredUsers();
    // this.getGenerateReport();
         this.$store
        .dispatch("getUserList")
        .then((data) => {
          this.groupedUsersStore=data;
          this.userListLoading=false;
        })
        .catch((error) => {
          this.userListLoading=false;
          console.error('Error in get User List in Dashboard.vue',error);
        });
    // this.getContentLogins();
    this.checkRoute = this._routerRoot._route.fullPath;
    },

  methods: {
    // Returns an object with a results key with the value of an array of objects. The objects in the results array represent a learner.
    // Each learner has mastered a course, but that course is unknown in this data set. In the learner object you can find their name
    // and the date the mastered a course. They will appear multiple times if they have completed multiple courses
    // CURRENTLY USED TO DISPLAY RECENT MASTERIES -- replace later with new API.
    async getGenerateReport() {
      let reportObj = {
        groupID: 3383,
        // groupID: 1,
        reportType: 10,
        includeContent: "*",
        includeContentType: 1,
        includeGroups: "*",
        includeUsers: "*",
        startDate: "1,2000",
        endDate: "11,2021",
        /*  startDate:"",
        endDate:"",*/
      };
      await this.$store
        .dispatch("generateReport", reportObj)
        .then((data) => {
          this.masteryReports = data;
          this.masteryLoading = false;
        })
        .catch((error) => {
          console.error('Error in get Generate Report in Dashboard.vue',error);
        });
    },

    showOld(){
      this.showOldDash = !this.showOldDash;
    },



    // Returns an array of objects. In the store, a filter for enabled, non-enabled and both types of users can be specified.
    // Enabled represents the users that are currently registered in courses.
    // Used TO DISPLAY CURRENT REGISTERED LEARNERS
    getRegisteredUsers() {
      this.$store
        .dispatch("getRegisteredUsers")
        .then((data) => {
          this.registeredUsers = data;
          this.registeredUserLoading = false;
        })
        .catch((error) => {
          console.error('Error getting registered users in Dashboard.vue',error);
        });
    },

    // Returns an object. The startDate and endDate can be specified
    // Enabled represents the users that are currently registered in courses.
    // Used TO Display information in the SAHARA Analytics table
    getSaharaData() {
      this.saharaDataLoading=true;
      let dashboardObj={
        startDate:'',
        endDate:''
      };
      this.$store.dispatch("getSaharaDashboardData",dashboardObj).then((data) => {
        if(data.status=='success')
        {
          setTimeout(()=>{
            this.saharaDashboardData=data.results;
          },900)
        }
        else{
          //TODO show some kind of error with the message
          this.saharaDashboardData='Error';
        }
          this.saharaDataLoading=false;
        })
        .catch((error) => {
          console.error('Error getting registered users in Dashboard.vue',error);
          this.saharaDataLoading=false;
        });
    },


    // Returns information about the users currently logged in. Specify the hostIP.
    // USED TO DISPLAY THE NUMBER OF USERS CURRENTLY ONLINE.
    // NOT BEING USED
    getCurrentLogins() {
      this.$store.dispatch("getCurrentLogins").then((data) => {
        this.currentlyOnline = data;
      });
    },

    // Returns information about the user and the course that they are currently participating in.
    // Needs to be parsed.
    // USED TO DISPLAY WHAT USERS ARE CURRENTLY WORKING ON & ACTIVE LEARNERS
    // NOT USED
    getContentLogins() {
      this.$store.dispatch("getContentLogins").then((data) => {
        this.contentLogins = data;
      });
    },

    // getCourseList() {
    //   this.$store.dispatch("getCourseList").then(() => {
    //     this.loading = true;
    //     const now = new Date(Date.now());
    //     this.selectedYear = now.getFullYear();
    //     const key = Object.keys(this.$store.state.courseNameAndID);
    //     this.selectedCourse = {
    //       courseName: key[0],
    //       courseID: this.$store.state.courseNameAndID[key[0]],
    //     };
    //     // this.render();
    //   });
    // },
  },

  mounted() {
    this.loading = true;
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/Dashboard.scss";
</style>
