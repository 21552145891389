<template>
  <div class="modal-backdrop">
    <div class="modal modal--groups-modal">
      <header class="modal-header">
        <h2>Industries</h2>
      </header>
      <div class="search-area">
      <input
        class="modal-search-bar"
        type="text"
        placeholder="Search for Industry"
        v-model="searchForIndustry"
        style="margin-left:0.4em; margin-bottom: 0;"

      />
      <button type="button" class="btn-reset" @click="resetBtn">
          Reset 
        </button>
      </div>
      <body class="modal-body">
        <div class="spinner" v-if="loading">
          <Spinner />
        </div>

        <form class="industry-selection" v-if="!loading">

            <span
            class="industry-content"
            v-for="(industry, index) in filteredIndustries"
            :key="index"
          >      

            <input
              class="checkbox"
              type="checkbox"
              v-model="industry.selected"
            />          
              <label class="content-name" @click="industry.selected=!industry.selected">{{industry.industryName}}</label>

          </span>
        </form>
      </body>

      <footer class="modal-footer">

        <button
          type="button"
          class="btn-close"
          @click="
            close();
            reset();
            clear();
          ">
          Cancel
        </button>
        <button type="button" class="btn-apply" @click="apply">
          Apply
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pill from "../AppliedFilterPill.vue";
import Spinner from "../Spinner.vue";

export default {
  name: "IndustryModal",
  components: {
    Pill,
    Spinner,
  },
   props: {
    user: Object,
    industries: Array,
    clearIndustry: {
      default: true,
      type: Boolean
    }
  },
  mounted(){
    this.loading=true;
    if(this.user && this.user.industries && this.user.industries.length>0)
    {
      var userIndustries=this.user.industries
      this.industries.forEach((industry)=>{
        if( userIndustries.find((userIndustry)=> userIndustry.industryID==industry.industryID))
        {
          industry.selected=true;
          industry.wasSelected=true;
        }
        else{
          industry.selected=false;
          industry.wasSelected=false;
        }
      })
    }
    else if(this.clearIndustry || this.industries[0]['selected'] == undefined){
      this.industries.forEach((industry)=>{
        industry.selected=false;
        industry.wasSelected=false;
      })
    }
    this.currentIndustries = JSON.parse(JSON.stringify(this.industries));
    this.loading=false;
  },
  data() {
    return {
      groupsSelected: [],
      searchForIndustry: "",
      loading: false,
      currentIndustries:[],
    };
  },
  computed: {
    ...mapState({
    }),

    filteredIndustries: function () {
      return this.currentIndustries.filter((industry) => {
        return industry.industryName.toLowerCase().match(this.searchForIndustry.toLowerCase());
      });
    },
  },
  methods: {

    resetBtn(){
      this.loading=true;
      this.currentIndustries.forEach((industry)=>{
        industry.selected=false;
      })
      this.loading=false;
    },
    close() {
      this.$emit("close");
      this.$emit("resetTable", [])
    },
    clear() {
      this.searchForIndustry = "";
    },
    apply() {
      let addingList=[];
      let removingList=[];
      this.currentIndustries.forEach((industry)=>{
        if(industry.selected==true && industry.wasSelected==false)
        {
          addingList.push(industry);
        }
        else if(industry.selected==false && industry.wasSelected==true)
        {
          removingList.push(industry);
        }
        else if(industry.selected==true)
        {
          addingList.push(industry);
        }
      })
          let industryObj={
          adding: addingList,
          removing: removingList
          }
          if(this.user)
          {
          industryObj.user=this.user.loginID;
          }
      this.$emit("enroll", industryObj);
      // this.industries.forEach((industry)=>{
      //   industry.selected=false;
      //   industry.wasSelected=false;
      // })
    },
    courseClicked(course)
    {
      if(this.coursesSelected.find((courseSel)=>courseSel.courseID==course.courseID))
      {
        this.coursesSelected.splice(this.coursesSelected.indexOf(course),1)
      }
      else{
        this.coursesSelected.push(course);
      }
    },
    reset() {
      this.searchForIndustry = "";
    },

  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/Industries.scss";
</style>
