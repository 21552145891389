import Vue from "vue";
import VueCookies from "vue-cookies";
import Vuex from "vuex";
import * as Parser from "../util/ObjectParse";
import router from "../util/Routes.js";
import {
  APICalls,
  testAuthorizationCode,
  coursesAPI,
  trustedAPI,
  callLogInAPI,
  POSTRequestTextResponse,
  // POSTFileResponse,
  POSTRequestJsonResponse,
  POSTFileResponse,
  gemsCallLogin,
  gemsSelectedServer,
  gemsCallLogout,
  showCertificate,
} from "../util/apiCalls";
// import { sortCourseNameAlphabetical, sortGroupNameAlphabetical} from "./helper";
import { sortNameAlphabetical } from "./helper";
import { sortObjByKey } from "./helper";
import createPersistedState from 'vuex-persistedstate';
// import * as Cookies from 'js-cookie';
// const testAuthorizationCode =
//   "7676BB3898DD1526FD96BB7912EC4277397B435E3ECD090B870FBE9E956F0227";

const saharaAuthorization="uTrtxbb3dXp6AF6hxMGjR7urn5ZN7FNaq2J9q5KXQWqPHBruF6XCjKksasbjytVcFYNCqzx9t7eHerM3xcCyz9dz63n36G28tSUQ";





Vue.use(Vuex);
Vue.use(VueCookies);
const store = new Vuex.Store({
  state: {
    orgName: "",
    courses: [],
    courseNameAndID: [],
    darkmode: false,
    groups: [],
    groupTree:{},
    environments:[],
    industries:[],
    industryTree:[],
    loginErrorShown:false,
    loggedIn: false,
    report: [],
    users: [],
    userID: "",
    // below for ms-form 
    firstName: "",
    lastName: "",
    email: "",
    userType: "Learner",
    hasFifthStep: false,
    authToken:"",
    generatingReport:false,
    adminLevel:{},
    saharaIndustries:{},
    menuVisible:true,
    currentLanguage:'',
    singleGroupUser:false,
    adminEmail:'',
    languageList:[
    {
    code: 'en|en',
    title: 'English',
  },
  {
    code: 'en|fr',
    title: 'French',
  },
   {
    code: 'en|af',
    title: 'Afrikaans',
  },
  {
    code: 'en|sq',
    title: 'Albanian',
  },
  {
    code: 'en|ar',
    title: 'Arabic',
  },
  {
    code: 'en|hy',
    title: 'Armenian',
  },
  {
    code: 'en|az',
    title: 'Azerbaijani',
  },
  {
    code: 'en|eu',
    title: 'Basque',
  },
  {
    code: 'en|be',
    title: 'Belarusian',
  },
  {
    code: 'en|bg',
    title: 'Bulgarian',
  },
  {
    code: 'en|ca',
    title: 'Catalan',
  },
  {
    code: 'en|zh-CN',
    title: 'Chinese (Simplified)',
  },
  {
    code: 'en|zh-TW',
    title: 'Chinese (Traditional)',
  },
  {
    code: 'en|hr',
    title: 'Croatian',
  },
  {
    code: 'en|cs',
    title: 'Czech',
  },

  {
    code: 'en|da',
    title: 'Danish',
  },
  {
    code: 'en|nl',
    title: 'Dutch',
  },

  {
    code: 'en|et',
    title: 'Estonian',
  },
  {
    code: 'en|tl',
    title: 'Filipino',
  },
  {
    code: 'en|fi',
    title: 'Finnish',
  },


  {
    code: 'en|de',
    title: 'German',
  },
  {
    code: 'en|el',
    title: 'Greek',
  },
  {
    code: 'en|hu',
    title: 'Hungarian',
  },
  {
    code: 'en|id',
    title: 'Indonesian',
  },
  {
    code: 'en|ga',
    title: 'Irish',
  },
  {
    code: 'en|it',
    title: 'Italian',
  },
  {
    code: 'en|ja',
    title: 'Japanese',
  },
  {
    code: 'en|ko',
    title: 'Korean',
  },
  {
    code: 'en|lt',
    title: 'Lithuanian',
  },
  {
    code: 'en|ms',
    title: 'Malay',
  },
  {
    code: 'en|no',
    title: 'Norwegian',
  },
  {
    code: 'en|pl',
    title: 'Polish',
  },
  {
    code: 'en|pt',
    title: 'Portuguese',
  },
  // {
  //   code: 'en|ro',
  //   title: 'Romanian',
  // },
  {
    code: 'en|ru',
    title: 'Russian',
  },
  {
    code: 'en|es',
    title: 'Spanish',
  },
  {
    code: 'en|sv',
    title: 'Swedish',
  },
  {
    code: 'en|th',
    title: 'Thai',
  },
  {
    code: 'en|tr',
    title: 'Turkish',
  },
  {
    code: 'en|uk',
    title: 'Ukrainian',
  },
],
  }, 
  //////////////////////////////!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!   LOOK AT IF WE WANT THIS IS PLACE FOR THE FULL APP 
  plugins:[
    // createPersistedState({
    //   getState:(key)=>Cookies.getJSON(key),
    //   setState:(key)=> Cookies.set(key,this.Store.state,{expires:3,secure:true})
    // })
    createPersistedState(),
  ],
  mutations: {
    //?This function will reset the state object so users can not access information
    resetState(state) {
      state.courses = [];
      state.report = [];
      state.groups = [];
      state.orgName="";
      state.courseNameAndID=[];
      state.environments=[]
      state.userID="";
      state.firstName="";
      state.lastName="";
      state.email="";
      state.userType="Learner";
      state.hasFifthStep=false;
      state.authToken="";
      state.generatingReport=false;
      state.adminLevel={};
      state.groupTree={};
      state.saharaIndustries={};
      state.adminEmail='';
      (state.users = []), (state.loggedIn = false);
    },
    setCourses(state, course) {
      state.courses = course;
    },
    setReportResults(state, report) {
      state.report = report;
    },
    setGroups(state, groups) {
      state.groups = groups;
    },
    setGroupTree(state, groupT) {
      if (groupT[0].Name !== "undefined") {
        state.groupTree = groupT;
      } else {
        state.groupTree = groupT[1];
      }
    },
    setEnvironments(state, environmentsPassed)
    {
      state.environments=environmentsPassed;
    },
    setIndustries(state, industriesPassed)
    {
      state.industries=industriesPassed;
    },
    setIndustryTree(state, industryTreePassed)
    {
      console.log('Setting the industry tree',industryTreePassed)
      state.industryTree=industryTreePassed;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setAppLoading(state, isLoading) {
      state.AppLoading = isLoading;
    },
    getLoggedIn(state) {
      return state.loggedIn;
    },
    setLoggedIn(state, loggedIN) {
      state.loggedIn = loggedIN;
    },
    setCourseNameAndID(state, courseINFO) {
      state.courseNameAndID = courseINFO;
    },
    setUserID(state, userID) {
      state.userID = userID;
    },
    setAdminEmail(state,email)
    {
      state.adminEmail=email;
    },
    setRecentLogins(state, logins) {
      state.logins = logins;
    },
    setRegisteredUsers(state, registeredUsers) {
      state.registeredUsers = registeredUsers;
    },
    setOrgName(state, payload) {
      state.orgName = payload.orgName;
    },
    setAuthToken(state,payload)
    {
      state.authToken=payload;
    },
    setFirstName(state, payload) {
      state.firstName = payload.firstName;
    },
    setLastName(state, payload) {
      state.lastName = payload.lastName;
    },
    setEmail(state, payload) {
      state.email = payload.email;
    },

    setUserType(state, payload) {
      state.userType = payload;
    },

    setHasFifthStep(state, payload) {
      state.hasFifthStep = payload;
    },
    setErrorShown(state,shown)
    {
      state.loginErrorShown=shown;
    },
    setAdminLevel(state,payload)
    {
      state.adminLevel=payload;
      // state.adminLevel.custodian=(payload.adminLevelInt==0 || payload.isCustodian==true);//This is used to hide or show things
      state.adminLevel.custodian = payload.isCustodian==true;//This is used to hide or show things
      // state.adminLevel.custodian = false//payload.isCustodian==true;//This is used to hide or show things
      //If they are a root level admin we need to see if we want to show them custodian things as well as what they usually thing
      //! START HERE TO FIGURE OUT HOW WE WILL HIDE OR SHOW THINGS IF THEY ARE A CUSTODIAN OR NOT
      //! MIGHT NEED TO SEE IF WE WANT ROOT LEVEL AND CUSTODIAN OR JUST LET THEM ALL IN 
    },

    setSaharaIndustries(state,payload)
    {
      state.saharaIndustries=payload;
    },
    setSingleGroupUser(state,payload)
    {
      state.singleGroupUser=payload;
    },
    setMenuState(state,payload)
    {
      state.menuVisible=payload;
    },
  },
  actions: {
    //?This function fill start the application and get some of the basic information such as the courses and groups that are available
    //?Once we figure out what more we want to add we can change what gets loaded in from the start and have different functionality
    // This function is called from pages/Login.vue
    async initializeValues({ commit, dispatch }) {
      commit("setAppLoading", true);
      // await dispatch("")
      // await dispatch("getAdminLevel",this.state.userID); 
      // await dispatch("getGroupUsers");
      await dispatch("getGroupList"); //find the list of the groups that the admin can see
      // await dispatch("getUserList"); //find the list of the groups that the admin can see
      // await dispatch("getCourseList");
      // await dispatch("getHistoryLogins");
      // commit("setAppLoading", false);
      // commit("setLoaded", true);
    },

    //?This function is called by many others and will return the user to the login page and reset all of the current values
    // How is this different from callLogoutAPI
    async loggedOut({ commit, dispatch }) {

      if(this.state.loginErrorShown==false)
      {
        dispatch('callLogOutAPI');
        alert("You are logged out.\nPlease log in again")//this will pop up the box and have it go back to the main page 
        router.push({name:'Login'})//push back to the login screen 
        commit('resetState');
        commit('setErrorShown',true);
      }

      commit;
      console.warn("You are logged out please log in again to get data");
    },

    async toggleMenu({commit}){
      commit('setMenuState',!this.state.menuVisible);
    },

    async callLogInAPI({ commit }, loginInfo) {
      return new Promise((resolve) => {
        callLogInAPI(APICalls.HTTP_POST_LOGIN.toString(), loginInfo).then(
          (res) => {
            if (res == "Sucess") {
              resolve(true);
              commit('setErrorShown',false);
            } else if (res == "Already Logged In") {
              resolve(false);
            } else {
              resolve(res);
            }
            commit;
            // commit("doNothing");
          }
        );
      });
    },

    async gemsLogin({ commit }, loginInfo) {
      return new Promise((resolve) => {
        gemsCallLogin(loginInfo).then((res) => {
          commit('setAuthToken',res.auth);
          if (res.status == "Sucess") {
            resolve(true);
          } else if (res.status == "Already Logged In") {
            resolve(false);
          } else {
            if(res.status)
            {
              resolve(res.status);
            }
            else{
              resolve(res);
            }
          }
          // commit;
          // commit("doNothing");
        });
      });
    },

    async gemsSelectedServerLogin({ commit }, serverInfo) {
      return new Promise((resolve) => {
        gemsSelectedServer(serverInfo).then((res) => {
          if (res == "Sucess") {
            resolve(true);
          } else if (res == "Already Logged In") {
            resolve(false);
          } else {
            resolve(res);
          }
          commit;
          // commit("doNothing");
        });
      });
    },

    async callLogOutAPI({ commit }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.HTTP_POST_LOGOFF.toString());
      urlencoded.append("sw_session_id", ""); //This seems to work by taking no information

      await POSTRequestJsonResponse(urlencoded).catch(() => {
        router.push({ name: "Login" }); //push back to the login screen
        commit("resetState");
      });
      await gemsCallLogout(this.state.authToken)
      // .then((returned)=>{
      // })
      router.push({ name: "Login" }); //push back to the login screen
      commit("resetState");
    },

    async callForceLoginAPI({ commit }, loginInfo) {
      const returnedLogin= await callLogInAPI(APICalls.HTTP_POST_FORCE_LOGIN.toString(), loginInfo).then((ret)=>{
        return ret;
      })
      commit('setErrorShown',false);
      // commit("doNothing");
      commit;
      return returnedLogin;
    },

    async setLoginID({ commit, dispatch },loginID) {
      await dispatch('getAdminLevel',loginID);
      commit('setUserID',loginID);
      return;
    },

    // Gets a list of all available courses from the sis-teacher-dashboard API
    async callCourseAPI({ commit }) {
      return new Promise((resolve) => {
       coursesAPI().then((tresult) => {
        commit("setCourses", tresult.results);
        resolve(tresult.results);
      });
    });
    },

    async callAdminGroupInfoAPI({ dispatch }, data) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_GROUP_PROPERTIES.toString()
      );
      urlencoded.append("groupID", data.groupID);
      urlencoded.append('fromLMS','true');

      const awaitGroupInfo = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const parsedResult = Parser.parseGroup(response);
          return parsedResult;
        })
        .catch(() => {
          dispatch("loggedOut");
        });
      return awaitGroupInfo;
    },

    async callGetHostIPProperties({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_HOSTIP_PROPERTIES.toString()
      );
      urlencoded.append("hostIP", "10.12.0.4");
      //urlencoded.append("hostIP","")

      await POSTRequestTextResponse(urlencoded)
        .then((IPstats) => {
          var IPProp = Parser.parseHostIPProperties(IPstats);
          IPProp;
        })
        .catch(() => {
          dispatch("loggedOut");
        });
    },

    async getGroupUsers({ commit, dispatch }) {
      //Because we are acting as an administrator this will return all students and will not take a group ID
      //? It will be called on log in so in some cases it will not be filled after reloading
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_GROUP_USERS.toString());
      // This groupID number is an ID with not alot of data to return
      // You can change it to find other responses
      urlencoded.append("groupID", "0");
      urlencoded.append("fromLMS","true");

      await POSTRequestTextResponse(urlencoded)
        .then((groupUsers) => {
          var groupedUsers = Parser.parseGroupedUsers(groupUsers);
          commit("setUsers", groupedUsers);
        })
        .catch((err) => {
          console.error('Error getting grouped users',err);
          dispatch("loggedOut");
        });
    },

    async getGroupSubgroups({ dispatch },groupID) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_GROUP_TREE.toString());
      urlencoded.append("by", "groupID");
      urlencoded.append("groupID", groupID);
      urlencoded.append("fromLMS", "true");
      const awaitGroupSubgroups = await POSTRequestTextResponse(urlencoded)
        .then((tree) => {
          let subgroupTree = Parser.parseGroupTree(tree);
          return subgroupTree;
        })
        .catch(() => {
          dispatch("loggedOut");
        });
        return awaitGroupSubgroups;
    },

    async generateReport({ commit, dispatch }, reportObj) {
      if(this.state.generatingReport)
      {
        var checkReportInterval= setInterval(()=>{
          if(!this.state.generatingReport)
          {
            clearInterval(checkReportInterval);
          }
        },3000);
      }
      return new Promise((resolve) => {
        this.state.generatingReport=true;
        var includeContentType = "*";
        //Some of the report types have different requirements and this will set the content type they should inlcude
        switch (reportObj.reportType) {
          case 0:
          case 1:
          case 2:
          case 5:
          case 6:
            includeContentType = "1";
            break;
          case 7:
            includeContentType = "3";
            break;
          default:
            includeContentType = "*";
            break;
        }
        const urlencoded = new URLSearchParams(); //? for testing can go between 2018 and 2019
        urlencoded.append(
          "request",
          APICalls.ADMIN_GENERATE_REPORT2.toString()
        );
        urlencoded.append("groupID", reportObj.groupID.toString()); //!must have a group ID will eventually need to replace with whichever group number the admin has access to
        urlencoded.append("reportType", reportObj.reportType.toString()); 
        /* 
        REPORT TYPES

          General: 0   
          ByUser: 1    
          ByCourse: 2   
          Tests: 3    
          CoursePostTest: 5   
          CoursePreVSPost: 6  
          TPCExceptions: 7    
          StellarStatus: 8   
          CourseUsage: 9   
          Certification1: 10   
          CertificationStatus: 11  
          HistoricalCourseMastery: 12  
          LUsUsed: 13  
          UserCertifications: 14

        */
        
        urlencoded.append("reportName", "reportType"+reportObj.reportType.toString());
        urlencoded.append("includeContentType", includeContentType); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4    Certification:5
        urlencoded.append("includeContent", reportObj.includeContent);
        urlencoded.append("includeGroups", reportObj.includeGroups);
        urlencoded.append("children", "false");
        urlencoded.append("includeUsers", reportObj.includeUsers);
        urlencoded.append("fromDate", reportObj.startDate);
        urlencoded.append("toDate", reportObj.endDate);
        urlencoded.append("emailAddress", ""); //? emailAddress does not work for the SIS and Tester Server groups but could work for others.
        // urlencoded.append("fromLMS", true); 
        //some of the cases will have additional parameters and this is where they will be added if needed
        switch (reportObj.reportType) {
          case 5:
            urlencoded.append("postTestSelector", "highest"); //[mostrecent|highest]
            urlencoded.append("inlcudeUnseated", "true"); //[true|false]
            break;
          case 8:
            urlencoded.append("postReport", "true"); //[true|false]
            break;
          case 13:
            urlencoded.append("includeNonGroupedAccounts", "TRUE"); //[true|false]
            break;
          case 14:
            urlencoded.append("certifications", "*");
            break;
        }
        urlencoded.append('fromLMS','true');
        // POSTFileResponse(urlencoded)
        POSTFileResponse;
        POSTRequestJsonResponse(urlencoded)
          .then((reportResults) => {
            // var report = Parser.parseReports(
            //   reportResults,
            //   reportObj.reportType
            // );
            this.state.generatingReport=false;
            if(reportResults.status!='failed')
            {
              commit("setReportResults", reportResults.results);
              resolve(reportResults.results);
            }
            resolve("error");

            // commit;
            // resolve;
          })
          .catch((error) => {
            console.error('Store Error in generateReport',error);
            dispatch("loggedOut");
          });
      });
    },

    async getMasteryStatusReport({ dispatch }, payload) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GENERATE_STATUS_REPORT.toString())

      urlencoded.append("contentIDs", payload.courses || "");
      urlencoded.append("groupIDs", payload.groups || "");
      // urlencoded.append("courseName", "");
      urlencoded.append("learnerName", payload.search || "");
      urlencoded.append("startTime", payload.dates.startTime || "");
      urlencoded.append("endTime", payload.dates.endTime || ""); //yyyy-mm-dd

      urlencoded.append("offset", payload.offset || 0);
      urlencoded.append("fetch", payload.fetch);
      urlencoded.append("returnStatus", payload.returnStatus || 0); // 0; EVERYONE 1; MASTERED 2; STARTED
      urlencoded.append("fromLMS", "true");


      const awaitMastery = await POSTRequestJsonResponse(urlencoded)
        .then(results => {
          return results;
        })
        .catch(() => {
          dispatch("loggedOut")
        })
      return awaitMastery;
    },

    async getContentUsers({ dispatch },usersObj) {
      //?this function does not seem to work well for getting the users as it does not return anything when a content name is passed in
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_CONTENT_USERS.toString());
      urlencoded.append("groupID", ""); // groupID is a random one that has lease amount of data for
      urlencoded.append("contentType", ""); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4    Certification:5
      urlencoded.append("contentName", "");
      urlencoded.append("includeGroupIDs", usersObj.groupIDs);
      urlencoded.append("children", "true");
      urlencoded.append("fromLMS","true");
     const contentUsers= await POSTRequestTextResponse(urlencoded)
        .then((results) => {
          var contentUsers = Parser.parseContentUsers(results);
         return contentUsers;
        })
        .catch(() => {
          dispatch("loggedOut");
        });
        return contentUsers;
    },

    async getCourseList({ commit, dispatch }, payload) {
      return new Promise((resolve) => {
      //this function will take in two arguments one for the group that the content is being searched for and the other that is the kind of content
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_CONTENT_LIST.toString());
      urlencoded.append("groupID", payload || "");
      urlencoded.append("contentType", "1"); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4    Certification:5
      urlencoded.append('fromLMS','true');
        POSTRequestTextResponse(urlencoded)
        .then((results) => {
          var courseList = Parser.parseContentList(results);
          // courseList = sortObjByKey(courseList);
          courseList = sortNameAlphabetical(courseList);
          // courseList.sort(sortNameAlphabetical); // This is a custom built sorting function from helper.js
          // commit("setCourseNameAndID", Object.values(courseList));
          resolve(courseList);
          commit("setCourseNameAndID", courseList);
          return Object.values(courseList);
        })
        .catch(() => {
          dispatch("loggedOut");
        });
      });
    },

    async groupTree({ commit,dispatch },updateList=false) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_GROUP_TREE.toString());
      urlencoded.append("by", "groupID");
      urlencoded.append("groupID", "0");
      // urlencoded.append("fromLMS", "true");
      /*urlencoded.append("contentType","1")
            urlencoded.append("contentName","Asbestos Awareness")*/
      const awaitGroupTree = await POSTRequestTextResponse(urlencoded)
        .then((tree) => {
          let groupTree=Parser.parseGroupTree(tree);
          (groupTree.length==1 && groupTree[0].children && groupTree[0].children.length==0)?commit("setSingleGroupUser",true):commit("setSingleGroupUser",false);
          
          if(updateList)
          {
            commit("setGroups", Parser.parseGroupList(tree))
            return groupTree;
          }
          else{
            commit("setGroupTree",groupTree);
            return groupTree;
          }
        })
        .catch((error) => {
          console.error('Store Error getting Group Tree',error);
          dispatch("loggedOut");
        });
      return awaitGroupTree;
    },

    async getRegisteredUsers({ commit, dispatch }) {
      /* As of 2021-09-23 this is on element of the returned array
        DateRegistered:1631200423
        Email:"deleteagaintobesure@example.com"
        Enabled:1
        FirstName:"Delete"
        HireDate:NaN
        LastLogin:NaN
        LastName:"AgainToBeSure"
        LoginID:"deleteagaintobesure"
        UserID:"deleteagaintobesure"
      */
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_REGISTERED_USERS.toString()
      );
      var filter = "01"; //? Filter values seem to be 01 for enabled users 10 non enabled users 00 for nothing and 11 for both enabled and non enabled users
      urlencoded.append("groupID", "");
      urlencoded.append("filter", filter);
      urlencoded.append("fromLMS","true");

      const awaitRegisteredUsers = await POSTRequestTextResponse(urlencoded)
        .then((users) => {
          const registeredUsers = Parser.parseRegisteredUsers(users);
          commit("setRegisteredUsers", registeredUsers);
          return registeredUsers;
        })
        .catch((error) => {
          dispatch("loggedOut");
          console.error('Store Error in getRegisteredUsers',error);
        });
      return awaitRegisteredUsers;
    },

    async getRegisteredCertifications({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_REGISTERED_CERTIFICATIONS.toString()
      );
      urlencoded.append("login", "");

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          response;
        })
        .catch((err) => {
          console.error('Store Error in getRegisteredCertifications',err);
          dispatch("loggedOut");
        });
    },

    async getAssignmentGroupTree({ dispatch }, data) {
      return new Promise((resolve) => {
        const urlencoded = new URLSearchParams();
        urlencoded.append(
          "request",
          APICalls.ADMIN_GET_ASSIGNMENT_GROUP_TREE.toString()
        );
        urlencoded.append("groupID", data.groupID);
        /**
         * gives two different results based on the group. If it is a content group like 2,3,4 it will get the groups in it but if it is a normal group it will get the content groups
         * groupID 2: long wait for request, might crash, executes catch error
         * groupID 3: long wait for request, might crash, executes catch error
         * groupID 4: long wait for request, might crash, executes catch error
         * will not test other groupID, JL
         */
        POSTRequestTextResponse(urlencoded)
          .then((assignmentGroupTree) => {
            var returnTree = Parser.parseAssignmentGroupTree(
              assignmentGroupTree
            );
            resolve(returnTree);
          })
          .catch((err) => {
            console.error("Store Error with the log in", err);
            dispatch("loggedOut");
          });
      });
    },

    async getCertifications({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_CERTIFICATIONS.toString()
      );
      urlencoded.append("certificationType", "-1"); //? -1 = both, 0 = altalink, 1 = iconic

      await POSTRequestTextResponse(urlencoded)
        .then((certifications) => {
          let certificationsArray = Parser.parseCertifications(certifications);
          return certificationsArray;
        })
        .catch((err) => {
          console.error("Store Error in getCertifications", err);
          dispatch("loggedOut");
        });
    },

    async getUserList({ dispatch }, payload) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_USER_LIST.toString());
      if(payload)
      {
        urlencoded.append("groupID", payload.groupID || "0"); // Devs: 0
        urlencoded.append("fromLMS","true");
        return await POSTRequestJsonResponse(urlencoded)
        .then((response) => {
          // let userList = Parser.parseUserList(response);
          // commit("setUsers", userList);
          return response.results;
        })
        .catch((err) => {
          console.error('Store Error getting user list',err);
          dispatch("loggedOut");
        });
      }
      else{
        urlencoded.append("groupID", ""); // Devs: 0
        return await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          let userList = Parser.parseUserList(response);
          // commit("setUsers", userList);
          return userList;
        })
        .catch((err) => {
          console.error('Store Error getting user list',err);
          dispatch("loggedOut");
        });
      }
      
    },
    // async getUserList({ commit }, payload) {
    //   const urlencoded = new URLSearchParams();
    //   urlencoded.append("request", APICalls.ADMIN_GET_USER_LIST.toString());
    //   urlencoded.append("groupID", payload || "0"); // Devs: 0

    //   return await POSTRequestTextResponse(urlencoded)
    //     .then((response) => {
    //       let userList = Parser.parseUserList(response);
    //       commit("setUsers", userList);
    //       return userList;
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },

    // async getUserList({ dispatch }, data) {
    //   const urlencoded = new URLSearchParams();
    //   urlencoded.append("request", APICalls.ADMIN_GET_USER_LIST.toString());
    //   urlencoded.append("groupID", data.groupID); // Devs: 0

    //   const awaitGroupID = await POSTRequestTextResponse(urlencoded)
    //     .then((response) => {
    //       // commit("setUsers", userList);
    //       return userList;
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       dispatch("loggedOut");
    //     });
    //     return awaitGroupID;
    // },

    async getCourseScores({ dispatch }, courseID) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_COURSE_SCORES.toString());
      urlencoded.append("contentType", "1"); // Course:1    Exam:2
      urlencoded.append("contentID", courseID); // 494, 474, 493, 445 and 369
      urlencoded.append("fromLMS", "true"); // Must be appended as true in lowercase

      const courseScores = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const courseScores = Parser.parseCourseScore(response);
          return courseScores;
          // commit("setCourses", courseScores);
        })
        .catch((err) => {
          console.error('Store Error Getting Course Scores',err);
          dispatch("loggedOut");
        });
      return courseScores;
    },

    async getRegisteredContent({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_REGISTERED_CONTENT.toString()
      );
      urlencoded.append("groupID", "1");
      urlencoded.append("contentType", "1"); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4    Certification:5
      urlencoded.append("filter", "xxxxx"); // must be length of 5 bytes
      urlencoded.append("fromLMS","true");

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          console.error("Store Getting registered content", response);
        })
        .catch(() => {
          dispatch("loggedOut");
        });
    },

    async getContentProperties({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_CONTENT_PROPERTIES.toString()
      );
      urlencoded.append("contentType", "1"); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4    Certification:5
      urlencoded.append("contentID", "384"); // ie: 494, 474, 493, 445 and 369

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const contentProperties = Parser.parseContentProperties(response);
          return contentProperties;
        })
        .catch(() => {
          dispatch("loggedOut");
        });
    },

    async getUserGroups({ dispatch }, loginID) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_USER_GROUPS.toString());
      urlencoded.append("groupID", "");
      urlencoded.append("loginID", loginID);
      // urlencoded.append("fromLMS","true");

      return await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const userGroups = Parser.parseUserGroups(response);
          return userGroups;
        })
        .catch(() => {
          dispatch("loggedOut");
        });
    },

    async getUserProperties({ dispatch }, userID) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_USER_PROPERTIES.toString()
      );
      // can add specific userID for testing
      urlencoded.append("UserID", userID);
      urlencoded.append("fromLMS","true");

      const userProperties = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          let userProperties = Parser.parseUserProperties(response);
          return userProperties;
        })
        .catch(() => {
          dispatch("loggedOut");
        });
      return userProperties;
    },

    async getSeatDetails({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_SEAT_DETAILS.toString());
      urlencoded.append("contentType", "1");
      urlencoded.append("contentID", "412");
      urlencoded.append("loginID", "angela.odland");
      urlencoded.append("fromLMS","true");

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const seatDetails = Parser.parseSeatDetails(response);
          return seatDetails;
        })
        .catch(() => {
          dispatch("loggedOut");
        });
    },

    async getHistoryLogins({ commit, dispatch }, periodID) {
      //loginid,loginid,fullname,logintype,started,duration,peer,hostname
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_HISTORY_LOGINS.toString()
      );
      urlencoded.append("hostIP", "10.12.0.4");
      urlencoded.append("includeChildGroups", "true");
      urlencoded.append("period", periodID); // 0: 1 hour, 1: 4 hours, 2: 12 hours, 3: 24 hours, 4: 48 hours, 5: 7 days, 6: 30 days --- server has switch-case with an empty default case that supposed to retrieve all sessions
      urlencoded.append("fromLMS","true");

      const awaitHistoryLogins = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const loginsHistory = Parser.parseLoginsHistory(response);
          // const loginsHistory = response;
          commit("setRecentLogins", loginsHistory);
          commit;
          return loginsHistory;
        })
        .catch((error) => {
          console.error('Store Error getting History Logins',error);
          dispatch("loggedOut");
        });
      return awaitHistoryLogins;
    },

    async getContentForSeating({ dispatch },userInfo) {
      return new Promise((resolve)=>{
        const urlencoded = new URLSearchParams();
        urlencoded.append(
          "request",
          APICalls.ADMIN_GET_CONTENT_FOR_SEATING.toString()
        );
        urlencoded.append("groupID", userInfo.groupID);
        urlencoded.append("loginID", userInfo.loginID);
        urlencoded.append('fromLMS',"true")
  
         POSTRequestTextResponse(urlencoded)
          .then((response) => {
            const contentForSeating = Parser.parseContentForSeating(response)
            .sort((a,b)=>{
              let fa= a.courseName.toLowerCase(),
              fb = b.courseName.toLowerCase();
              if(fa<fb)
              {
                return -1;
              }
              if(fa>fb)
              {
                return 1;
              }
              return 0;
            })
            resolve(contentForSeating);
            return contentForSeating;
          })
          .catch((err) => {
            console.error('Store Error getting Content for Seating',err);
            dispatch("loggedOut");
          });
      });
    },

    async getUserLKBS({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_USER_LKBS.toString());
      urlencoded.append("userID", "angela.odland");

      const awaitUserLKBS = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const userLKBS = Parser.parseUserLKBS(response);
          return userLKBS;
        })
        .catch((err) => {
          console.error('Store Error Getting User LKBS',err);
          dispatch("loggedOut");
        });
      return awaitUserLKBS;
    },

    async getUserLKBSdata({ dispatch }, payload) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_USERLKB_DATA.toString());
      urlencoded.append("userID", payload);
      urlencoded.append("lkbID", "c342");
      urlencoded.append("fromLMS","true");
      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const userLKBSdata = Parser.parseUserLKBSdata(response);
          return userLKBSdata;
        })
        .catch((err) => {
          console.error('Store Error getting user LKBS Data',err);
          dispatch("loggedOut");
        });
    },

    async getUserLKBtests({ dispatch }, payload) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_USERLKB_TESTS.toString());
      urlencoded.append("userID", payload.userID);
      urlencoded.append("lkbID", payload.lkbID);
      // urlencoded.append("fromLMS","true");
      const awaitUserLKBTests = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const userLBKtests = Parser.parseUserLKBtests(response);
          return userLBKtests;
        })
        .catch((err) => {
          console.error('Store Error getting user LKB Tests',err);
          dispatch("loggedOut");
        });
      return awaitUserLKBTests;
    },

    async getCourseModules({ dispatch }, payload) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_COURSE_MODULES.toString()
      );
      //specify userID to retrieve time spent in the module
      urlencoded.append("userID", "");
      urlencoded.append("lkbID", payload);
      urlencoded.append("fromLMS","true");

      const awaitCourseModules = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const courseModules = Parser.parseCourseModules(response);
          return courseModules;
        })
        .catch((err) => {
          console.error('Store Error getting course modules',err);
          dispatch("loggedOut");
        });
      return awaitCourseModules;
    },

    async getClassroomsForContent({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_CLASSROOMS_FOR_CONTENT.toString()
      );
      urlencoded.append("groupID", "");
      urlencoded.append("contentType", "4"); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4 -> only valid content    Certification:5
      urlencoded.append("contentID", "384");
      urlencoded.append("visibleToChildHostIPs", "true");

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error('Store Error in getClassroomsForContent',err);
          dispatch("loggedOut");
        });
    },

    async updateUser({ dispatch }, data) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_UPDATE_USER.toString());
      urlencoded.append("userID", data.userID);
      urlencoded.append("type", data.method);
      urlencoded.append("FirstName", data.firstName);
      urlencoded.append("LastName", data.lastName);
      urlencoded.append("learnerID", "");
      urlencoded.append("loginID", data.loginID);
      urlencoded.append("email", data.email);
      urlencoded.append("Password", (Math.random() + 1).toString(36).substring(6));
      // urlencoded.append("Password", data.password);
      urlencoded.append("Enabled", data.enabled);
      // urlencoded.append("Enabled", "true");
      urlencoded.append("isAvailableFrom", "-1");
      urlencoded.append("availableFromDate", "-1");
      urlencoded.append("isAvailable", "-1");
      urlencoded.append("availableToDate", "-1");
      urlencoded.append("Comments", "");
      urlencoded.append("seatedContent", data.seatedContent || '');
      urlencoded.append("userGroups", data.group); //groupID;member;wasMember[;isPrimaryGroup;isAdministrator;isAuthor;isLearner;isVerifier]
      urlencoded.append("fromLMS","true");
      if(data.password && data.password!="")
      {
        urlencoded.append("lmsPasswordUpdate",data.password)
      }
      return await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          if(response=="")
          {
            dispatch("loggedOut");
            return {
              status: 'Error',
              errorMsg: 'You are logged out. Please log in again.'
            };
          }
          var parsedEnroll=Parser.parseAddEditUser(response);
          return parsedEnroll;
        })
        .catch(() => {
          dispatch("loggedOut");
          return {
            status: 'Error',
            errorMsg: 'You are logged out. Please log in again.'
          };
        });
    },

    async deleteUser({ dispatch }, payload) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_DELETE_USER.toString());
      urlencoded.append("UserIDList", payload);

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          dispatch("loggedOut");
          console.error('Store Error in deleteUser',err);
        });
    },

    async getHostInformation() {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_HOST_INFORMATION.toString()
      );

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error('Store error in getHostInformation',err);
        });
    },

    async getUnregisteredCourses() {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_UNREGISTERED_COURSES.toString()
      );
      // 504 Gateway time-out response from server on group 0 or 1
      urlencoded.append("groupID", "1845");

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const unregisteredCourses = Parser.parseUnregisteredCourses(response);
          return unregisteredCourses;
        })
        .catch((err) => {
          console.error('Store Error Getting Unregistered Courses',err);
        });
    },

    async getUnregisteredExams() {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_UNREGISTERED_EXAMS.toString()
      );
      urlencoded.append("groupID", "0");

      await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error('Store error getting unregistered exams',err);
        });
    },

    async getUsersForCourseComplete() {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_USERS_FOR_COURSE_COMPLETE.toString()
      );
      urlencoded.append("groupID", ""); //leave blank for full list. Otherwise 0 or 1.
      urlencoded.append("contentType", "1"); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4 -> only valid content    Certification:5
      urlencoded.append("contentID", "412"); //384, 494, 474, 493, 445 369
      urlencoded.append("visibleToChildHostIPs", "true");
      urlencoded.append("fromLMS","true");

      const awaitUsersForCourseComplete = await POSTRequestTextResponse(
        urlencoded
      )
        .then((response) => {
          const usersForCourseComplete = Parser.parseUsersForCourseComplete(
            response
          );
          return usersForCourseComplete;
        })
        .catch((err) => {
          console.error('Store Error getting users for course complete',err);
        });
      return awaitUsersForCourseComplete;
    },

    async getUsersData({ dispatch }, payload) {
      //request=1333&offset=[number]&fetch=[number]&groupID=[number]&firstName=[text]&lastName=[text]&
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_USERS_LIST.toString());
      urlencoded.append("offset", payload.offset);
      urlencoded.append("fetch", payload.fetch);
      // urlencoded.append("groupID", "");
      urlencoded.append("firstName", payload.search.firstName);
      urlencoded.append("lastName", payload.search.lastName);
      if(payload.saharaData==true)
      {
        urlencoded.append("getSaharaData",payload.saharaData);
        urlencoded.append("getOnlySaharaUsers",payload.onlySahara);
        urlencoded.append("industries",payload.inustriesSearch||"");
      }
      if(payload.selectedGroups)
      {
        urlencoded.append("groups",payload.selectedGroups);
      } 

      if(payload.sortBy)
      {
        urlencoded.append("sortBy",payload.sortBy);
      } 
      if(payload.sortAscending!=null)
      {
        urlencoded.append("sortAscending",payload.sortAscending);
      } 
      if(payload.enabled)
      {
      urlencoded.append("enabled",payload.enabled);
      }

      const userList = await POSTRequestJsonResponse(urlencoded)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error('Store Error getting users data',err);
          if(err=="Logged Out")
          {
            dispatch("loggedOut");
          }
          else{
            console.warn('We could not get this done with the err',err)
            return "Error: Could not retreive data";
          }
        });
      return userList;
    },

    async getContentLogins({ dispatch }) {
      const urlencoded = new URLSearchParams();

      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_CONTENT_LOGINS.toString()
      );
      urlencoded.append("groupID", "3"); //3, 4, 2219, 2226, 2227 and 3031
      urlencoded.append("contentType", "1"); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4 -> only valid content    Certification:5
      urlencoded.append("fromLMS","true");
      const awaitContentLogins = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const contentLogins = Parser.parseContentLogins(response);
          return contentLogins;
        })
        .catch((error) => {
          console.error('Store Error getting content logins',error);
          dispatch("loggedOut");
        });
      return awaitContentLogins;
    },

    async getCurrentLogins({ dispatch }) {
      const urlencoded = new URLSearchParams();

      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_CURRENT_LOGINS.toString()
      );
      urlencoded.append("hostIP", "10.12.0.4");
      urlencoded.append("includeChildGroups", "false");
      urlencoded.append("fromLMS", "true");

      const awaitCurrentLogins = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const currentLogins = Parser.parseCurrentLogins(response);
          return currentLogins;
        })
        .catch((error) => {
          console.error('Store Error getting current logins',error);
          dispatch("loggedOut");
        });
      return awaitCurrentLogins;
    },

    async getAccountProperties({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_ACCOUNT_PROPERTIES.toString()
      );
      urlencoded.append("loginID", "");

      await POSTRequestTextResponse(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error('Store Error while getting account properties',err);
          dispatch("loggedOut");
        });
    },

    // async getUpdateAccountProperties({dispatch}) {
    //   const urlencoded = new URLSearchParams();

    //   urlencoded.append("request", APICalls.ADMIN_UPDATE_ACCOUNT_PROPERTIES.toString());
    //   urlencoded.append("loginID", "")
    //   urlencoded.append("ccEmail", "")
    //   urlencoded.append("status", "")
    //   urlencoded.append("selection", "")
    //   urlencoded.append("updateHireDate", "")
    //   urlencoded.append("hireDate", "")
    //   urlencoded.append("newCompany", "")
    //   urlencoded.append("moveAccount", "")

    //   const awaitUpdateAccountProperties = await POSTRequestTextResponse(urlencoded)
    //     .then((response) => {
    //      response;
    //     })
    //     .catch((error) => {
    //       dispatch("loggedOut");
    //     })
    //     return awaitUpdateAccountProperties;
    // }

    async updateGroup({ dispatch }, data) {
      const urlencoded = new URLSearchParams();

      urlencoded.append("request", APICalls.ADMIN_UPDATE_GROUP.toString());
      // urlencoded.append("parentGroupID", "1");
      //When the parent group id is set to 0 it will cause and error and cause the get group tree method to stop working so we do not want to allow any newly added groups to be a child of the root group 
      urlencoded.append("parentGroupID", data.parentGroupID==0 && data.method=='add'? 1 : data.parentGroupID);
      urlencoded.append("groupID", data.groupID);
      urlencoded.append("type", data.method); // "add" / "edit"
      urlencoded.append("name", data.name); // Name of Company (form)
      urlencoded.append("allowGuests", data.allowGuests??"false"); // ????
      urlencoded.append("clientEmail", data.clientEmail??"swiftsupport@swiftlearning.com"); // Company Email (form)
      urlencoded.append("clientEmailRecursive", "true"); // Boolean (make the client email above be the email for groups under this group?)
      urlencoded.append("exhaustiveTracking", "false"); // Boolean
      urlencoded.append("exhaustiveTrackingRecursive", "true"); // Boolean
      urlencoded.append("ipAddress", ""); // ????
      urlencoded.append("randomizeTestQuestions", data.randomizeTestQuestions??"false"); // Boolean
      urlencoded.append("showDemoContent", data.showDemoContent??"false"); // Boolean
      urlencoded.append(
        "showDemoContentRecursive",
        data.showDemoContentRecursive
      ); // Boolean
      urlencoded.append("showSupplemental", data.showSupplemental??"false"); // ????
      urlencoded.append("showUnavailableContent", data.showUnavailableContent??"false"); // Boolean
      urlencoded.append("showUnavailableContentRecursive", "true"); // Boolean
      urlencoded.append("showOnlyAssigned", data.showOnlyAssigned??"false"); // Boolean
      urlencoded.append(
        "showOnlyAssignedRecursive",
        data.showOnlyAssignedRecursive
      ); // Boolean
      urlencoded.append("supportEmail", "swiftsupport@swiftlearning.com"); // ????
      urlencoded.append("supportEmailRecursive", "true"); // Boolean
      urlencoded.append("licenseKey", ""); // ????
      urlencoded.append("customField1", ""); // ????
      urlencoded.append("selectedGroupIDs", data.selectedGroupIDs??''); // ????
      urlencoded.append("unselectedGroupIDs", data.unselectedGroupIDs??''); // ????
      urlencoded.append("comment", data.comment??''); // ????
      urlencoded.append("fromLMS","true");
      const awaitUpdateGroup = await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          const orgNameID = Parser.parseNewOrganization(response);
          return orgNameID;
        })
        .catch((error) => {
          console.error('Store Error while updating group',error);
          dispatch("loggedOut");
        });
      return awaitUpdateGroup;
    },

    //////// NEW API TESTS /////////

    async testCourseList({ commit, dispatch }) {
      return new Promise((resolve) => {
        //this function will take in two arguments one for the group that the content is being searched for and the other that is the kind of content
        const urlencoded = new URLSearchParams();
        urlencoded.append(
          "request",
          APICalls.ADMIN_GET_CONTENT_LIST.toString()
        );
        urlencoded.append("groupID", "0");
        urlencoded.append("contentType", "1"); // Invalid:0    Course:1    Exam:2    Supplemental:3     Classroom:4    Certification:5
        urlencoded.append("fromLMS","true");
        POSTRequestTextResponse(urlencoded)
          .then((results) => {
            var courseList = Parser.parseContentList(results);
            courseList = sortObjByKey(courseList);
            commit("setCourseNameAndID", courseList);
            resolve(courseList);
          })
          .catch(() => {
            dispatch("loggedOut");
          });
      });
    },

    // USELESS atm
    // async getGroupTable({ dispatch }) {
    //   const urlencoded = new URLSearchParams();

    //   urlencoded.append("request", APICalls.ADMIN_GET_GROUPS_TABLE.toString());
    //   // urlencoded.append("groupID", "1");

    //   await POSTRequestTextResponse(urlencoded)
    //     .then((response) => {
    //       return response;
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       dispatch("loggedOut")
    //     })
    // }

    async getGroups({ dispatch }) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_GROUPS.toString());
      urlencoded.append("login", "");
      urlencoded.append("groupID", "1");
      urlencoded.append("filter", "");
      urlencoded.append("loginID", "");
      urlencoded.append("fromLMS","true");

      await POSTRequestTextResponse(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error('Store Error getting groups',err);
          dispatch("loggedOut");
        });
    },

    async deleteGroup({ dispatch }, groupID) {
      const urlencoded = new URLSearchParams();
      if(groupID==1||groupID==0)
      {
        return {
          groupID: 0,
          status: 'Error',
          message:'We cannot delete root groups'
        };
      }
      urlencoded.append("request", APICalls.ADMIN_DELETE_GROUP.toString());
      urlencoded.append("groupID", groupID);//This can take a list of groups as well separated by semi-colon 
      urlencoded.append("fromLMS","true");

      const deleteGroupMsg= await POSTRequestTextResponse(urlencoded)
        .then((res) => {
          var deleteMsg= Parser.parseDeleteGroup(res);
          return deleteMsg;
        })
        .catch((err) => {
          console.error('Store error while deleting group',err);
          dispatch("loggedOut");
        });
        return deleteGroupMsg;
    },


    async deleteGroupAndSubgroups({ dispatch }, groupID) {
      const urlencoded = new URLSearchParams();
      if(groupID==1||groupID==0)
      {
        return {
          groupID: 0,
          status: 'Error',
          message:'We cannot delete root groups'
        };
      }
      urlencoded.append("request", APICalls.ADMIN_DELETE_GROUP_AND_SUBGROUPS.toString());
      urlencoded.append("groupID", groupID);//This can take a list of groups as well separated by semi-colon 

      const deleteGroupMsg= await POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error('Store error while deleting group and subgroups',err);
          dispatch("loggedOut");
        });
        return deleteGroupMsg;
    },

    async getContentList({ dispatch }, data) {
      const urlencoded = new URLSearchParams();

      urlencoded.append("request", APICalls.ADMIN_GET_CONTENT_LIST.toString());
      urlencoded.append("groupID", data.groupID);
      urlencoded.append("contentType", "1");
      urlencoded.append("fromLMS","true");
      const awaitContentList = await POSTRequestTextResponse(urlencoded)
        .then((res) => {
          let content = Parser.parseContentList(res);
          return content;
        })
        .catch((err) => {
          console.error('Store Error getting content list',err);
          dispatch("loggedOut");
        });
      return awaitContentList;
    },

    // async getSeatDetails({dispatch}) {

    // },
    async getUsersForContentSeating({ dispatch }, enrollmentInfo) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_USERS_FOR_CONTENT_SEATING
      );
      urlencoded.append("groupID", enrollmentInfo.groupID);
      urlencoded.append("contentType", "1");
      urlencoded.append("contentID", enrollmentInfo.contentID);
      urlencoded.append("visableToChildHostIPs", "");
      urlencoded.append('fromLMS','true');

      const awaitUsers = await POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          // let users = Parser.parseUsersForContentSeating(res);
          return res.results;
        })
        .catch((err) => {
          console.error('Store Error getting users for content seating',err);
          dispatch("loggedOut");
        });
      return awaitUsers;
    },

    async updateSeatableContent({ dispatch }) {
      const urlencoded = new URLSearchParams();

      await POSTRequestTextResponse(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error('Store Error updating seatable content ',err);
          dispatch("loggedOut");
        });
    },

    // async getGroupProperties({dispatch}) {

    // },
    // async getCourseModules({dispatch}) {

    // },
    async adminGetModuleScores({ dispatch }) {
      const urlencoded = new URLSearchParams();

      urlencoded.append("request", APICalls.ADMIN_GET_MODULE_SCORES.toString());
      urlencoded.append("lkbID", "c412");
      urlencoded.append("fromLMS","true");
      const awaitModuleScores = POSTRequestTextResponse(urlencoded)
        .then((res) => {
          let modScores = Parser.parseModuleScores(res);
          return modScores;
        })
        .catch((error) => {
          console.error('Store error getting module scores',error);
          dispatch("loggedOut");
        });

      return awaitModuleScores;
    },

    //// API 1275 ////
    async testLoginHistory({ dispatch }) {
      const urlencoded = new URLSearchParams();

      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_USERLKB_LOGIN_HISTORY.toString()
      );
      urlencoded.append("userID", "sunnyn");
      urlencoded.append("lkbID", "c384");
      urlencoded.append("fromLMS","true");
      const awaitLoginHistory = await POSTRequestTextResponse(urlencoded)
        .then((res) => {
          let loginHx = Parser.parseTestLoginHx(res);
          return loginHx;
        })
        .catch((error) => {
          console.error('Store Error testing login history',error);
          dispatch("loggedOut");
        });

      return awaitLoginHistory;
    },

    //// API 1288 ////
    async getModuleScores({ dispatch }, payload) {
      const urlencoded = new URLSearchParams();

      urlencoded.append("request", APICalls.ADMIN_GET_MODULE_SCORES.toString());
      urlencoded.append("lkbID", payload);
      urlencoded.append("fromLMS","true");

      const awaitModuleScores = POSTRequestTextResponse(urlencoded)
        .then((res) => {
          let modScores = Parser.parseModuleScores(res);
          return modScores;
        })
        .catch((error) => {
          console.error('Store Error getting module scores',error);
          dispatch("loggedOut");
        });

      return awaitModuleScores;
    },

    async getGroupsForUser({ dispatch }, payload) {
      const urlencoded = new URLSearchParams();
      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_GROUPS_FOR_USER.toString()
      );
      urlencoded.append("LoginID", payload);

      const awaitGroups = await POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          return res.results;
        })
        .catch((err) => {
          console.error('Store Error getting groups for user',err);
          dispatch("loggedOut");
        });
      return awaitGroups;
    },

    //// test retrieve graph data ////
    async retrieveGraphData({ dispatch }) {
      /// APICalls.ADMIN_GET_USER_LIST ///
      dispatch("getUserList")
        .then((userList) => {
          const userNames = [];
          for (let user of userList) {
            dispatch("getUserLKBSdata", user.value); // user.value = user IDs for a group
            userNames.push(user.value);
          }
        })
        .catch((err) => {
          console.error('Store Error retrieveing graph data',err);
        });
    },

    async getEventLog({ dispatch }, bytePos) {
      const urlencoded = new URLSearchParams();

      urlencoded.append("request", APICalls.ADMIN_GET_EVENT_LOG.toString());
      urlencoded.append("logName", "current");
      urlencoded.append("filter", "111111");
      urlencoded.append("bytePos", bytePos);

      return await POSTRequestTextResponse(urlencoded)
        .then((response) => {
          let events = Parser.parseEventLog(response);
          return events;
        })
        .catch((err) => {
          console.error('Store Error getting event log',err);
          dispatch("loggedOut");
        });
    },

    async getEventLogList() {
      const urlencoded = new URLSearchParams();

      urlencoded.append(
        "request",
        APICalls.ADMIN_GET_EVENT_LOG_LIST.toString()
      );
      await POSTRequestTextResponse(urlencoded).then((response) => {
        return response;
      });
    },

    async getEventLogTable() {
      const urlencoded = new URLSearchParams();

      urlencoded.append("request", APICalls.ADMIN_GET_EVENT_TABLE.toString());
      await POSTRequestTextResponse(urlencoded).then((response) => {
        return response;
      });
    },

    async getGroupList({ commit }) {
      return new Promise((resolve) => {
        if(Object.keys(this.state.groups).length!=0)
        {
          resolve(this.state.groups);
        }
        else{
          const urlencoded = new URLSearchParams();
          urlencoded.append("request", APICalls.ADMIN_GET_GROUP_TREE.toString());
          urlencoded.append("by", "groupID");
          urlencoded.append("groupID", "0");
          /*urlencoded.append("contentType","1")
                urlencoded.append("contentName","Asbestos Awareness")*/
          POSTRequestTextResponse(urlencoded)
            .then((tree) => {
              const groupList = Parser.parseGroupList(tree);
                Object.keys(groupList).length==1?commit("setSingleGroupUser",true):commit("setSingleGroupUser",false);
              commit("setGroups", groupList);
              resolve(groupList);
            })
            .catch((error) => {
              console.error('Store Error getting group List',error);
            });
          }
          });
    },

    // getChildren(parentObj) {},
    //// API 1253 ////
    forceUserLogout({ dispatch }, selectedUsers) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_FORCE_USER_LOGOUT.toString());
      urlencoded.append("UserIDList", selectedUsers);

      const awaitForceLogout = POSTRequestTextResponse(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error('Store Error forcing user logout',error);
          dispatch("loggedOut");
        });

      return awaitForceLogout;
    },
    //// API 333 - SiS Teacher APIs ///

    // angela.odland
    // arickpeters
    // autumn.crosby
    // ben.wiebe
    // caleb.ypma
    enrollLearner({ dispatch }, enrollData) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ENROLLMENT_API.toString());
      urlencoded.append("authorization", testAuthorizationCode);
      urlencoded.append("loginID", enrollData.loginID);
      urlencoded.append("contentIDs", enrollData.contentID);

      const awaitEnrollment = trustedAPI(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error('Store Error enrolling learner',error);
          dispatch("loggedOut");
        });
      return awaitEnrollment;
    },
    //// API 291 - SiS Teacher APIs ///
    unenrollLearner({ dispatch }, enrollData) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.UNENROLLMENT_API.toString());
      urlencoded.append("authorization", testAuthorizationCode);
      urlencoded.append("loginID", enrollData.loginID);
      urlencoded.append("contentID", enrollData.contentID);

      const awaitUnenrollment = trustedAPI(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error('Store Error unenrolling user',error);
          dispatch("loggedOut");
        });
      return awaitUnenrollment;
    },

    getSaharaRecords({ dispatch }, saharaRecords) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_SAHARA_RECORDS.toString());
      urlencoded.append("groupIDs", saharaRecords.groupIDs);
      urlencoded.append("industry", saharaRecords.industry);
      urlencoded.append("environments", saharaRecords.environments);
      urlencoded.append("userName", saharaRecords.userName);
      urlencoded.append("startTime", saharaRecords.startTime);
      urlencoded.append("endTime", saharaRecords.endTime);
      urlencoded.append("fetch", saharaRecords.fetch);
      urlencoded.append("offset", saharaRecords.offset);

      const awaitEnrollment = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error('Store Error while awaiting enrollment',error);
          dispatch("loggedOut");
        });
      return awaitEnrollment;
    },

    getRecordHazards({ dispatch }, saharaSessionID) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_SAHARA_RECORD_HAZARDS.toString());
      urlencoded.append("saharaSessionID", saharaSessionID.toString());

      const awaitEnrollment = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          if(res.status=='success')
          {
            return res.results;
          }
          else{
            return res.message;
          }
        })
        .catch((error) => {
          console.error('Store Error Getting record hazards',error);
          dispatch("loggedOut");
        });
      return awaitEnrollment;
    },


    getRecordWellness({ dispatch }, saharaSessionID) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_SAHARA_RECORD_WELLNESS.toString());
      urlencoded.append("saharaSessionID", saharaSessionID.toString());
      urlencoded.append("includeCourses", "true");
      const awaitEnrollment = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          if(res.status=='success')
          {
            return res.results;
          }
          else{
            return res.message;
          }
        })
        .catch((error) => {
          console.error('Store Error Getting record hazards',error);
          dispatch("loggedOut");
        });
      return awaitEnrollment;
    },


    getSaharaWellnessAverages({ dispatch }, wellnessObj) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_SAHARA_MENTAL_WELLNESS.toString());
      urlencoded.append("startDate", wellnessObj.startDate);
      urlencoded.append("endDate", wellnessObj.endDate);
      if(wellnessObj.industries)
      {
        urlencoded.append("industries", wellnessObj.industries);
      }

      const mentalWellness = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error('Store error getting SAHARA Wellness Averages',error);
          // dispatch("loggedOut");
          dispatch;
        });
      return mentalWellness;
    },

    getAdminLevel({ commit,dispatch }, loginID) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_ADMIN_LEVEL.toString());
      urlencoded.append("LoginID", loginID);

      const adminLevel = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          if(res.results!={})
          {
            commit('setAdminLevel',res.results);
            return res.results;
          }
          if(res.status=='failed')
          {
            dispatch('callLogOutAPI');
            alert("You are not recongnized as an administrator.\nPlease log in again and contact is if this problem persists");//this will pop up the box and have it go back to the main page  
            // router.push({name:'Login'})//push back to the login screen 
            commit('resetState');
            return res;
          }
        })
        .catch((error) => {
          console.error('Store error getting SAHARA Wellness Averages',error);
          dispatch("loggedOut");
        });
      return adminLevel;
    },

    getSaharaEnvironments({ commit, dispatch }, environmentObj) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_SAHARA_ENVIRONMENTS.toString());
      urlencoded.append("returnOption", environmentObj.returnOption);
      urlencoded.append("groupIDs", environmentObj.groupIDs);
      if(typeof environmentObj.onlyLive != 'undefined')
      {
        urlencoded.append("onlyLive", environmentObj.onlyLive);
      }

      if(typeof environmentObj.recordsOnly != 'undefined')
      {
        urlencoded.append("recordsOnly", environmentObj.recordsOnly);
      }

      const environments = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          if(res.results.industries)
          {
              commit('setIndustries',res.results.industries);
          }
          if(res.results.environments)
          {
            commit('setEnvironments',res.results.environments);
          }
          if(res.results.industryTree)
          {
              commit('setIndustryTree',res.results.industryTree);
          }

          // switch(parseInt(environmentObj.returnOption)){
          //   case(0):
          //   {
          //     commit('setIndustries',res.results.industries);
          //     commit('setEnvironments',res.results.environments)
          //   }
          //   break;
          //   case(1):
          //   {
          //     commit('setEnvironments',res.results.environments)
          //   }
          //   break;
          //   case(2):{
          //     commit('setIndustries',res.results.industries);
          //   }
          //   break;
          //   case(3):{
          //     console.log('in the industry tree with the results',res.results)
          //     console.log('in the industry tree with the returnOption',environmentObj.returnOption)
          //     commit('setIndustryTree',res.results.industryTree);
          //   }
          //   break;
          // }
          return res;
        })
        .catch((error) => {
          console.error('Store Error getting SAHARA Environments',error);
          dispatch("loggedOut");
        });


      return environments;
    },

    async getSaharaIndustry({ commit, dispatch }) {
      return new Promise((resolve) => {
        //this function will take in two arguments one for the group that the content is being searched for and the other that is the kind of content
        const urlencoded = new URLSearchParams();
        urlencoded.append(
          "request",
          APICalls.HTTP_POST_TRUSTED_GET_SAHARA_INDUSTRY.toString()
        );
        urlencoded.append("authorization",saharaAuthorization);
        urlencoded.append("LoginID", this.state.userID); 
        urlencoded.append("getCourseInfo","true");
        POSTRequestJsonResponse(urlencoded)
          .then((result) => {
            if(result.status=="success")
            {
              commit("setSaharaIndustries", result.results);
              resolve(result.results);
            }
            else{
              resolve('Error');
            }

          })
          .catch(() => {
            dispatch("loggedOut");
          });
      });
    },

    getSaharaCourses({ dispatch }, coursesObj) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_SAHARA_COURSES.toString());
      urlencoded.append("courseName", coursesObj.courseName);
      urlencoded.append("allResults", coursesObj.allResults);
      urlencoded.append("excludeNotConnected",true);

      const courses = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error('Store Error getting SAHARA Courses',error);
          dispatch("loggedOut");
        });

      return courses;
    },

    changeSaharaUserIndustries({ dispatch }, industriesObj) {
      return new Promise((resolve) => {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_CHANGE_SAHARA_USER_INDUSTRIES.toString());
      urlencoded.append("userID", industriesObj.user);
      urlencoded.append("additionList", industriesObj.addingIndustries);
      urlencoded.append("removalList", industriesObj.removingIndustries);
      const industryResponse = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error('Store Error changinf SAHARA User Industries',error);
          dispatch("loggedOut");
        });

      return industryResponse;
      })
    },

    getSaharaDashboardData({ dispatch }, dashboardObj) {
      return new Promise((resolve) => {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_SAHARA_DASHBOARD_DATA.toString());
      urlencoded.append("startDate", dashboardObj.startDate);
      urlencoded.append("endDate", dashboardObj.endDate);
      const saharaDashboardResponse = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error('Store Error Getting SAHARA Dashboard Data',error);
          dispatch;
          // dispatch("loggedOut");
        });

      return saharaDashboardResponse;
      })
    },

    getGeneralContentData({ dispatch }, courseObj) {
      return new Promise((resolve) => {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.ADMIN_GET_GENERAL_COURSE_DATA.toString());
      urlencoded.append("courseID", courseObj.courseID);
      urlencoded.append("groupID", courseObj.groupID);
      const courseDataResponse = POSTRequestJsonResponse(urlencoded)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error('Store Error Getting SAHARA Dashboard Data',error);
          dispatch;
          // dispatch("loggedOut");
        });

      return courseDataResponse;
      })
    },

    async getCertificate({ dispatch }, certInfo) {
      return new Promise((resolve) => {
      const urlencoded = new URLSearchParams();
      urlencoded.append("request", APICalls.HTTP_POST_TRUSTED_CERTIFICATE_REQUEST.toString());
      urlencoded.append("authorization", testAuthorizationCode);
      urlencoded.append("loginID", certInfo.loginID);
      urlencoded.append("contentID",certInfo.contentID);
      urlencoded.append("update2021", "yes");
    
      const courseDataResponse = POSTRequestJsonResponse(urlencoded)
        .then(async (res) => {
          if(res && res.status!='failed')
          {
             showCertificate(res.results).then((certSuccess)=>{
              certSuccess?resolve(true):resolve('Error: Could not display the certificate please contact us if you continue having issues');
             })
          }
          else{
            if(res && res.message)
            {
              resolve(res.message);
            }
            else{
              resolve('Error: We could not find the certificate informaiton');
            }
          }
        })
        .catch((error) => {
          console.error('Store Error Getting SAHARA Dashboard Data',error);
          resolve('Store Error Getting Certificate information')
          dispatch;
          // dispatch("loggedOut");
        });
        return courseDataResponse;
      })
    },

  },
});

var observer=new MutationObserver(function(mutations){
  var changeFound= mutations.find((mutation)=>{
    return (mutation.type==="attributes" && mutation.attributeName==="lang");
  })
  if(changeFound && changeFound!=undefined)
  {
   store.state.currentLanguage=getLanguage();
  }
})
var element=document.querySelector('html');
observer.observe(element,{
  attributes:true,
  // characterDataOldValue: true,
  attributeOldValue:false,
  attributeFilter:['lang'],
})

function getLanguage()
{
    // var lang=document.querySelector('html').getAttribute('lang');
    var langu=document.querySelector('html').getAttribute('lang');
    var foundlang = store.state.languageList.find((language) =>{
      return language.code.split('|')[1].toLowerCase()===langu.toLowerCase();
    })
  if(foundlang && foundlang.title)
  {
    return foundlang.title;
  }
  else{
    return 'English';
  }
}
if(store.state.currentLanguage=='' || store.state.currentLanguage!=getLanguage())
{
  store.state.currentLanguage=getLanguage();
}

export  { store };
