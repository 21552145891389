<template>
  <div id="server">
    <Navbar>Server Events</Navbar>
    <SideMenu></SideMenu>

    <div class="header" :class="{ darkmode: this.$store.state.darkmode }">
      <!-- <router-link
        to="/dashboard"
        class="back-to-dashboard"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        <h3>
          <font-awesome-icon :icon="['fas', 'arrow-left']" /> Back to Dashboard
        </h3>
      </router-link> -->
      <h3>Server Events</h3>
    </div>
    <Tabs class="tabs">
      <Tab name="Server Log" selected="true">
        <button class="btn" @click="initializeLogs()">Get Server Logs</button>

        <div
          class="table-container"
          :class="{ darkmode: this.$store.state.darkmode }"
        >
          <table class="table-main">
            <thead class="table-header">
              <th>
                Date
                <label class="sort">
                  <input type="checkbox" class="sort-btn" @click="sort()" />
                  <div class="sort-icon">
                    <font-awesome-icon :icon="['fas', 'angle-down']" />
                  </div>
                </label>
              </th>
              <th>Host IP</th>
              <th>Source</th>
              <th>User</th>
              <th>Peer</th>
              <th>General Description</th>
              <th>Specific Description</th>
            </thead>

            <tbody
              class="table-body"
              v-for="(event, index) in events"
              :key="index"
            >
              <tr v-if="event.type !== 'eot'">
                <td>{{ event.date }}</td>
                <td>{{ event.hostIP }}</td>
                <td>{{ event.source }}</td>
                <td>{{ event.user }}</td>
                <td>{{ event.peer }}</td>
                <td>{{ event.generalDescription }}</td>
                <td>{{ event.specificDescription }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Tab>
      <Tab name="Force Logout">
        <ForceLogout />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import Tab from "../components/Tab.vue";
import Tabs from "../components/Tabs.vue";
import ForceLogout from "../components/ForceLogout";

export default {
  name: "ServerEvents",
  components: {
    Navbar,
    SideMenu,
    Tab,
    Tabs,
    ForceLogout,
  },
  data() {
    return {
      events: [],
    };
  },
  methods: {
    initializeLogs() {
      this.events = [];
      this.fetchLogs(0);
    },

    fetchLogs(bytePos) {
      this.$store.dispatch("getEventLog", bytePos).then((events) => {
        this.events = [...this.events, ...events];
        if (this.events[this.events.length - 1].type === "cont") {
          const cont = this.events.pop();
          this.fetchLogs(cont.bytePos);
        }
      });
    },

    sort() {
      this.events = this.events.reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/ServerEvents.scss";
</style>
