<template>
    <div class="third">
        <h4>Confirm User</h4>
        Make {{this.$store.state.firstName}} {{this.$store.state.lastName}} a {{this.$store.state.userType}} of _____? 
        <div class="description">
          This will enable them to: 
          ---
          ---
          ---
        </div>
    </div>
</template>
<script>
export default {
  name: 'ThirdStep',
  components: {
  },
}
</script>
<style scoped>
@import './msFormStyles/msForm.scss';
</style>