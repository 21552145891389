<template>
  <div class="modal-backdrop">
    <div class="modal modal--groups-modal">
      <header class="modal-header">
        <h1>Server Selection</h1>
      </header>

      <!-- <input
        class="modal-search-bar"
        type="text"
        placeholder="Search for Server"
        v-model="searchForServer"
      /> -->

      <body class="modal-body">
        <div class="server-options">
          <div class="modal-pill" v-for="server in servers" :key="server.server_id">
            <!-- <Pill :filterName="server.server_name" @click="loginServer(server)" />
             -->
            <div @click="loginServer(server)">
                <span>{{server.server_name}}</span>
            </div>
          </div>
        </div>

        <div class="spinner" v-if="loading">
          <Spinner />
        </div>

        <!-- <form class="modal-filter-options">
          <span
            class="groupContent"
            v-for="(group, index) in filteredServers"
            :key="index"
          >
            <input
              class="checkbox"
              type="checkbox"
              v-model="groupsSelected"
              :value="group"
              :key="index"
            />
            {{ group }}
          </span>
        </form> -->
      </body>

      <footer class="modal-footer">
        <!-- <button type="button" class="btn-apply" @click="apply">
          Apply Selection
        </button> -->
        <button
          type="button"
          class="btn-close"
          @click="
            close();
            clear();
          "
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pill from "../AppliedFilterPill.vue";
import Spinner from "../Spinner.vue";

export default {
  name: "ServerSelectModal",
  components: {
    Pill,
    Spinner,
  },
  props:{
    servers: Array,
  },
//   created() {

//     if(this.groups==[] || this.groups.length==0)
//     {
//       this.getGroupList();
//     }
//   },
//   mounted(){
//     if(this.groups==[] || this.groups.length==0)
//     {
//       this.getGroupList();
//     }
//   },
  data() {
    return {
      groupsSelected: [],
      searchForServer: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
    //   groups: (state) => state.groups,
    }),

    filteredServers: function () {
      const values = Object.values(this.groups);
      return values.filter((group) => {
        return group.toLowerCase().match(this.searchForServer.toLowerCase());
      });
    },
  },
  methods: {
    close() {
      this.$emit("close");
    //   this.$emit("resetTable", [])
    },
    clear() {
      this.searchForServer = "";
    },
    apply() {
      let groupsSelectedObject={};
      for(var i in this.groupsSelected)
      {
        groupsSelectedObject[this.getKeyByValue(this.groups,this.groupsSelected[i])]=this.groupsSelected[i];
      }
      this.$emit("apply", groupsSelectedObject);
    },
    loginServer(server)
    {
        this.$emit('serverSelected',server);
    },
    checkState() {
      // console.log("Checking the State Courses in the Groups vue", this.groups);
    },

    getKeyByValue(results,groupName)
    { 
      return Object.keys(results).find(
              (groupID) => results[groupID] === groupName
            );
    },

    async getGroupList() {
      this.loading = true;
      await this.$store.dispatch("getGroupList").then((res) => {
        return res;
      });

      this.loading = false;
    },
    removeFilter: function (groupName) {
      this.groupsSelected = this.groupsSelected.filter((name) => {
        return groupName !== name;
      });
    },

    // findUndefined() {
    //   var i = 0;
    //   for(const group of this.groups) {
    //     if (!group.groupName) {
    //       console.log(group)
    //       console.log(group.groupID)
    //       console.log(i)
    //     }
    //     i++;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
// @import "../styles/Groups.scss";
@import "../styles/ServerSelect.scss";
</style>
