<template>
    <div class="modal-backdrop">
        <div class="modal">
            <header class="modal-header">Add Organization</header>
            <body class="modal-body">
                <label for="organization-name">Organization Name</label>
                <input type="text" id="orgnaization-name" name="orgName" v-model="orgName" />
            </body>
            <footer class="modal-footer">
                <button type="button" class="btn-close" @click="close()">Close</button>
                <button type="button" class="btn-apply" @click="confirm(orgName)">Confirm</button>
            </footer>
        </div>
    </div>
</template>


<script>
export default {
    computed: {
        orgName: {
            get () {
                return this.$store.state.orgName;
            },
            set (value) {
                this.$store.commit('setOrgName', {orgName: value});
            }
        }
        // orgName: ""
    },
    methods: {
        close() {
            this.$emit("close");
        },
        confirm(payload) {
            this.$emit("confirm", payload);
        },
    }
}
</script>
<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
// @import "./styles/AddCourses.scss";
</style>