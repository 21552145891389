import { Bar, Scatter } from "vue-chartjs";
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Bar,
  Scatter,
  name: "BarLineChart",
  props: {
    options: {
      type: Object,
    },
    render: {
      type: Object,
    },
  },
  mounted() {
    if(this.options.plugins && this.options.plugins.datalabels)
    {
      this.addPlugin(ChartJsPluginDataLabels);
    }
    this.renderChart(this.render, this.options);
  },
};
