import { Line } from "vue-chartjs";

export default {
  extends: Line,
  name: "LineChart",
  props: {
    options: {
      type: Object,
    },
    render: {
      type: Object,
    },
    styles: {
      type: Object,
    }
  },
  mounted() {
    this.renderChart(this.render, this.options, this.styles);
    // console.log('Rendering the chart in the lineChart.js vue aspect',this.render, this.options)
  },
};
