<template>
  <div >
    <b-navbar class="navbar"   toggleable="lg"  type="dark" variant="dark">
      <!-- <b-button v-b-toggle.sidebar-no-header>Menu</b-button> -->
      <div id="left-nav-items">
        <b-button
          id="tooltip-button-1"
          v-b-toggle.sidebar-no-header
          variant="light"
          data-toggle="tooltip"
          data-placement="top" 
          data-trigger="hover"
          title="Navigation"
          @click="toggleMenu"
        >
          <img src="../assets/side menu icon.png" data-cy="side-nav-icon" />
        </b-button>
        <b-navbar-brand tag="h1" class="mb-0" href="#">
          <span>
            <b>{{ username }}</b>
          </span>
        </b-navbar-brand>
        <!-- <b-tooltip
          :show.sync="show"
          target="tooltip-button-1"
          placement="top"
          data-trigger="hover"
          class="tooltip"
        >
          <strong>Navigation</strong>
        </b-tooltip> -->
      </div>
      <!-- <Translator style="border: solid red 2px;"/> -->
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse class="nav-menu" id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav>
          <div class="additional-items"> 
          <b-nav-form>
            <!-- <b-button
              @click="toggleDarkMode"
              size="md"
              class="sign-out-button my-2 my-sm-0"
            >
            
              Toggle Dark Mode
            </b-button> -->
            <span class="switch-label">
              Dark Mode
              <label class="switch">
                <input
                  type="checkbox"
                  @change="toggleDarkMode()"
                  :checked="this.$store.state.darkmode"
                />
                <span class="slider round"></span>
              </label>
            </span>
          </b-nav-form>
</div>
          <b-nav-item-dropdown right style="display: inline-block;" class="navbar-dropdown">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b>User</b>
            </template>
            <!-- <b-dropdown-item href="#">Profile</b-dropdown-item> -->
            <!-- <b-dropdown-item href="#">
                  <b-nav-item>
                <router-link to="/account-information">
                  Profile
                </router-link>
              </b-nav-item>
              </b-dropdown-item> -->
              <b-dropdown-item @click="goProfile()"> 
                Profile
              </b-dropdown-item>
            <b-dropdown-item @click="logOut">Log Out</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-button
          data-toggle="tooltip"
          data-placement="top" 
          data-trigger="hover"
          class="language-button"
          title="Navigation"
          @click="openLanguage"
        >
        <img class="flag" alt="" :src="flagIcon">
        <span v-show="currentLanguage==''">Change Language</span>
        <span v-show="currentLanguage!==''">{{currentLanguage}}</span>
        <!-- <span v-show="languageSelected!==''">{{languageSelected}}</span> -->
        </b-button>
<!-- </div> -->
          <!-- <b-nav-form>
            <b-button
              size="md"
              class="sign-out-button my-2 my-sm-0"
              type="submit"
            >
              Log Out
            </b-button>
          </b-nav-form> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <ChangeLanguage v-show="languageShown" @close="close"></ChangeLanguage>

  </div>
</template>

<script>

import { mapState } from "vuex";
import ChangeLanguage from "./modals/ChangeLanguage.vue";
// import {Translator} from "vue-google-translate";
export default {
  name: "Navbar",
  components:{
    ChangeLanguage,
    // Translator,
  },
  data() {
    return {
      show: false,
      languageShown:false,
      languageSelected:'',
    };
  },
  computed:{
    ...mapState({
        currentLanguage:(state)=>state.currentLanguage,
        // adminGroups:(state)=> state.groups,
        menuVisible:(state)=>state.menuVisible,
        username:(state)=>state.adminEmail,
      }),
      flagIcon: function()
      {
        return 'https://cdn.jsdelivr.net/gh/lewis-kori/vue-google-translate/src/assets/images/flags/__'+this.currentLanguage+'.png'
      },
  },
  watch:{

  },
  created() {
    // setTimeout(() => {
    //   this.show = !this.show;
    // }, 5000);
  },
  methods: {
    logOut() {
      this.$store.dispatch("callLogOutAPI").then(() => {
        location.reload();
      });
    },
    goProfile(){
      this.$router.push("/account-information");
    },
    toggleDarkMode() {
      let x = document.querySelector("#body");
      if (!this.$store.state.darkmode) {
        x.style.backgroundColor = "#000"; //Black
      } else if (this.$store.state.darkmode) {
        x.style.backgroundColor = "#f7f7f7"; //Off white
      }
      this.$store.state.darkmode = !this.$store.state.darkmode;
    },
    openLanguage(){
      this.languageShown=true;
    },
    toggleMenu()
    {
      this.$store.dispatch("toggleMenu");
    },
    close(){
      this.languageShown=false;
    },
   
  },
};
</script>
<style scoped>
@import "./styles/Navbar.scss";
</style>
