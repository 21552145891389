<template>
    <div class="checkbox">
     <!-- {{index}} {{checked[index][method]}} {{isChecked}} -->
    <input type="checkbox" @change="change()" :checked="isChecked" />
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    index: String,
    checked: Object,
    method: String,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    isChecked: function() {
      return (
        this.$props.checked[this.index] &&
        this.$props.checked[this.index][this.method]
      );
    },
  },
  methods: {
    change() {
      let obj = {};
      // Checked for existing user groups passed in via prop and creates group flag object if prop is empty
      if (!this.$props.checked[this.index] ) {
        obj.isMember = true;
        obj.isPrimaryGroup = false;
        obj.isAdmin = false;
        obj.isAuthor = false;
        obj.isLearner = false;
        obj.isVerifier = false;
        obj[this.method] = true;
        if (this.method === "isMember") {
          obj.isLearner = true;
        }
      } else {
        // User group exists and was passed in as prop checked
        // automatically raises the isMember flag if another flag is raised while isMember is false
        if (
          !this.$props.checked[this.index]["isMember"] &&
          !this.$props.checked[this.index][this.method]
        ) {
          obj.isMember = true;
        }

        // automatically raises the learner flag if member flag is being raised
        if (
          !this.$props.checked[this.index]["isLearner"] &&
          !this.$props.checked[this.index]["isMember"] &&
          this.method === "isMember"
        ) {
          obj.isLearner = true;
        }
        //Automatically raise learner flag if adding as an Admin
        if (
          !this.$props.checked[this.index]["isLearner"] &&
          !this.$props.checked[this.index]["isAdmin"] &&
          this.method === "isAdmin"
        ) {
          obj.isLearner = true;
        }

        //Automatically raise learner flag if adding in their primary group
        if (
          !this.$props.checked[this.index]["isLearner"] &&
          !this.$props.checked[this.index]["isPrimaryGroup"] &&
          this.method === "isPrimaryGroup"
        ) {
          obj.isLearner = true;
        }

        obj[this.method] = !this.$props.checked[this.index][this.method];
      }
      
      this.$emit("change", {
        [this.index]: { ...this.$props.checked[this.index], ...obj },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
