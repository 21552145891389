<template>
    <div style="float: right;padding-right: 5px;" >
        <div v-if="arrowOption==='ascending'"><font-awesome-icon class="sort-icon" style="color: rgb(251, 143, 6);" :icon="['fas', 'sort-down']"/></div>
        <div v-else-if="arrowOption==='descending'"><font-awesome-icon class="sort-icon" style="color: rgb(251, 143, 6);" :icon="['fas', 'sort-up']"/></div>
        <div v-else><font-awesome-icon v-if="useWhite" class="sort-icon" style="color:white;" :icon="['fas', 'sort']"/>
          <font-awesome-icon v-else class="sort-icon" style="color:black;" :icon="['fas', 'sort']"/></div>
  </div>
</template>

<script>
export default {
  name: "SortArrow",
  props: {
    sorting: String,
    ascending: Boolean,
    column:String,
    useWhite:Boolean,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    arrowOption(){
        if(this.sorting!=this.column)
        {
            return 'none';
        }
        else if(this.ascending)
        {
            return 'ascending';            
        }
        else{
            return 'descending';
        }
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped></style>
