<template>
  <div class="account-info-container">
    <div class="sidemenu-container"><SideMenu></SideMenu></div>
    <div id="navbar-container">
      <Navbar>Account Information</Navbar>
  <div id="account-info">
    
    

    <div class="header" :class="{ darkmode: this.$store.state.darkmode }">
      <!-- <router-link
        to="/dashboard"
        class="back-to-dashboard"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        <h3>
          <font-awesome-icon :icon="['fas', 'arrow-left']" /> Back to Dashboard
        </h3>
      </router-link> -->
      <h3>Account Information</h3>
    </div>

    <div class="edit-container">
      <button @click="showRegisterUserModal" class="edit-btn">Edit</button>
    </div>

    <div class="container" :class="{ darkmode: this.$store.state.darkmode }">
      <div class="content-headers">
        <span> Admin Level: </span>
        <span> First Name: </span>
        <span> Last Name: </span>
        <span> Registration Date: </span>
        <span> Email: </span>
      </div>

      <div class="content-data">
        <span> {{ adminLevel }} </span>
        <span> {{ form.firstName }} </span>
        <span> {{ form.lastName }} </span>
        <span> {{ registrationDate }} </span>
        <span> {{ form.email }} </span>
      </div>
    </div>

    <RegisterUserModal
      v-show="registerUserModalVisible"
      method="Edit"
      :form="{ ...this.form, loginID: $store.state.userID }"
      @update="update"
      @close="closeModals"
    />
  </div>
</div>
</div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import RegisterUserModal from "../components/modals/RegisterUser.vue";

export default {
  name: "AccountInformation",
  components: {
    Navbar,
    SideMenu,
    RegisterUserModal,
  },

  data() {
    return {
      registerUserModalVisible: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
      },
      adminLevel: "",
      registrationDate: "",
    };
  },

  // API Call to initialize data
  async mounted() {
    await this.$store
      .dispatch("getUserProperties", this.$store.state.userID)
      .then((user) => {
        this.form.firstName = user.firstName;
        this.form.lastName = user.lastName;
        this.form.email = user.email;
        this.adminLevel = user.creationType;
        this.registrationDate = user.registrationDate;
      });
  },

  methods: {
    showRegisterUserModal() {
      this.registerUserModalVisible = true;
    },

    closeModals() {
      this.registerUserModalVisible = false;
    },

    // API Call to refresh page data
    update() {
      this.$store
        .dispatch("getUserProperties", this.$store.state.userID)
        .then((user) => {
          this.form.firstName = user.firstName;
          this.form.lastName = user.lastName;
          this.form.email = user.email;
          this.adminLevel = user.creationType;
          this.registrationDate = user.registrationDate;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/AccountInformation.scss";
</style>
