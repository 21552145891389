<template>
  <div id="add-user">
    <Navbar>Add User</Navbar>
    <SideMenu></SideMenu>
    <header class="header">
      <router-link
        to="/dashboard"
        class="router-link"
        style="text-decoration: none"
      >
        <span
          class="back-to-dashboard"
          :class="{ darkmode: this.$store.state.darkmode }"
        >
          <h3>
            <font-awesome-icon :icon="['fas', 'arrow-left']" /> Back to
            Dashboard
          </h3>
        </span>
      </router-link>

      <span class="title" :class="{ darkmode: this.$store.state.darkmode }">
        <h3>
          <font-awesome-icon class="title-icon" :icon="['fas', 'user-plus']" />
          Add User
        </h3>
      </span>
    </header>
    <div class="register">
      <img alt="SWIFTLMS" src="../assets/SWIFT LMS.svg" width="500px" />

      <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <div class="form">
        <div class="steps">
          <FirstStep v-show="step() === 'firstStep'" />
          <SecondStep v-show="step() === 'secondStep'" />
          <ThirdStep v-show="step() === 'thirdStep'" />
          <FourthStep v-show="step() === 'fourthStep'" />
          <FifthStep v-show="step() === 'fifthStep'" />
        </div>
        <div class="form-nav">
          <FormNavigation v-show="step() !== 'addGroup'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";

import FirstStep from "../components/msForm/FirstStep.vue";
import SecondStep from "../components/msForm/SecondStep.vue";
import ThirdStep from "../components/msForm/ThirdStep.vue";
import FourthStep from "../components/msForm/FourthStep.vue";
import FifthStep from "../components/msForm/FifthStep.vue";

import FormNavigation from "../components/msForm/FormNavigation.vue";

export default {
  name: "AddUser",
  components: {
    Navbar,
    SideMenu,

    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    FifthStep,

    FormNavigation,
  },
  methods: {
    step() {
      return this.$route.name;
    },
  },
};
</script>
<style scoped>
@import "./styles/Registration.scss";
.register {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.form {
  border: 1px solid orange;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 8px 8px -4px orange;
  width: 600px;
  height: 350px;
  margin: auto;
  margin-top: 20px;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>