<template>
  <div class="modal-backdrop">
    <div class="modal">

      <header class="modal-header">
        <h1>Course Groupings</h1>
      </header>

      <body class="modal-body">
        <tree :tree-data="AssignmentGroupTree">
        </tree>
      </body>

      <footer class="modal-footer">
        <button type="button" class="btn-close" @click="close">
          Cancel
        </button>
        <button type="button" class="btn-apply" @click="apply">
          Apply
        </button>
      </footer>

    </div>
  </div>
</template>

<script>

import Tree from "../Tree.vue";

export default {
  name: "AddCoursesModal",
  components: {
    Tree
  },
  props: {
    method: String,
    orgID: String,
  },
  data() {
    return {
      AssignmentGroupTree: {},
      // GroupTreeDefault:{},
    }
  },
  watch: {
    orgID: function() {
      this.getAssignmentGroupTree(this.orgID)
    }
  },
  // created() {
  //   console.log("AddCourses Modal", this.orgID)
  //   this.getAssignmentGroupTree(this.orgID)
  // },
  methods: {
    // setDefaultTree(tree){
    //   console.log('Setting Tree',tree)
    //   {
    //     this.GroupTreeDefault=tree;
    //   }
    // },
    getAssignmentGroupTree(nodeID) {
      // console.log("working on getAssignmentGroupTree request")
      this.$store
        .dispatch("getAssignmentGroupTree", {groupID: nodeID})
        .then((data) => {
            this.AssignmentGroupTree = data[0];  //data is being updated all the way to the top level in the store.
            // this.setDefaultTree(data[0])
        })
    },

    close() {
      this.$emit("close");
    },

    apply() {
      this.getSelectedAndUnselectedGroups();
      this.resetAssignmentGroupTree(this.AssignmentGroupTree);
    },

    getSelectedAndUnselectedGroups() {
      let AssignmentGroupTreeArr = this.convertTreeToList(this.AssignmentGroupTree)
      let selectedGroups='';
      let unselectedGroups='';

      for(let i = 0; i < AssignmentGroupTreeArr.length; i++)
      {
        if(AssignmentGroupTreeArr[i].Selected == true)
        {
          selectedGroups += AssignmentGroupTreeArr[i].ID.toString() + ';';
        }
        else{
          unselectedGroups += AssignmentGroupTreeArr[i].ID.toString() + ';';
        }
      }
      // Remove semicolon from the end of the string. 
      selectedGroups = selectedGroups.replace(/;\s*$/, "");
      unselectedGroups = unselectedGroups.replace(/;\s*$/, "");

      this.$emit("apply", selectedGroups, unselectedGroups);
    },
    convertTreeToList(root) {
        let stack = [], array = [], hashMap = {};
        stack.push(root);

        while(stack.length !== 0) {
            let node = stack.pop();
            if(node.children.length===0) {
                this.visitNode(node, hashMap, array);
            } else {
                for(let i = node.children.length - 1; i >= 0; i--) {
                    stack.push(node.children[i]);
                }
                array.push(node);
            }
        }
        return array;
    },
    visitNode(node, hashMap, array) {
      if(!hashMap[node.ID]) {
          hashMap[node.ID] = true;
          array.push(node);
      }
    },

    /**
    *  For each node visit if node not a hashMap key, insert 
    *  into array.  Then append node into end of the array.
    *  @params node - object to check
    *  @param hashMap - object literal used for deduping
    *  @param array - final array that nodes are inserted
    */

    resetAssignmentGroupTree(obj) { // Ideally we could create a deafult tree for resetting purposes.
      if(obj.Selected==true)        // However, this recursive function does the job.
      {
          obj.Selected=false;
      }
      if(obj.children.length!==0)
      {
        for (let i=0;i<obj.children.length;i++){
          this.resetAssignmentGroupTree(obj.children[i])
      }
      }
    },

  },


};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/AddCourses.scss";
</style>
