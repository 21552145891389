<template>
    <div class="analytics-container">
      <div class="sidemenu"> 
        <SideMenu></SideMenu>
      </div>
      <div id="navbar-container">
      <Navbar>Course Reports</Navbar>
  <div id="da">

    <div class="da-header">
      <span class="title">
        <font-awesome-icon class="title-icon" :icon="['fas', 'chart-bar']" />
        Analytics
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'chart-bar']" /> -->
        <!-- SWIFT Analytics -->
      </span>
    </div>
    <div class="da-content">
      <div class="wrapper">
        <div class="da-group-list">
          <div class="search-bar">
          <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
          <input
            type="search"
            class="search-input"
            placeholder="Search for Group"
            v-model="companyName"
          />
          </div>
          <div
            id="group-list"
            :class="{ darkmode: this.$store.state.darkmode }"
            v-if="Object.keys(this.companyTree).length !== 0"
          >
            <!-- <div class="da-group-tree-browser"> -->
              <TreeBrowser
                :node="this.filteredCompanies || this.companies"
                @onClick="nodeWasClicked"
                :callback="doSomething"
                :selectedKey="selectedKey"
                @update:selectedKey="updateKey"
              />
            <!-- </div> -->
          </div>
          <!-- <button class="org-list-create-subgroup" @click="doSomething()">
                Create Subgroup
            </button> -->
        </div>
      </div>
      <div class="da-body">
        <div class="da-group-header title">
          {{ this.theNode.groupName }}
        </div>
        <div class="da-group-data">
          <div class="course-list">
            <div class="course-list-header search-bar">
              <font-awesome-icon
                class="search-icon"
                :icon="['fas', 'search']"
              />
              <input
                type="search"
                class="course-list-header-search-bar search-input"
                placeholder="Search for Course"
                v-model="courseName"
              />
            </div>
            <div
              class="course-list-data text"
              :class="{ darkmode: this.$store.state.darkmode }"
            >
              <div v-if="loading" class="spinner">
                <Spinner />
              </div>
              <div
                v-else
                v-for="course in filteredCompanyCourses"
                :key="course.courseID"
                @click="
                  (e) => {
                    !loading && setCourse(course.courseID, course.courseName);
                    enrollmentStatus();
                  }
                "
                :class="{
                  selected: course.courseID == LKBtests.selectedCourse,
                }"
              >
                &#9671; {{ course.courseName }}
              </div>
            </div>
          </div>

          <div class="org-test-stats">
            <div class="subtitle">
              {{ this.LKBtests.selectedCourseName }} {{ "Post Test Stats" }}
            </div>
            <div class="org-test-stats-data">
              <div
                class="tests-taken-card"
                :class="{ darkmode: this.$store.state.darkmode }"
              >
                <span class="tests-taken-card-title">Course Pass Rate </span>
                <span class="tests-taken-card-data">
                  <!-- this shows the all the attempts -->
                  {{ passRate || mastered }}%
                  <!-- {{ mastered }}
                  /
                  {{ this.testStats.testsTakenArr.length }} -->
                </span>
              </div>
              <div
                class="score-card"
                :class="{ darkmode: this.$store.state.darkmode }"
              >
                <span class="score-card-title"> Score </span>
                <span class="score-card-data">
                  <span>High: {{ testScores.maxScore }}</span>
                  <span>Low: {{ testScores.minScore }}</span>
                  <span>Average: {{ testScores.meanScore }}</span>
                </span>
              </div>
              <div
                class="duration-card"
                :class="{ darkmode: this.$store.state.darkmode }"
              >
                <span class="duration-card-title"> Duration(minutes) </span>
                <span class="duration-card-data">
                  <span>Max: {{ testScores.maxDuration }}</span>
                  <span>Min: {{ testScores.minDuration }}</span>
                  <span>Average: {{ testScores.meanDuration }}</span>
                </span>
              </div>
            </div>

            <div class="org-test-stats-module">
              <div class="subtitle">Module Post Tests</div>
              <div class="org-test-stats-module-chart">
                <BarLineChart
                  v-if="!loading"
                  :options="chartOptions"
                  :render="renderOptions"
                  :styles="myStyles"
                />
              </div>
            </div>
          </div>

          <div class="learner-data">
            <div class="learner-list">
              <div class="learner-list-header">
                <div class="search-bar">
                <font-awesome-icon
                  class="search-icon"
                  :icon="['fas', 'search']"
                />
                <input
                  type="search"
                  class="learner-list-header-search-bar search-input"
                  placeholder="Search for Learner"
                  v-model="memberName"
                />
                </div>
                <button class="unenroll-all" @click="this.unenrollAll">
                  Unenroll All
                </button>
                <!-- <button>sdfsds</button> -->
                <button class="enroll-all" @click="this.enrollAll">
                  Enroll All
                </button>
              </div>

              <div
                class="learner-list-data"
                :class="{ darkmode: this.$store.state.darkmode }"
              >
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th v-if="adminLevel.adminLevelInt==0">Login ID</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="user in checkEnrollment"
                      :key="user.id"
                      :class="{
                        selected: user.ID == LKBtests.selectedUser,
                      }"
                      @click="(e) => !loading && setUser(user.loginID)"
                    >
                      <td>{{ user.firstName }} {{ user.lastName }}</td>
                      <td v-if="adminLevel.adminLevelInt==0">
                        {{ user.ID }}
                      </td>
                      <button
                        class="btn"
                        id="enroll"
                        size="sm"
                        @click="enrollLearner(user)"
                        v-if="user.enrolled === 'false'"
                      >
                        <strong>enroll</strong>
                      </button>
                      <button
                        class="btn"
                        id="unenroll"
                        size="sm"
                        @click="confirmUnenroll(user)"
                        v-else
                      >
                        <strong>unenroll</strong>
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="modules-by-learner">
              <div class="subtitle">
                Tests for
                {{ this.LKBtests.selectedUser || "Selected Learner" }}
              </div>
              <div
                class="modules-by-learner-data"
                :class="{ darkmode: this.$store.state.darkmode }"
              >
                <table class="moduleapis">
                  <thead>
                    <tr>
                      <th>Module</th>
                      <th>~Duration</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody v-if="this.LKBtests.selectedUser !== null">
                    <tr v-for="(test, index) in userTests" :key="index">
                      <td v-if="test.name !== 'null'">
                        {{ test.name }}
                      </td>
                      <td v-else>Course Post Test</td>
                      <td>{{ Math.ceil(test.duration / 60) }} Min</td>
                      <td>
                        {{ test.score }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddOrg v-show="AddOrgVisable" @close="close" @confirm="confirm" />

    <div v-show="ConfirmationVisable" class="modal-backdrop">
      <Confirmation
        v-show="ConfirmationVisable"
        :unenrollMany="this.unenrollMany"
        :enrollment="this.userToUnenroll"
        @cancel="close"
        @confirm="confirmedToUnenroll"
      />
    </div>
  </div>
  </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { sleep } from "../util/helper";
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import TreeBrowser from "../components/TreeBrowser.vue";
import BarLineChart from "../components/BarLineChart.js";
import Spinner from "../components/Spinner.vue";

import AddOrg from "../components/modals/AddOrg.vue";
import Confirmation from "../components/modals/Confirmation.vue";

export default {
  name: "DetialedAnalytics",
  components: {
    Navbar,
    SideMenu,
    TreeBrowser,
    BarLineChart,
    Spinner,
    AddOrg,
    Confirmation,
  },
  created() {
    if(Object.keys(this.companyTree).length==0)
      {
        this.loadingCompanies=true;
        this.$store.dispatch("groupTree").then(() => {
          this.loadingCompanies=false;
        });
      }
      if(this.singleGroupUser && this.companyTree[0])//When they are only in one group we want to select the only group
      {
        this.nodeWasClicked(this.companyTree[0]);
      } 
  },
  data() {
    return {
      AddOrgVisable: false,
      ConfirmationVisable: false,

      userToUnenroll: {
        loginID: "",
        contentName: "",
      },
      unenrollMany: [],
      loading: false,
      companyName: "",
      theNode: {},
      theNodeID: "",
      theNodeName: "",
      groupInfo: {},
      visable: false,
      memberName: "",
      courseName: "",
      assignmentGroupTree: {},
      userList: [],
      contentList: [],
      courseList: [],
      userTests: [],
      courseModules: [],
      groupModuleScores: {},
      status: {},
      selectedKey:null,
      LKBtests: {
        selectedCourseName: "",
        selectedCourse: "",
        selectedUser: null,
      },

      testStats: {
        testsTakenArr: [],
        scoreTotal: 0,
        scoreCount: 0,
        testsDurationArr: [],
        durationTotal: 0,
        durationCount: 0,
      },

      renderOptions: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          postion: "right",
          labels: {
            fontsize: 18,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 20,
                fixedStepSize: 1,
                min: 0,
                max: 100,
                beginAtZero: true,
                fontColor: "#000000",
                fontSize: 15,
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Score",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Module",
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapState({
      companies: (state) => state.groups,
      adminLevel: (state) =>state.adminLevel,
      companyTree:(state) => state.groupTree,
      singleGroupUser: (state)=>state.singleGroupUser,
    }),
    passRate: function () {
      return Math.round(
        (this.mastered / this.testStats.testsTakenArr.length) * 100
      );
    },
    mastered: function () {
      let count = 0;
      for (let test of this.testStats.testsTakenArr) {
        if (test > 80) {
          count++;
        }
      }
      return count;
    },

    testScores: function () {
      if (this.testStats.testsTakenArr.length === 0) {
        return {
          minScore: 0,
          maxScore: 0,
          meanScore: 0,
          minDuration: 0,
          maxDuration: 0,
          meanDuration: 0,
        };
      } else {
        return {
          minScore: Math.min(...this.testStats.testsTakenArr),
          maxScore: Math.max(...this.testStats.testsTakenArr),
          meanScore: Math.round(
            this.testStats.scoreTotal / this.testStats.scoreCount
          ),
          minDuration: Math.round(
            Math.min(...this.testStats.testsDurationArr) / 60
          ),
          maxDuration: Math.round(
            Math.max(...this.testStats.testsDurationArr) / 60
          ),
          meanDuration: Math.round(
            Math.round(
              this.testStats.durationTotal / this.testStats.durationCount
            ) / 60
          ),
        };
      }
    },

    event() {
      return this.shouldShow ? "click" : null;
    },

    myStyles() {
      return {
        postion: `relative`,
        width: `450px`,
        height: `320px`,
      };
    },
    filteredCompanies: function () {
      let stack = [],
        node,
        ii;
      let children = [];
      let matches = {};
      if(this.companyTree.length)
      {
        for(var company in this.companyTree)
        {
          stack.push(this.companyTree[company]);
        }
      }
      else{
        stack.push(this.companyTree);
      }
      // stack.push(this.companyTree);
      while (stack.length > 0) {
        node = stack.pop();
        if (
          node.Name ||
          node.groupName.toLowerCase().match(this.companyName.toLowerCase())
        ) {
          children.push(node);
        } else if (node.children && node.children.length) {
          for (ii = 0; ii < node.children.length; ii += 1) {
            stack.push(node.children[ii]);
          }
        }
      }
      return Object.assign(matches, {
        groupName: "Company List",
        children: children,
      });
    },
    filteredCompanyMembers: function () {
      return this.userList.filter((user) => {
        return (
          user.firstName.toLowerCase().match(this.memberName.toLowerCase()) ||
          user.lastName.toLowerCase().match(this.memberName.toLowerCase())
        );
      });
    },
    checkEnrollment: function () {
      return this.status;
    },
    filteredCompanyCourses: function () {
      let filter = "";
      this.courseList.forEach((courseObj) => {
        filter = courseObj.filter((course) => {
          return course.courseName
            .toLowerCase()
            .match(this.courseName.toLowerCase());
        });
      });
      return filter;
    },
  },
  methods: {
    enrollmentStatus() {
      const enrollmentInfo = {
        groupID: this.theNode.ID,
        contentID: this.LKBtests.selectedCourse,
      };
      this.$store
        .dispatch("getUsersForContentSeating", enrollmentInfo)
        .then((data) => {
          this.status = data;
        });
    },
    AddGroup(newOrgName) {
      //This function is called when creating a new organization.
      this.$store
        .dispatch("updateGroup", {
          name: newOrgName,
          parentGroupID: this.theNodeID,
          method: "add",
        })
        .then((res) => {
          if (!res[1]) {
            alert("this name already exists, try something different");
          }
        })
        .catch((error) => {
          console.error('Error adding/updating group in DetailedAnalytics',error);
        });
    },
    confirm(payload) {
      this.AddGroup(payload);
      this.AddOrgVisable = false;
    },
    close() {
      this.AddOrgVisable = false;
      this.ConfirmationVisable = false;
      this.unenrollMany = [];
    },
    doSomething() {
      this.AddOrgVisable = true;
    },
    updateKey(groupID){
      this.selectedKey=groupID;
    },
    async getTestStats(LKBtests) {
      console.log('Getting the test stats with the data',LKBtests)
      this.loading = true;
      for (const user of this.userList) {
        await this.$store
          .dispatch("getUserLKBtests", {
            userID: user.ID,
            lkbID: `c${LKBtests.selectedCourse}`,
          })
          .then((data) => {
            if(data.length!=0)
            {
              console.log('the user: '+user.ID+" returned ",data)
            }
            this.formatModuleScores(data);
          });
        sleep(150);
      }
      this.fillRenderOptions();
      this.loading = false;
    },
    formatModuleScores(data) {
      data.forEach((userTestObj) => {
        if (!this.groupModuleScores[userTestObj.name]) {
          this.groupModuleScores[userTestObj.name] = {
            scoreTotal: 0,
            testsCount: 0,
          };
        }
        if (userTestObj.testType === "Course Posttest") {
          this.testStats.testsTakenArr.push(userTestObj.score);
          this.testStats.scoreCount += 1;
          this.testStats.scoreTotal += userTestObj.score;

          this.testStats.testsDurationArr.push(userTestObj.duration);
          this.testStats.durationCount += 1;
          this.testStats.durationTotal += userTestObj.duration;
        }
        if (userTestObj.name !== "null") {
          this.groupModuleScores[userTestObj.name].scoreTotal +=
            userTestObj.score;
          this.groupModuleScores[userTestObj.name].testsCount += 1;
        }
      });
    },
    setUser(userid) {
      console.log('Setting the user with the ID',userid)
      this.LKBtests.selectedUser = userid;
      this.getUserLKBtests(this.LKBtests);
    },
    setCourse(courseid, coursename) {
      this.testStats.testsTakenArr = [];
      this.testStats.scoreTotal = 0;
      this.testStats.scoreCount = 0;
      this.testStats.testsDurationArr = [];
      this.testStats.durationTotal = 0;
      this.testStats.durationCount = 0;
      this.groupModuleScores = {};

      this.LKBtests.selectedUser = null;
      this.LKBtests.selectedCourse = courseid;
      this.LKBtests.selectedCourseName = coursename;
      // this.getUserLKBtests(this.LKBtests)
      this.getTestStats(this.LKBtests);
    },

    // getGroupTree() {
    //   this.$store.dispatch("groupTree").then((res) => {
    //     if (res[0].Name !== "undefined") {
    //       this.companyTree = res;
    //     } else {
    //       this.companyTree = res[1];
    //     }
    //   });
    // },
    callAdminGroupInfoAPI(nodeid) {
      this.$store
        .dispatch("callAdminGroupInfoAPI", { groupID: nodeid })
        .then((data) => {
          this.groupInfo = data;
        });
    },
    getUserList(nodeid) {
      this.$store.dispatch("getUserList", { groupID: nodeid }).then((data) => {
        this.userList = data;
      });
    },
    getAssignmentGroupTree(nodeid) {
      this.$store
        .dispatch("getAssignmentGroupTree", { groupID: nodeid })
        .then((data) => {
          this.assignmentGroupTree = data[0];
        });
    },
    // getContentList(nodeid) {
    //   this.$store
    //     .dispatch("getContentList", {groupID: nodeid})
    //     .then((data) => {
    //       this.contentList = data;
    //     })
    // },
    getCourseList(groupID) {
      this.$store.dispatch("getCourseList", groupID).then(() => {
        // this.courseList = Object.keys(this.$store.state.courseNameAndID); // This one for name displayed. issue with passing the key when selected
        if (this.courseList.length > 0) {
          this.courseList = [];
        }
        this.courseList.push(Object.values(this.$store.state.courseNameAndID));

      });
    },
    getUserLKBtests(LKBtests) {
      this.$store
        .dispatch("getUserLKBtests", {
          userID: LKBtests.selectedUser,
          lkbID: `c${LKBtests.selectedCourse}`,
        })
        .then((data) => {
          this.userTests = data;
        });
    },

    nodeWasClicked(node) {
      this.visible = !this.visable;

      this.theNode = node;
      this.theNodeID = node.ID;
      this.theNodeName = node.groupName;

      this.callAdminGroupInfoAPI(this.theNodeID);
      this.getAssignmentGroupTree(this.theNodeID);
      this.getUserList(this.theNodeID);
      this.getCourseList(this.theNodeID);

      this.resetTabsData();
    },
    resetTabsData() {
      this.status = {};
      this.testStats.testsTakenArr = [];
      this.testStats.scoreTotal = 0;
      this.testStats.scoreCount = 0;
      this.testStats.testsDurationArr = [];
      this.testStats.durationTotal = 0;
      this.testStats.durationCount = 0;
      this.renderOptions = {};
      this.setCourse("", "");
    },

    apply() {
      this.getSelectedAndUnselectedGroups();
      this.editGroup();
    },
    getSelectedAndUnselectedGroups() {
      let AssignmentGroupTreeArr = this.convertTreeToList(
        this.assignmentGroupTree
      );
      let selectedGroups = "";
      let unselectedGroups = "";
      for (let i = 0; i < AssignmentGroupTreeArr.length; i++) {
        if (AssignmentGroupTreeArr[i].Selected == true) {
          selectedGroups += AssignmentGroupTreeArr[i].ID.toString() + ";";
        } else {
          unselectedGroups += AssignmentGroupTreeArr[i].ID.toString() + ";";
        }
      }
      // Remove semicolon from the end of the string.
      selectedGroups = selectedGroups.replace(/;\s*$/, "");
      unselectedGroups = unselectedGroups.replace(/;\s*$/, "");
      this.selectedCourses = selectedGroups;
      this.unselectedCourses = unselectedGroups;
      this.editGroup(this.selectedCourses, this.unselectedCourses);
    },
    convertTreeToList(root) {
      let stack = [],
        array = [],
        hashMap = {};
      stack.push(root);

      while (stack.length !== 0) {
        let node = stack.pop();
        if (node.children.length === 0) {
          this.visitNode(node, hashMap, array);
        } else {
          for (let i = node.children.length - 1; i >= 0; i--) {
            stack.push(node.children[i]);
          }
          array.push(node);
        }
      }
      return array;
    },
    visitNode(node, hashMap, array) {
      if (!hashMap[node.ID]) {
        hashMap[node.ID] = true;
        array.push(node);
      }
    },
    editGroup(selected, unselected) {
      this.$store
        .dispatch("updateGroup", {
          name: this.theNodeName,
          groupID: this.theNodeID,
          method: "Edit",
          selectedGroupIDs: selected,
          unselectedGroupIDs: unselected,

          randomizeTestQuestions: this.groupInfo.randomizeTestQuestions,
          comment: this.groupInfo.comment,
          clientEmail: this.groupInfo.clientEmail,
          showDemoContent: this.groupInfo.showDemoContent,
          showDemoContentRecursive: this.groupInfo.showDemoContent,
          showSupplemental: this.groupInfo.showSupplemental,
          showUnavailableContent: this.groupInfo.showUnavailableContent,
          showOnlyAssigned: this.groupInfo.showOnlyAssigned,
          showOnlyAssignedRecursive: this.groupInfo.showOnlyAssignedRecursive,
          allowGuests: this.groupInfo.allowGuests,
        })
        .then(() => {});
    },
    // the function below is not implemented
    // deleteGroup(nodeid) {
    //   this.$store.dispatch("deleteGroup", { groupID: nodeid }).then((res) => {
    //     this.getGroupTree();
    //   });
    // },
    fillRenderOptions() {
      this.renderOptions = {
        labels: [],
        datasets: [
          {
            type: "bar",
            label: `Average`,
            borderColor: "rgb(159, 55, 69)",
            tension: 0.1,
            fill: true,
            backgroundColor: [],
            data: [],
          },
        ],
      };
      for (const mod in this.groupModuleScores) {
        if (mod !== "null") {
          this.renderOptions.labels.push(mod);
          this.renderOptions.datasets[0].data.push(
            this.groupModuleScores[mod].scoreTotal /
              this.groupModuleScores[mod].testsCount
          );
          this.renderOptions.datasets[0].backgroundColor.push(
            "rgb(159, 55, 69)"
          );
        }
      }
      this.loading = false;
    },
    enrollAll() {
      for (let user of this.userList) {
        const enrollData = {
          loginID: user.value,
          contentID: this.LKBtests.selectedCourse,
        };
        this.$store.dispatch("enrollLearner", enrollData).then(() => {
          this.enrollmentStatus();
        });
      }
    },
    unenrollAll() {
      let startedArr = [];
      for (let user of this.status) {
        const enrollData = {
          loginID: user.loginID,
          contentID: this.LKBtests.selectedCourse,
          contentName: this.LKBtests.selectedCourseName
        };
        if (user.started != "null" && user.enrolled == "true") {
          startedArr.push(enrollData);
        } else {
          this.$store.dispatch("unenrollLearner", enrollData).then(() => {
            this.enrollmentStatus();
          });
        }
      }
      this.unenrollMany = startedArr;
      this.ConfirmationVisable = true;
    },
    confirmedToUnenroll(data) {
      this.ConfirmationVisable = false;
      if (data.length === 0) {
        this.unenrollLearner(this.userToUnenroll);
      }
      data.map((user) => {
        this.unenrollLearner(user);
      });
    },
    enrollLearner(user) {
      const enrollData = {
        loginID: user.loginID,
        contentID: this.LKBtests.selectedCourse,
      };
      this.$store.dispatch("enrollLearner", enrollData).then(() => {
        this.enrollmentStatus();
      });
    },
    unenrollLearner(user) {
      this.ConfirmationVisable = false;
      const enrollData = {
        loginID: user.loginID,
        contentID: this.LKBtests.selectedCourse,
      };
      this.$store.dispatch("unenrollLearner", enrollData)
      this.unenrollMany = [];
      this.enrollmentStatus();
    },
    confirmUnenroll(user) {
      const enrollData = {
        loginID: user.loginID,
        contentID: this.LKBtests.selectedCourse,
      };
      if (user.started != "null") {
        this.ConfirmationVisable = true;
        this.userToUnenroll.loginID = user.loginID;
        this.userToUnenroll.contentName = this.LKBtests.selectedCourseName;
      } else {
        this.$store.dispatch("unenrollLearner", enrollData).then(() => {
          this.enrollmentStatus();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../components/styles/BasicModal.scss";
@import "../assets/styles/main.scss";

$blue: rgb(0, 69, 185);
$orange-dark: rgb(255, 126, 0);
$orange-light: rgb(251, 143, 6);
$burgundy: rgb(159, 55, 69);
$black: rgb(27, 29, 40);
$red: rgb(244, 67, 54);
$green: rgb(126, 159, 62);
$white: rgb(240, 241, 238);

$primary-color: #f5f5f5;
$secondary-color: #343a40;
$accent-color: rgb(255, 126, 0);
$text-color: rgb(27, 29, 40);

$font-sizes: (
  "title": 40px,
  "subtitle": 30px,
  "text": 20px,
);
$font-weights: (
  "regular": 400,
  "medium": 500,
  "bold": 700,
);
#da {
  // background-image: url("../assets/SWIFT LMS.svg"),
  //   linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  // background-blend-mode: overlay;
  // background-repeat: no-repeat;
  // background-position: center center;
  // height: 100vh;
  // max-width: 100vw;
  // overflow: hidden;
  height: 100%;
    width:100%;
    overflow: auto;
}
// .wrapper {
//   position: relative;
//   width: 550px;
//   height: 100%;
// }
.da-header {
  display: flex;
  justify-content: space-around;
  margin: 1% 3% 0% 3%;
  padding: 0;
}
.da-content {
  margin: 10px 10px 10px 10px;
  display: flex;
  width: auto;
}
.da-body {
  margin: 0px 10px 0px 10px;
  width: 1900px;
}
.da-group-list {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 750px;
  width: 250px;
}
.search-bar {
  // margin: 0 0 10px 0;
}
#group-list {
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  height: 650px;
  width: 250px;
  overflow-y: scroll;
}
.da-group-header {
  display: flex;
  align-items: center;
  height: 40px;
  // justify-content: center;
}
.da-group-data {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
}
.course-list {
  width: 410px;
  &-header {
    display: flex;
    flex-direction: row;
    &-title {
      width: 160px;
      height: 35px;
    }
    &-search-bar {
      width: 100%;
      height: 35px;
    }
  }
  &-data {
    border: 1px solid rgb(160, 160, 160);
    border-radius: 5px;
    background-color: white;
    width: 100%;
    height: 595px;
    overflow-y: scroll;
    cursor: pointer;
  }
}
.learner-list {
  width: 650px;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // margin: 0 0 10px 0;
    &-title {
      width: 160px;
      height: 35px;
    }
    &-search-bar {
      width: 100%;
      height: 35px;
    }
  }
  &-data {
    background-color: white;
    width: 100%;
    height: 550px;
    border: 1px solid rgb(160, 160, 160);
    border-radius: 5px;
    overflow-y: scroll;
    cursor: pointer;
  }
}
.org-test-stats {
  height: 595px;
  width: 500px;
  &-data {
    display: flex;
    flex-direction: column;
  }
  &-module {
    width: 500px;
    &-chart {
      background-color: white;
      border: 1px solid rgb(160, 160, 160);
      border-radius: 5px;
      height: 317px;
      width: 500px;
    }
  }
  .tests-taken-card,
  .score-card,
  .duration-card {
    background-color: white;
    width: 500px;
    height: 71px;
    border: 1px solid rgb(160, 160, 160);
    border-radius: 5px;
    margin: 0 0 10px 0;

    &-title {
      font-size: 20px;
      font-weight: 700;
      margin: 10px;
    }
    &-data {
      display: flex;
      justify-content: space-evenly;
      font-weight: map-get($font-weights, bold);
      font-size: 20px;
    }
  }
  .duration-card {
    margin: 0 0 0 0;
  }
}
.learner-data {
  display: flex;
  flex-direction: column;
  .learner-list {
    width: 600px;
    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      display: flex;
      &-title {
        width: 160px;
        height: 35px;
      }
      &-search-bar {
        width: 100%;
        height: 35px;
      }
    }
    &-data {
      background-color: white;
      width: 100%;
      height: 380px;
      border: 1px solid rgb(160, 160, 160);
      border-radius: 5px;
      overflow-y: scroll;
      cursor: pointer;
    }
  }
  .modules-by-learner {
    width: 600px;
    &-data {
      height: 170px;

      background-color: white;
      border: 1px solid rgb(160, 160, 160);
      border-radius: 5px;
      overflow-y: scroll;
    }
  }
}
.title {
  font-size: map-get($font-sizes, title);
  font-weight: map-get($font-weights, bold);
}

.subtitle {
  text-align: center;
  font-size: map-get($font-sizes, subtitle);
  font-weight: map-get($font-weights, medium);
  // height: 35px;
}

.text {
  font-size: map-get($font-sizes, text);
  font-weight: map-get($font-weights, regular);
}

table {
  background-color: white;
  width: 100%;
  border-collapse: collapse;
  font-size: map-get($font-sizes, text);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  thead tr {
    position: sticky;
    top: 0;
    background-color: #343a40;
    color: #ffffff;
    text-align: left;
    font-weight: map-get($font-weights, bold);
  }
  th td {
    padding: 12px 15px;
  }
  tbody tr {
    border-bottom: 1px solid $secondary-color;
  }
  tbody tr:last-of-type {
    border-bottom: 1px solid $secondary-color;
  }
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #343a40;
  outline: 1px solid slategrey;
}

.spinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.common-btn {
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 0 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: auto;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
}
.btn {
  color: white;
  background-color: rgb(0, 69, 185);
  margin: 5px 5px 5px 5px;
  width: 100px;
}
.btn:hover {
  background-color: rgba(65, 113, 196, 0.9);
}
#unenroll {
  background-color: rgb(251, 143, 6);
}
#unenroll:hover {
  background-color: rgba(251, 143, 6, 0.7);
}


.search-bar:focus {
  border: 1px solid $accent-color;
}

.selected {
  background-color: rgba(155, 177, 112, 0.5);
}
// 
.title-icon {
  color: rgb(251, 143, 6);
}
.enroll-all {
  height: 35px;
  width: 100px;
  margin: 0 0 0 10px;
  background-color: #0045b9;
  color: white;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid transparent;
}
.unenroll-all {
  font-size: 12px;
  height: 35px;
  width: 100px;
  margin: 0 0 0 10px;
  padding: 6px 12px;
  background-color: #fb8f06;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid transparent;
}
@media screen and (max-width: 1919px) {
  #da {
    height: auto;
  }
  .da-content {
    display: flex;
    margin: 10px auto;
    width: 1200px;
  }
  .da-group-data {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .wrapper {
    position: relative;
    width: 550px;
    height: 100%;
  }
  .da-group-list {
    // position: fixed;
  }
  .learner-data {
    width: 100%;
    margin: 10px 0 0 0;
    .learner-list,
    .modules-by-learner {
      width: 100%;
    }
    .learner-list-header-search-bar {
      width: 75%;
    }
  }
}
.analytics-container{
    display: flex;
    flex-direction: row;
    margin: 0;

  }
</style>