import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import { store } from "./store/Store";
import router from "./util/Routes";
import VueCookies from "vue-cookies";
import JsonExcel from "vue-json-excel";

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import './assets/styles/main.scss'

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false;
Vue.use(VueCookies);
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  const store = require('./store/Store.js')
  // console.log(store.store.state.hasFifthStep)
  if (to.name === 'fifthStep' && !store.store.state.hasFifthStep) {
    next(false);
  } else {
    next();
  }
});