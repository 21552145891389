<template>
  <div class="wellness-container">
    <div id="sidemenu">
      <SideMenu />
    </div>

    <div id="navbar-container">
    <Navbar />

  <div id="sahara-wellness">
    <div class="da-header" >
      <!-- Used to make the title appear in the centers -->
      <!-- <div class="left-title"> </div>  -->

      <!-- <img class="sahara-image" src="../assets/Sahara Logo Horizontal.png" > -->
      <!-- <div class="sahara-image">
      <img style="height:50px;" src="../assets/sahara_icon_normal.png" >
      <img style="height:85px; padding-right: 40px;" src="../assets/Sahara Logo Horizontal.png" >
    </div> -->
    </div>
    <div class="title">
        <!-- <font-awesome-icon class="title-icon" :icon="['fas', 'bolt']" /> -->
        <img class="title-icon" src="../assets/wellness.png" >
         <!-- Overall Wellness -->
         SAHARA Wellness Report
    </div>
    <!-- <Tabs class="tabs"> -->
      <!-- <Tab name="Hazards Graph" selected="true">
        <div class="content"></div>
      </Tab> -->
      <div class="date-pickers">
      <label for="startDate">Start Date:&nbsp;</label>
      <input type="month" @change="dateChanged()" id="startDate" :min="minDate" :max="endDate==''?maxDate:endDate" v-model="startDate"  name="startDate">
      <span class="spacer"></span>
      <label for="endDate">End Date:&nbsp;</label>
      <input type="month" :min="minDate" @change="dateChanged()" :max="maxDate"  v-model="endDate" id="endDate" name="endDate">
      <br>
      <button class="filter-button" @click="industrySelectionVisible=true" v-if="adminLevel.custodian" >Sort by Industry</button>
      </div>
      <div class="null-message"> 
        <span v-if="nullRecords">There are questions with no data for this time frame</span>
      </div>
    <div class="coursescore">
    <div class="coursescore-data">
      <!-- <Spinner class="CourseScore-data-spinner"></Spinner> -->
      <div v-if="graphLoading" class="coursescore-data-spinner">
        <Spinner />
      </div>
        <HorizontalBarChart
        v-if="!graphLoading"
        class="coursescore-data-chart"
        :style="myStyles"
        :options="chartOptionsHorizontal"
        :render="renderOptionsHorizontal"
      >
      </HorizontalBarChart>
      <!-- <BarLineChart
        v-if="!graphLoading"
        class="CourseScore-data-chart"
        :style="myStyles"
        :options="chartOptions"
        :render="renderOptions"
      >
      </BarLineChart> -->

    </div>
    </div>

      <!-- <Tab name="Wellness Graph" selected="true">
        
      </Tab>
    </Tabs> -->
    <ErrorMessageModal 
        v-show="showErrorMessage"
        :errorTitle="errorMsgTitle"
        :errorBody="errorMsgBody"
        @close="closeErrorModal"
        />

        <Industries 
      v-if="industrySelectionVisible"
      :industries="sortIndustries"
      @close="industrySelectionVisible=false"
      @enroll="setIndustryObject"
      :clearIndustry="false"
      ></Industries>
  </div>
</div>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import HorizontalBarChart from "../components/HorizontalBarChart";
import ErrorMessageModal from "../components/modals/ErrorMessage.vue";
import { mapState } from "vuex";
import Industries from "../components/modals/Industries.vue";


import Spinner from "../components/Spinner.vue";
export default {
  name: "SAHARA_Wellness_Test",
  components: {
    Navbar,
    SideMenu,
    // Tab,
    // Tabs,
    Spinner,
    // BarLineChart,
    HorizontalBarChart,
    ErrorMessageModal,
    Industries,
  },
    data() {
    return {
      showErrorMessage:false,
      errorMsgBody:"",
      errorMsgTitle:"",
      startDate:"",
      minDate:"2020-01",
      endDate:"",
      graphLoading:false,
      maxTickWidth:300,
      renderOptions: {},
      nullRecords:false,
      selectedIndustries:[],
      sortIndustries:[],
      industrySelectionVisible:false,
      renderOptionsHorizontal: {},
      questionCount:0,
        positiveChartColors: {
        pointBordercolor: "rgb(244, 67, 54)",
        pointBackgroundColor: "rgb(244, 67, 54)",
        backgroundColorBelow: "rgb(0,0,255)",
        backgroundColorNeutral: "rgb(0,255,0)",
        },
    };
  },
computed:{
  chartOptionsHorizontal(){
    let searchIndustries=this.selectedIndustries;
   return {
        title:{
          display:false,
          text: "Corporate Wellness Responses",
          fontSize: 20,
          fontColor: "#7a7a7a",
          position:"top",
        },
        responsive: true,
        maintainAspectRatio: false,
        layout:{
          padding:{
            left:3,
            right:5,
            top:0,
            bottom:0
          },
        },

        legend: {
          display: true,
          position: "top",
          labels: {
            fontSize: 16,
            fontColor: "#7a7a7a",
          },
        },
              tooltips:{
                enabled: true,
                callbacks: {
                  label: ((tooltipItems)=>{
                    var fullString="";
                    switch(tooltipItems.datasetIndex)
                    {
                      case 0:
                        {
                          fullString="Low: ";
                        }
                        break;
                      case 1:
                        {
                          fullString="Medium: ";
                        }
                        break;

                        case 2:
                        {
                          fullString="High: ";
                        }
                        break;
                    }
                    fullString+=tooltipItems.xLabel.toFixed(2)+'%';
                    tooltipItems.title=fullString;
                    return fullString;
                  }),
                },
                },
        scales: {
          xAxes: [
            {
              stacked:true,
              ticks: {
                max: 100,
                min: 0,
              //   stepSize: 200,
                beginAtZero: true,
                fontColor: "#7a7a7a",
                fontSize: 15,
                display: false,
              },
              // beforeFit: function(scale) {
              //  scale.width = 10  //<-- set value as you wish
              // },
              display: true,
            },
          ],
          //https://www.chartjs.org/docs/latest/axes/cartesian/category.html
          yAxes: [
            {
              pointLabels:{
                callback: ((label)=>{
                  label;
                })
              },
              type: 'category',
              axis:"y",
              bounds:"ticks",
              stacked: true,
              // categoryPercentage: 0.8, // Adjust the categoryPercentage to control bar width
              // barPercentage: 0.9,
              offset:true,
              position:'left',

              ticks: {
                padding:10, 
                fontColor: "#7a7a7a",
                fontSize: 15,
                // maxRotation:90,
                // minRotation:90,
                autoSkip:false,
                backdropColor: "rgba(389, 127, 78, 1)",
                backdropPadding: 20,
                //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!UNDO THESE TWO TO GET THE TEXT ON THE LEFT SIDE
                // mirror:true,
                // padding:570,
                showLabelBackdrop:true,
                lineHeight:0.9,
                align:"left",
                callback: function(value, index, values)
                {
                  index;
                  values;
                  return value;
                }
              },
              gridLines:{
                display:true,
                drawOnChartArea:true,
                drawTicks:true,
                drawBorder: true,
                offsetGridLines:true, 
              },
              grid:{
                tickLength: 100,
                borderColor: "red",
                display:true,
                drawTicks: true,
              },
              
              afterFit: function(scale){
                scale.fullWidth=true;
                scale.width=scale.longestLabelWidth+25; //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!THIS IS HOW YOU MAKE THE ACTUAL GRAPH SMALLER COMPARED TO THE TEXT
                let indStandIn=searchIndustries;
                if(indStandIn && indStandIn.length>1)
                {
                  setTimeout(()=>{
                  indStandIn.forEach((industry)=>{
                    let foundLabel=scale._labelItems.find((label)=>{return label.label[0]==industry.industryName});
                    if(foundLabel)
                    {
                      foundLabel.font = {
                    ...foundLabel.font,
                    color: 'black',
                    size: 20,
                    weight: 400,
                    style: "bold",
                    string: "bold 20px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                  };
                  foundLabel.textAlign="left";
                  foundLabel.x+=30;
                    }
                    
                  });
                },10);
                }
   
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Question",
                fontColor: "#7a7a7a",
                fontSize: 18,
              },
            },
          ],
        },
        plugins:{
          datalabels:{
            anchor: 'middle',
            align: 'middle',
            color: 'black',
            formatter: function(value)
            {
              var roundedVal=Math.round(value);
                return roundedVal==0?"":roundedVal+'%';
            }
            // font:{
            //   weight: 'bold',
            // }
          },
        },
      }
    },
  totalString()
  {
    return "Total Records: "+this.total.toString();
  },
  maxDate()
  {
    var year= new Date().getFullYear();
    var month= new Date().getMonth();
    if(month<10)
    {
    return year.toString()+'-0'+month.toString();
    }
    return year.toString()+'-'+month.toString();
  },
    myStyles() {
      return {
        position: "relative",
        // height: 500,
        borderRadius: "10px",
        backgroundColor: "transparent",
        height: (this.questionCount)*50+"px"
      };
    },
    ...mapState({
      adminLevel: (state) => state.adminLevel,
      saharaIndustries: (state) => state.saharaIndustries,
    })
},

  created () {
    this.endDate=this.maxDate;
    this.getWellnessAverage();
    if(this.adminLevel.custodian)
    {
      if(this.saharaIndustries.length==undefined || this.saharaIndustries.length==0)
      {
        this.$store.dispatch('getSaharaIndustry').then((industries)=>{
            if(industries=='Error')
            {
                alert('Error getting SAHARA Industries');
                this.showErrorMessage=true;
                this.errorMsgBody="Error: Not able to find your SAHARA Industries";
                this.errorMsgTitle="SAHARA Error";
            }
            else{
              this.sortIndustries=industries;
            }
        })
      }
      else{
        this.sortIndustries=[...this.saharaIndustries];
      }
      //? used to sort by all of the industries at the start if custodian
          this.sortIndustries.forEach((industry)=>{
            industry.selected=true;
          })
          let addObj={
            adding: this.saharaIndustries,
          }
          this.setIndustryObject(addObj);
    }
    // window.addEventListener('resize',this.getWellnessAverage);
  },
  destroyed(){
    // window.removeEventListener('resize',this.getWellnessAverage)
  },
  filters:{
   
  },

  mounted(){

  },

  methods: {

    closeErrorModal(){
      this.showErrorMessage=false;
      this.errorMsgBody="";
      this.errorMsgTitle="";
    },
      dateChanged(){
        if(this.endDate && this.endDate<this.startDate) //Set the dates to be equal when the end date is after
        {
          this.startDate=this.endDate;
        }
        this.getWellnessAverage();
      },

      setIndustryObject(indust)
      {
        this.industrySelectionVisible=false
        var industryString="";
        if(indust.adding)
        {
          this.selectedIndustries=indust.adding;
          this.sortIndustries.forEach((ind)=>{
            ind.selected = indust.adding.some((element) => element.industryID === ind.industryID);
            if(ind.selected)
            {
              industryString+=ind.industryID+";";
            }
          })
          this.getWellnessAverage(industryString);
        }
      },

      getWellnessAverage(checkIndustries=""){
          this.graphLoading=true;
            var newEnd=""
          if(this.endDate!="")
          {
            var seperation = this.endDate.split("-");
            let newMonth=parseInt(seperation[1])+1;
            newEnd=seperation[0]+'-';
            if(newMonth>12)
            {
              newEnd=(parseInt(seperation[0])).toString()+'-'+'01';
            }
            else if (newMonth<10){
              newEnd+='0'+newMonth.toString();
            }
            else{
              newEnd+=newMonth.toString();
            }
            newEnd+='-01';
          }
            let wellnessObject={
                startDate:this.startDate?this.startDate.toString()+"-01":"",
                endDate:newEnd
            };
        var multipleIndustries=false;
        if(this.adminLevel.custodian) 
        {
          wellnessObject.industries=checkIndustries;
          if(checkIndustries!="" && checkIndustries.match(/;/g).length>1)
          {
            multipleIndustries=true;
          }
        }
        this.$store.dispatch("getSaharaWellnessAverages", wellnessObject)
        .then((result) => {
            if(result.status==='success')
            {
                const questions = result.results.map((d) => d.questionText);
                const low = result.results.map((d) => (d.low/(d.low+d.medium+d.high))*100);
                const high = result.results.map((d) => (d.high/(d.low+d.medium+d.high))*100);
                const medium = result.results.map((d) => (d.medium/(d.low+d.medium+d.high)*100));

                const {
                    borderColor,
                    pointBorderColor,
                    pointBackgroundColor,
                    backgroundColorBelow,
                    backgroundColorNeutral
                } = this.positiveChartColors;

                var findNull=result.results.find(function(data){
                  if( data.average==null)
                  {
                    return data;
                  }
                });

                if(findNull)
                {
                  this.nullRecords=true;
                }
                else{
                  this.nullRecords=false;
                }

                      //!!!!!!!!!!!!!!!!!!!!!!!!!!THIS IS HOW YOU CHANGE HOW MUCH TEXT IS ALLOWED IN EACH LINE AND WILL AFFECT THE WIDTH OF THE GRAPH
                      // var lineLength=Math.round(window.innerWidth/30); //? This is the current allowable length of text line ro be displayed on the graph
                      // var lineLength=Math.round(window.innerWidth/12); //? This is the current allowable length of text line ro be displayed on the graph
                      var lineLength=Math.round(window.innerWidth/20); //? This is the current allowable length of text line ro be displayed on the graph
                      if(lineLength>100)
                      {
                        lineLength=100;
                      }
                      var finalQuestion= [];
                      var finalLow=[];
                      var finalMedium=[];
                      var finalHigh= [];

                      for(var i in questions)
                      {
                        // if( multipleIndustries && result.results[i].industryID && questions[i]==result.results[i].questionText &&  (i==0 || result.results[i].industryID!=result.results[i-1].industryID))
                        if( multipleIndustries && result.results[i].industryID && (i==0 || result.results[i].industryID!=result.results[i-1].industryID))
                        {
                          finalQuestion.push([this.selectedIndustries.find((el)=>el.industryID==result.results[i].industryID).industryName]);
                          finalLow.push(null);
                          finalMedium.push(null);
                          finalHigh.push(null);
                        }
                        else{
                        var finalArray=[];
                        var allStrings=questions[i].split(" ");
                        var instance="";
                        for(var b in allStrings)
                        {
                          if(allStrings[b].length+instance.length>=lineLength)
                          {
                            instance=instance+allStrings[b];
                            finalArray.push(instance);
                            instance="";
                          }
                          else{
                            instance=instance+allStrings[b]+" ";
                          }
                        }
                        if(instance!="")
                        {
                          finalArray.push(instance.trim());
                        }
                        finalQuestion.push(finalArray);
                        finalLow.push(low[i]);
                        finalMedium.push(medium[i]);
                        finalHigh.push(high[i]);
                      }
                      }
                      this.questionCount=finalQuestion.length;
                      this.renderOptionsHorizontal = {
                        labels: finalQuestion,

                        datasets: [
                        {
                            type: "horizontalBar",
                            label: "Low",
                            data: finalLow,
                            borderColor,
                            pointBorderColor,
                            pointBackgroundColor,
                            backgroundColor:'#b6ff5c',
                            
                            backgroundColorBelow,
                        },  
                        {
                            type: "horizontalBar",
                            label: "Medium",
                            data: finalMedium,
                            borderColor,
                            pointBorderColor,
                            pointBackgroundColor,
                            backgroundColor:'#ffbe33',
                            backgroundColorNeutral,
                        },  
                        {
                            type: "horizontalBar",
                            label: "High",
                            data: finalHigh,
                            borderColor,
                            pointBorderColor,
                            pointBackgroundColor,
                            backgroundColor:'#e43a48',
                        },  
                        ],
                    };

                this.graphLoading=false;
            }
            else{
              this.errorMsgTitle="Wellness Error";
              this.errorMsgBody="Failed to get wellness records. Please reload or log in again. If this problem persists please contact us.";
              this.showErrorMessage=true;
            }
        });

      },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/SaharaWellness.scss";
</style>