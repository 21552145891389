<template>
      
  <div >
    <div
      :style="{ 'margin-left': `${(depth-1) * 20}px` }"
      class="node"
      v-bind:class="{clicked: selectedKey==node.ID}"
      ref="groupTitle"
      v-if="depth>0"
    >
      <span  @click="expandClicked" v-if="hasChildren" class="type">
        {{ expanded ? "&#9660;" : "&#9658;" }}
      </span>
      <span v-else>
        &#9671;
        <!-- <button @click="edit">Edit</button> 
        <button>Delete</button>  -->
      </span>
      <span @click="nodeClicked">
      {{ node.Name || node.groupName }}
    </span>

      <font-awesome-icon 
      v-if="showAdd&&(selectedKey==node.ID||hasChildren)&&node.ID!=undefined && node.ID!=0"
                class="add-icon"
                style="color:black;"
                :icon="['fas', 'plus-circle']"
                @click="addGroup(node.ID)"
              />
    </div> 
    <!-- <button @click="execute">&#43;</button>   -->
    <TreeBrowser 
      v-show="expanded"
      v-for="child in node.children"
      :key="child.name"
      :node="child"
      :selectedKey="selectedKey"
      ref="childGroups"
      :depth="depth + 1"
      @onClick="(node) => $emit('onClick', node)"
      :showAdd="showAdd"
      :scrollToSelected="scrollToSelected"
      @addingGroup="addGroup"
      @update:selectedKey="$emit('update:selectedKey',$event)"
    />
  </div>
</template>

<script>
export default {
  name: "TreeBrowser",
  props: {
    node: Object,
    depth: {
      type: Number,
      default: 0,
    },
    callback: {
      type: Function
    },
    selectedKey: Number,
    showAdd:Boolean,
    scrollToSelected:Number,
  },
  data() {
    return {
      expanded: false,
      alreadyScrolled:false,
    };
  },
  created(){
    if(this.depth==0)//expand the first element which is the course list
    {
      this.expanded=true;
    }
  },
  watch: {
    scrollToSelected:function()
    {

      if(this.scrollToSelected!=null&&this.node.ID==this.scrollToSelected && this.$refs.groupTitle)
      {
          this.$refs.groupTitle.scrollIntoView({behavior:'smooth'});
      }
    },
   
    'node.children':function(newChildren,oldChildren)// when the children have changed we will expand and scroll into view so we can see any new group added for some reason the one above will not work
    {
      // if( newChildren.length>0 && this.scrollToSelected!=null&&oldChildren.length!=newChildren.length && newChildren.some((child)=>child.ID==this.scrollToSelected))
      if( this.scrollToSelected!=null&&oldChildren.length!=newChildren.length )
      {
        this.expanded=true;
        if(this.$refs.groupTitle)
        {
          this.$refs.groupTitle.scrollIntoView({behavior:'smooth'});
        }
      }
    },
  },
  methods: {
    // function will emit the node details onclick and do a check to see if it has children,
    // if it does have children it will trigger the node to expand its children. 
    nodeClicked() {
        this.$emit('onClick', this.node)
      if (!this.expanded) {//When it is clicked and is closed it will open
        this.expanded = !this.expanded;
      } 
      this.$emit('update:selectedKey',this.node.ID);
    },
    addGroup(groupID)
    {
      this.activateScroll=true;
      this.$emit('addingGroup',groupID)

    },
    expandClicked(){
      this.expanded=!this.expanded;
    },
    execute() {
      this.callback()
    }
  },
  computed: {
    isSelected (){
      return this.node.ID ===this.selectedKey;
    },
    hasChildren() {
      return this.node.children.length > 0; // this throws an error when page is refreshed.
      // this is likely due to data not being on the page before this computed property is checked
      // remove the .length > 0, load the page, put .length > 0 back in.
    },
  },
};
</script>

<style scoped>
.node {
  text-align: left;
}
.type{
  /* color:green; */
  color:black;
  font-family: Arial, Helvetica, sans-serif;
}
.clicked{
  background-color: rgba(251, 143, 6, 0.7);
}
</style>
