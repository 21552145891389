<template>
  <div class="force-logout">
    <div class="current-logins">
      <span class="current-logins-title"><strong>Online Users:&nbsp;&nbsp;</strong><button @click="getCurrentLogins"><span v-if="!loading">Refresh</span><span  v-if="loading"> <Spinner2 style="height:25px;" class="center-card-data-spinner" /></span></button><br /></span>
    </div>
    <table class="online-users">
      <thead>
        <tr>
          <th>Name</th>
          <th>User ID</th>
          <th>Select</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="online in this.currentlyOnline" :key="online.loginID">
          <td>
            {{ online.fullName }}
          </td>
          <td>
            {{ online.loginID }}
          </td>
          <td>
            <input
              type="checkbox"
              @change="selectRowID(online.loginID)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <button @click="forceUserLogout">Logout selected user</button><br />
  </div>
</template>

<script>
import Spinner2 from "../components/Spinner2.vue";


export default {
  name: "ForceLogout",
components:{
    Spinner2
},
  data() {
    return {
      currentlyOnline: [],
      selectedUsers: [],
      loading:false,
    };
  },
  created() {
    this.getCurrentLogins();
  },
  methods: {
    selectRowID(userID) {
      const index = this.selectedUsers.indexOf(userID) // finds the index of passed userID
      if (index > -1) { // if it is -1 then it doesnt exist in the array
        this.selectedUsers.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        this.selectedUsers.push(userID);
      }
    },
    getCurrentLogins() {
      this.loading=true;
      this.$store.dispatch("getCurrentLogins").then((data) => {
        this.currentlyOnline = data;
        this.loading=false;
      });
    },
    // uses API 1253 w/ list of user ids //
    forceUserLogout() {
      let newStr = this.selectedUsers.toString();
      let userIDs = newStr.replace(/,/g, ';');
      this.$store.dispatch("forceUserLogout", userIDs);
      this.currentlyOnline=[];
      this.getCurrentLogins();
    },
  },
};
</script>

<style scoped>
  @import "./styles/ForceLogout.scss";
</style>