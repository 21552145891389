<template>
  <div class="modal-backdrop">
    <div class="modal modal--courses-modal">
      <header class="modal-header">
        <h2>Course Selection</h2>
      </header>
      <div class="search-area">
      <input
        class="modal-search-bar"
        type="text"
        placeholder="Search for Course"
        style="margin-left:0.4em; margin-bottom: 0;"

        v-model="searchForCourse"
      />
      <button type="button" class="btn-reset" @click="resetBtn">
          Reset 
        </button>
      </div>
      <body class="modal-body">
        <div class="modal-selected-filters">
          <div
            class="modal-pill"
            v-for="course in coursesSelectedList"
            :key="course.courseID"
          >
            <Pill :filterName="course.courseName" @removeFilter="userCoursesExist?course.registered=false:removeFilter(course.courseName)" />
          </div>
        </div>

        <div class="spinner" v-if="loading">
          <Spinner />
        </div>

        <form class="modal-filter-options">
          <span
            class="courseContent"
            v-for="(course, index) in filteredCourses"
            :key="index"
          >
            <input
            v-if="userCoursesExist"
              class="checkbox"
              type="checkbox"
              v-model="course.registered"
              :value="course"
            />
            <input
            v-else
              class="checkbox"
              type="checkbox"
              v-model="coursesSelected"
              :value="course"
            />
            <label class="content-name" @click="userCoursesExist?course.registered=!course.registered:courseClicked(course)" for="course.courseName"  >
              {{ course.courseName }}
            </label>
          </span>
        </form>
      </body>
      <footer class="modal-footer">


        <button
          type="button"
          class="btn-close"
          @click="
            clear();
            reset();
            close();
          "
        >
          Cancel
        </button>
        <button type="button" class="btn-apply" @click="apply">
          Apply
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pill from "../AppliedFilterPill.vue";
import Spinner from "../Spinner.vue";

export default {
  name: "CoursesModal",
  components: {
    Pill,
    Spinner,
  },
  props:['selectedGroupID'],
  created() {
    this.getCourseList();
  },
  watch:{
    selectedGroupID: function()
    {
      if(this.selectedGroupID!=null) 
      {
        this.getCourseList(this.selectedGroupID);
      }
    },
  },
  data() {
    return {
      coursesSelected: [],
      searchForCourse: "",
      loading: false,
      userCourses:[],
      userID:'',
      origionalUserCourses:[],
    };
  },
  computed: {
    ...mapState({
      courses: (state) => state.courseNameAndID,
    }),
    filteredCourses: function () {
      let courseNames = Object.values(this.courses);
      if(this.userCoursesExist)
      {
        courseNames=this.userCourses;
      }
      return courseNames.filter((course) => {
        return course.courseName
          .toLowerCase()
          .match(this.searchForCourse.toLowerCase());
      });
      // return this.courses.filter((course) => {
      //   return course.contentName
      //     .toLowerCase()
      //     .match(this.searchForCourse.toLowerCase());
      // });
    },
    userCoursesExist()
    {
      if(this.userCourses.length>0)
      {
        return true;
      }
      else{
        return false;
      }
    },

    userCoursesSelected()
    {
      if(this.userCoursesExist)
      {
        return this.userCourses.filter((course)=>course.registered==true);
      }
      return [];
    },
    coursesSelectedList()
    {
      if(this.userCoursesExist)
      {
        return this.userCoursesSelected;
      }
      else{
        return this.coursesSelected;
      }
    }
  },

  methods: {
    resetBtn(){
      this.loading=true;
      if(this.userCoursesExist)
      {
        this.userCourses=JSON.parse(JSON.stringify(this.origionalUserCourses));//We need to do this so they do not copy references to the data because it will update both and not reset
      }
      else{
        this.coursesSelected=[];
      }
      this.loading=false;
    },
    close() {
      this.userID='';
      this.$emit("close", this.coursesSelected);
      this.$emit("resetTable", []);
    },
    clear() {
      this.searchForCourse = "";
    },
    apply() {
      this.userCoursesExist?this.$emit("apply",this.userCourses):this.$emit("apply", this.coursesSelected);
    },
    // create a button that calls checkState to see course data
    reset() {
      this.coursesSelected = [];
      this.searchForCourse = "";
    },

    getCoursesForUser(userLoginID,userGroupID)
    {
      if(userLoginID!=this.userID || this.userCourses.length==0)//Do this so we can 
      {
        this.loading=true;
        this.userCourses=[]; 
        let userCourseObj={
            loginID:userLoginID,
            groupID:userGroupID
        }
        this.$store.dispatch('getContentForSeating',userCourseObj).then((userCourse)=>{
          this.origionalUserCourses=JSON.parse(JSON.stringify(userCourse))//Used to keep a record of the origional that we can use to reset the list if needed
          this.userCourses=userCourse;
          this.loading=false;
          this.userID=userLoginID;
            // this.courseList=courses; 
            // this.coursesLoading=false;
        });
      }

    },

    courseClicked(course)
    {
      if(this.coursesSelected.find((courseSel)=>courseSel.courseID==course.courseID))
      {
        this.coursesSelected.splice(this.coursesSelected.indexOf(course),1)
      }
      else{
        this.coursesSelected.push(course);
      }
    },
    //fetch the data upon page creation.
    async getCourseList(groupID=null) {
      this.userCourses=[];
      this.userID='';
      this.loading = true; //loading is used for the spinner
      //A groupID can be provided to this user
      await this.$store.dispatch("getCourseList",groupID).then((res) => {
        return res;
      });

      this.loading = false;
    },
    removeFilter: function (courseName) {
      this.coursesSelected = this.coursesSelected.filter((name) => {
        return courseName !== name.courseName;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/Courses.scss";
</style>
