<template>
  <div class="reports-container">
    <div id="side-menu"><SideMenu></SideMenu></div>
    <div id="navbar-container">
    <Navbar>Reports</Navbar>

  <div id="reports">
    <header class="header">
      <span class="title">
        <font-awesome-icon class="title-icon" :icon="['fas', 'scroll']" />
        All Course reports
      </span>
      <!-- <span class="title">
        <font-awesome-icon class="title-icon" :icon="['fas', 'scroll']" />
        SWIFT Reports
      </span> -->
    </header>

    <!-- <Tabs class="tabs"> -->
      <!-- <Tab name="Mastery" selected="true" class="mastery"> -->
        <MasteryTableV2/>
      <!-- </Tab> -->
      <!-- <Tab name="Course Post-tests" class="Post-tests">
        <CoursePostTestScores />
      </Tab>
      <Tab name="Course Usage" class="course-usage">
        <CourseUsage />  
      </Tab> -->
    <!-- </Tabs> -->
  </div>
</div>
</div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.4/lodash.min.js"></script>

<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import Tabs from "../components/Tabs";
import Tab from "../components/Tab";
// import MasteryTable from "../components/MasteryTable";
import MasteryTableV2 from "../components/MasteryTableV2";
import CourseUsage from "../components/CourseUsage.vue"
import CoursePostTestScores from "../components/CoursePostTestScores.vue"
// import { debounce } from "../util/helper";

export default {
  name: "Reports",
  components: {
    Navbar,
    SideMenu,
    Tabs,
    Tab,
    // MasteryTable,
    MasteryTableV2,
    CourseUsage,
    CoursePostTestScores
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/Reports.scss";

</style>
