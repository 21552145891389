<template>
  <div class="second">
    <h4>User Type and Group Selection</h4>

    <div class="select">
      <div class="select-user-type">
        <h5>User Type</h5>
        <select v-model="userType" class="select-user-type-dropdown">
          <option selected value="Learner">Learner</option>
          <option value="Primary Admin">Primary Admin</option>
          <option value="Secondary Admin">Secondary Admin</option>
        </select>
        information about user type selected will appear here.
      </div>

      <div class="select-group">
        <h5>Group Selection</h5>
        Add {{ this.$store.state.firstName }}
        {{ this.$store.state.lastName }} to a Group from the list below
        <div class="select-group-tree"></div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "SecondStep",
  components: {},
  computed: {
    userType: {
      get() {
        return this.$store.state.userType;
      },
      set(value) {
        this.$store.commit("setUserType", value);
      },
    },
  },
};
</script>
<style scoped>
@import './msFormStyles/msForm.scss';
</style>