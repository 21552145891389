<template>
  <div class="modal-backdrop">
    <div class="modal modal--groups-modal">
      <header class="modal-header">
        <h2>Environment Selection</h2>
      </header>

      <input
        class="modal-search-bar"
        type="text"
        placeholder="Search for Environment"
        v-model="searchForEnvironment"
      />

      <body class="modal-body">
        <div class="modal-selected-filters">
          <div class="modal-pill" v-for="environment in environmentsSelected" :key="environment.environmentID">
            <Pill :filterName="environment.environmentName" @removeFilter="removeFilter(environment)" />
          </div>
        </div>

        <div class="spinner" v-if="loading">
          <Spinner />
        </div>

        <form class="modal-filter-options">
          <span
            class="groupContent"
            v-for="(environment, environmentID) in filteredEnvironments"
            :key="environmentID"
          >
            <input
              class="checkbox"
              type="checkbox"
              v-model="environmentsSelected"
              :value="environment"
            />
            <span @click="clickEnvName(environment)">{{ environment.environmentName }}</span>
          </span>
          <!-- <button @click="checkState()">get state</button> -->
        </form>
      </body>

      <footer class="modal-footer">

        <button
          type="button"
          class="btn-close"
          @click="
            close();
            reset();
            clear();
          "
        >
          Cancel
        </button>
        <button type="button" class="btn-apply" @click="apply">
          Apply
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pill from "../AppliedFilterPill.vue";
import Spinner from "../Spinner.vue";

export default {
  name: "EnvironmentsModal",
  components: {
    Pill,
    Spinner,
  },
  created() {
    if(this.environments.length==0)
    {
      this.getEnvironmentList();
    }
    else{
      this.loading=false;
    }
    // this.getGroupList();
  },
  data() {
    return {
      environmentsSelected: [],
      searchForEnvironment: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      environments: (state) => state.environments,
    }),

    filteredEnvironments: function () {
      if(!this.environments)
      {
        return [];
      }
      const values = Object.values(this.environments);
      return values.filter((environment) => {
        return environment.environmentName.toLowerCase().match(this.searchForEnvironment.toLowerCase());
      });
    },
  },
  methods: {
    clickEnvName(environment)
    {
      let index=this.environmentsSelected.indexOf(environment);

      if(index!=-1)
      {
        this.environmentsSelected.splice(index,1);
      }else{
        this.environmentsSelected.push(environment);
      }
      // typeof environment.selected!='undefined'?environment.selected=!environment.selected:environment.selected=true
    },
    close() {
      this.$emit("close");
      this.$emit("resetTable", [])
    },
    clear() {
      this.searchForEnvironment = "";
    },
    apply() {
      this.$emit("apply", this.environmentsSelected);
    },
    checkState() {
    },

    reset() {
      this.environmentsSelected = [];
      this.searchForEnvironment = "";
    },
    async getEnvironmentList() {
      this.loading = true;
      let environmentObject={
        returnOption:"0",
        groupIDs:""
      }
      await this.$store.dispatch("getSaharaEnvironments",environmentObject).then((res) => {
        return res;
      });
      this.loading = false;
    },
    removeFilter: function (environmentName) {
      this.environmentsSelected = this.environmentsSelected.filter((name) => {
        return environmentName !== name;
      });
    },

    // findUndefined() {
    //   var i = 0;
    //   for(const group of this.groups) {
    //     if (!group.groupName) {
    //     }
    //     i++;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/Groups.scss";
</style>
