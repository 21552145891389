<template>
  <div class="modal-backdrop">
    <div class="modal modal--groups-modal">
      <header class="modal-header">
        <h2>{{errorTitle}}</h2>
      </header>

      <body class="modal-body">
        <div class="error-body">
        {{errorBody}}
        </div>
      </body>

      <footer class="modal-footer">
        <!-- <button type="button" class="btn-apply" @click="apply">
          Apply Selection
        </button> -->
        <button
          type="button"
          class="btn-apply"
          @click="close();">
          Ok
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";

export default {
  name: "ErrorMessageModal",
  props:['errorTitle','errorBody'],
  components: {
  },

  mounted(){
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("resetTable", [])
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/ErrorMessage.scss";
</style>
