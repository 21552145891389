<template>
  <div class="LearnerEngagement">
    <div v-if="loading" class="LearnerEngagement-spinner">
      <Spinner />
    </div>
    <LineChart
      v-if="!loading"
      class="LearnerEngagement-chart"
      :height="height"
      :options="chartOptions"
      :render="renderOptions"
    ></LineChart>
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";
import LineChart from "./LineChart";

export default {
  name: "LearnerEngagement",
  components: {
    Spinner,
    LineChart,
  },
  data() {
    return {
      loading: true,
      height: 160,
      loginHistory: {},
      averageSessions: {},
      renderOptions: {
        labels: [],
        datasets: [
          {
            label: "Hours of activity per learner each day",
            borderColor: "rgb(159, 55, 69)",
            tension: 0.1,
            fill: false,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Averege Session Duration",
          fontSize: 18,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 0.5,
                beginAtZero: true,
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Hours",
                fontColor: "#000000",
                fontSize: 18,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Date",
                fontColor: "#000000",
                fontSize: 18,
              },
            },
          ],
        },
      },
    };
  },

  created() {
    /**
     * Line chart has a problem where css styling will not change the size of the chart
     * Requires to pass width and height of chart as props
     * Solution is to add event listener to the window size and change chart size dynamically
     */
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetchHistoryLogins();
  },

  destroyed() {
    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    /**
     * Handles the resizing of the chart
     */
    handleResize() {
      // this.loading = true;
      // if (window.innerWidth < 1079) {
      //   this.height = 250;
      //   // this.height = 260;
      // }
      // if (window.innerWidth >= 1080) {
      //   // this.height = 160;
      //   this.height = 30;
      // }
      // // this.fetchHistoryLogins();
    },

    // Fetches all logins from a specific period of time.
    // Please reference Store.js to see the time period.
    fetchHistoryLogins() {
      this.$store
        .dispatch("getHistoryLogins", 5)
        .then((data) => {
          // Sort data by learner and date
          this.sortByDateAndLearner(data);
        })
        .catch((error) => {
          alert(error);
          console.log('Error Fetching History Logins in LearnerEngagement',error);
        });
    },

    // Sorts the login history by date
    // Only used when period id is 5 or 6
    sortByDateAndLearner(data) {
      this.loginHistory = {};
      for (const obj of data) {
        // Check if the date has been recorded into loginHistory
        if (Object.keys(this.loginHistory).includes(obj.timeStarted)) {
          // Check if learner is in object
          if (
            Object.keys(this.loginHistory[obj.timeStarted]).includes(
              obj.fullName
            )
          ) {
            this.loginHistory[obj.timeStarted][obj.fullName] += parseInt(
              obj.sessionDuration,
              10
            );
          } else {
            // Learner is not in object, create object for learner
            this.loginHistory[obj.timeStarted][obj.fullName] = parseInt(
              obj.sessionDuration,
              10
            );
          }
        } else {
          // Date is not in loginHistory, create key-value pair
          this.loginHistory[obj.timeStarted] = {
            [obj.fullName]: parseInt(obj.sessionDuration, 10),
          };
        }
      }
      // console.log(this.loginHistory);
      this.calculateAverageSession();
      this.fillRenderOptions();
      this.loading = false;
    },

    // Calculate the average sessionDuration per day
    calculateAverageSession() {
      for (const date in this.loginHistory) {
        let sum = 0;
        const day = this.loginHistory[date];
        for (const learner in day) {
          sum += day[learner];
        }
        const numOfLearners = Object.keys(day).length;
        const avgSessionsDuration = sum / numOfLearners;
        this.averageSessions[date] = avgSessionsDuration / 3600;
      }
      // console.log(this.averageSessions);
    },

    // Fill render options to pass as prop to line chart
    fillRenderOptions() {
      // Must reset all labels and data
      // If not reset, duplicate labels and data will appear on window resize
      this.renderOptions.labels = [];
      this.renderOptions.datasets[0].data = [];

      // Push data into render options
      for (const [key, value] of Object.entries(this.averageSessions)) {
        this.renderOptions.labels.push(key);
        this.renderOptions.datasets[0].data.push(value);
      }

      // Data is pushed in reversed, so needs to be reversed again
      this.renderOptions.labels.reverse();
      this.renderOptions.datasets[0].data.reverse();

      // console.log(this.renderOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/LearnerEngagement.scss";
</style>
