<template>
    <div class="hazards-container">
      <div class="sidemenu">
        <SideMenu />
      </div>
  <div id="navbar-container">
  <Navbar />
    <div id="sahara-hazards">
      
      <div class="da-header">
        <!-- Used to make the title appear in the centers -->
        <!-- <div class="left-title"> </div>  -->
  
        <!-- <div class="sahara-image">
        <img style="height:50px;" src="../assets/sahara_icon_normal.png" >
        <img style="height:85px; padding-right: 40px;" src="../assets/Sahara Logo Horizontal.png" >
      </div> -->
      </div>
      <div class="title">
          <font-awesome-icon class="title-icon" :icon="['fas', 'user']" />
          <!-- <img class="title-icon" src="../assets/wellness.png" > -->
          Individual Wellness Report
      </div>
          <div class="content">
          <!-- <div class="fixed-wrapper">
            <div class="reports-filter">
              <h4>Filter By</h4>
              <div class="filter-modals">
                <button class="filter-button" @click="showEnvironmentsModal">
                  Environments
                </button>
  
                <button class="filter-button" @click="showGroupsModal">
                  Groups
                </button>
              </div>
              <div class="date-picker">
                <DatePicker 
                  @updatedDate="setStartDate"
                  :maxDate="maxStartDate"
                  :blockResetMessage="true"
                  ref="resetStartDate"
                > 
                  Starting
                </DatePicker>
  
                <DatePicker 
                  @updatedDate="setEndDate"
                  :maxDate="maxDate"
                  :blockResetMessage="true"
                  ref="resetEndDate"
                > 
                  Ending 
                </DatePicker>
              </div>
  
              <div class="table-options">
                <button class="reset-button" @click="resetTable">
                  Reset Table
                </button>
              </div>
            </div>
          </div> -->
            <div class="learners-table">
              <div class="filters">
                <div class="search-bar">
                <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
  
                  <input
                  type="search"
                  placeholder="Search for User"
                  class="search-input"
                  v-model="userSearch"
                  v-on:input="searchUser"
                  id='searchRecords'
                />
                  </div>
              </div>
              <div class="table">
                <table>
                  <thead>
                    <tr>
                      <!-- <th colspan="3">Sessions</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <!-- <th class="sessions-header">Learner</th>
                      <th class="sessions-header">Date</th>
                      <th class="sessions-header">Risk Level</th> -->
                    </tr>
                      <tr v-for="record in currentRecords" :key="record.SaharaSessionID" @click="getrecordWellness(record)" :class="{'highlight': (record.SaharaSessionID == selectedSession)}">
                        <td class="record-names">{{record.firstName}} {{record.lastName}}</td>
                        <td class="record-dates">{{ removeTime(record.endTime)}}</td>
                        <!-- <td>{{record.riskScore}}</td> -->
                        <td v-bind:class="getClass(record.riskLevel)" class="risk-level">{{record.riskLevel}}</td>
                      </tr>
                  </tbody>
                </table>
                <div v-if="recordsLoading" class="loading-spinner"><Spinner/></div>
              </div>
          <div class="table-controls">
  
          <div id="fetch-options">
              <label for="fetchSelect" >Results: </label>
              <select name="fetchSelect" @change="changeFetch()" v-model="fetch" id="fetchOption">
                <option value=10>10</option>
                <option value=20>20</option>
                <option value=30>30</option>
                <option value=40>40</option>
                <option value=50>50</option>
                <option value=100>100</option>
              </select>
            </div>
            <div class="pagination">
              <button :disabled="backDisabled" @click="decreaseTablePage" class="table-btn" v-bind:style="[backDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Back</button>
              {{currentTablePage}}
              <button :disabled="forwardDisabled" @click="increaseTablePage" class="table-btn" v-bind:style="[forwardDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Next</button>
          </div>
          </div>
            </div>
            <div class="hazards-table">
              <div class="environment-header"><span>Environment:</span> {{ environment }}</div>
              <div class="table">
                <table>
                  <thead>
                    <tr>
                    <th colspan="" class="environment-header" ><span >Question</span> </th>
                    <th colspan="" class="environment-header" ><span >Response</span></th>
                    <th colspan="" class="environment-header" ><span >Red Flag</span></th>
                    </tr>
                  </thead>
                  <tbody v-for="wellnessResponse in recordWellness"  :key="wellnessResponse.question">
                      <tr >
                        <td>{{wellnessResponse.question}}</td>
                        <td>{{wellnessResponse.questionResponse}}</td>
                        <td><span v-if="wellnessResponse.redFlag">&#10004;</span></td>
                      </tr>
                  </tbody>
                  <td colspan="5" v-if="!resultsFound && !wellnessLoading" class="none-selected">No responses found</td>
                </table>
                  <div v-if="wellnessLoading" class="loading-spinner"><Spinner/></div>
              </div>
  
            </div>
            <div class="course-table-container">
              <div class="filters">
                <div class="search-bar">
                <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
                  <input
                  type="search"
                  placeholder="Search for Course"
                  class="search-input"
                  v-model="courseSearch"
                  id='searchRecords'
                />
                </div>
              </div>
              <div class="table">
                <table>
                  <thead>
                    <tr>
                    <th >Course</th>
                      <th >Mastered</th>
                    </tr>
                  </thead>
                  <tbody >
                  <!-- <tbody v-if="filteredCourse"> -->
  
                      <tr>
                      <th colspan="2" v-if="filteredCourse.personalCourses && filteredCourse.personalCourses.length!=0" class="sub-header">Personal Hazard Courses</th>
                      </tr>
                      <tr  v-for="course in filteredCourse.personalCourses" :key="course.courseName">
                        <td>{{course.courseName}}</td>
                        <td>{{course.mastered}}</td>
                      </tr>
                      <tr>
                      <th colspan="2" v-if="filteredCourse.physical && filteredCourse.physical.length!=0" class="sub-header">Physical Hazard Courses</th>
                      </tr>
                      <tr v-for="course in filteredCourse.physical" :key="course.courseName">
                        <td>{{course.courseName}}</td>
                        <td>{{course.mastered}}</td>
                      </tr>
                  </tbody>
                  <!-- <td colspan="2" class="none-selected">No courses recommended</td> -->
                  <td colspan="2" v-if="!coursesFound && !wellnessLoading" class="none-selected">No courses recommended</td>
                </table>
                <div v-if="wellnessLoading" class="loading-spinner"><Spinner/></div>
              </div>
            </div>
          </div>
        <GroupsModal 
        v-show="groupsModalVisible"
        @close="closeModals"
        @apply="setGroups"
        @resetTable="setGroups"
        ref="resetGroups"
      />
        <EnvironmentsModal 
        v-show="environmentsModalVisible"
        @close="closeModals"
        @apply="setEnvironments"
        @resetTable="setEnvironments"
        ref="resetEnvironments"
      />
  
      <ErrorMessageModal 
          v-show="showErrorMessage"
          :errorTitle="errorMsgTitle"
          :errorBody="errorMsgBody"
          @close="closeErrorModal"
          />
    </div>
  </div>
  </div>
  </template>
  <script>
  import Navbar from "../components/Navbar.vue";
  import SideMenu from "../components/SideMenu.vue";
  import Tab from "../components/Tab.vue";
  import Tabs from "../components/Tabs.vue";
  // import Spinner2 from "../components/Spinner2.vue";
  import TreeBrowser from "../components/TreeBrowser.vue";
  // import Tree from "../components/Tree.vue";
  import Spinner from "../components/Spinner.vue";
  import DatePicker from "../components/DatePicker.vue";
  import GroupsModal from "../components/modals/GroupsModal.vue";
  import EnvironmentsModal from "../components/modals/Environments.vue";
  import ErrorMessageModal from "../components/modals/ErrorMessage.vue";
  
  export default {
    name: "SAHARA_Individual_Wellness",
    components: {
      Navbar,
      SideMenu,
      Tab,
      Tabs,
      Spinner,
      // Tree,
      TreeBrowser,
      DatePicker,
      GroupsModal,
      EnvironmentsModal,
      ErrorMessageModal,
    },
      data() {
      return {
        currentRecords:[],
        fetch: 20, 
        offset: 0,
        recordWellness:[],
        customHazards:[],
        resultsFound:true,
        coursesFound:true,
        selectedSession:null,
        mitigationWidth: null,
        statusWidth:null,
        masteredWidth:null,
        wellnessLoading:false,
        environment:'',
        currentTablePage:1,
        userSearch:'',
        courseSearch:'',
        previousFetch:20,
        recordsLoading:true,
        showNoRecord:false,
        saharaEnvironments:[],
        saharaIndustries:[],
        startDate:null,
        endDate:null,
        groupsModalVisible:false,
        environmentsModalVisible:false,
        groupsSelected:{},
        environmentsSelected:"",
        showErrorMessage:false,
        errorMsgTitle:"",
        errorMsgBody:"",
        coursesRecommended:{},
      };
    },
    created () {
      this.getRecordList();
      // this.getSaharaEnvironments();
      // window.addEventListener('resize',this.resizeHandler);
    },
    // destroyed(){
    //   window.removeEventListener('resize',this.resizeHandler)
    // },
    filters:{
      removeTime: function(value)
      {
        let beforeVal=value.split(" ");
        return beforeVal[0];
      },
      removeSeconds: function(secondsVal)
      {
        return secondsVal.slice(0,-3);//? remove the last 3 charters from the end of the string to get rid of the seconds
      },
  
    },
  
    mounted(){
    },
  
    computed:{
      filteredCourse(){
        var findingCourse={};
        if(this.coursesRecommended.personalCourses)
        {
          findingCourse.personalCourses= this.coursesRecommended.personalCourses.filter((personal)=>{
            return personal.courseName.toLowerCase().match(this.courseSearch)
          })
        }
        if(this.coursesRecommended.physical)
        {
          findingCourse.physical=this.coursesRecommended.physical.filter((physical)=>{
            return physical.courseName.toLowerCase().match(this.courseSearch)
          })
        }
        return findingCourse;
      },
  
      maxStartDate(){
        if(this.endDate==''||this.endDate==null)
        {
          return this.maxDate;
        }
        else{
          return this.endDate;
        }
      },
      groupIDs(){
              return Object.keys(this.groupsSelected).join(",");
      },
      maxDate(){
        return new Date().toISOString().split('T')[0];
      },
      backDisabled(){
        if(this.currentTablePage<=1 || this.currentRecords==[])
        {
          return true;
        }
        return false;
      },
      forwardDisabled()
      {
         if( this.currentRecords.length<this.fetch || this.currentRecords==[])
         {
           return true;
         }
        return false;
      },
    },
  
    methods: {
      closeErrorModal()
      {
        this.showErrorMessage=false;
        this.errorMsgBody="";
        this.errorMsgTitle="";
      },
     decodeCustom(customName)
      {
        return decodeURIComponent(customName);
      },
      removeTime(value)
      {
        let beforeVal=value.split(" ");
        return beforeVal[0];
      },
      removeSeconds(secondsVal)
      {
        return secondsVal.slice(0,-3);//? remove the last 3 charters from the end of the string to get rid of the seconds
      },
      closeModals(){
        this.groupsModalVisible=false;
        this.environmentsModalVisible=false;
      },
      setGroups(groups)
      {
        this.groupsSelected=groups;
        this.groupsModalVisible=false;
        this.currentTablePage=1;
        this.getRecordList();
      },
      setEnvironments(environments){
        this.environmentsModalVisible=false;
        this.environmentsSelected="";
        for(var e in environments)
        {
          this.environmentsSelected+="'"+environments[e].environmentID+"',";
        }
        this.environmentsSelected=this.environmentsSelected.slice(0,-1);
        this.getRecordList();
        },
      getSaharaEnvironments(){
        let environmentObj={
          returnOption:"0",
          groupIDs:this.groupIDs
        }
        this.$store.dispatch("getSaharaEnvironments", environmentObj)
        .then((result) => {
  
          if(result.status==='success') 
          {
            this.saharaEnvironments=result.results.environments;
            this.saharaIndustries=result.results.industries;
          }
          else{
            this.errorMsgTitle="Environment Error";
            this.errorMsgBody="Unable to find SAHARA environments. Please reload the page or log in again. If this problem persists please contact us.";
            this.showErrorMessage=true;
          }
        });
      },
      setStartDate(date) {
        this.startDate=date;
        this.currentTablePage=1;
        this.getRecordList();
      },
      setEndDate(date) {
        this.endDate=date;
        if(this.startDate && this.startDate>date)
        {
          this.startDate=date;
        }
        this.currentTablePage=1;
        this.getRecordList();
      },
      showCoursesModal() {
        // this.coursesModalVisible = true;
      },
      showGroupsModal() {
        this.groupsModalVisible = true;
      },
      showEnvironmentsModal() {
        this.environmentsModalVisible = true;
      },
      resetTable() {
        this.groupsSelected={};
        this.startDate=null;
        this.endDate=null;
        this.environmentsSelected="";
        // this.returnStatus = 0;
        // this.searchedName = "";
        // this.courseIDArr = [];
        // this.groupsIDArr = [];
        // this.$refs.resetCourses.reset();
        this.$refs.resetGroups.reset();
        this.$refs.resetEnvironments.reset();
        // this.dates.startTime = "";
        // this.dates.endTime = "";
        this.$refs.resetStartDate.resetDate();
        this.$refs.resetEndDate.resetDate();
        // this.tablePage = 1;
        // this.fetchAmount = 10;
        // this.getMasteryStatusReport(this.tablePage);
        this.currentTablePage=1;
        this.getRecordList();
      },
  
      getClass(dat)
      {
        if(dat==="LOW")
        {
          return 'low-class';
        }
        if(dat==='MEDIUM')
        {
          return 'medium-class'
        }
        if(dat==='HIGH')
        {
          return 'high-class'
        }
      },
  
      // resizeHandler()//? Get the columns to resize when the page is resized or brought to a larger screen
      // {
      //   // this.calculateWidth();
      // },
  
      searchUser()
      {
        this.currentTablePage=1;
        this.getRecordList();
      },
  
      changeFetch()
      {
        // this.currentTablePage=1;
        this.getRecordList();
        this.previousFetch=this.fetch;
      },
  
      increaseTablePage(){
        this.currentTablePage++;
        this.getRecordList();
      },
  
      decreaseTablePage(){
        if(this.currentTablePage>1)
        {
          this.currentTablePage--;
          this.getRecordList();
        }
      },
      
      getRecordList()
      {
        this.recordsLoading=true;
        this.showNoRecord=false;
        this.currentRecords=[];
        let recordListObj={
          groupIDs:this.groupIDs,
          industry:"",
          environments:this.environmentsSelected,
          userName:this.userSearch,
          startTime: this.startDate?this.startDate+" 00:00:00.000":"",//Make it so it would be the start of the day
          endTime: this.endDate? this.endDate+" 23:59:59.000":"",//Make it so it would be the end of the day 
          fetch:this.fetch.toString(),
          offset:((this.currentTablePage-1)*this.fetch).toString(),
        }
        if(this.previousFetch!=this.fetch)
        {
          this.currentTablePage=(Math.floor(((this.currentTablePage-1)*this.previousFetch)/this.fetch)+1);
          recordListObj.offset=((this.currentTablePage-1)*this.fetch).toString()
        }
        this.$store.dispatch("getSaharaRecords", recordListObj)
        .then((result) => {
          if(result.status==='success') 
          {
            console.log('The records are',result.results)
            this.currentRecords=result.results;
            this.recordsLoading=false;
            if(result.results && result.results.length==0)
            {
              this.showNoRecord=true;
            }
            else{
              this.showNoRecord=false;
            }
          }
          else{
            this.errorMsgTitle="Sessions Error";
            this.errorMsgBody="Unable to find SAHARA sessions. Please reload the page or log in again. If this problem persists please contact us.";
            this.showErrorMessage=true;
          }
        });
          // this.changeCellBackground();//Change the background colour of the results to match what it is in the app
  
      },
      // calculateWidth()
      // {
      //   this.wellnessLoading=true;
      //     setTimeout(()=>{
      //       // this.mitigationWidth=this.$refs.mitigation.clientWidth;
      //       // this.statusWidth=this.$refs.status.clientWidth;
      //       // this.masteredWidth=this.$refs.mastered.clientWidth;
      //       this.wellnessLoading=false;
      //     },5)
      // },
      getrecordWellness(record)
      {
        this.environment=record.environmentName;
        this.wellnessLoading=true;
        this.resultsFound=true;
        this.coursesFound=true;
        this.selectedSession=record.SaharaSessionID
        this.recordWellness=[];
        this.coursesRecommended={};
      this.$store.dispatch("getRecordWellness", record.SaharaSessionID)
        .then((result) => {

            if(typeof(result)=='string')
            {
              this.wellnessLoading=false;
              //! show some kind of error message with the message passed back
              return;
            }
            console.log('the specific results with the wellness are',result)
            if(result.wellnessResponses && result.wellnessResponses.length!=0)
            {
              this.recordWellness=result.wellnessResponses;
              this.resultsFound=true;
            }
            else{
              this.resultsFound=false;
            }

            if(result.recommendedCourses && (result.recommendedCourses.personalCourses || result.recommendedCourses.physical))
            {
              this.coursesRecommended=result.recommendedCourses;
            }
            else{
              //? possibly have it load a message here that will say that no courses have been recommended  
              this.coursesRecommended={};
              this.coursesFound=false;         
            }
  
            this.wellnessLoading=false;
            // this.calculateWidth();
        });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/SaharaIndividualWellness.scss";
  </style>