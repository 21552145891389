<template>
  <div class="mastery-status">
    <div class="content">
      <div class="mastery-table"  v-bind:style="[hideFilterOptions ? {'width':'100%'}:{}]" >
        <div class="content-header" v-if="!hideFilterOptions">
          <div class="content-header-table-search">
            <div class="search-bar">
            <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
            <input
              class="search-input"
              v-model="searchedName"
              type="search"
              placeholder="Search for Learner"
            />
            </div>
          </div>
          <div class="table-controls" v-if="!hideFilterOptions">
            <div id="fetch-options">
            <label for="fetchSelect" >Results: </label>
            <select name="fetchSelect" @change="togglePerPage" v-model="fetchAmount" id="fetchOption">
              <option>10</option>
                <option>25</option>
                <option>50</option>
                <option value="1000">All</option>
            </select>
          </div>
          <div class="pagination">
            <button :disabled="backDisabled" @click="decreaseTablePage" class="table-btn" v-bind:style="[backDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Back</button>
            {{tablePage}}
            <button :disabled="forwardDisabled" @click="increaseTablePage" class="table-btn" v-bind:style="[forwardDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Next</button>
        </div>
        </div>
        </div>


        <div class="content-header-sm"  v-if="hideFilterOptions">
          <div class="content-header-sm-table-search">
            <div class="search-bar">
            <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
            <input
              class="search-input"
              v-model="searchedName"
              type="search"
              placeholder="Search for Learner"
            />
            </div>
            <div class="status-dropdown" style="width:60%;" v-if="!hideCourseScores">
               <label for="statusDrop" style="padding-right:15px;" >Status</label>
                        <select name="statusDrop" @change="changeStatus()" v-model="returnStatus" id="fetchOption">
                          <option value=1>Mastered</option>
                          <option value=2>Started</option>
                          <option value=0>All</option>
                        </select>
              </div>
          </div>
          <div v-if="hideCourseScores">
            <div class="status-dropdown-groups"  v-if="hideCourseScores">
              <div>
               <label for="statusDrop"  >Status: </label>
                        <select name="statusDrop" @change="changeStatus()" v-model="returnStatus" id="fetchOption">
                          <option value=1>Mastered</option>
                          <option value=2>Started</option>
                          <option value=0>All</option>
                        </select>
                        </div>
                        &nbsp;
                        <button class="filter-button" style="width:auto;" @click="showGroupsModal">
                          Groups
                        </button>
                        
              </div>
          </div>
          
        </div>

        <!-- <div class="table" v-bind:style="[hideFilterOptions ? {'height':'65vh','margin-bottom':'5px'}:{}]"> -->
        <!-- <div class="table"  v-bind:style="[hideFilterOptions ? {'height':'calc(65vh - 185px)','margin-bottom':'5px'}:{},hideCourseScores?{'height':'calc(100% - 110px )'}:{}]"> -->
        <div class="table"  v-bind:style="[hideFilterOptions ? {'height':'calc(65vh - 200px)','margin-bottom':'5px'}:{'height':'65vh'},hideCourseScores?{'height':'calc(100% - 110px )'}:{}]">
          <div class="spinner" v-if="tableLoading">
            <Spinner />
          </div>
          <table ref="export_table" v-if="!tableLoading && learnersMastery.length!=0">
            <thead>
              <tr>
                <th>Learner</th>
                <th v-if="!selectedCourse">Course</th>
                <th v-if="returnStatus!=1">Started</th>
                <th v-if="returnStatus!=2">Mastered</th>
              </tr>
            </thead>
            <tbody
              v-for="(learner, index) in this.learnersMastery"
              :key="index"
            >
              <tr>
                <td>{{ learner.firstName }} {{ learner.lastName }}</td>
                <td v-if="!selectedCourse">{{ learner.courseName }}</td>
                <td v-if="returnStatus != 1">
                  <span v-if="learner.firstAccessed != null">{{ formatDate(learner.firstAccessed) }}</span>
                </td>
                <td v-if="returnStatus!=2" class="mastered-cell">
                  <span  v-if="learner.dateMastered != null"><span v-if="!hideCourseScores"> {{ learner.score }}%  &nbsp;{{ secondsToMs(learner.duration) }}<br></span>{{ formatDate(learner.dateMastered) }}<br>
                  <button  @click="printCertificate(learner)" class="print-btn" >Print</button>
                  </span>
                </td>

                <!-- <td
                  v-else-if="
                    learner.firstAccessed != 'null' &&
                    learner.dateMastered == 'null'
                  "
                >
                  In Progress
                </td>
                <td v-else>Not Started</td>
                <td v-if="learner.dateMastered != 'null'">Mastered</td>
                <td v-else>Not Mastered</td> -->
              </tr>
            </tbody>
          </table>
          <h4 class="no-user-message" v-else> <span v-if="!tableLoading && selectedCourse">No Users Found</span></h4>
        </div>
        <div class="table-controls"  v-if="hideFilterOptions">
            <div id="fetch-options">
            <label for="fetchSelect" >Results: </label>
            <select name="fetchSelect" @change="togglePerPage" v-model="fetchAmount" id="fetchOption">
              <option>10</option>
                <option>25</option>
                <option>50</option>
                <option value="1000">All</option>
            </select>
          </div>
          <div class="pagination">
            <button :disabled="backDisabled" @click="decreaseTablePage" class="table-btn" v-bind:style="[backDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Back</button>
            {{tablePage}}
            <button :disabled="forwardDisabled" @click="increaseTablePage" class="table-btn" v-bind:style="[forwardDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Next</button>
        </div>
        </div>
      </div>
      <div class="filter" v-if=" hideFilterOptions==false">
        <div class="save-file">
          <button class="save-file-button" @click="exportExcel('xlsx')">
            Save
          </button>
          <input type="text" placeholder="Spreadsheet Name" v-model="exportFileName" class="search-input" />
        </div>
        <div class="fixed-wrapper">
          <div class="reports-filter">
            <h4>Filter By</h4>
            <div class="filter-modals">
              <button class="filter-button" @click="showCoursesModal">
                Course
              </button>

              <button class="filter-button" @click="showGroupsModal">
                Group
              </button>

              <div class="status-dropdown" style="width:100%;">
               <label for="statusDrop" >Status</label>
                        <select name="statusDrop" @change="changeStatus()" v-model="returnStatus" id="fetchOption">
                          <option value=1>Mastered</option>
                          <option value=2>Started</option>
                          <option value=0>All</option>
                        </select>
              </div>
             
            </div>

            <DateRange 
            @updateStartDate="startDate"
            @updateEndDate="endDate"
            ref="dateRange"
            ></DateRange>
            <div class="table-options">

              <button class="reset-button" @click="resetTable">
                Reset Table
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CoursesModal
      v-show="coursesModalVisible"
      @close="closeModals"
      @apply="getCourseInfo"
      @resetTable="getCourseInfo"
      ref="resetCourses"
    />
    <GroupsModal 
      v-show="groupsModalVisible"
      @close="closeModals"
      @apply="setGroups"
      @resetTable="setGroups"
      ref="resetGroups"
    />
    <ErrorMessageModal 
        v-show="showErrorMessage"
        :errorTitle="errorMsgTitle"
        :errorBody="errorMsgBody"
        @close="showErrorMessage=false"
        />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "../util/helper.js";
import XLSX from "xlsx";
import DateRange from "./DateRange.vue";
import CoursesModal from "./modals/Courses.vue";
import GroupsModal from "./modals/GroupsModal.vue";
import Spinner from "./Spinner.vue"
import ErrorMessageModal from "./modals/ErrorMessage.vue";
export default {
  name: "Mastery",
  components: {
    DateRange,
    CoursesModal,
    GroupsModal,
    Spinner,
    ErrorMessageModal,
  },
  props:{
    hideFilterOptions:Boolean,
    selectedCourse:String,
    selectedGroup:Object,
    hideCourseScores:Boolean,

  },
  created() {
    this.getMasteryStatusReport();
  },
  data() {
    return {
      learnersMastery: [],
      tableLoading: false,
      searchedName: "",
      debounced: "",
      coursesModalVisible: false,
      groupsModalVisible: false,
      selectedCoursesArr: [],
      selectedGroupsArr: {},
      courseIDArr: [],
      // groupsIDArr: [],
      tablePage: 1,
      fetchAmount: 10,
      dates: { startTime: "", endTime: "" },
      returnStatus: 1,
      exportFileName: "",
      showErrorMessage:false,
      errorMsgTitle:"",
      errorMsgBody:"",
    };
  },
  watch: {
    searchedName: {
      handler: debounce(function (val) {
        this.debounced = val;
        this.tablePage = 1;
        this.getMasteryStatusReport();
      }, 500),
    },
    selectedCourse: function(){
      this.resetTable(false);
      if(this.selectedCourse!="" || (Object.keys(this.selectedGroup).length!=0 && this.selectedCourse=="") )
      {
        this.getMasteryStatusReport();
      }
    },
    selectedGroup: function(){
      this.resetTable(false);
      // if(this.selectedGroup && Object.keys(this.selectedGroup).length!=0)
      // {
        this.getMasteryStatusReport();
      // }
    }
  },
  computed: {
    ...mapState({
      courses: (state) => state.courseNameAndID,
      groups: (state) => state.groups,
    }),
    courseIDArrToString() {
      return this.courseIDArr.join(",");
    },
    // matchCourseNamesWithIDs() {
    //   return this.courses.filter((course) => {
    //     const courseName = course.courseName;
    //     const courseID = course.courseID;
    //     if (this.selectedCoursesArr.includes(courseName)) {
    //       this.courseIDArr.push(courseID);
    //     }
    //     return;
    //   });
    // },
    // matchGroupNamesWithIDs() {
    //   return this.groupsObjToArr.filter(group => {
    //     const groupName = group.groupName;
    //     const groupID = group.groupID;
    //     if (this.selectedGroupsArr.includes(groupName)) {
    //       this.groupsIDArr.push(groupID)
    //     }
    //     return;
    //   })
    // },
    groupsObjToArr() {
      let groups = Object.entries(this.groups);
      let groupsArr = [];
      for (const [key, value] of groups) {
        groupsArr.push({groupID: key, groupName: value})
      }
      return groupsArr;
    },
    groupIDArrToString() {
      return Object.keys(this.selectedGroupsArr).join(",");
    },
    backDisabled(){
      if(this.tablePage<=1 || this.learnersMastery==[])
      {
        return true;
      }
      return false;
    },
    forwardDisabled()
    {
       if( this.learnersMastery.length<this.fetchAmount || this.learnersMastery==[])
       {
         return true;
       }
      return false;
    },
  },
  methods: {
    printCertificate(learner)
    {
      let certParams={
        loginID: learner.loginID,
        contentID: learner.contentID
      };
      this.$store.dispatch("getCertificate",certParams).then((certResp)=>{
        if(certResp!=true && typeof(certResp)=='string')
        {
          this.errorMsgTitle="Certificate Error";
          this.errorMsgBody=certResp;
          this.showErrorMessage=true;
        }

      })

    },
    formatDate(dateStr)
    {
      const dateParts = dateStr.split(" ")[0].split("-");
      return `${dateParts[2]}/${dateParts[1]}/${dateParts[0].substring(2)}`;
    },
    secondsToMs(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secondsLeft = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
    },
    showCoursesModal() {
      this.coursesModalVisible = true;
    },
    showGroupsModal() {
      this.groupsModalVisible = true;
    },
    closeModals() {
      this.coursesModalVisible = false;
      this.groupsModalVisible = false;
    },

    getCourseInfo(courses) {
      this.selectedCoursesArr = courses;
      this.coursesModalVisible = false;
      this.courseIDArr = [];
      this.tablePage = 1;
      // this.matchCourseNamesWithIDs; //was used when the old return was in place
      courses.forEach(course => {
        this.courseIDArr.push(course.courseID);   
      });
      this.getMasteryStatusReport(0);
    },
    setGroups(groups){
      this.selectedGroupsArr = groups;
      this.groupsModalVisible = false;
      // this.groupsIDArr = [];
      this.tablePage = 1;
      this.matchGroupNamesWithIDs;
      this.getMasteryStatusReport(0);
    },
    changeStatus()
    {
      this.tablePage=1;
       this.getMasteryStatusReport();
    },


    async getMasteryStatusReport(offset) {
      if(this.hideCourseScores==true && !this.selectedCourse)
      {
        return;
      }
      this.tableLoading= true;
      await this.$store
        .dispatch("getMasteryStatusReport", {
          offset,
          fetch: this.fetchAmount,
          search: this.searchedName,
          courses: this.hideFilterOptions?this.selectedCourse:this.courseIDArrToString,
          groups: this.hideFilterOptions?this.selectedGroup?.ID:this.groupIDArrToString,
          dates: this.dates,
          returnStatus: this.returnStatus,
        })
        .then((res) => {
          this.learnersMastery = res.results;
          this.tableLoading = false;
        });
    },

    increaseTablePage() {
      if (this.learnersMastery.length == this.fetchAmount) {
        this.tablePage++;
        const offset = (this.tablePage - 1) * this.fetchAmount;
        this.getMasteryStatusReport(offset);
      }
    },
    decreaseTablePage() {
      if (this.tablePage > 1) {
        this.tablePage--;
        const offset = (this.tablePage - 1) * this.fetchAmount;
        this.getMasteryStatusReport(offset);
      }
    },

    startDate(date) {
      this.dates.startTime = date;
      this.tablePage = 1;
      this.getMasteryStatusReport(0);
    },
    endDate(date) {
      this.dates.endTime = date;
      this.tablePage = 1;
      this.getMasteryStatusReport(0);
    },

    resetTable(callFunction=true) {
      // this.returnStatus = 0;
      // this.searchedName = "";
      this.courseIDArr = [];
      // this.groupsIDArr = [];
      this.$refs.resetCourses.reset();
      this.$refs.resetGroups.reset();
      this.dates.startTime = "";
      this.dates.endTime = "";
      if(!this.hideFilterOptions)
      {
        this.$refs.dateRange.resetDate();
      }
      this.tablePage = 1;
      // this.fetchAmount = 10;
      callFunction??this.getMasteryStatusReport(0);
    },

    exportExcel() {
      let table = this.$refs.export_table;
      let workbook = XLSX.utils.table_to_book(table, {sheet: "Sheet JS" });
      let fileName = (this.exportFileName || "sheeetJSTableExport"); 
      let fileExport = XLSX.writeFile(workbook, fileName + ".xlsx");
      
      this.exportFileName = "";
      return fileExport;
    },
    togglePerPage() {
      this.tablePage = 1;
      this.getMasteryStatusReport(0)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/MasteryTableV2.scss"
</style>
