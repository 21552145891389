<template>
  <div class="hazards-container">
    <div class="sidemenu">
      <SideMenu />
    </div>
<div id="navbar-container">
<Navbar />
  <div id="sahara-hazards">
    
    <div class="da-header">
      <!-- Used to make the title appear in the centers -->
      <!-- <div class="left-title"> </div>  -->

      <!-- <div class="sahara-image">
      <img style="height:50px;" src="../assets/sahara_icon_normal.png" >
      <img style="height:85px; padding-right: 40px;" src="../assets/Sahara Logo Horizontal.png" >
    </div> -->
    </div>
    <div class="title">
        <font-awesome-icon class="title-icon" :icon="['fas', 'bolt']" />
        <!-- <img class="title-icon" src="../assets/wellness.png" > -->
        SAHARA Hazards
    </div>
    <div class="filter-btn-container">
          <button v-if="!multipleIndustries"  @click="showEnvironmentsModal" class="table-btn" >Filter By Environment</button>
          <button v-if="multipleIndustries"  @click="showIndustryTreeModal" class="table-btn" >Filter By Industry</button>
         <DateRange 
         @updateStartDate="setStartDate"
         @updateEndDate="setEndDate"
         :shorten="true"
         ref="dateRange"
         ></DateRange>
    </div>
        <div class="content">
        <!-- <div class="fixed-wrapper">
          <div class="reports-filter">
            <h4>Filter By</h4>
            <div class="filter-modals">
              <button class="filter-button" @click="showEnvironmentsModal">
                Environments
              </button>

              <button class="filter-button" @click="showGroupsModal">
                Groups
              </button>
            </div>


            <div class="table-options">
              <button class="reset-button" @click="resetTable">
                Reset Table
              </button>
            </div>
          </div>
        </div> -->
          <div class="learners-table">
            <div class="filters">
              <div class="search-bar">
              <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />

                <input
                type="search"
                placeholder="Search for User"
                class="search-input"
                v-model="userSearch"
                v-on:input="searchUser"
                id='searchRecords'
              />
                </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <!-- <th colspan="3">Sessions</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <th class="sessions-header">Learner</th>
                    <th class="sessions-header">Risk Level</th> -->
                  </tr>
                    <tr v-for="record in currentRecords" :key="record.SaharaSessionID" @click="getRecordHazards(record)" :class="{'highlight': (record.SaharaSessionID == selectedSession)}">
                      <td class="record-names">{{record.firstName}} {{record.lastName}}</td>
                      <td class="record-dates">{{ removeTime(record.endTime)}}</td>
                      <td v-bind:class="getClass(record.riskLevel)" class="risk-level">{{record.riskLevel}}</td>
                      <!-- THIS WILL SHOW THE INDUSTRIES IF THERE IS MORE THAN ONE SELECTED BUT ONLY ONE RETURNED WHICH MIGHT BE GOOD TO KEEP CONSISTENT   -->
                       <td v-if=" recordIndustriesAndEnvironments.environments.length>1" ><p v-if="record.industryName && (industriesSelected.length!=1 || recordIndustriesAndEnvironments.industries.length>1) " class="industry-span">{{ record.industryName }}</p>
                        <p v-if="record.environmentName && ( industriesSelected.length==1 || recordIndustriesAndEnvironments.industries.length<=1 ) && recordIndustriesAndEnvironments.environments.length>1" class="environment-span">{{ record.environmentName }}</p></td> 
                    </tr>
                </tbody> 
              </table>
              <div v-if="recordsLoading" class="loading-spinner"><Spinner/></div>
            </div>
        <div class="table-controls">

        <div id="fetch-options">
            <label for="fetchSelect" >Results: </label>
            <select name="fetchSelect" @change="changeFetch()" v-model="fetch" id="fetchOption">
              <option value=10>10</option>
              <option value=20>20</option>
              <option value=30>30</option>
              <option value=40>40</option>
              <option value=50>50</option>
              <option value=100>100</option>
            </select>
          </div>
          <div class="pagination">
            <button :disabled="backDisabled" @click="decreaseTablePage" class="table-btn" v-bind:style="[backDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Back</button>
            {{currentTablePage}}
            <button :disabled="forwardDisabled" @click="increaseTablePage" class="table-btn" v-bind:style="[forwardDisabled ? {'background-color':'grey'}:{'background-color': 'rgb(0, 69, 185)'}]">Next</button>
        </div>
        </div>
          </div>
          <div class="hazards-table">
            <!-- <div class="environment-header"><span>Environment:</span> {{ environment }}</div> -->
            <div class="table">
              <table>
                <thead>
                  <tr>
                  <th colspan="2" class="environment-header" ><span >Environment:</span> {{environment}}</th>
                  <th class="controls-header" v-if="containsControlsUsed">Controls Used</th>
                  <th class="controls-header" v-if="containsLevelSelected">Level</th>

                  </tr>
                </thead>
                <tbody v-for="category in recordHazards"  :key="category.categoryID">
                    <tr >
                    <th colspan="5" class="sub-header">{{category.categoryName}}</th>
                    </tr>

                      <tr id="hazards" v-for="(hazard,index) in category.hazards" :key="index" >
                      <td ref="courseRow" class="hazard-name">{{hazard.hazardName}}</td>
                      <td v-bind:class="getClass(hazard.hazardRisk)" class="risk-level">{{hazard.hazardRisk}}</td>
                      <td v-if="containsControlsUsed" class="controls-data" ><span v-if="hazard.controlsUsed==true">&#10004;</span>
                        <span v-if="hazard.controlsUsed!=true && (typeof hazard.controlsUsed)!=='undefined'">&#10060;</span></td> 
                        <td class="level-data" v-if="containsLevelSelected"><span v-if="hazard.level && hazard.level.levelName">{{ hazard.level.levelName }}</span></td>
                    </tr>
                </tbody>
                <tbody v-if="customHazards.length>0" v-show="!hazardsLoading">
                  <tr >
                    <th colspan="5" class="sub-header">Custom</th>
                    </tr>
                      <tr id="customHazards" v-for="customHazard in customHazards" :key="customHazard.customHazardName">
                      <td class="hazard-name" >{{ decodeCustom(customHazard.customHazardName) }}</td>
                      <td v-bind:class="getClass(customHazard.hazardRisk)" class="risk-level">{{customHazard.hazardRisk}}</td> 
                      <!-- <td>{{customHazard.riskValue}}</td>  -->
                      </tr>
                </tbody>
                <td colspan="5" v-if="!resultsFound && !hazardsLoading" class="none-selected">No hazards selected</td>
              </table>
                <div v-if="hazardsLoading" class="loading-spinner"><Spinner/></div>
            </div>

          </div>
          <div class="course-table-container">
            <div class="filters">
              <div class="search-bar">
              <font-awesome-icon class="search-icon" :icon="['fas', 'search']" />
                <input
                type="search"
                placeholder="Search for Course"
                class="search-input"
                v-model="courseSearch"
                id='searchRecords'
              />
              </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                  <th >Course</th>
                    <th >Mastered</th>
                  </tr>
                </thead>
                <tbody >
                <!-- <tbody v-if="filteredCourse"> -->

                    <tr>
                    <th colspan="2" v-if="filteredCourse.personalCourses && filteredCourse.personalCourses.length!=0" class="sub-header">Personal Hazard Courses</th>
                    </tr>
                    <tr  v-for="course in filteredCourse.personalCourses" :key="course.courseName">
                      <td>{{course.courseName}}</td>
                      <td>{{course.mastered}}</td>
                    </tr>
                    <tr>
                    <th colspan="2" v-if="filteredCourse.physical && filteredCourse.physical.length!=0" class="sub-header">Physical Hazard Courses</th>
                    </tr>
                    <tr v-for="course in filteredCourse.physical" :key="course.courseName">
                      <td>{{course.courseName}}</td>
                      <td>{{course.mastered}}</td>
                    </tr>
                </tbody>
                <!-- <td colspan="2" class="none-selected">No courses recommended</td> -->
                <td colspan="2" v-if="!coursesFound && !hazardsLoading" class="none-selected">No courses recommended</td>
              </table>
              <div v-if="hazardsLoading" class="loading-spinner"><Spinner/></div>
            </div>
          </div>
        </div>
      <GroupsModal 
      v-show="groupsModalVisible"
      @close="closeModals"
      @apply="setGroups"
      @resetTable="setGroups"
      ref="resetGroups"
    />
      <EnvironmentsModal 
      v-show="environmentsModalVisible"
      @close="closeModals"
      @apply="setEnvironments"
      @resetTable="setEnvironments"
      ref="resetEnvironments"
    />
    <IndustryTreeModal 
      v-show="industryTreeModalVisible"
      @close="closeModals"
      @apply="setEnvironments"
      @resetTable="setEnvironments"
      ref="resetIndustryTree"
    />
    <ErrorMessageModal 
        v-show="showErrorMessage"
        :errorTitle="errorMsgTitle"
        :errorBody="errorMsgBody"
        @close="closeErrorModal"
        />
  </div>
</div>
</div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import Tab from "../components/Tab.vue";
import Tabs from "../components/Tabs.vue";
// import Spinner2 from "../components/Spinner2.vue";
import TreeBrowser from "../components/TreeBrowser.vue";
// import Tree from "../components/Tree.vue";
import Spinner from "../components/Spinner.vue";
import DateRange from "../components/DateRange.vue";
import GroupsModal from "../components/modals/GroupsModal.vue";
import EnvironmentsModal from "../components/modals/Environments.vue";
import IndustryTreeModal from "../components/modals/IndustryTree.vue";
import ErrorMessageModal from "../components/modals/ErrorMessage.vue";
import { mapState } from "vuex";


export default {
  name: "SAHARA_Hazards",
  components: {
    Navbar,
    SideMenu,
    Tab,
    Tabs,
    Spinner,
    // Tree,
    TreeBrowser,
    DateRange,
    GroupsModal,
    EnvironmentsModal,
    ErrorMessageModal,
    IndustryTreeModal,
  },
    data() {
    return {
      currentRecords:[],
      fetch: 20, 
      offset: 0,
      recordHazards:[], //Seeing why the login does not work 
      customHazards:[],
      resultsFound:true,
      coursesFound:true,
      selectedSession:null,
      mitigationWidth: null,
      statusWidth:null,
      masteredWidth:null,
      hazardsLoading:false,
      environment:'',
      currentTablePage:1,
      userSearch:'',
      courseSearch:'',
      previousFetch:20,
      recordsLoading:true,
      showNoRecord:false,
      saharaEnvironments:[],
      saharaIndustries:[],
      startDate:null,
      endDate:null,
      groupsModalVisible:false,
      environmentsModalVisible:false,
      industryTreeModalVisible:false,
      groupsSelected:{},
      environmentsSelected:"",
      industriesSelected:[],
      showErrorMessage:false,
      errorMsgTitle:"",
      errorMsgBody:"",
      coursesRecommended:{},
      selectedStart: "",

    };
  },
  created () {
    this.getRecordList();
    if(Object.values(this.adminIndustries).length==0)
    {
      this.getSaharaIndustries();
    }
    // this.getSaharaEnvironments();
    // window.addEventListener('resize',this.resizeHandler);
  },
  // destroyed(){
  //   window.removeEventListener('resize',this.resizeHandler)
  // },
  filters:{
    removeTime: function(value)
    {
      let beforeVal=value.split(" ");
      return beforeVal[0];
    },
    removeSeconds: function(secondsVal)
    {
      return secondsVal.slice(0,-3);//? remove the last 3 charters from the end of the string to get rid of the seconds
    },

  },

  mounted(){
  },

  computed:{
    ...mapState({
      adminIndustries: (state) => state.saharaIndustries,
    }),
    filteredCourse(){
      var findingCourse={};
      if(this.coursesRecommended.personalCourses)
      {
        findingCourse.personalCourses= this.coursesRecommended.personalCourses.filter((personal)=>{
          return personal.courseName.toLowerCase().match(this.courseSearch)
        })
      }
      if(this.coursesRecommended.physical)
      {
        findingCourse.physical=this.coursesRecommended.physical.filter((physical)=>{
          return physical.courseName.toLowerCase().match(this.courseSearch)
        })
      }
      return findingCourse;
    },
    multipleIndustries()
    {
      // console.log('admin industries',this.adminIndustries)
      if(this.adminIndustries && this.adminIndustries.length>1)
      {
        return true;
      }
      else{
        return false;
      }
    },
    containsControlsUsed()
    {
      for(const hazardCategory of this.recordHazards)
      {
        for(const haz of hazardCategory.hazards)
        {
          if((typeof haz.controlsUsed)!=='undefined')
          {
            return true;
          }
        }
      }
      return false;
    },

    containsLevelSelected()
    {
      for(const hazardCategory of this.recordHazards)
      {
        for(const haz of hazardCategory.hazards)
        {
          if((typeof haz.level)!=='undefined')
          {
            return true;
          }
        }
      }
      return false;
    },

    groupIDs(){
            return Object.keys(this.groupsSelected).join(",");
    },
    backDisabled(){
      if(this.currentTablePage<=1 || this.currentRecords==[])
      {
        return true;
      }
      return false;
    },
    forwardDisabled()
    {
       if( this.currentRecords.length<this.fetch || this.currentRecords==[])
       {
         return true;
       }
      return false;
    },
    recordIndustriesAndEnvironments()
    {
      let recordIndustries=[];
      let recordEnvironments=[];
      this.currentRecords.forEach((record)=>{
        let industryIndex=recordIndustries.indexOf(record.industryName);
        if(industryIndex==-1)
        {
          recordIndustries.push(record.industryName)
        }

        let environmentIndex=recordEnvironments.indexOf(record.environmentName);
        if(environmentIndex==-1)
        {
          recordEnvironments.push(record.environmentName)
        }
      })
      return{
        environments: recordEnvironments,
        industries: recordIndustries
      }
    },
  },

  methods: {

    closeErrorModal()
    {
      this.showErrorMessage=false;
      this.errorMsgBody="";
      this.errorMsgTitle="";
    },
   decodeCustom(customName)
    {
      return decodeURIComponent(customName);
    },
    removeTime(value)
    {
      let beforeVal=value.split(" ");
      return beforeVal[0];
    },
    removeSeconds(secondsVal)
    {
      return secondsVal.slice(0,-3);//? remove the last 3 charters from the end of the string to get rid of the seconds
    },
    closeModals(){
      this.groupsModalVisible=false;
      this.environmentsModalVisible=false;
      this.industryTreeModalVisible=false;
    },
    setGroups(groups)
    {
      this.groupsSelected=groups;
      this.groupsModalVisible=false;
      this.currentTablePage=1;
      this.getRecordList();
    },
    setEnvironments(environments){
      this.industryTreeModalVisible=false;
      this.environmentsModalVisible=false;
      this.environmentsSelected="";
      this.industriesSelected=[];
       environments.forEach( (e) =>
      {
        let industryIndex=this.industriesSelected.indexOf(e.industryID);
        if(industryIndex==-1)
        {
          this.industriesSelected.push(e.industryID)
        }
        if(e.environmentID)
        {
          this.environmentsSelected+="'"+e.environmentID+"',";
        }
      });
      this.environmentsSelected=this.environmentsSelected.slice(0,-1);
      this.currentTablePage=1;
      this.getRecordList();
      },
    getSaharaEnvironments(){
      let environmentObj={
        returnOption:"0",
        groupIDs:this.groupIDs
      }
      this.$store.dispatch("getSaharaEnvironments", environmentObj)
      .then((result) => {

        if(result.status==='success') 
        {
          this.saharaEnvironments=result.results.environments;
          this.saharaIndustries=result.results.industries;
        }
        else{
          this.errorMsgTitle="Environment Error";
          this.errorMsgBody="Unable to find SAHARA environments. Please reload the page or log in again. If this problem persists please contact us.";
          this.showErrorMessage=true;
        }
      });
    },
    getSaharaIndustries()
    {
    this.$store.dispatch('getSaharaIndustry').then((industries)=>{
            if(industries=='Error')
            {
                this.showErrorMessage=true;
                this.errorMsgBody="Error: Not able to find your SAHARA Industries";
                this.errorMsgTitle="SAHARA Error";
            }
        })
      },

    setStartDate(date) {
      this.startDate=date;
      this.currentTablePage=1;
      this.getRecordList();
    },
    setEndDate(date) {
      this.endDate=date;
      if(this.startDate && this.startDate>date)
      {
        this.startDate=date;
      }
      this.currentTablePage=1;
      this.getRecordList();
    },
    showCoursesModal() {
      // this.coursesModalVisible = true;
    },
    showGroupsModal() {
      this.groupsModalVisible = true;
    },
    showEnvironmentsModal() {
      this.environmentsModalVisible = true;
    },
    showIndustryTreeModal() {
      this.industryTreeModalVisible = true;
    },
    resetTable() {
      this.groupsSelected={};
      this.startDate=null;
      this.endDate=null;
      this.environmentsSelected="";
      // this.returnStatus = 0;
      // this.searchedName = "";
      // this.courseIDArr = [];
      // this.groupsIDArr = [];
      // this.$refs.resetCourses.reset();
      this.$refs.resetGroups.reset();
      this.$refs.resetEnvironments.reset();
      this.$refs.resetIndustryTree.reset();
      
      // this.dates.startTime = "";
      // this.dates.endTime = "";
      this.$refs.dateRange.resetDate();
      // this.$refs.resetEndDate.resetDate();
      // this.tablePage = 1;
      // this.fetchAmount = 10;
      // this.getMasteryStatusReport(this.tablePage);
      this.currentTablePage=1;
      this.getRecordList();
    },

    getClass(dat)
    {
      if(dat==="LOW")
      {
        return 'low-class';
      }
      else if(dat==='MEDIUM')
      {
        return 'medium-class'
      }
      else if(dat==='HIGH')
      {
        return 'high-class'
      }
      else if(dat==='EXTREME')
      {
        return 'extreme-class';
      }
    },

    // resizeHandler()//? Get the columns to resize when the page is resized or brought to a larger screen
    // {
    //   // this.calculateWidth();
    // },

    searchUser()
    {
      this.currentTablePage=1;
      this.getRecordList();
    },

    changeFetch()
    {
      // this.currentTablePage=1;
      this.getRecordList();
      this.previousFetch=this.fetch;
    },

    increaseTablePage(){
      this.currentTablePage++;
      this.getRecordList();
    },

    decreaseTablePage(){
      if(this.currentTablePage>1)
      {
        this.currentTablePage--;
        this.getRecordList();
      }
    },
    
    getRecordList()
    {
      this.recordsLoading=true;
      this.showNoRecord=false;
      this.currentRecords=[];
      let recordListObj={
        groupIDs:this.groupIDs,
        industry:"",
        environments:this.environmentsSelected,
        userName:this.userSearch,
        startTime: this.startDate?this.startDate+" 00:00:00.000":"",//Make it so it would be the start of the day
        endTime: this.endDate? this.endDate+" 23:59:59.000":"",//Make it so it would be the end of the day 
        fetch:this.fetch.toString(),
        offset:((this.currentTablePage-1)*this.fetch).toString(),
      }
      if(this.previousFetch!=this.fetch)
      {
        this.currentTablePage=(Math.floor(((this.currentTablePage-1)*this.previousFetch)/this.fetch)+1);
        recordListObj.offset=((this.currentTablePage-1)*this.fetch).toString()
      }
      this.$store.dispatch("getSaharaRecords", recordListObj)
      .then((result) => {
        if(result.status==='success') 
        {
          this.currentRecords=result.results;
          this.recordsLoading=false;
          if(result.results && result.results.length==0)
          {
            this.showNoRecord=true;
          }
          else{
            this.showNoRecord=false;
          }
        }
        else{
          this.errorMsgTitle="Sessions Error";
          this.errorMsgBody="Unable to find SAHARA sessions. Please reload the page or log in again. If this problem persists please contact us.";
          this.showErrorMessage=true;
        }
      });
        // this.changeCellBackground();//Change the background colour of the results to match what it is in the app

    },
    // calculateWidth()
    // {
    //   this.hazardsLoading=true;
    //     setTimeout(()=>{
    //       // this.mitigationWidth=this.$refs.mitigation.clientWidth;
    //       // this.statusWidth=this.$refs.status.clientWidth;
    //       // this.masteredWidth=this.$refs.mastered.clientWidth;
    //       this.hazardsLoading=false;
    //     },5)
    // },
    getRecordHazards(record)
    {
      this.environment=record.environmentName;
      this.hazardsLoading=true;
      this.resultsFound=true;
      this.coursesFound=true;
      this.selectedSession=record.SaharaSessionID
      this.recordHazards=[];
      this.customHazards=[];
      this.coursesRecommended={};
      var hasHazards=false;
    this.$store.dispatch("getRecordHazards", record.SaharaSessionID)
      .then((result) => {
          if(typeof(result)=='string')
          {
            this.hazardsLoading=false;
            //! show some kind of error message with the message passed back
            return;
          }
          if(result.CustomHazards && result.CustomHazards.length!=0)
          {
            this.customHazards=result.CustomHazards;
            hasHazards=true;
          }
      
          if(result.hazards && result.hazards.length!=0)
          {
            this.recordHazards=result.hazards;
            hasHazards=true;
          }

          if(result.recommendedCourses && (result.recommendedCourses.personalCourses || result.recommendedCourses.physical))
          {
            this.coursesRecommended=result.recommendedCourses;
          }
          else{
            //? possibly have it load a message here that will say that no courses have been recommended  
            this.coursesRecommended={};
            this.coursesFound=false;         
          }

          if(!hasHazards)
          {
            this.resultsFound=false;
          }

          this.hazardsLoading=false;
          // this.calculateWidth();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/main.scss";
@import "./styles/SaharaHazards.scss";
</style>