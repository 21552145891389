<template>
    <div class="hazards-table-container">
   <div class="hazards-table">
    <table >
        <thead>
            <tr>
                <th colspan="2" class="table-header">Leading Hazard Indicators</th>

                <!-- <th style="border-left:none;">Environment</th>
                <th colspan="2">Leading Physical Hazard</th>
                <th style="border-right:none;" colspan="2">Leading Wellness Question</th> -->
            </tr>
        </thead>
        <tbody v-if="isLoading" class="spinner-container" >
                <Spinner/>
        </tbody>
        <tbody v-if="!isLoading && saharaData!='Error'">
            <tr>
                <!-- <td class="blue-background">Physical</td> -->
                <td><b>PHYSICAL</b></td>
                <!-- <td style="font-size: 23px;"><b>Physical</b></td> -->
                <td><b>{{Math.round(saharaData.topHazard.hazardPercent).toString()+'%'}}</b></td>
            </tr>
            <tr>
                <td>Environment</td>
                <!-- <td class="blue-background">Transportation & Delivery</td> -->
                <td >{{saharaData.topEnvironment.environment}}</td>
            </tr>
            <tr>
                <td>Top Hazard</td>
                <!-- <td class="blue-background">Dangerous driving conditions</td> -->
                <td >{{saharaData.topHazard.hazard}}</td>
            </tr>
            <tr>
               <td colspan="2" class="seperator"></td> 
            </tr>
            <tr>
                <td ><b>PSYCHOSOCIAL</b></td>
                <!-- <td style="font-size: 23px;"><b>Psychosocial</b></td> -->
                <td><b>{{Math.round(saharaData.topWellnessQuestion.questionPercent).toString()+'%'}}</b></td>
            </tr>
            <tr>
                <td>Question</td>
                <td>{{saharaData.topWellnessQuestion.question}}</td>
            </tr>
            <!-- <tr v-for="environment in leadingHazards" :key="environment.environment">
                <td style="border:none;">{{environment.environment}}</td>
                <td >{{environment.physical.hazardName}}</td>
                <td v-bind:class="getClass(environment.physical.percentSelected)" class="percentage-data">{{environment.physical.percentSelected}}%</td>
                <td >{{environment.psychological.questionText}}</td>
                <td v-bind:class="getClass(environment.psychological.psychosocialPercent)" class="percentage-data">{{environment.psychological.psychosocialPercent}}%</td>
            </tr> -->
        </tbody>
        <tbody v-if="saharaData=='Error'" style="text-align: center;">
            <h4>There was an Error loading the data</h4>
            <p>Please contact us if<br/>this problem continues</p>
        </tbody>
    </table>
   </div>
</div>
  </template>
  
  
  <script>

  import Spinner from "./Spinner.vue";
  // import { debounce } from "../util/helper";
  
  export default {
    name: "SaharaHazardsTable",
    components: {

      Spinner,
    },
    props:["saharaData"],
    data() {
      return {
        leadingHazards:[
            {
                environment:"Transportation & Delivery",
                physical:{
                    hazardName:"Dangerous driving conditions",
                    percentSelected:67
                },
                psychological:{
                    questionText:"I have the tools, equipment and/or PPE to work safely",
                    psychosocialPercent:45
                }
            },
            {
                environment:"Front Desk/Cleaners",
                physical:{
                    hazardName:"Human contamination",
                    percentSelected:78
                },
                psychological:{
                    questionText:"I have the tools, equipment and/or PPE to work safely",
                    psychosocialPercent:37
                }
            },
            {
                environment:"Kitchen and Food Prep",
                physical:{
                    hazardName:"Sharp knives/blades",
                    percentSelected:53
                },
                psychological:{
                    questionText:"I can handle my workloads within the timelines expected",
                    psychosocialPercent:62
                }
            },
            {
                environment:"Food Counter Attendants",
                physical:{
                    hazardName:"Crowded workspace",
                    percentSelected:47
                },
                psychological:{
                    questionText:"My supervisors and/or peers create a toxic work environment",
                    psychosocialPercent:32
                }
            },
            {
                environment:"Food and Beverage Servers",
                physical:{
                    hazardName:"Slippery floor",
                    percentSelected:34
                },
                psychological:{
                    questionText:"I can handle my workloads within the timelines expected",
                    psychosocialPercent:56
                }
            },





            // {
            //     environment:"Transportation & Delivery1",
            //     physical:{
            //         hazardName:"Dangerous driving conditions",
            //         percentSelected:67
            //     },
            //     psychological:{
            //         questionText:"I have the tools, equipment and/or PPE to work safely",
            //         psychosocialPercent:45
            //     }
            // },
            // {
            //     environment:"Kitchen and Food Prep1",
            //     physical:{
            //         hazardName:"Sharp knives/blades",
            //         percentSelected:53
            //     },
            //     psychological:{
            //         questionText:"I can handle my workloads within the timelines expected",
            //         psychosocialPercent:62
            //     }
            // },
            // {
            //     environment:"Food Counter Attendants1",
            //     physical:{
            //         hazardName:"Crowded workspace",
            //         percentSelected:47
            //     },
            //     psychological:{
            //         questionText:"My supervisors and/or peers create a toxic work environment",
            //         psychosocialPercent:32
            //     }
            // },
            // {
            //     environment:"Food and Beverage Servers1",
            //     physical:{
            //         hazardName:"Slippery floor",
            //         percentSelected:34
            //     },
            //     psychological:{
            //         questionText:"I can handle my workloads within the timelines expected",
            //         psychosocialPercent:56
            //     }
            // },
            // {
            //     environment:"Front Desk/Cleaners1",
            //     physical:{
            //         hazardName:"Human contamination",
            //         percentSelected:78
            //     },
            //     psychological:{
            //         questionText:"I have the tools, equipment and/or PPE to work safely",
            //         psychosocialPercent:37
            //     }
            // },
        ],
      };
    },
    //// debounce for search input - import { debounce } above ////
    // watch: {
    //   filter: {
    //     handler: debounce(function (val) {
    //       this.filter = val;
    //     }, 100),
    //   },
    // },
    created() {
    },
    computed: {
        isLoading(){
            if(Object.keys(this.saharaData).length==0)
            {
                return true;
            }
            return false;
        }
    },
    methods: {
    getClass(dat)
    {
      if(dat<=33.3)
      {
        return 'low-class';
      }
      else if(dat>33.3&&dat<66.7)
      {
        return 'medium-class'
      }
      else if(dat>=66.7)
      {
        return 'high-class'
      }
    },
      //// search functions speeds up search input ////
    },
  };
  
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "styles/SaharaHazardsTable.scss";
  </style>
  