import {store} from "../store/Store";

const testAuthorizationCode =
  "7676BB3898DD1526FD96BB7912EC4277397B435E3ECD090B870FBE9E956F0227";
//
// For production, uncomment
//
// let testServer = 'https://sisfoundation.swiftlearning.com';
//
//
// For testing, comment out for production
//
let testServer = "https://mobiletestserver.swiftlearning.com";
//
//
// To use a CAP Server uncomment, comment out for Mobile Test Server
//
// let testServer = "https://swiftpilot.swiftlearning.com";

const gemsAPI = "https://swiftapi.swiftlearning.com/api/v1/";

let origin = "";

const APICalls = {
  //this is a simple object to contain all of the api call names and values paired so functions can be linked to the API Call name
  HTTP_POST_LOGIN: 200,
  HTTP_POST_LOGOFF: 201,
  HTTP_POST_FORCE_LOGIN: 255,
  ENROLLMENT_API: 333,
  HTTP_POST_TRUSTED_GET_SAHARA_INDUSTRY: 376,
  UNENROLLMENT_API: 291,
  HTTP_POST_TRUSTED_CERTIFICATE_REQUEST: 294,
  ADMIN_GET_GROUP_PROPERTIES: 1200,
  ADMIN_DELETE_GROUP: 1202,
  ADMIN_GET_GROUPS: 1203,
  ADMIN_GET_GROUPS_TABLE: 1204,
  ADMIN_GET_GROUP_LIST: 1205,
  ADMIN_GET_EVENT_LOG: 1210,
  ADMIN_GET_REGISTERED_CONTENT: 1211, //Request going through, empty responses
  ADMIN_GET_REGISTERED_USERS: 1212,
  ADMIN_GET_HOSTIP_PROPERTIES: 1214,
  ADMIN_GET_SEAT_DETAILS: 1216,
  ADMIN_GET_GROUP_USERS: 1219,
  ADMIN_GET_CONTENT_USERS: 1221,
  ADMIN_GET_USER_GROUPS: 1223,
  ADMIN_GET_HISTORY_LOGINS: 1230, //Request going through, empty responses
  ADMIN_GET_HOST_INFORMATION: 1233, //!This is having a server error which is strange because it does not take any reqests apart from the number
  ADMIN_GET_EVENT_TABLE: 1235,
  ADMIN_GENERATE_REPORT2: 1242,
  ADMIN_GET_USER_LIST: 1245,
  ADMIN_GET_CONTENT_LIST: 1246,
  ADMIN_GET_CONTENT_FOR_SEATING: 1248,
  ADMIN_GET_USER_PROPERTIES: 1249,
  ADMIN_UPDATE_USER: 1250,
  ADMIN_DELETE_USER: 1251,
  ADMIN_FORCE_USER_LOGOUT: 1253,
  ADMIN_GET_CONTENT_PROPERTIES: 1266,
  ADMIN_GET_USERS_FOR_CONTENT_SEATING: 1268,
  ADMIN_GET_GROUP_TREE: 1272,
  ADMIN_GET_USERLKB_LOGIN_HISTORY: 1275,
  ADMIN_GET_USER_LKBS: 1277,
  ADMIN_GET_USERLKB_DATA: 1279,
  ADMIN_GET_USERLKB_TESTS: 1281,
  ADMIN_GET_COURSE_SCORES: 1283,
  ADMIN_GET_COURSE_MODULES: 1285,
  ADMIN_GET_MODULE_SCORES: 1288,
  ADMIN_GET_EVENT_LOG_LIST: 1289,
  ADMIN_GET_UNREGISTERED_COURSES: 1291, //Gateway time-out on requests to group 0 or 1
  ADMIN_GET_UNREGISTERED_EXAMS: 1292, //Request going through, empty responses
  ADMIN_GET_CLASSROOMS_FOR_CONTENT: 1301, //Request going through, empty responses
  ADMIN_GET_CLASSROOM_DATA: 1303, //no valid classroomID, error retrieving data
  ADMIN_GET_CLASSROOM_POTENTIAL_USERS: 1306, //TODO
  ADMIN_GET_CLASSROOM_ACTUAL_USERS: 1308, //TODO
  ADMIN_GET_REGISTERED_CERTIFICATIONS: 1311, //!This is having a server error which is strange because it does not take any reqests apart from the number
  ADMIN_GET_USERS_FOR_COURSE_COMPLETE: 1319,
  ADMIN_GET_ASSIGNMENT_GROUP_TREE: 1321, //?This will return the clustering and location of the courses
  ADMIN_GET_CERTIFICATIONS: 1326,
  ADMIN_GET_USERS_LIST: 1333,
  ADMIN_GET_CONTENT_LOGINS: 1226,
  ADMIN_GET_CURRENT_LOGINS: 1209,
  ADMIN_GET_ADMIN_LEVEL: 1335,

  ADMIN_UPDATE_GROUP: 1201,
  ADMIN_GET_ACCOUNT_PROPERTIES: 1322,
  ADMIN_GET_GROUPS_FOR_USER: 1334,
  ADMIN_UPDATE_SEATABLE_CONTENT: 1227,
  ADMIN_DELETE_GROUP_AND_SUBGROUPS: 1344,

  // ADMIN_UPDATE_ACCOUNT_PROPERTIES: 1323,

  ADMIN_GENERATE_STATUS_REPORT: 1336, 

  ADMIN_GET_SAHARA_RECORDS: 1337,
  ADMIN_GET_SAHARA_RECORD_HAZARDS: 1338,
  ADMIN_GET_SAHARA_MENTAL_WELLNESS: 1339,
  ADMIN_GET_SAHARA_ENVIRONMENTS: 1340,
  ADMIN_GET_SAHARA_COURSES:   1341,
  ADMIN_CHANGE_SAHARA_USER_INDUSTRIES: 1342,
  ADMIN_GET_SAHARA_DASHBOARD_DATA: 1343,
  ADMIN_GET_GENERAL_COURSE_DATA: 1345,
  ADMIN_GET_SAHARA_RECORD_WELLNESS: 1346,
};

/**
 * Returns a block of text to Store.js file
 * This is a request to the old server
 * @param {Object} body
 * @returns a block of text
 */
const POSTRequestTextResponse = (body) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json, text/plain, text/html");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: body,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };
    return fetch(testServer, requestOptions)
      .then((response) => {
        response.text().then((text) => {
          if (text[0] == "{") {
            reject("Logged Out");
          } else {
            resolve(text);
            return text;
          }
        });
      }) // console.error(response.status) === 200
      .then((result) => {
        result;
      })
      .catch((error) => {
        console.error("POSTRequestTextResponse error", error);
        reject("Error with the call");
      });
  });
};

/**
 * This is a request to the old server
 * @param {Object} body
 * @returns a JSON object
 */
const POSTRequestJsonResponse = (body) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json, text/plain, text/html");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: body,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };
    return fetch(testServer, requestOptions)
      .then((response) => {
        response.json().then((object) => {
          if (object.usermessage == "LOGGED_OUT") {
            reject("Logged Out");
          } else {
            resolve(object);
            //return object
          }
        }).catch((error)=>{
          console.error('Error while getting the json of the object',error)
          reject(error);
          // setTimeout(()=>{
          //    POSTRequestJsonResponse(body).then((res)=>{
          //     resolve(res)
          //    })
          // },1000)
        });
      }) // console.error(response.status) === 200
      .then((result) => {
        result;
      })
      .catch((error) => {
        console.error("POSTRequestJsonResponse error", error);
        reject("Error with the call");
      });
  });
};

// Returns a file, might be specialized
/**
 * This is a request to the old server
 * This might be specialized request
 * @param {Object} body
 * @returns a file
 */
const POSTFileResponse = (body) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json, text/plain, text/html");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: body,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };
    return fetch(testServer, requestOptions)
      .then((response) => {
        response.text().then((text) => {
          if (text[0] == "{") {
            reject("Logged Out");
          } else {
            let filename = text.split(";");
            filename = filename[1].split("\n");
            fetch(testServer + "/" + filename[0]).then((response) => {
              response.text().then((text) => {
                resolve(text);
              });
            });
          }
        });
      }) // console.error(response.status) === 200
      .then((result) => {
        result;
      })
      .catch((error) => {
        console.error("POSTFileResponse error", error);
        reject("Error with the call");
      });
  });
};

/**
 * This is not used at the moment
 * @param {*} bodyValues
 * @returns
 */
const POSTRequestNoResponse = (bodyValues) => {
  const myHeaders = new Headers();
  myHeaders.set(
    "Content-Type",
    "application/x-www-form-urlencoded;charset=UTF-8"
  );
  myHeaders.set("accept", "application/json, text/plain, text/html");
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "reload",
    // credentials: "include",
    headers: myHeaders,
    body: bodyValues,
    redirect: "follow",
    referrer: origin,
    referrerPolicy: "no-referrer-when-downgrade",
    credentials: "include",
    origin: origin,
  };

  return fetch(testServer, requestOptions)
    .then((response) => {
      return response;
    }) // console.error(response.status) === 200
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error("POSTRequestNoResponse error", error);
    });
};

//// enroll learners ////
const trustedAPI = (queryParams) => {
  const myHeaders = new Headers();
  myHeaders.set(
    "Content-Type",
    "application/x-www-form-urlencoded;charset=UTF-8"
  );

  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "reload",
    headers: myHeaders,
    body: queryParams,
    redirect: "follow",
    referrer: origin,
    referrerPolicy: "no-referrer-when-downgrade",
    credentials: "include",
    origin: origin,
  };

  return fetch(testServer, requestOptions)
    .then((response) => response)
    .then((result) => result)
    .catch((error) => {
      console.error("enrollment/unenrollment error", error);
    });
};

/**
 * Gets a list of all the available courses from the sis-teacher-dashbaord api
 * More information on this API request can be found on the Database Diagrams on draw.io/google docs
 * This is a request #279
 * @returns
 */
const coursesAPI = () => {
  const myHeaders = new Headers();
  myHeaders.set(
    "Content-Type",
    "application/x-www-form-urlencoded;charset=UTF-8"
  );
  myHeaders.set("accept", "application/json, text/plain");

  const urlencoded = new URLSearchParams();
  urlencoded.append("request", "279");
  urlencoded.append("authorization", testAuthorizationCode);
  urlencoded.append("update2021", "yes");
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "reload",
    // credentials: "include",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
    referrer: origin,
    referrerPolicy: "no-referrer-when-downgrade",
    credentials: "include",
    origin: origin,
  };

  return fetch(testServer, requestOptions)
    .then((response) => {
      return response.json();
    }) // console.error(response.status) === 200
    .then((result) => result)
    .catch((error) => {
      console.error("coursesApi error", error);
    });
};

const callLogInAPI = (request, loginInfo) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json");
    const urlencoded = new URLSearchParams();
    urlencoded.append("request", request);
    urlencoded.append("loginType", "1");
    urlencoded.append("Username", loginInfo.username);
    urlencoded.append("Password", loginInfo.password);
    //var urlencoded="Request=1249&UserID=admin14&"
    //var urlencoded="Request=1243&"

    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };
    return (
      fetch(testServer, requestOptions)
        .then((response) => {
          response.text().then(async (responseText) => {
            let splitText = responseText.split("\n");
            switch (splitText.length) {
              case 111:
                resolve("Already Logged In");
                break;
              case 83:
                await store.dispatch('setLoginID',loginInfo.username)
                resolve("Sucess");
                break;
              case 91:
                var substring = splitText[17].substring(
                  splitText[17].lastIndexOf("=") + 3,
                  splitText[17].lastIndexOf(".") + 1
                );
                resolve(substring);
                break;
            }
          });
        }) // console.error(response.status) === 200
        // .then((result) => {
        //   result;
        // })
        .catch((error) => {
          console.error("callLoginAPI error", error);
          reject("Error with the call");
        })
    );
  });
};

/* 
  https://swiftapi.swiftlearning.com/api/v1/get_all_servers not needed atm
  header Authorization=token
*/

/**
 * gemsCallLogin takes login info and makes a request to the API for an auth token
 * https://swiftapi.swiftlearning.com/api/v1/api_sessions
 * @param {Object} loginInfo contains a String username and String password
 * @returns Promise
 */
const gemsCallLogin = (loginInfo) => {
  // const sessionsGEMS = "https://swiftapi.swiftlearning.com/api/v1/api_sessions";
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json");
    const urlencoded = new URLSearchParams();
    urlencoded.append("Username", loginInfo.username);
    urlencoded.append("Password", loginInfo.password);
    urlencoded.append("server", "");
    urlencoded.append("gemskey", "");

    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };

    return (
      fetch(gemsAPI + "api_sessions", requestOptions)
        .then((response) => {
          response.json().then((object) => {
            switch (object.status) {
              case 200:
                gemsCheckAuthToken(object.auth_token).then((result) => {
                  let loginObj={
                    auth:object.auth_token,
                    status:result
                  }
                  resolve(loginObj);
                });
                break;
              case 404:
                resolve(object.errorMsg);
                reject("Incorrect login info");
                break;
            }
          });
        })
        // .then((result) => {
        //   result;
        // })
        .catch((error) => {
          console.error("gemsCallLogin error", error);
          reject("Error with the call");
        })
    );
  });
};


/**
 * gemsCallLogout takes the auth token and logs the user out
 * https://swiftapi.swiftlearning.com/api/v1/api_sessions
 * @param {String} authToken contains a String with the auth token to log the user out
 * @returns Promise
 */
 const gemsCallLogout = (authToken) => {
  // const sessionsGEMS = "https://swiftapi.swiftlearning.com/api/v1/api_sessions";
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json");
    const urlencoded = new URLSearchParams();
    urlencoded.append("auth_token", authToken);

    const requestOptions = {
      method: "DELETE",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };

    return (
      fetch(gemsAPI + "api_sessions", requestOptions)
        .then((response) => {
          response.json().then((object) => {
            switch (object.status) {
              case 204:
                resolve(true);
                break;
              case 404:
                resolve(false);
                reject("Incorrect logout info");
                break;
            }
          });
        })
        // .then((result) => {
        //   result;
        // })
        .catch((error) => {
          console.error("gemsCallLogout error", error);
          reject("Error with the call");
        })
    );
  });
};

/**
 * gemsCheckAuthToken verifies that auth token is legitimate and makes API request for user information
 * https://swiftapi.swiftlearning.com/api/v1/check_auth_token
 * @param {String} token generated from gemsCallLogin
 * @returns Promise
 */
const gemsCheckAuthToken = (token) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json");
    const urlencoded = new URLSearchParams();
    urlencoded.append("auth_token", token);

    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };

    return (
      fetch(gemsAPI + "check_auth_token", requestOptions)
        .then((response) => {
          response.json().then((object) => {
            if (object.authorized && object.is_gems_admin==true) {
              gemsGetAllServers(token).then((result)=>{
                if(result.status==200 && result.registered_servers && result.registered_servers.length>0)
                {
                  if(result.registered_servers.length==1)
                  {
                    var credObj={
                      authToken:token,
                      serverID:result.registered_servers[0].server_id
                    }

                    if(credObj.serverID==null)
                    {
                      credObj.serverID="197";
                    }

                    // gemsGetCredentials;
                    gemsGetCredentials(credObj).then((result) => {

                      resolve(result);
                    });
                  }
                  else{
                    let askObject={
                      servers: result.registered_servers,
                      authToken: token 
                    };
                    resolve(askObject);
                  }
                }
                else{
                  let returnObj={
                    canLogin:false,
                    errorMessage:"Error finding login servers. Please try again"
                  }
                  resolve(returnObj);
                }
              });
            }
            else{
              let returnObj={
                canLogin:false,
                errorMessage:"You are not recognized as an admin. Please contact someone at SWIFT if you believe this is an error"
              }
              resolve(returnObj);
            }
          });
        })
        // .then((result) => {
        //   result;
        // })
        .catch((error) => {
          console.error("gemsCheckAuthToken error", error);
          reject("Error with the call");
        })
    );
  });
};


/**
 * gemsSelectedServer is passed the server to be logged into then gets the credentials and sets the server url
 * @param {Object} serverLogin contains the server info and auth token generated on the login page
 * @returns Promise
 */
 const gemsSelectedServer = (serverLogin) => {
  return new Promise((resolve)=>{

    testServer="https://"+serverLogin.serverInfo.server_url;

    var credObj={
      authToken:serverLogin.authToken,
      serverID:serverLogin.serverInfo.server_id.toString()
    }

    if(credObj.serverID==null)
    {
      credObj.serverID="197";
    }

    // gemsGetCredentials;
    gemsGetCredentials(credObj).then((result) => {
      resolve(result);
    });
    
    
    // resolve(true);
  })
};

/**
 * gemsGetCredentials gets user login information for a specific server
 * --- currently hard coded to server_id 197 ---
 * https://swiftapi.swiftlearning.com/api/v1/get_course_credentials
 * @param {String} token generated from gemsCallLogin
 * @returns Promise
 */
const gemsGetCredentials = (credObj) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json");
    myHeaders.set("Authorization", `Token token="${credObj.authToken}"`);
    const urlencoded = new URLSearchParams();
    urlencoded.append("server_id", credObj.serverID);

    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };

    return (
      fetch(gemsAPI + "get_course_credentials", requestOptions)
        .then((response) => {
          response.json().then((object) => {
            callLogInAPI(
              APICalls.HTTP_POST_LOGIN.toString(),
              object.credentials
            ).then((result) => {
              resolve(result);
            });
          });
        })
        // .then((result) => {
        //   result;
        // })
        .catch((error) => {
          console.error("gems get Credentials error", error);
          reject("Error with the call");
        })
    );
  });
};


/**
 * gemsGetCredentials gets user login information for a specific server
 * --- currently hard coded to server_id 197 ---
 * https://swiftapi.swiftlearning.com/api/v1/get_all_servers
 * @param {String} token generated from gemsCallLogin
 * @returns Promise
 */
 const gemsGetAllServers = (token) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json");
    myHeaders.set("Authorization", `Token token="${token}"`);
    // const urlencoded = new URLSearchParams();
    // urlencoded.append("server_id", "197");

    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      // body: urlencoded,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };

    return (
      fetch(gemsAPI + "get_all_servers", requestOptions)
        .then((response) => {
          response.json().then((object) => {
            resolve(object);
          });
        })
        // .then((result) => {
        //   result;
        // })
        .catch((error) => {
          console.error("get_all_servers error", error);
          reject("Error with the call");
        })
    );
  });
};


// Basically a test API to see if stuff works
// Does basically the same stuff as the above
// Just has extra console logs for debugging
const testPOSTTextResponse = (body) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json, text/plain, text/html");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: body,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };
    return fetch(testServer, requestOptions)
      .then((response) => {
        response.text().then((text) => {
          if (text[0] == "{") {
            reject("Logged Out");
          } else {
            resolve(text);
            return text;
          }
        });
      }) // console.error(response.status) === 200
      .then((result) => {
        result;
      })
      .catch((error) => {
        console.error("testPOSTTextResponse error", error);
        reject("Error with the call");
      });
  });
};


const showCertificate = (data) => {
  return new Promise((resolve) => {
  const myHeaders = new Headers();
  myHeaders.set("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
  const resultsJSON = {
    "ContentID": data.contentID,
    "PartnerID": "8",
    "issued": data.issued,
    "expires": data.expires,
    "fullname": encodeURIComponent( data.fullname ),
    "course": encodeURIComponent( data.course ),
    "credentials": encodeURIComponent( data.credentials ),
    "certnum": data.certnum,
    "score": data.score
  }
  const encoded = "data=" + JSON.stringify( resultsJSON ) + "&";
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: myHeaders,
    body: encoded,
    redirect: "follow",
    referrer: origin,
    referrerPolicy: "no-referrer-when-downgrade",
    // credentials: "include",//might be brought back when it is done in full but for now we want to keep it as is
    origin: origin
  };
  return fetch("https://swiftlearning.com/dashboard/php/Gemini_Certificate.php", requestOptions)
    .then((response) => response.arrayBuffer())
    .then((res) => {
      const file = new Blob([res], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      resolve(true);
    })
    .catch((error) => {
      console.error("showCert error", error);
      resolve(false);
    });
});
};


export {
  APICalls,
  testAuthorizationCode,
  trustedAPI,
  coursesAPI,
  callLogInAPI,
  POSTRequestTextResponse,
  POSTFileResponse,
  POSTRequestNoResponse,
  POSTRequestJsonResponse,
  testPOSTTextResponse,
  gemsCallLogin,
  gemsSelectedServer,
  gemsCallLogout,
  showCertificate,
};
