<template>
  <div class="modal-backdrop">
    <div class="modal modal--quick-data">
      <header class="modal-header">
        <h1 name="header">
          Your Metrics
        </h1>
      </header>

      <section class="modal-body">
        <h1 class="graph-title">Graph</h1>
        <!-- <Graph></Graph> -->
      </section>

      <footer class="modal-footer">
        <button type="button" class="btn-close" @click="close">
          Close
        </button>
      </footer>
    </div>
  </div>
</template>

<script>

// import Graph from '../Graph';

export default {
  name: "GraphModal",
  // components: {
  //   Graph
  // },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 600px;
  position: relative;
  border-radius: 10%;

  &--quick-data {
    height: 550px;
    width: 660px;
  }

  &--confirmation {
    height: fit-content;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100%;
  }

  &-header {
    background-color: #4aae9b;
    border-bottom: 1px solid #eeeeee;
    color: white;
    justify-content: center;
    margin-bottom: 5px;
    padding: 0;
  }

  &-footer {
    background-color: #4aae9b;
    justify-content: space-evenly;
  }
}

.graph-title {
  text-align: center;
}

.btn-close {
background-color: #cccccc;
border-color: #8c8c8c;
color: black;
}

</style>