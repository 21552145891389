<template>
  <div id="LoginPage" class="loginDiv">
    <br />
    <img src="../assets/SWIFT LMS.svg" alt="SWIFT LMS" />
    <br />
    <h6 v-show="!secondaryError">Log in to your SWIFT Learning Account</h6>
    <!-- <Translator /> -->
    <!-- <ChangeLanguage></ChangeLanguage> -->
    <div v-show="!loginIssue && !secondaryError">

      <Spinner2 v-if="loading" class="login-spinner" />

      <!-- <p>Please enter your username and password</p> -->
      <p v-if="loginMsg" class="loginError" data-cy="loginErrMsg">
        {{ loginMsgStr }}
      </p>
      <form>
        <b-form-input
          required
          v-model="username"
          type="text"
          placeholder="Email"
          :state="usernameState"
          class="input-field username"
          data-cy="input-field-username"
        />

        <b-form-input
          required
          v-model="password"
          v-on:keyup="checkCaps"
          :type="passwordType"
          placeholder="Password"
          :state="passwordState"
          class="input-field password"
          data-cy="input-field-password"
          @keyup.enter="login()"
        />
        <!-- <div class="form-group" v-if="isProd"> 
          <label htmlfor="password"></label>
          <div id="login-captcha" class="mtcaptcha" mtcapwid="login-captcha-6">
            <iframe
              id="login-captcha-iframe-6"
              style="
                display: inline-block;
                width: 400px;
                border-radius: 0.25rem;
                background: transparent;
                height: 65px;
                border: 1px solid #ced4da;
              "
              src="https://service.mtcaptcha.com/mtcv1/client/iframe.html?v=2021-07-21.20.11.45&amp;sitekey=MTPublic-YLOJZeaNh&amp;iframeId=login-captcha-iframe-6&amp;widgetSize=mini&amp;custom=false&amp;widgetInstance=login-captcha&amp;challengeType=standard&amp;theme=basic&amp;lang=en&amp;action=&amp;autoFadeOuterText=true&amp;host=http%3A%2F%2Flocalhost%3A8080&amp;hostname=localhost%3A8080&amp;serviceDomain=service.mtcaptcha.com&amp;textLength=0&amp;lowFrictionInvisible=&amp;enableMouseFlow=false&amp;miniFormWidth=0&amp;miniFormHeight=45"
              title="mtcaptcha"
              sandbox=" allow-forms allow-popups allow-same-origin allow-scripts allow-modals allow-popups-to-escape-sandbox"
              width="100%"
              height="65px"
            ></iframe
            ><input
              type="hidden"
              class="mtcaptcha-verifiedtoken"
              name="mtcaptcha-verifiedtoken"
              id="login-captcha-verifiedtoken-6"
              readonly="readonly"
              value=""
            />
          </div>
        </div> -->
        <div v-if="capsWarning" class="loginWarning" data-cy="caps-warning">
          <p>WARNING: Caps lock on. <br />Your password may be incorrect</p>
        </div>

        <div class="btn-login">
          <b-button pill variant="outline-primary" @click="showHidePass()"
            >{{ hideShow }} Password</b-button
          >&nbsp;

          <b-button
            pill
            variant="primary"
            @click="login()"
            data-cy="login-button"
            >Login</b-button
          >
        </div>
      </form>
    </div>

    <div v-if="loginIssue" id="loginresults" data-cy="login-issue">
      <p>
        There was an issue with the login. <br />This is possibly because you
        have another active session <br />Would you like to Force Login?
        <br />This will end your other sessions
      </p>
      <b-button v-on:click="forceLogin()" data-cy="force-login">Yes</b-button
      >&nbsp;
      <b-button v-on:click="loginIssue = false">No</b-button>
    </div>

    <div v-if="secondaryError" id="loginresults" data-cy="login-issue">
      <p class="loginError">
        {{loginMsgStr}} <br> Please try again or contact us if this continues.
      </p>
      
      <b-button v-on:click="secondaryError=false; loginMsg=false;" data-cy="force-login">Back</b-button
      >
    </div>
      <ServerSelectModal 
      v-show="serversModalVisible"
      :servers="loginServers"
      @close="closeModals"
      @serverSelected="loginServer"
      ref="resetGroups"
    />
  </div>
</template>

<script>
import global from "../util/GlobalFunctions.js";
// import axios from "axios";
import ServerSelectModal from "../components/modals/ServerSelect.vue";
import Spinner2 from "../components/Spinner2.vue";
import {Translator} from "vue-google-translate";
import ChangeLanguage from "../components/modals/ChangeLanguage.vue";
// const url = "https://service.mtcaptcha.com/mtcv1/api/checktoken";

//const captchaKey = process.env.VUE_APP_CAPTCHAKEY;

export default {
  name: "Login",
  mixins: [global],
   components: {
    ServerSelectModal,
    Spinner2,
    Translator,
    ChangeLanguage,
  },
  data() {
    return {
      username: "",
      password: "",
      passwordType: "password",
      capsWarning: false,
      hideShow: "Show",
      loginIssue: false,
      loginMsg: false,
      loginMsgStr: "",
      verifiedtoken: "",
      isProd: false,
      loginServers: [],
      auth: "",
      serversModalVisible:false,
      loginError:"",
      loading:false,
      secondaryError:false,
    };
  },
  computed: {
    usernameState() {
      if (this.username === "") {
        return null;
      } else if (!this.emailIsValid(this.username)) {
        return false;
      } else {
        return true;
      }
    },
    passwordState() {
      if (this.password === "") {
        return null;
      } else {
        return true;
      }
    },
  },
  created: function () {
    if(process.env.NODE_ENV === 'production')
    {
      this.renderCaptchaOnload();
      this.isProd=true;
    }
  },
  methods: {
    // renderCaptchaOnload: function () {
    //   window.mtcaptchaConfig.renderQueue.push("login-captcha");
    // },
    checkCaps: function (event) {
      //This function will check the caps lock for the password feild
      if (event.getModifierState("CapsLock")) {
        this.capsWarning = true;
      } else {
        this.capsWarning = false;
      }
    },
    // reloadCaptcha()
    // {
    //  if(process.env.NODE_ENV === 'production')
    //  {
    //   window.mtcaptcha.resetUI();
    //  }
    // },
    showHidePass() {
      if (this.passwordType == "text") {
        this.passwordType = "password";
        this.hideShow = "Show";
      } else if (this.passwordType == "password") {
        this.passwordType = "text";
        this.hideShow = "Hide";
      }
    },
    
    closeModals(){
      this.serversModalVisible=false;
      this.loading=false;
      // this.reloadCaptcha();
    },
    loginServer(server)
    {
      // this.closeModals();
      this.serversModalVisible=false;
      let serverLogObject={
        authToken: this.auth,
        serverInfo: server
      };
        this.$store.dispatch("gemsSelectedServerLogin", serverLogObject)
              .then((result) => {
              this.loading=false;
             if (result == true) {
              this.LoginSucess();
            } else if (result == false) {
              this.loginIssue = true;
            } 
            else {
              this.loginMsg = true;
                this.loginMsgStr = "You are not  an admin of the "+serverLogObject.serverInfo.server_name+" Server. Please try a different server";
            }
              });
    },
    login() {
      this.loading=true; 
      this.loginMsg = false;
      this.loginMsgStr = "";
      this.secondaryError=false;
    // if(process.env.NODE_ENV === 'production')
    // {
    //     let loginDetails = {
    //         username: this.username,
    //         password: this.password,
    //         token: window.mtcaptcha.getVerifiedToken(),
    //       };
    //   let captchaResponse = {};
    //   axios.get(url, {
    //       params: {
    //         privatekey: process.env.VUE_APP_CAPTCHAKEY,
    //         token: window.mtcaptcha.getVerifiedToken(),
    //       },
    //     })
    //     .then((response) => {
    //       captchaResponse = response.data;
    //     })
    //     .then(() => {
    //       // timeout refresh on incorrect creds to reset captcha token //
    //       if (captchaResponse.success === true) {
    //       this.$store.dispatch("gemsLogin", loginDetails).then((result) => {
    //     if(result.authToken)
    //     {
    //       this.loginServers=result.servers;
    //       this.auth=result.authToken;
    //       this.serversModalVisible=true;
    //     }
    //     else if (result == true) {
    //       this.LoginSucess();
    //     } else if (result == false) {
    //       this.loginIssue = true;
    //       this.loading=false;
    //     } 
    //     else {
    //       this.loginMsg = true;
    //       if( result.canLogin==false)
    //       {
    //        this.loginMsgStr=result.errorMessage;
    //       } 
    //       else{
    //         this.loginMsgStr = result;
    //       }
    //       this.loading=false;
    //       // setTimeout(() => {
    //       //   location.reload();
    //       // }, 2000);
    //   this.reloadCaptcha();

    //     }
    //     });
    //       } else {
    //         // failsafe refresh, as incorrect creds should do so //
    //         // setTimeout(() => {
    //         //   location.reload();
    //         // }, 2000);
            
    //         //? Sometimes after saving and loading on the development server this would always get hit and not reload the captcha. 
    //         //? It seems to be an issue with the actual mtcaptcha and not on our side 
    //         this.loading=false;
    //         this.loginMsg = true;
    //         this.loginMsgStr = "something went wrong - please retry";
    //         this.reloadCaptcha();
    //       }
    //     });
    // }
    // else{
    let loginDetails = {
        username: this.username,
        password: this.password,
      };
      this.$store.dispatch("gemsLogin", loginDetails).then((result) => {
        if(result.authToken)
        {
          this.loginServers=result.servers;
          this.auth=result.authToken;
          this.serversModalVisible=true;
        }
        else if (result == true) {
          this.LoginSucess();
        } else if (result == false) {
          this.loginIssue = true;
          this.loading=false;
        } 
        else {
          this.loginMsg = true;
          if( result.canLogin==false)
          {
           this.loginMsgStr=result.errorMessage;
          } 
          else{
            this.loginMsgStr = result;
          }
          this.loading=false;

        }
      });
    // }
      //  let loginDetails = {
      //   username: this.username,
      //   password: this.password,
      // };
      // console.log('Calling the login api the second time')
      // this.$store
      // .dispatch("callLogInAPI", loginDetails)
      // .then((result) => {
      //    if (result == true) {
      //             this.LoginSucess();
      //           } else if (result == false) {
      //             this.loginIssue = true;
      //           } else {
      //             this.loginMsg = true;
      //             this.loginMsgStr = result;
      //             // setTimeout(() => {
      //             //   location.reload();
      //             // }, 2000);
      //           }
      //         });
    },

    forceLogin() {
      let loginDetails = {
        username: this.username,
        password: this.password,
      };
      this.$store.dispatch("callForceLoginAPI", loginDetails).then((ret) => {
        if(ret!='Sucess')
        {
          this.loginIssue=false;
          this.loginMsg = true;
          this.secondaryError=true;
          if(ret=='Already Logged In')
          {
            this.loginMsgStr = "We where not able to end your previous session. Please try again or contact us if you continue having this issue";
          }
          else{
            this.loginMsgStr = ret; 
          }

        }
        else{
          this.LoginSucess();
        }
      });
    },

    LoginSucess() {
      this.loading=false;
      this.$store.commit("setAdminEmail", this.username);
      this.username = ""; //return the values of the username and password to null
      this.password = "";
      this.$store.commit("setLoggedIn", true);
      this.$store.dispatch("initializeValues"); //Initialize the values needed for the rest of the application
      this.$router.push({ name: "Dashboard" }); //go to the Course reports page we can change this later when we have more pages done
    },
  },
};
</script>

<style scoped>
@import "./styles/Login.scss";
</style>
