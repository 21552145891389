<template>
  <article>
    <header class="tabs" :style="cssVars">
      <ul>
        <li v-for="(tab, index) in tabs" :key="index">
          <div
            class="nav-item"
            :class="{ 'is-active': tab.isActive }"
            @click="selectTab(tab)"
          >
            {{ tab.name }}
          </div>
        </li>
      </ul>
    </header>
    <section class="tabs-details">
      <slot></slot>
    </section>
  </article>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
      width: 100, // Used for passing js objects into css as variables
    };
  },

  computed: {
    // css variables to be passed into header props
    cssVars() {
      return {
        // Dynamic width styling of number of tabs
        "--width": this.width / this.tabs.length + "%",
      };
    },
  },

  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name === selectedTab.name;
      });
    },
  },
  created() {
    this.tabs = this.$children;
  },
};
</script>

<style lang="scss" scoped>
$blue: rgb(0, 69, 185);
$orange-dark: rgb(255, 126, 0);
$orange-light: rgb(251, 143, 6);
$burgundy: rgb(159, 55, 69);
$black: rgb(27, 29, 40);
$red: rgb(244, 67, 54);
$green: rgb(126, 159, 62);
$white: rgb(240, 241, 238);

.tabs {
  margin: 0;
  // font-size: 20px;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  // height: 100%;
  border-radius: 10px 10px 0 0;
}

.tabs-details {
  margin: 10px 0px 10px 0px;
  height: 100%;
  overflow:auto;
}
ul {
  display: flex;
  padding: 0;
  list-style: none;
  flex-direction: row;
  justify-content: space-evenly;
  // height: 40px;
  margin: 0;
  background-image: linear-gradient($orange-light, rgb(251, 200, 134));
  border-radius: 10px 10px 0 0;

  li {
    width: var(--width);
    text-align: center;
    // height: 40px;
  }

  .nav-item {
    cursor: pointer;
    // height: 40px;

    &:hover {
      color: #ffffff;
    }
    &.is-active {
      background-image: radial-gradient(at 50% 100%, $orange-light, white);
      border-radius: 10px 10px 0 0;
    }
  }
}
</style>
