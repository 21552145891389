<template>
  <div class="modal-backdrop">
    <Confirmation
      v-show="confirmationModalVisible"
      @confirm="updateUser"
      @cancel="cancel"
      :message="!addingUser?'Are you sure you want to make the following changes?':'Are you sure you would like to add this user?'"
      :form="form"
      :modalTitle="!addingUser?'Confirm User Changes':'Confirm New User'" 
      :loading="confirmationLoading"
      :groups="groups"
    ></Confirmation>
    <!-- groupRegisteryVisible is set to true in the openGroups function -->
    <!-- openGroups is called upon clicking "Add User" button after filling out the form-->
    <!-- the :checked prop is assigned a groups object, populated with the groups available-->
    <!-- the :loginID prop is assigned the loginID that was filled in the form -->
    <RegisterToGroup
      v-show="groupRegisteryVisible"
      :checked="groups"
      :loginID="form.loginID"
      :selectedGroup="groupSelected"
      :selectedGroupID="groupSelectedID"
      @userGroups="selectedGroups"
      @cancel="cancel"
    >
    </RegisterToGroup>
    <CoursesModal
      v-show="coursesModalVisible"
      @close="coursesModalVisible=false;addingUser?confirmationModalVisible=true:confirmationModalVisible=false;courseGroupID=null;"
      @apply="getCourseInfo"
      ref="coursesModal"
    />
      <Industries 
      v-if="industrySelectionVisible"
      :industries="industries"
      @close="closeIndustry"
      @enroll="setIndustryObject"
      ></Industries>
        <ErrorMessageModal 
        v-show="showErrorMessage"
        :errorTitle="errorMsgTitle"
        :errorBody="errorMsgBody"
        @close="closeErrorModal"
        />
    <div
      class="modal"
      v-show="!confirmationModalVisible && !groupRegisteryVisible && !industrySelectionVisible && !showErrorMessage && !coursesModalVisible"
    >
      <header class="modal-header">
        <h2>{{ form.method }} User</h2>
      </header>
      <body
        class="modal-body"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        <div class="register-form-card">
          <p v-if="errors.length" class="error-msg">
            {{ errors }}
          </p>
          <form class="register-form">
            <div class="register-form-row">
              <div class="register-form-row-elem">
                <label class="register-form-label" v-bind:class="{'form-error':firstNameState==false}" for="First Name"
                  >First Name</label
                >
                <input
                  class="register-form-input"
                  name="First Name"
                  v-model="form.firstName"
                />
              </div>

              <div class="register-form-row-elem">
                <label class="register-form-label" v-bind:class="{'form-error':lastNameState==false}" for="Last Name"
                  >Last Name</label
                >
                <input
                  class="register-form-input"
                  name="Last Name"
                  v-model="form.lastName"
                  required
                />
              </div>
            </div>
            <label class="register-form-wide-label" v-bind:class="{'form-error':emailState==false}" for="Email">Email</label>
            <input
              class="register-form-wide"
              type="email"
              name="Email"
              v-model="form.email"
            />
            <div class="register-form-row" >
              <div class="register-form-row-elem" v-if="adminLevel.adminLevelInt==0">
                <label class="register-form-label"  v-bind:class="{'form-error':loginIDState==false}" for="Login ID">
                  Login ID (Optional)
                </label>
                <input
                  class="register-form-input"
                  name="Login ID"
                  v-model="form.loginID"
                />
              </div>
              
            </div>
            <div class="register-form-row" >
              <div class="register-form-row-elem">
              <!-- <div class="register-form-row-elem" v-if="form.method!='Add'"> -->
                <label class="register-form-label"  v-bind:class="{'form-error':passwordState==false}"  for="Password">
                  Password<span  v-if="addingUser"> (Optional)</span><span  v-if="!addingUser"> (Optional)</span>
                </label>
                <input
                  class="register-form-input"
                  name="Password"
                  type="password"
                  v-model="form.password"
                />
              </div>
              <br>
              <div class="register-form-row-elem" v-if="form.password.length>0">
                <label class="register-form-label"  v-bind:class="{'form-error':passwordConfirmState==false}"  for="confirmPassword">
                  Confirm Password
                </label>
                <input
                  class="register-form-input"
                  name="confirmPassword"
                  type="password"
                  v-model="confirmPassword"
                />
              </div>
              <br>
            </div>
            <div class="password-state">
              {{passwordErrState}}
            </div>
            
              <div v-if="adminGroups && singleGroup">
              <label class="register-form-enroll-label" for="saharaEnroll">
                <input
                class="register-form-enroll-input"
                  name="userIsAdmin"
                  type="checkbox"
                  v-model="userIsAdmin"
                />
                  Administrator
                </label>
              </div>

              <div>
              <label class="register-form-enroll-label" for="saharaEnroll">
                <input
                class="register-form-enroll-input"
                  name="userEnabled"
                  type="checkbox"
                  v-model="form.enabled"
                />
                  Enabled
                </label>
              </div>

            <div v-if="industries && industries.length==1 && addingUser">
              <label class="register-form-enroll-label" for="saharaEnroll">
                <input
                class="register-form-enroll-input"
                  name="saharaEnroll"
                  type="checkbox"
                  v-model="enrollSahara"
                />
                  Enroll in SAHARA
                </label> 
              </div>
          </form>
          <div v-if="!addingUser" class="edit-buttons"> 
            <button class="btn-blue" v-if="!singleGroup" @click="openGroups()">Edit Groups</button>
            <button class="btn-blue" @click="openCourses()">Enroll in Courses</button>
          </div>
        </div>
      </body>

      <footer class="modal-footer">

        <button type="button" class="btn-close" @click="close">
          Close
        </button>
        <button v-if="addingUser" type="button" :disabled="!formValid" v-bind:class="{'btn-disabled':!formValid,'btn-apply':formValid}" @click="openGroups()">
          {{ form.method }} User
        </button>
        <button v-if="!addingUser" type="button" :disabled="!dataChanged" v-bind:class="{'btn-disabled':!dataChanged,'btn-apply':dataChanged}" @click="confirmationModalVisible=true">
          {{ form.method }} User 
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import Confirmation from "./Confirmation.vue";
import RegisterToGroup from "./RegisterToGroup.vue";
import Industries from "./Industries.vue"
import global from "../../util/GlobalFunctions.js";
import ErrorMessageModal from "./ErrorMessage.vue"
import {mapState} from 'vuex'
import CoursesModal from "./Courses.vue";

export default {
  components: { Confirmation, RegisterToGroup, Industries, ErrorMessageModal, CoursesModal },
  mixins: [global],
  name: "RegiserUserModal",
  data() {
    return {
      errors: "",
      confirmationModalVisible: false,
      groupRegisteryVisible: false,
      industrySelectionVisible:false,
      groups: {},
      enrollSahara:true,
      confirmationLoading:false,
      industriesSelected:{},
      showErrorMessage:false,
      errorMsgTitle:"",
      errorMsgBody:"",
      userIsAdmin:false,
      confirmPassword:"",
      passwordErr:"",
      enrollmentErr:false,
      coursesModalVisible:false,
      courseGroupID: null,
      enrollingCourses:'',
      dataChanged: false,
    };
  },
  props: ['form','industries','isAdmin','userID','groupSelected','groupSelectedID'],
  //   form: Object,
  //   industries: Array,
  // },
  watch: {
    formForWatcher(valNew,valOld)
    {
      let valN=JSON.parse(valNew);
      let valO=JSON.parse(valOld);
      if(!this.addingUser) //When we are editing we want to see when changes are made to show the apply changes button
      {
        let variablesChanged=0;
      if(valN.firstName!=valO.firstName)
      {
        variablesChanged++;
      }

      if(valN.lastName!=valO.lastName)
      {
        variablesChanged++;
      }

      if(valN.email!=valO.email)
      {
        variablesChanged++;
      }
      if(valN.loginID!=valO.loginID)
      {
        variablesChanged++;
      }

      if(valN.enabled!=valO.enabled)
      {
        variablesChanged++;
      }

      if(valN.password!=valO.password)
      {
        variablesChanged++;
      }

      if(variablesChanged==1)//When a new form is passed all will change so we only want to set it when one is changed
      {
        this.dataChanged=true;
      }
      else{
        this.dataChanged=false;
      }
    }
    },
    isAdmin:function(val)
    {
      this.userIsAdmin=val;
    },
    firstName: function(val) {
      this.form.firstName = val;
    },

    lastName: function(val) {
      this.form.lastName = val;
    },

    email: function(val) {
      this.form.email = val;
    },

    loginID: function(val) {
      this.form.loginID = val;
    },
    enabled: function(val)
    {
      this.form.enabled=val;
    },
    passwordState: function(val)
    {
      if(val==true && this.form.password=="")
      {
        this.confirmPassword=""; //When the first password is erased also remove the confirmation password
      }
    },
  },
  computed:{
    ...mapState({
      adminLevel: (state) => state.adminLevel,
      adminGroups:(state)=> state.groups,
      courses:(state)=> state.courses,
    }),
    addingUser(){
      if(this.form.method=="Add")
      {
        return true;
      }
      else{
        return false;
      }
    },
    formForWatcher(){
      return JSON.stringify(this.form);
    },
    formValid(){
      if(this.form.password!="" && this.confirmPassword!=this.form.password)
      {
        return false
      }

      if(this.addingUser)
      {
        if(this.emailState && this.firstNameState && this.lastNameState)
        {
          return true;
        }
        else{
          return false;
        }
      }
      else{
        if(this.emailState && this.passwordState && this.firstNameState && this.lastNameState)
        // if(this.emailState && this.passwordState && this.firstNameState && this.lastNameState && this.loginIDState)
        {
          return true;
        }
        else{
          return false;
        }
      }

    },
    singleGroup(){
      if(Object.keys(this.adminGroups).length!=1)
      {
       return false;
      }
      else{
        return true;
      }
    },
    emailState() {
      if (this.form.email === "") {
        return null;
      } else if (!this.emailIsValid(this.form.email)) {
        return false;
      } else {
        return true;
      }
    },
    passwordState() {
      if (this.form.password === "") {
        return true;
      } else if(this.form.password.length<6) {
        return false;
      }
      else if(this.form.password.length>=6)
      {
        return true;
      }
      else{
        return null;
      }
    },
    passwordConfirmState() {
      if ( (this.form.password||this.confirmPassword) && (this.form.password.length<6 || this.confirmPassword.length<6) ) {
        return true;
      } else if( this.confirmPassword.length>=6 && this.form.password!=this.confirmPassword || this.confirmPassword.length<this.form.password.length ) {
        return false;
      }
      else{
        return true;
      }
    },

    passwordErrState()
    {
        // {
        //   return '';
        // }
       /* else */if(this.form.password.length>0 && this.form.password.length<6)
        {
          return 'Password must be 6 or more characters long'
        }
        else if(this.form.password.length>=6 && this.form.password!=this.confirmPassword ||this.confirmPassword.length<this.form.password.length)
        {
          return 'Passwords do not match';
        }
        else 
        {
          return '';
        }
    },

    firstNameState() {
      if (this.form.firstName === "") {
        return null;
      } else if (!this.nameIsValid(this.form.firstName)) {
        return false;
      } else {
        return true;
      }
    },
      lastNameState() {
      if (this.form.lastName === "") {
        return null;
      } else if (!this.nameIsValid(this.form.lastName)) {
        return false;
      } else {
        return true;
      }
    },
    loginIDState() {
      if (this.form.loginID === "") {
        return null;
      } else {
        return true;
      }
    },
  },
  methods: {
    getCourseInfo(courses) {
      this.dataChanged=true;
      this.enrollingCourses='';
      if(courses[0] && courses[0].availableHours)
      {
        courses.forEach((course)=>{
          if(course.registered!=course.registered1)
          {
            this.enrollingCourses+=(course.registered==true && course.registered1==false)?"true;":"false;";
            this.enrollingCourses+="c"+course.courseID.toString()+";Inf;Inf;"
          }
      });
      }
      else{
        courses.forEach((course)=>{
        this.enrollingCourses+="true;c"+course.courseID.toString()+";Inf;Inf;"
      });
      }
      // this.coursesSelected = courses;
      this.coursesModalVisible = false;
      if(this.addingUser)
      {
        this.confirmationModalVisible=true;
      }
      // this.submitForm();
    },
    closeErrorModal()
    {
      this.showErrorMessage=false;
      this.errorMsgBody="";
      this.errorMsgTitle="";
      if(this.enrollmentErr)
      {
        this.dataChanged=false;
        this.$emit('update');
      }
    },
    closeIndustry(){
      this.industrySelectionVisible=false;
      this.industriesSelected={};
      //Remove to return to the user information page when pressing cancel
      this.confirmationModalVisible=true;
    },
    setIndustryObject(enrollIndustries){
      this.industriesSelected=enrollIndustries;
      this.industrySelectionVisible=false;
      this.confirmationModalVisible=true;
    },
    openGroups() {
      // let r = (Math.random() + 1).toString(36).substring(6);
      this.$store
        .dispatch("getUserGroups", this.userID)
        .then((groups) => {
          this.groups = groups;
          if(this.groupSelectedID || this.groupSelectedID==0)//If we are passing in the selected group for a new user we want them to be enrolled
          {
            if(!this.groups[this.groupSelectedID].isMember)groups
            {
              if(this.addingUser)//When it is the first user we want to start them off as having their primary group as the one selected
              {
                this.groups[this.groupSelectedID].isPrimaryGroup=true;
              }
              this.groups[this.groupSelectedID].isMember=true;
              this.groups[this.groupSelectedID].changed=true;
              this.groups[this.groupSelectedID].isLearner=true;
            }

          }
          if(this.singleGroup==false)
          {
            this.groupRegisteryVisible = true;
            this.userIsAdmin=false;
          }
          else{
            // this.groupRegisteryVisible = true;
            let groupNumber= parseInt(Object.keys(this.adminGroups)[0])
            let primaryGroup=false;
            if(this.addingUser)
            {
              primaryGroup=true;
                let groupAdded={
                changed: true,
                isAdmin: this.userIsAdmin,
                isAuthor: false,
                isLearner: true,
                isMember: true,
                isPrimaryGroup:primaryGroup,
                isVerifier: false
            }
              this.groups = { ...this.groups, [groupNumber]: groupAdded };
            }
            else if(!this.addingUser && groups[groupNumber] && groups[groupNumber].isAdmin!=this.userIsAdmin)
            {
              groups[groupNumber].isAdmin=this.userIsAdmin;
              groups[groupNumber].changed=true;
              this.groups = { ...this.groups, [groupNumber]: groups[groupNumber] };
            }

            // this.isAdmin=false;
             if(this.industries && this.industries.length>1 && this.addingUser==true)
              {
                this.industriesSelected={};
                this.industrySelectionVisible=true;
              }
              else{
                  if(this.addingUser)
                  {
                    this.openCourses();
                  }
                  else{
                    this.confirmationModalVisible = true;
                  }
              }
          }
        });
    },
    close() {
      this.userIsAdmin=false;
      this.confirmPassword="";
      this.form.password="";
      this.dataChanged=false;
      this.$emit("close");
    },
    selectedGroups(groups) {
      this.dataChanged=true;
      this.groups = {};
      for (let groupID in groups) {
        if (groups[groupID].wasMember || groups[groupID].isMember) {
        // if (groups[groupID].changed) {
          this.groups = { ...this.groups, [groupID]: groups[groupID] };
        }
      }
      this.groupRegisteryVisible = false;
      if(this.industries && this.industries.length>1 && this.addingUser==true)
      {
        this.industriesSelected={};
        this.industrySelectionVisible=true;
      }
      else{
        if(this.addingUser)
        {
          this.openCourses();
        }
        // else{
        //   // this.confirmationModalVisible = true;
        // }
      }
    },

  async openCourses()
  {
    // this.selectedGroupID=null;
    this.enrollingCourses='';
    if(Object.keys(this.groups).length>0)
    {
      let primary=Object.keys(this.groups).find((groupID)=>
      {
        if(this.groups[groupID].isPrimaryGroup)
        {
          return groupID;
        }
      })
      if(primary)
      {
        this.courseGroupID=primary;
      }
      else if(this.groupSelectedID)
      {
        this.courseGroupID=this.groupSelectedID;
      }
    }
    else if(this.groupSelectedID)
    {
      this.courseGroupID=this.groupSelectedID;
    }
    if(!this.addingUser && this.userID)
    {
      this.$refs.coursesModal.getCoursesForUser(this.userID,this.courseGroupID);
    }
    else{
      this.$refs.coursesModal.getCourseList(this.courseGroupID);
    }

    this.coursesModalVisible = true;
  }, 

    async updateUser() {
      let groupStr = "";
      this.confirmationLoading=true;
      for (let groupID in this.groups) {
        if (this.groups[groupID].changed) {
        // if (this.groups[groupID].wasMember || this.groups[groupID].isMember) {
          //groupID;member;wasMember[;isPrimaryGroup;isAdmin;isAuthor;isLearner;isVerifier;isSME;]
          groupStr += `${groupID};${this.groups[groupID].isMember};${this.groups[groupID].wasMember};${this.groups[groupID].isPrimaryGroup};${this.groups[groupID].isAdmin};false;${this.groups[groupID].isLearner};false;false;`;
        }
      }
      var addedUser= await this.$store.dispatch("updateUser", {
        method: this.form.method,
        userID: this.userID,
        loginID:this.form.loginID,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        password: this.form.password,
        group: groupStr,
        enabled: this.form.enabled,
        seatedContent: this.enrollingCourses,
      });
        this.enrollingCourses='';
      if(addedUser.status=='Error' || !addedUser.loginID)
      {
        this.errorMsgBody=addedUser.errorMsg;
        this.errorMsgTitle=this.form.method+" User Error";
        this.showErrorMessage=true;
        this.confirmationModalVisible=false;
        this.confirmationLoading=false;
        console.error('We had an error with the user enrollment',addedUser)
        return;
      }
      if(this.industries && this.industries.length>0 && this.addingUser && addedUser.loginID)
      {
        var saharaEnrollObj={
          user: addedUser.loginID,
          removingIndustries:"",
          addingIndustries:"",
        };
        if(this.industries && this.industries.length==1 && this.enrollSahara==true)
        {
          saharaEnrollObj.addingIndustries=this.industries[0].industryID.toString();
        }
        else if(this.industriesSelected.adding && this.industriesSelected.adding.length>0)
        {
          saharaEnrollObj.addingIndustries="";
          this.industriesSelected.adding.forEach((industry)=>{
            saharaEnrollObj.addingIndustries+=industry.industryID+";";
          })
        }
        this.$store.dispatch("changeSaharaUserIndustries", saharaEnrollObj)
        .then((response) => {
          this.form.password="";
          this.confirmPassword="";
          if(response.status=="success")
          {
            this.enrollmentErr=false;
            if(response.results && response.results.failures)
            {
              this.enrollmentErr=true;
              this.$store.dispatch('callCourseAPI').then((coursesRet)=>{
                let courses=response.results.failures.split(", ");
                let uniqueCourses=[...new Set(courses)];
                //  let uniqueCourses=["509","510","511","512","513"];//? used for testing it out
                var failedNames="";
                var failedIDs="";
                for(var i=0; i<uniqueCourses.length;i++)
                {
                  let matchingCourse=coursesRet.find(courseInfo=>courseInfo.contentid === `${uniqueCourses[i].trim()}`)
                  if(matchingCourse)
                  {
                    failedNames+=decodeURIComponent(matchingCourse.contentname)+", ";
                  }
                  else{
                    failedIDs+=uniqueCourses[i]+", ";
                  }
                }
                this.errorMsgTitle="Enrollment Error";
                this.errorMsgBody="Failed to enroll in courses with the ";
                if(failedNames!="")
                {
                  this.errorMsgBody+="names: "+failedNames.substring(0,failedNames.length-2);
                }
                if(failedNames!="" && failedIDs!="")
                {
                  this.errorMsgBody+=" and the ";
                }
                if(failedIDs!="")
                {
                  this.errorMsgBody+="IDs: "+failedIDs.substring(0,failedIDs.length-2);
                }
                this.confirmationModalVisible=false;
                this.confirmationLoading=false;
                this.showErrorMessage=true;
                this.confirmationModalVisible=false;
                this.confirmationLoading=false;
              })
            }
            else{
              this.confirmationLoading=false;
              this.confirmationModalVisible = false;
              this.dataChanged=false;
              this.$emit("update");
            }
          }
          else{
            this.errorMsgTitle="Industry Error";
            this.errorMsgBody=response.message;
            this.showErrorMessage=true;
            this.confirmationModalVisible=false;
            this.confirmationLoading=false;
            return;
          }

        })
      }
      else{
        this.confirmationLoading=false;
        this.confirmationModalVisible = false;
        this.form.password="";
        this.confirmPassword="";
        this.dataChanged=false;
        this.$emit("update");
      }
    },

    confirm() {
      if (this.form.firstName == "") {
        this.errors = "Missing first name";
      } else if (this.form.lastName == "") {
        this.errors = "Missing last name";
      } else if (this.form.email == "") {
        this.errors = "Missing email";
      } else if (this.form.password == "") {
        this.errors = "Missing password";
      } else if (this.form.loginID == "") {
        this.errors = "Missing loginID";
      } else if (!this.emailIsValid(this.form.email)) {
        this.errors = "Invalid character in email";
      } else if (!this.nameIsValid(this.form.firstName)) {
        this.errors = "Invalid character in first name";
      } else if (!this.nameIsValid(this.form.lastName)) {
        this.errors = "Invalid character in last name";
      } else {
        this.errors = "";
        this.confirmationModalVisible = true;
      }
    },

    cancel() {
      this.confirmationModalVisible = false;
      this.groupRegisteryVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/BasicModal.scss";
@import "../styles/Buttons.scss";
@import "../styles/RegisterUser.scss";
</style>
