<template>
    <div @click="removeFilter">
        <span>{{filterName}}</span>
    </div>
</template>

<script>
export default {
  name: "Pill",
  props: ["filterName"],

//   data() {

//   },

  methods:{

    removeFilter() {
        this.$emit("removeFilter")
    }
  }
};
</script>

<style scoped></style>