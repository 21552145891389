<template>
  <div class="CourseScore">

    <div v-if="!loading" class="CourseScore-selector">
      <div class="select-course">
      <span>Select A Course:&nbsp;</span>
      <!-- <label for="selectCourse">Select A Course:&nbsp;</label> -->

      <select v-model="selectedCourse" id="selectCourse" class="CourseScore-selector-course">
        <option disabled value="">Please select one</option>
        <option
          v-for="(course, index) in this.$store.state.courseNameAndID"
          :key="index"
          v-bind:value="{
            courseName: course.courseName,
            courseID: course.courseID,
          }"
        >
          {{ course.courseName }}
        </option>
      </select>
    </div>
      <!-- <select v-model="selectedMethod" class="CourseScore-selector-period">
        <option>Before</option>
        <option>Only</option>
        <option>After</option>
      </select>
      <input
        type="number"
        v-model="selectedYear"
        class="CourseScore-selector-year"
      /> -->
        <div class="date-pickers">
      <label for="startDate">Start Date:&nbsp;</label>
      <input type="date" @change="dateChanged()" id="startDate" :min="minDate" :max="endDate==''?maxDate:endDate" v-model="startDate"  name="startDate">
      <span class="spacer"></span>
      <label for="endDate">End Date:&nbsp;</label>
      <span class="input-span"><input type="date" :min="minDate" @change="dateChanged()" :max="maxDate"  v-model="endDate" id="endDate" name="endDate"></span>
      </div>
    </div>
        <div class="CourseScore-data">
      <!-- <Spinner class="CourseScore-data-spinner"></Spinner> -->
      <div v-if="loading" class="CourseScore-data-spinner">
        <Spinner />
      </div>
      <BarLineChart
        v-if="!loading"
        class="chart"
        :style="myStyles"
        :options="chartOptions"
        :render="renderOptions"
        :height="320"
      >
      </BarLineChart>
    </div>
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";
import BarLineChart from "./BarLineChart";

export default {
  name: "CourseScore",
  components: {
    BarLineChart,
    Spinner,
  },

  data() {
    return {
      loading: false,
      renderOptions: {},
      selectedYear: "0",
      selectedMethod: "Before",
      startDate: "",
      minDate:"",
      endDate:"",
      selectedCourse: {
        courseName: "",
        courseID: 0,
      },
      positiveChartColors: {
        pointBordercolor: "rgb(244, 67, 54)",
        pointBackgroundColor: "rgb(244, 67, 54)",
        backgroundColor: "rgb(126, 159, 62)",
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "top",
          labels: {
            fontSize: 18,
            fontColor: "#7a7a7a",
          },
        },
        title: {
          display: true,
          text: "",
          fontSize: 20,
          fontColor: "#7a7a7a",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 10,
                beginAtZero: true,
                fontColor: "#7a7a7a",
                fontSize: 15,
              },
              // afterFit: function(scale) {
              //  scale.width = 100  //<-- set value as you wish
              // },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Score",
                fontColor: "#7a7a7a",
                fontSize: 18,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#7a7a7a",
                fontSize: 15,
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Date",
                fontColor: "#7a7a7a",
                fontSize: 18,
              },
            },
          ],
        },
      },
    };
  },

  computed: {
    myStyles() {
      return {
        position: "relative",
        height: 500,
        borderRadius: "10px",
        backgroundColor: "transparent",
      };
    },
    fontColortest() {
      return this.$store.state.darkmode ? "#ffffff" : "#000000";
    },

  maxDate()
  {
    return new Date().toJSON().slice(0,10);
  },
  },

  methods: {

      dateChanged(){
        if(this.endDate && this.endDate<this.startDate) //Set the dates to be equal when the end date is after
        {
          this.startDate=this.endDate;
        }
        this.render();
      },
    render() {
      this.loading = true;

      this.chartOptions.title.text = this.selectedCourse.courseName;

      this.$store
        .dispatch("getCourseScores", `${this.selectedCourse.courseID}`)
        .then((data) => {
          this.chartData = data;
          this.scoresChart();
          this.loading = false;
        });
    },
    scoresChart() {
      let sorted = this.chartData.sort(
        ({ dateOfExam: a }, { dateOfExam: b }) => b - a
      );
  if(sorted[0] )
  {
    if(this.startDate!='')
    {
      var startDateSeconds=new Date(this.startDate)/1000;
      var startIndex= sorted.findIndex((element)=>{
        return element.dateOfExam<=startDateSeconds;
      })
      sorted=sorted.slice(0,startIndex);

    }
    if(this.endDate!='')
    {
      var endDateSeconds=new Date(this.endDate)/1000;
      var endIndex= sorted.findIndex((element)=>{
        return element.dateOfExam<=endDateSeconds;
      })
      sorted=sorted.slice(endIndex,sorted.legend)
    }
  }
      sorted.forEach((element)=>{
        element.dateOfExam=this.convertUTF(element.dateOfExam);
      })
      const dates = sorted.map((d) => d.dateOfExam).reverse();
      const totals = sorted.map((d) => d.score).reverse();
      const target = sorted.map((d) => d.targetScore).reverse();

      const {
        borderColor,
        pointBorderColor,
        pointBackgroundColor,
        backgroundColor,
      } = this.positiveChartColors;

      this.renderOptions = {
        labels: dates,
        datasets: [
          {
            type: "scatter",
            label: "Mastery Score",
            data: target,
            borderColor: "rgb(244, 67, 54)",
            pointBorderColor: "rgb(244, 67, 54)",
            pointBackgroundColor: "rgb(244, 67, 54)",
            backgroundColor: "rgb(244, 67, 54)",
          },
          {
            type: "bar",
            label: "Achieved Score",
            data: totals,
            borderColor,
            pointBorderColor,
            pointBackgroundColor,
            backgroundColor,
          },
        ],
      };
    },
    convertUTF(date) {
      const dateStr = new Date(date * 1000);
      return `${dateStr.getDate()}/${dateStr.getMonth() +
        1}/${dateStr.getFullYear()}`;
    },
  },

  watch: {
    selectedYear: function() {
      if (this.selectedYear > 2012 && this.selectedYear.length === 4)
        this.render();
    },
    selectedCourse: function() {
      this.render();
    },
    selectedMethod: function() {
      this.render();
    },

  },

  mounted() {
    this.$store.dispatch("getCourseList").then(() => {
      this.loading = true;
      const now = new Date(Date.now());
      this.selectedYear = now.getFullYear();
      const key = this.$store.state.courseNameAndID;
      this.selectedCourse = {
        courseName: key[0].courseName,
        courseID: key[0].courseID,
      };
    });
    this.render();
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/CourseScore.scss";
</style>
